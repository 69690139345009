import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Can } from 'components';
import useMobile from 'hooks/use-mobile';
import useRequest from 'hooks/use-request';

import {
  Arrow,
  ArrowContainer,
  BackText,
  ButtonContainer,
  ButtonsContainer,
  Content,
  Edit,
  PageHeader,
  Select,
  TopButton,
  Wrapper,
} from './ReportDetailPage.style';

function ReportDetailPage(detailInfo) {
  const { emailId, regionalId, periodicity, time } = detailInfo;
  const history = useHistory();
  const isMobile = useMobile();
  const request = useRequest();
  const [edit, setEdit] = useState(false);
  const selectsInformations = [
    {
      fieldName: 'regionalId',
      optionsPath: '/region',
    },
    {
      fieldName: 'userEmails',
      optionsPath: '/user',
    },
  ];

  const periodicityOptions = [
    {
      label: 'Mensal',
      value: 'monthly',
    },
    {
      label: 'Semanal',
      value: 'weekly',
    },
    {
      label: 'Diária',
      value: 'daily',
    },
  ];

  const [selectsOptions, setSelectsOptions] = useState({});

  useEffect(() => {
    if (!isMobile) {
      history.push('/relatorio');
    }
  }, [isMobile]);

  useEffect(() => {
    const getOptions = async (fieldName, optionsPath) => {
      await request.get({
        url: optionsPath,
        onSuccess: (data) => {
          if (fieldName === 'userEmails') {
            const itemsArr = data.map(({ id, email }) => ({
              value: id,
              label: email,
            }));
            setSelectsOptions((prevSelectsOptions) => ({
              ...prevSelectsOptions,
              [fieldName]: itemsArr,
            }));
          } else {
            const itemsArr = data.map(({ id, name }) => ({
              value: id,
              label: name,
            }));
            setSelectsOptions((prevSelectsOptions) => ({
              ...prevSelectsOptions,
              [fieldName]: itemsArr,
            }));
          }
        },
      });
    };

    selectsInformations.forEach((select) => {
      getOptions(select.fieldName, select.optionsPath);
    });
  }, []);

  const CreateConfigutarionSchema = Yup.object().shape({
    emailId: Yup.array().required('Este campo é obrigatório'),
    regionalId: Yup.string().required('Este campo é obrigatório'),
    periodicity: Yup.string().required('Este campo é obrigatório'),
    time: Yup.string().required('Este campo é obrigatório'),
  });

  const initialValues = {
    emailId: emailId ? [emailId] : [],
    regionalId,
    periodicity,
    time,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: CreateConfigutarionSchema,
    validateOnChange: false,
    onSubmit: async () => {},
  });

  return (
    <Wrapper>
      <PageHeader>
        <ArrowContainer onClick={() => history.push('/relatorio')}>
          <Arrow />
          <BackText>Envio automático de relatório</BackText>
        </ArrowContainer>
      </PageHeader>
      <form onSubmit={formik.handleSubmit}>
        <Content>
          {edit && (
            <ButtonContainer>
              <TopButton>Desativar</TopButton>
              <TopButton red>Excluir</TopButton>
            </ButtonContainer>
          )}
          <Select
            mode="multiple"
            label="Email"
            name="emailId"
            absoluteError
            onChange={(e) => formik.setFieldValue('emailId', e)}
            error={formik.errors.emailId}
            value={formik.values.emailId}
            onBlur={formik.handleBlur}
            options={selectsOptions?.userEmails}
            disabled={!edit}
            schema={CreateConfigutarionSchema}
          />
          <Select
            label="Regional"
            name="regionalId"
            absoluteError
            onChange={(e) => formik.setFieldValue('regionalId', e)}
            error={formik.errors.regionalId}
            value={formik.values.regionalId}
            onBlur={formik.handleBlur}
            options={selectsOptions?.regionalId}
            disabled={!edit}
            schema={CreateConfigutarionSchema}
          />
          <Select
            label="Periodicidade"
            name="periodicity"
            absoluteError
            onChange={(e) => formik.setFieldValue('periodicity', e)}
            error={formik.errors.periodicity}
            value={formik.values.periodicity}
            onBlur={formik.handleBlur}
            options={periodicityOptions}
            disabled={!edit}
            schema={CreateConfigutarionSchema}
          />
          <Select
            label="Horário"
            name="time"
            absoluteError
            onChange={(e) => formik.setFieldValue('time', e)}
            error={formik.errors.time}
            value={formik.values.time}
            onBlur={formik.handleBlur}
            disabled={!edit}
            schema={CreateConfigutarionSchema}
          />
        </Content>
        <Can perform={['report:update', 'report.self:update']}>
          <ButtonsContainer>
            <Edit type="button" onClick={() => setEdit(!edit)}>
              {edit ? 'Cancelar' : 'Editar'}
            </Edit>
            {edit && (
              <Edit save type="submit">
                Salvar
              </Edit>
            )}
          </ButtonsContainer>
        </Can>
      </form>
    </Wrapper>
  );
}

export default ReportDetailPage;
