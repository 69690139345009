import { PreffixIcon, StyledSelect } from './Select.style';

function Button({ children, icon, ...props }) {
  return (
    <>
      <PreffixIcon src={icon} alt="" />
      <StyledSelect {...props} size="large">
        {children}
      </StyledSelect>
    </>
  );
}

export default Button;
