import { useEffect, useState } from 'react';
import { scroller } from 'react-scroll';
import { Spin } from 'antd';

import { ImageWithZoom } from 'components';

import getDatasource from './getDatasource';
import {
  LoadingContainer,
  StyledMessage,
  StyledScroll,
  StyledSystemMessage,
  Wrapper,
} from './MessageList.style';

const loading = (
  <LoadingContainer>
    <Spin />
  </LoadingContainer>
);

function MessageList({
  messages,
  hasMore,
  getMore,
  messageToScroll,
  scrollFlag,
}) {
  const [imagesActive, setImagesActive] = useState({});

  const handleImage = (id, active) => {
    setImagesActive((prevImages) => ({
      ...prevImages,
      [id]: active,
    }));
  };

  useEffect(() => {
    if (messageToScroll) {
      const messageDiv = document.getElementsByClassName(messageToScroll)[0];
      scroller.scrollTo(messageToScroll, {
        duration: 1000,
        delay: 100,
        smooth: 'easeInOutCubic',
        containerId: 'scrollableDiv',
        offset: -50,
      });

      setTimeout(() => {
        messageDiv.classList.add('message-found');
      }, 1000);

      setTimeout(() => {
        messageDiv.classList.remove('message-found');
      }, 1500);
    }
  }, [scrollFlag]);

  const dataSource = getDatasource(messages, handleImage);

  return (
    <Wrapper id="scrollableDiv">
      <StyledScroll
        dataLength={dataSource.length}
        next={getMore}
        inverse
        hasMore={hasMore}
        scrollableTarget="scrollableDiv"
        loader={loading}
        containerId="scrollableDiv"
        endMessage={
          <StyledSystemMessage text="Fim do histórico de mensagens" />
        }
      >
        {dataSource?.map((props) => {
          if (props.type === 'system') {
            return (
              <StyledSystemMessage className={props.id} text={props.text} />
            );
          }
          if (props.type === 'photo') {
            return (
              <StyledMessage
                className={props.id}
                renderAddCmp={() => (
                  <ImageWithZoom
                    showNormalImage={false}
                    src={props.data.uri}
                    active={imagesActive[props.id]}
                    onClose={() => {
                      handleImage(props.id, false);
                    }}
                  />
                )}
                key={props.id}
                {...props}
              />
            );
          }
          return (
            <StyledMessage className={props.id} key={props.id} {...props} />
          );
        })}
      </StyledScroll>
    </Wrapper>
  );
}

export default MessageList;
