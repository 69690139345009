import { ToastContainer } from 'react-toastify';
import { Divider as AntDivider } from 'antd';
import styled from 'styled-components';

import colors from 'styles/colors';

import 'react-toastify/dist/ReactToastify.min.css';

export const StyledToast = styled(ToastContainer)`
  .Toastify__toast {
    width: 550px;
    right: 17em;
    border-radius: 10px;
    min-height: 130px;
    padding-left: 24px;
  }

  .Toastify__toast--error {
    background: white;
  }

  .Toastify__toast-body {
    margin: 0;
  }

  .Toastify__progress-bar {
    height: 7px;
    bottom: 1em;
    right: 24px;
    left: 24px;
    background: #558b2f;
    border-radius: 10px;
  }

  .Toastify__progress-bar--error {
    background: #cb6969;
  }

  .Toastify__close-button--error {
    color: grey;
  }
`;

export const MessageRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;
export const Title = styled.div`
  color: ${(props) => (props.error ? colors.roseRed : colors.grassGreen)};
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: 0.64px;
`;

export const Divider = styled(AntDivider)`
  margin: 6px -24px 15px;
  border-top: 1px solid #c7ccd8;
  width: 550px;
`;

export const Text = styled.div`
  color: ${colors.grayBlue};
  letter-spacing: 0.64px;
`;
