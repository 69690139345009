import { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import { Can } from 'components';
import { useModal } from 'contexts';
import { withModalProvider } from 'contexts/ModalProvider';
import { useUserInfo } from 'contexts/UserProvider/UserProvider';
import useRequest from 'hooks/use-request';
import colors from 'styles/colors';

import {
  HeaderButtonsWrapper,
  HeaderContainer,
  HeaderEditButton,
  HeaderTitle,
  PencilIcon,
  RoundButton,
} from './EditDataBoard.style';
import { deactivateBodyContent, deleteBodyContent } from './helpers';

const Header = ({ title, user, disabled, setDisable, path, history }) => {
  const [userStatus, setUserStatus] = useState('active');
  const [isUser, setIsUser] = useState(true);
  const { modal } = useModal();
  const request = useRequest();
  const { userData } = useUserInfo();

  const isAdmin = userData?.role === 'admin';
  const ableToEditOtherUser =
    userData?.role === 'supervisor' &&
    ['attendant', 'technique'].includes(user?.role) &&
    user?.regionId === userData?.regionId;
  const isMyProfileEdit =
    userData?.id === history.location.pathname.split('/')[2];

  useEffect(() => {
    if (user) {
      setUserStatus(user?.enabled);
      setIsUser(!!user?.role);
    }
  }, [user]);

  useEffect(() => {
    if (history.location.pathname.split('/')[1] === 'solicitantes')
      setIsUser(false);
  }, []);

  const deleteUser = async (closeModal) => {
    await request.delete({
      url: path,
      onSuccess: () => {
        history.push(`/${history.location.pathname.split('/')[1]}`);
        return {
          customMessage: 'Usuário deletado com sucesso!',
        };
      },
    });

    closeModal();
  };

  const deactivateUser = async (closeModal) => {
    const newStatus = !userStatus;

    await request.patch({
      url: path,
      body: {
        enabled: newStatus,
      },
      onSuccess: () => {
        setUserStatus(newStatus);

        return {
          customMessage: `Usuário ${
            newStatus ? 'ativado ' : 'desativado '
          } com sucesso!`,
        };
      },
    });

    closeModal();
  };

  const handleDeactivateUser = () => {
    const modalParams = {
      title: `Deseja ${userStatus ? 'desativar' : 'reativar'} a ${user?.name}?`,
      okText: userStatus ? 'Desativar' : 'Reativar',
      onOk: (closeModal) => {
        deactivateUser(closeModal);
      },
    };
    modal(
      () => deactivateBodyContent(user, userStatus),
      modalParams,
      'configurations-remove-modal'
    );
  };

  const handleDeleteUser = () => {
    const modalParams = {
      title: `Deseja excluir a ${user?.name}?`,
      okText: 'Excluir',
      onOk: (closeModal) => {
        deleteUser(closeModal);
      },
    };
    modal(
      () => deleteBodyContent(user),
      modalParams,
      'configurations-remove-modal'
    );
  };

  return (
    <HeaderContainer>
      <HeaderTitle>{title}</HeaderTitle>
      {disabled && (
        <HeaderButtonsWrapper>
          <Can perform={['users:update', 'resident-center:update']}>
            {(isAdmin || ableToEditOtherUser || isMyProfileEdit || !isUser) && (
              <HeaderEditButton
                onClick={() => {
                  setDisable(false);
                }}
                htmlType="button"
              >
                <PencilIcon />
                Editar
                {isUser ? ' usuário' : ' solicitante'}
              </HeaderEditButton>
            )}
          </Can>
          {isUser && (
            <Can perform={['users:delete']}>
              <RoundButton
                textColor={colors.white}
                backgroundColor={colors.persianGreen}
                htmlType="button"
                onClick={handleDeactivateUser}
                disabled={user === null}
              >
                {userStatus ? 'Desativar ' : 'Reativar '}
                usuário
              </RoundButton>
            </Can>
          )}
          <Can perform={['users:delete']}>
            <RoundButton
              htmlType="button"
              disabled={user === null}
              onClick={handleDeleteUser}
            >
              Excluir
              {isUser ? ' usuário' : ' solicitante'}
            </RoundButton>
          </Can>
        </HeaderButtonsWrapper>
      )}
    </HeaderContainer>
  );
};

export default withModalProvider(withRouter(Header));
