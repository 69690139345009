import { useEffect, useState } from 'react';
import axios from 'axios';
import { useFormik } from 'formik';

import { Can } from 'components';
import { FormButton } from 'components/inputs';
import { useConfirmCancel } from 'contexts/ConfirmCancelProvider';
import getDiffBetweenObjects from 'helpers/getDiffBetweenObjects';
import useRequest from 'hooks/use-request';
import useTouched from 'hooks/use-touched';

import { FormButtonContainer, Input, Select } from './CreateAreaModal.style';
import { CreateAreaSchema, validate } from './validation';

function CreateAreaModal({
  type,
  closeModal,
  create,
  isEdit,
  area,
  edit,
  watchTouched = () => {},
}) {
  const [regionOptions, setRegionOptions] = useState([]);
  const request = useRequest();
  const confirmCancel = useConfirmCancel();

  const isArea = type === 'area';

  const initialValues = {
    name: '',
    regionId: '',
    lat: '',
    lng: '',
    address: '',
    numberOfApartments: 0,
    numberOfBlocks: 0,
  };

  const formik = useFormik({
    initialValues: area || initialValues,
    validationSchema: CreateAreaSchema,
    validateOnChange: false,
    validate: (values) => validate(values, type),
    onSubmit: async (values) => {
      const submitValues = { ...values };
      submitValues.numberOfApartments = parseInt(
        submitValues.numberOfApartments,
        10
      );
      submitValues.numberOfBlocks = parseInt(submitValues.numberOfBlocks, 10);

      try {
        const splitedAddress = values.address.split(/[ .,]+/);
        const cleanedAddress = splitedAddress.filter(
          (item) => item.toLowerCase() !== 's/n' && item !== ''
        );
        const formattedAddress = cleanedAddress.join('+');

        const {
          data: { results },
        } = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${formattedAddress}&key=${process.env.REACT_APP_MAPS_API_KEY}`
        );

        const coords = {
          lat: results[0]?.geometry.location.lat.toFixed(2),
          lng: results[0]?.geometry.location.lng.toFixed(2),
        };

        let body = {
          ...submitValues,
          ...coords,
        };

        if (type === 'area') {
          const { ...otherValues } = submitValues;
          body = {
            ...otherValues,
            ...coords,
          };
        }

        if (isEdit) {
          await edit(area.id, { ...getDiffBetweenObjects(area, body) });
        } else {
          await create({
            ...body,
            type,
          });
        }
        closeModal();
      } catch (err) {
        console.log(err);
      }
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      await request.get({
        url: '/region',
        onSuccess: (data) => {
          setRegionOptions(
            data.map(({ id, name }) => ({ value: id, label: name }))
          );
        },
      });
    };

    fetchData();
  }, []);

  const touched = useTouched(formik.values, initialValues);
  watchTouched(touched);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Input
        label={`Nome ${isArea ? 'da área' : 'do empreendimento'}`}
        name="name"
        width="100%"
        absoluteError
        error={formik.errors.name}
        value={formik.values.name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        schema={CreateAreaSchema}
      />
      <Select
        label="Regional"
        name="regionId"
        absoluteError
        onChange={(value) => formik.setFieldValue('regionId', value)}
        error={formik.errors.regionId}
        value={formik.values.regionId}
        onBlur={formik.handleBlur}
        options={regionOptions}
        schema={CreateAreaSchema}
      />
      <Input
        label="Latitude"
        name="lat"
        width="100%"
        absoluteError
        error={formik.errors.lat}
        value={formik.values.lat}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        schema={CreateAreaSchema}
      />
      <Input
        label="Longitude"
        name="lng"
        width="100%"
        absoluteError
        error={formik.errors.lng}
        value={formik.values.lng}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        schema={CreateAreaSchema}
      />
      <Input
        label="Endereço"
        name="address"
        width="100%"
        absoluteError
        error={formik.errors.address}
        value={formik.values.address}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        schema={CreateAreaSchema}
      />
      <Input
        label="Número de apartamentos"
        name="numberOfApartments"
        width="100%"
        absoluteError
        error={formik.errors.numberOfApartments}
        value={formik.values.numberOfApartments}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        schema={CreateAreaSchema}
      />
      <Input
        label="Número de blocos"
        name="numberOfBlocks"
        width="100%"
        absoluteError
        error={formik.errors.numberOfBlocks}
        value={formik.values.numberOfBlocks}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        schema={CreateAreaSchema}
      />
      <FormButtonContainer>
        <FormButton onClick={() => confirmCancel(closeModal, touched)} $cancel>
          Cancelar
        </FormButton>
        <Can perform={['areas:create']}>
          <FormButton loading={formik.isSubmitting} htmlType="submit">
            Adicionar
          </FormButton>
        </Can>
      </FormButtonContainer>
    </form>
  );
}

export default CreateAreaModal;
