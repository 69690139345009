import { Modal as AntModal } from 'antd';
import styled from 'styled-components';

import { Close } from 'components/icons/noise';
import colors from 'styles/colors';

export const StyledModal = styled(AntModal)`
  .ant-modal-close-x {
    top: 0px !important;
    right: initial !important;
    background-color: transparent !important;
  }

  .ant-modal-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    padding: 1.375rem 1.5rem 0.625rem;
  }

  .ant-modal-body {
    > div {
      width: 100% !important;
    }
  }

  .ant-modal-footer {
    display: flex;
    justify-content: space-evenly;
    border-top: none !important;
    padding-bottom: 1.5rem;

    > button {
      border: none !important;
      width: 9rem !important;
      height: 2rem !important;

      &:nth-child(1) {
        background-color: rgba(204, 204, 204, 1) !important;
        color: rgba(44, 62, 80, 1) !important;
      }

      &:nth-child(2) {
        background-color: ${({ footerButtonColor }) =>
          footerButtonColor || 'rgba(0, 155, 144, 1)'} !important;
        color: #ffffff !important;
      }
    }
  }
`;

export const StyledClose = styled(Close)`
  font-size: 0.625rem;

  path {
    fill: ${colors.text};
  }
`;
