import { Ball, ShortStrings } from 'components/icons/noise';
import useMobile from 'hooks/use-mobile';

import { Col, Content, LongStrings, Page, Row } from './AuthLayout.style';
import { BigOrangeBall, Houses, WaveDark, WaveLight } from './Images';

function AuthLayout({ children }) {
  const isMobile = useMobile();

  return (
    <Page>
      <Row>
        <Col span={8}>
          <BigOrangeBall />
          <WaveLight />
          <WaveDark />
          <Houses />
        </Col>
        <Col span={isMobile ? 24 : 16}>
          {isMobile && (
            <>
              <LongStrings
                $position={{ left: '1.2031rem', top: '3.25rem' }}
                $size={{ width: '55px' }}
                $color="green"
              />
              <Ball
                $position={{ right: '1.2031rem', top: '4.625rem' }}
                $size={{ width: '26px' }}
                $color="green"
                $rotatePos
              />
              <Ball
                $position={{ left: '.875rem', top: '9.125rem' }}
                $size={{ width: '34px' }}
                $color="orange"
              />
              <ShortStrings
                $position={{ right: '1.24rem', top: '8.5625rem' }}
                $size={{ width: '34px' }}
                $color="orange"
              />
            </>
          )}
          <Content>{children}</Content>
        </Col>
      </Row>
    </Page>
  );
}

export default AuthLayout;
