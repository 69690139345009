import { Bold } from './Areas.style';

export default [
  {
    title: 'Área/empreendimento',
    dataIndex: 'name',
    key: 'area',
    span: 1.25,
    render: (name) => <Bold>{name}</Bold>,
  },
  {
    title: 'Profissionais alocados',
    dataIndex: 'allocatedUsers',
    key: 'amount-users',
    span: 0.75,
    render: (locatedUsers) => locatedUsers || '0',
  },
  {
    title: 'Quant. de solicitantes',
    dataIndex: 'allocatedRequesters',
    key: 'amount-residents',
    span: 0.5,
    render: (locatedRequesters) => locatedRequesters || '0',
  },
  {
    title: 'Regional',
    dataIndex: 'region',
    key: 'region',
    span: 0.5,
    render: ({ name }) => name,
  },
  {
    title: 'Ver detalhes',
    dataIndex: 'buttons',
    key: 'buttons',
    align: 'middle',
    span: 1.25,
  },
];
