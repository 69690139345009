import { useEffect, useState } from 'react';

import { BaseTable } from 'components';

function DemandType({ data }) {
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    const requestData = data?.callMotives;
    if (requestData) {
      const tableData = requestData?.map((item, index) => ({
        ...item,
        key: index.toString(),
        demandTypeCount: ` ${item.demandTypeCount.toFixed(2)} %`,
      }));
      setDataSource(tableData);
    }
  }, [data]);

  const columns = [
    {
      title: 'Demanda',
      dataIndex: 'name',
      key: 'demand',
    },
    {
      title: 'Tipo de demanda',
      dataIndex: 'demandTypeCount',
      key: 'demand-type-count',
    },
    {
      title: 'Tipo de demanda (Total)',
      dataIndex: 'amount',
      key: 'demand-type-total',
    },
    {
      title: 'Atendimentos na fila',
      dataIndex: 'onWaitUnifiedAttendances',
      key: 'on-hold-unified-attendances',
    },
  ];

  return (
    <BaseTable
      title="Tipo de demanda"
      dataSource={dataSource}
      columns={columns}
    />
  );
}

export default DemandType;
