import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { AuthLayout, ChangePasswordForm, ForgotPasswordForm } from 'components';
import { useAuth } from 'contexts';
import useRequest from 'hooks/use-request';

function ForgotPassword() {
  const { login } = useAuth();
  const [error, setError] = useState('');
  const [step, setStep] = useState(0);
  const [userEmail, setUserEmail] = useState('');
  const history = useHistory();
  const request = useRequest();

  const handleEmailSubmit = async ({ email }) => {
    setUserEmail(email);
    await request.unauthorized.post({
      url: '/user/forgot-password',
      body: { email },
      onSuccess: () => {
        setStep(1);
        return {
          customMessage: 'Email enviado com sucesso!',
        };
      },
    });
  };

  const handleSubmitLogin = async ({ email, password }, formik) => {
    try {
      formik.setSubmitting(true);
      await login(email, password);
      formik.setSubmitting(false);
    } catch (err) {
      formik.setSubmitting(false);
      if (err.response?.status === 404) {
        setError('Usuário não encontrado');
        return;
      }
      if (err.response?.status === 401) {
        setError('Usuário ou senha incorretos');
        return;
      }
      if (err.response?.status === 400) {
        setError('Senha inválida');
        return;
      }
      setError('Erro ao se conectar com o servidor');
    }

    history.push('/');
  };

  const handlePasswordChange = async (
    { code, newPassword, confirmNewPassword, email },
    formik
  ) => {
    if (newPassword === confirmNewPassword) {
      await request.unauthorized.post({
        url: '/user/confirm-forgot-password',
        body: {
          confirmationCode: code,
          newPassword,
          email,
        },
        onSuccess: () => {
          handleSubmitLogin(
            {
              email,
              password: newPassword,
            },
            formik
          );
          return {
            customMessage:
              'Senha alterada com sucesso! Acessando o Portal do Morador...',
          };
        },
      });
    } else {
      setError('As senhas não coincidem');
    }
  };

  return (
    <AuthLayout>
      {step === 0 && (
        <ForgotPasswordForm
          error={error}
          setError={setError}
          onSubmit={handleEmailSubmit}
          setStep={setStep}
        />
      )}
      {step === 1 && (
        <ChangePasswordForm
          error={error}
          renderEmail={userEmail}
          setError={setError}
          onSubmit={handlePasswordChange}
          setStep={setStep}
        />
      )}
    </AuthLayout>
  );
}

export default ForgotPassword;
