export default [
  {
    type: 'text',
    label: 'Nome completo',
    name: 'name',
    rules: ['required'],
    span: 12,
  },
  {
    type: 'text',
    label: 'RG',
    name: 'rg',
    rules: [],
    span: 6,
  },
  {
    type: 'text',
    label: 'CPF',
    name: 'cpf',
    rules: ['required', 'cpf'],
    span: 6,
    mask: 'cpf',
  },
  {
    type: 'text',
    label: 'Telefone',
    name: 'phone',
    span: 6,
    rules: ['phone', 'required'],
    mask: 'phone',
  },
  {
    type: 'text',
    label: 'Whatsapp',
    name: 'phoneWhatsapp',
    span: 6,
    rules: ['phone'],
    mask: 'phone',
  },
  {
    type: 'text',
    label: 'E-mail',
    name: 'email',
    rules: ['email'],
    span: 12,
  },
  {
    type: 'select',
    label: 'Tipo de Solicitante',
    name: 'typeId',
    rules: ['required'],
    span: 6,
    options: '/type-requester',
  },
  {
    type: 'text',
    label: 'Contato de Emergência',
    name: 'contactName',
    span: 6,
    rules: [],
  },
  {
    type: 'text',
    label: 'Telefone do contato',
    name: 'contactPhone',
    span: 6,
    rules: ['phone'],
    mask: 'phone',
  },
  {
    type: 'select',
    label: 'Status',
    name: 'requesterStatusId',
    rules: ['required'],
    span: 6,
    options: '/requester-status',
  },
  {
    type: 'title',
    label: 'Localidade',
  },
  {
    type: 'select',
    label: 'Regional',
    name: 'regionId',
    rules: ['required'],
    span: 6,
    options: '/region',
    clearOnChange: ['areaId'],
  },
  {
    type: 'autocomplete',
    label: 'Área / empreendimento',
    name: 'areaId',
    rules: ['required'],
    span: 6,
    options: '/area',
    filter: (items, context) => {
      if (context.regionId) {
        return items.filter(({ regionId }) => context.regionId === regionId);
      }
      return items;
    },
  },
  {
    type: 'text',
    label: 'Lote',
    name: 'batch',
    rules: [],
    span: 6,
  },
  {
    type: 'text',
    label: 'Setor',
    name: 'sector',
    span: 6,
    rules: [],
  },
  {
    type: 'text',
    label: 'Selo',
    name: 'stamp',
    span: 6,
    rules: [],
  },
  {
    type: 'text',
    label: 'SEI',
    name: 'sei',
    span: 6,
    rules: [],
  },
  {
    type: 'text',
    label: 'Endereço',
    name: 'address',
    rules: [],
    span: 12,
  },
  {
    type: 'text',
    label: 'Bairro',
    name: 'neighborhood',
    rules: [],
    span: 6,
  },
  {
    type: 'text',
    label: 'Cidade',
    name: 'city',
    rules: [],
    span: 6,
  },
  {
    type: 'text',
    label: 'Imóvel/UH',
    name: 'place',
    rules: [],
    span: 12,
  },
  {
    type: 'autocomplete',
    label: 'Área de Origem',
    name: 'originAreaId',
    rules: ['required'],
    span: 6,
    options: '/area',
    filter: (items, context) => {
      console.log(1)
      if (context.regionId) {
        return items.filter(({ regionId }) => context.regionId === regionId);
      }
      return items;
    },
  },
  {
    type: 'title',
    label: 'Dados do segundo titular',
  },
  {
    type: 'text',
    label: 'Nome completo do segundo titular',
    name: 'relativeName',
    rules: [],
    span: 12,
  },
  {
    type: 'text',
    label: 'RG',
    name: 'relativeRg',
    rules: ['number'],
    span: 6,
  },
  {
    type: 'text',
    label: 'CPF',
    name: 'relativeCpf',
    rules: ['cpf'],
    span: 6,
    mask: 'cpf',
  },
  {
    type: 'text',
    label: 'Endereço atual',
    name: 'relativeAddress',
    rules: [],
    span: 12,
  },
  {
    type: 'text',
    label: 'Telefone',
    name: 'relativePhone',
    span: 6,
    rules: ['phone'],
    mask: 'phone',
  },
  {
    type: 'textarea',
    label: 'Observações',
    name: 'relativeObservation',
    rules: [],
    span: 12,
    placeholder:
      'Observação sobre o munícipe sendo cadastrado na plataforma. Aqui deve conter informações que são relevantes para o conhecimento de todo o time.',
  },
];
