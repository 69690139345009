import { useEffect, useState } from 'react';
import { ResponsivePie } from '@nivo/pie';

import {
  Card,
  CenterContainer,
  Color,
  Container,
  CustomTooltip,
  Divider,
  Label,
  Labels,
  LabelText,
  Percentage,
  PercentageSign,
  StyledSpin,
  TextBlock,
  Title,
  TooltipTitle,
  TooltipValue,
} from './DoughnutChart.style';

function DoughnutChart({ title, data, colors, flex }) {
  const [selectedPercentage, setSelectedPercentage] = useState('--');
  const [color, setColor] = useState(colors ? colors[0] : null);

  const formattedData = data?.map((item) => ({
    id: item.name,
    label: item.name,
    value: item.amount,
    percentage: item.percentage,
    color: item.color,
  }));

  useEffect(() => {
    if (formattedData?.length > 0) {
      setSelectedPercentage((formattedData[0].percentage * 100).toFixed(0));
    }
  }, [data]);

  return (
    <Card>
      <Title>{title}</Title>
      {formattedData ? (
        <Container>
          {selectedPercentage && (
            <CenterContainer color={color}>
              {selectedPercentage}
              {selectedPercentage !== '--' && <PercentageSign />}
            </CenterContainer>
          )}
          {formattedData && (
            <ResponsivePie
              data={formattedData}
              sortByValue
              innerRadius={0.85}
              padAngle={2}
              cornerRadius={45}
              colors={colors}
              enableRadialLabels={false}
              enableSliceLabels={false}
              tooltip={(item) => (
                <CustomTooltip>
                  <TooltipTitle>{item?.datum?.data.id}</TooltipTitle>
                  <Divider />
                  <TooltipValue>
                    {`${(item?.datum?.data.percentage * 100).toFixed(0)}%   `}
                    {`${item?.datum?.data.value}`}
                  </TooltipValue>
                </CustomTooltip>
              )}
              onMouseEnter={(item) => {
                setSelectedPercentage((item?.data.percentage * 100).toFixed(0));
                setColor(item?.data.color);
              }}
            />
          )}
        </Container>
      ) : (
        <StyledSpin />
      )}
      <Labels flex={flex}>
        {formattedData?.map((item) => (
          <Label key={item.id} justify="center">
            <Color color={item.color} />
            <TextBlock disabled={item.percentage === 0}>
              <LabelText>{item.label}</LabelText>
              <Percentage>
                {`${(item.percentage * 100).toFixed(0)}%`}
              </Percentage>
            </TextBlock>
          </Label>
        ))}
      </Labels>
    </Card>
  );
}

export default DoughnutChart;
