import addSolicitant from 'assets/icons/add-simbol.svg';
import updateProfile from 'assets/icons/update-profile.svg';
import { Can } from 'components';
import { withModalProvider } from 'contexts/ModalProvider';

import {
  Card,
  CardContainer,
  Icon,
  Title,
  Wrapper,
} from './HeaderButtons.styles';

function HeaderButtons() {
  return (
    <Wrapper>
      <CardContainer>
        <Card>
          <Icon src={updateProfile} alt="Atualizar perfil" />
          <Title>Atualizar meu perfil</Title>
        </Card>
        <Can perform={['resident-center:create']}>
          <Card>
            <Icon src={addSolicitant} alt="Cadastrar solicitante" />
            <Title>Cadastrar solicitante</Title>
          </Card>
        </Can>
      </CardContainer>
    </Wrapper>
  );
}

export default withModalProvider(HeaderButtons);
