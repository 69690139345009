export const ACCESS_TOKEN = '_portal_access';
export const REFRESH_TOKEN = '_portal_refresh';
export const USER_ID = '_portal_user_id';
export const USER_INFO = '_portal_user_info';

export const MAP_DEFAULT_COORDINATE = {
  latitude: -23.560278,
  longitude: -46.599444,
  zoom: 11,
  bearing: 0,
  pitch: 0,
};
export const MAP_STYLE = 'mapbox://styles/rafaellfaria/ckmflxhox14ri17pcho1s0z0e';

export const ALERT_SERVER_ERROR = {
  type: 'error',
  message: 'Ops! Houve um problema com a requisição.',
};