import useMobile from 'hooks/use-mobile';

import Tab from './Tab';
import { Row, StyledTabs } from './Tabs.style';

function Tabs({ children }) {
  const isMobile = useMobile();

  if (isMobile) return <StyledTabs defaultActiveKey="1">{children}</StyledTabs>;

  return <Row>{children}</Row>;
}

Tabs.Tab = Tab;

export default Tabs;
