import { Col, Row } from 'antd';
import styled, { css } from 'styled-components';

import { Button as AntButton } from 'components/inputs';
import colors from 'styles/colors';
import { respondTo } from 'styles/mixins';

export const Card = styled.div`
  background-color: ${colors.white};
  padding: 1.875rem 2.1875rem;
  border-radius: 10px;
  ${respondTo('medium')(css`
    margin-bottom: 3rem;
  `)}
`;

export const Title = styled.h2`
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.75rem;
  letter-spacing: 0.03rem;
  color: ${colors.black};
  margin: 0;

  ${respondTo('medium')(css`
    font-size: 1rem;
  `)}
`;

export const DocumentsContainer = styled.div`
  margin: 1.875rem 0;
  display: flex;
`;

export const Divider = styled.div`
  min-height: 100%;
  width: 1px;
  background-color: ${colors.smoothBlack};
  opacity: 0.1;

  ${respondTo('big')(css`
    height: 1px;
    width: 100%;
  `)}
`;

export const Button = styled(AntButton)`
  height: 2.9375rem;
  width: 12.3125rem;
  font-size: 1rem;
  margin-bottom: 1.125rem;
`;

export const StyledCol = styled(Col)`
  margin-bottom: 4rem;
`;

export const StyledRow = styled(Row)`
  width: 100%;
`;
