import { Drawer } from 'antd';
import styled from 'styled-components';

import colors from 'styles/colors';

export const StyledDrawer = styled(Drawer)`
  position: absolute;
  border-right: 1px solid #00000010;
  .ant-drawer-body {
    padding-top: 3rem;
  }
`;

export const SuggestedMessageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
  margin: 1rem 0.2rem;
`;

export const MessageIdentifiersRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const MessageSender = styled.h2`
  color: ${colors.black};
  font-size: 0.8rem;
  font-weight: 600;
`;

export const MessageDate = styled.p`
  color: ${colors.black};
  font-size: 0.65rem;
  font-weight: 400;
`;

export const MessageContent = styled.p`
  color: ${colors.black};
  font-weight: 400;
  font-size: 0.8rem;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;

  & > span {
    font-weight: 600;
  }
`;

export const NoDataParagraph = styled.p`
  margin-top: 1rem;
  color: ${colors.lightGray};
  font-size: 0.9rem;
  font-weight: 400;
`;
