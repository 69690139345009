import { useState } from 'react';

import bell from 'assets/icons/dark-bell.svg';
import hamburguer from 'assets/icons/dark-hamburguer.svg';
import {
  HeaderButtons,
  MobileNavbarDrawer,
  NotificationDrawer,
  ProfilePhotoEdit,
} from 'components';
import { useUserInfo } from 'contexts/UserProvider/UserProvider';

import { Icon, Title, UserInfo, Wrapper } from './MobileHeader.styles';

function MobileHeader({ showButtons = true }) {
  const [open, setOpen] = useState(false);
  const [openNavbarDrawer, setOpenNavbarDrawer] = useState(false);
  const { userData } = useUserInfo();

  return (
    <>
      <NotificationDrawer open={open} setOpen={setOpen} />
      <MobileNavbarDrawer
        open={openNavbarDrawer}
        setOpen={setOpenNavbarDrawer}
      />
      <Wrapper>
        <UserInfo>
          <ProfilePhotoEdit
            mobileHeader
            user={userData}
            path={`/user/${userData?.id}`}
          />
          <Title>{`Bem-vindo, ${userData?.name}!`}</Title>
        </UserInfo>
        <Icon onClick={() => setOpen(true)} src={bell} alt="notificações" />
        <Icon
          onClick={() => setOpenNavbarDrawer(true)}
          src={hamburguer}
          alt="botões da barra de navegação"
        />
      </Wrapper>
      {showButtons && <HeaderButtons />}
    </>
  );
}

export default MobileHeader;
