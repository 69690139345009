import { createContext, useContext, useState } from 'react';

import { ConfirmationModal } from 'components';

const ConfirmationContext = createContext({
  confirm: () => {},
});

function ConfirmationProvider({ children }) {
  const [visible, setVisible] = useState(false);
  const [modalProps, setModalProps] = useState({});

  const confirm = (props) => {
    setModalProps(props);
    setVisible(true);
  };

  return (
    <ConfirmationContext.Provider value={{ confirm }}>
      {children}
      <ConfirmationModal
        visible={visible}
        setVisible={setVisible}
        {...modalProps}
      />
    </ConfirmationContext.Provider>
  );
}

export const useConfirmation = () => useContext(ConfirmationContext);

export default ConfirmationProvider;
