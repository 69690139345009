import defaultMessages from './notificationMessages.json';

const createNotificationService = (showNotification) => {
  const notificationService = {
    success: (method, customMessage) => {
      const message = customMessage || defaultMessages.success[method];

      if (message) {
        showNotification({ message, type: 'success' });
      }
    },
    error: (method, customMessage) => {
      const message = customMessage || defaultMessages.error[method];

      if (message) {
        showNotification({ message, type: 'error' });
      }
    },
  };

  return notificationService;
};

export default createNotificationService;
