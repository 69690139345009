import { useHistory } from 'react-router-dom';
import moment from 'moment';

import Call from 'assets/icons/calls-balloon.svg';
import Citizen from 'assets/icons/citizen-icon.svg';
import Clock from 'assets/icons/clock.svg';

import {
  Bold,
  Card,
  CitizenData,
  CitizenIcon,
  Content,
  Footer,
  FooterButton,
  InfoText,
  Italic,
  Name,
  SecondaryText,
  Title,
} from './UnifiedAttendanceCard.style';

function UnifiedAttendanceCard({
  createdAt = new Date().toString(),
  requester = {},
  motive = {},
  mobile,
  id,
  ...props
}) {
  const history = useHistory();

  const onCardClick = () => {
    history.push(`/central-do-morador/${requester.id}`);
  };

  return (
    <Card>
      <Content mobile={mobile}>
        <Title>{moment(createdAt).format('dddd, DD/MM/YYYY')}</Title>
        <SecondaryText>
          <CitizenIcon src={Citizen} alt="Ícone do solicitante" />
          Solicitante
        </SecondaryText>
        <CitizenData>
          <Name>{requester.name}</Name>
          <InfoText>
            <Bold>{`CPF: `}</Bold>
            {requester.cpf}
          </InfoText>
          <InfoText>
            <Bold>{`Área/empreendimento: `}</Bold>
            {requester.area?.name}
          </InfoText>
          <InfoText>
            <Bold>{`Regional ${requester.region?.name}`}</Bold>
          </InfoText>
          <InfoText>
            <Bold>{`Canal de Comunicação: `}</Bold>
            {props?.channel?.name}
          </InfoText>
        </CitizenData>
        <SecondaryText>
          <CitizenIcon src={Call} alt="Chamado" />
          Demanda
        </SecondaryText>
        <Title>{motive?.name}</Title>
        <Italic>
          <CitizenIcon src={Clock} alt="Tempo" />
          {`Aberto ${moment(createdAt).fromNow()}`}
        </Italic>
      </Content>
      <Footer>
        <FooterButton onClick={onCardClick} color="green">
          Ver detalhes
        </FooterButton>
      </Footer>
    </Card>
  );
}

export default UnifiedAttendanceCard;
