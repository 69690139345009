import {
  Can,
  FilterButton,
  PageTable,
  TablesSearchBar,
  TitledPage,
} from 'components';
import { useModal, withModalProvider } from 'contexts/ModalProvider';
import useCrud from 'hooks/use-crud';

import {
  Button,
  ButtonContainer,
  Count,
  FiltersContainer,
  SubTitle,
} from './Areas.style';
import columns from './columns';
import CreateAreaModal, { DetailAreaButton } from './CreateAreaModal';
import { filters, searchParameters } from './filters';

function Areas() {
  const [
    areas,
    { create, edit, remove, get, getSpecificPage, itemsAmount, loading },
  ] = useCrud('/area', {
    autoinit: true,
    trackQueryParameters: true,
    pagination: true,
  });
  const { modal } = useModal();

  const handleCreateArea = (type) => () => {
    const modalProps = {
      title: `Adicionar ${type === 'area' ? 'área' : 'empreendimento'}`,
      footer: null,
      watchTouched: true,
      maskClosable: false,
    };

    modal(
      (props) => <CreateAreaModal create={create} type={type} {...props} />,
      modalProps
    );
  };

  const titleButtons = [() => <FilterButton filters={filters} />];

  return (
    <TitledPage
      buttons={titleButtons}
      justifyButtons="flex-end"
      title="Áreas e empreendimentos"
    >
      <ButtonContainer>
        <Can perform={['areas:create']}>
          <Button onClick={handleCreateArea('area')}>Adicionar Área</Button>
          <Button onClick={handleCreateArea('enterprise')}>
            Adicionar empreendimento
          </Button>
        </Can>
      </ButtonContainer>
      <FiltersContainer>
        <TablesSearchBar
          queryIds={searchParameters}
          requestFunction={get}
          placeholder="Pesquise pelo nome da área / empreendimento"
        />
      </FiltersContainer>
      <SubTitle>Regional centro detalhamento</SubTitle>
      <Count>{`${areas?.length || 0} empreendimentos listados`}</Count>
      <PageTable
        dataSource={areas}
        itemsAmount={itemsAmount}
        get={getSpecificPage}
        columns={columns}
        loading={loading}
        mobileTitleColumns={['name', 'region']}
        extras={{
          buttons: (props) => (
            <DetailAreaButton {...props} remove={remove} edit={edit} />
          ),
        }}
      />
    </TitledPage>
  );
}

export default withModalProvider(Areas);
