import { useState } from 'react';

function useMask({ mask, defaultValue = '', defaultOnChange = () => {} }) {
  const [value, setValue] = useState(defaultValue);

  const onChange = (event) => {
    const string = event.target.value;
    const currentIndex = string.length - 1;
    const currentChar = string[currentIndex];
    let newValue = value;

    if (string.length < value.length) {
      const stringArrReversed = string.split('').reverse();
      let hasRemovedRegex = false;

      const newStringArray = stringArrReversed.filter((element, i) => {
        if (typeof mask[stringArrReversed.length - i - 1] === 'object') {
          hasRemovedRegex = true;
          return true;
        }

        return hasRemovedRegex;
      });

      setValue(newStringArray.reverse().join(''));
      defaultOnChange(event);
      return;
    }

    if (typeof mask[currentIndex] === 'object') {
      if (currentChar.match(mask[currentIndex])) {
        newValue += currentChar;
      }
    } else {
      let hasAddedChar = false;

      mask.forEach((element, i) => {
        if (i < currentIndex) return;
        if (hasAddedChar) return;

        if (typeof element === 'object') {
          if (currentChar.match(element)) {
            newValue += currentChar;
            hasAddedChar = true;
          }
          return;
        }

        newValue += element;
      });
    }

    setValue(newValue);
    defaultOnChange(event);
  };

  return { value, onChange };
}

export default useMask;
