import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import personSvg from 'assets/icons/person.svg';
import { TextInput } from 'components/inputs';

import {
  Actions,
  Form,
  InputWrapper,
  Next,
  Previous,
  StyledLink,
  SubText,
  TextContainer,
  Title,
  Wrapper,
} from './ForgotPasswordForm.style';

function ForgotPasswordForm({ onSubmit, error, setStep }) {
  const history = useHistory();
  const EmailSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email inválido')
      .required('Email não pode ser vazio'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: EmailSchema,
    onSubmit: (values) => {
      onSubmit(values, formik);
    },
  });

  return (
    <Wrapper>
      <Form onSubmit={formik.handleSubmit}>
        <Title>Redefinição de senha</Title>
        <InputWrapper>
          <TextInput
            name="email"
            label="Email"
            placeholder="Coloque seu Email"
            icon={personSvg}
            onChange={formik.handleChange}
            error={formik.errors.email || error}
            value={formik.values.email}
            schema={EmailSchema}
          />
          <TextContainer>
            <SubText marginTop>
              É necessário que seja o email cadastrado na plataforma
            </SubText>
            <SubText>Você receberá um código por email</SubText>
            <SubText marginTop center>
              Já tem um código?
              <StyledLink to="/redefinir-senha" onClick={() => setStep(1)}>
                Clique aqui
              </StyledLink>
            </SubText>
          </TextContainer>
        </InputWrapper>
        <Actions>
          <Previous onClick={() => history.push('/login')}>Voltar</Previous>
          <Next htmlType="submit">Enviar email</Next>
        </Actions>
      </Form>
    </Wrapper>
  );
}

export default ForgotPasswordForm;
