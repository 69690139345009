import { useState } from 'react';

import { Filter as FilterIcon } from 'components/icons/noise';

import Filter from '../Filter';

import { Button, ButtonText } from './FilterButton.style';

function FilterButton({ filters }) {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleToggleDrawer = () => {
    setDrawerOpen((prevStatus) => !prevStatus);
  };

  return (
    <>
      <Button onClick={handleToggleDrawer}>
        <FilterIcon />
        <ButtonText>Filtros</ButtonText>
      </Button>
      <Filter
        filters={filters}
        isDrawerOpen={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      />
    </>
  );
}

export default FilterButton;
