const adminRules = {
    static: [
      // PAGES can visit
      'dashboard:visit',
      'resident-center:visit',
      'configurations:visit',
      'areas:visit',
      'users:visit',
      'calls:visit',  
      'report:visit',
      'appointments:visit',
      
      // users CRUD
      'users:create',
      'users:read',
      'users:update',
      'users:delete',

      // resident-center CRUD
      'resident-center:create',
      'resident-center:read',
      'resident-center:update',
      'resident-center:delete',

      // areas CRUD
      'areas:create',
      'areas:read',
      'areas:update',
      'areas:delete',

      // configurations CRUD
      'configurations:create',
      'configurations:read',
      'configurations:update',
      'configurations:delete',

      // calls CRU
      'calls:create',
      'calls:read',
      'calls:update',
        
      // appointments CRUD
      'appointments:create',
      'appointments:read',
      'appointments:update',
      'appointments:delete',

      // dashboard R
      'dashboard:read',

      // report CRUD
      'report:create',
      'report:read',
      'report:update',
      'report:delete',

    ]
}

export default adminRules;
