import { StyledCard, StyledTable, Title } from './BaseTable.style';

function BaseTable({ title, ...props }) {
  return (
    <StyledCard>
      <Title>{title}</Title>
      <StyledTable scroll={{ y: 365 }} pagination={false} {...props} />
    </StyledCard>
  );
}

export default BaseTable;
