import { Modal } from 'antd';

import {
  Card,
  CardSubTitle,
  CardTitle,
  Container,
  Content,
  Data,
  HighlightedText,
  SubTitle,
  Text,
  Title,
} from './DetailsModal.style';

function DetailsModal({
  visible,
  setVisible,
  reason = 'Gestão condominial',
  openDate = '04/04/1998',
  closeDate = '08/10/2020',
  repetition = 3,
  synthesis = 'Morador pediu ajuda na gestão condominial, pediu uma assistência mais pessoal, portanto é necessário dedicar um tempo para auxiliar-lo.',
  technician = 'Renata Capecci',
}) {
  return (
    <Modal
      style={{ top: 20 }}
      width="fit-content"
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={false}
    >
      <Container>
        <Title>Chamado Solucionado</Title>
        <SubTitle>
          Este chamado se repetiu
          <strong>
            {' '}
            {repetition}
            {repetition === 1 ? ` vez` : ` vezes`}
          </strong>
          para você nos últimos
          <strong> 5 meses</strong>
        </SubTitle>
        <Content>
          <Data>
            <Text>Motivo do chamado</Text>
            <HighlightedText>{reason}</HighlightedText>
            <Text>Data de abertura</Text>
            <HighlightedText>{openDate}</HighlightedText>
            <Text>Sintese do caso</Text>
            <Text textStyle="italic">{synthesis}</Text>
          </Data>
          <Card>
            <CardTitle>
              Resolvido em
              {` ${closeDate}`}
            </CardTitle>
            <CardSubTitle> Solucionado em 3 dias!</CardSubTitle>
            Técnica(o) responsável
            <HighlightedText style={{ fontSize: '0.875rem', marginTop: 10 }}>
              {technician}
            </HighlightedText>
          </Card>
        </Content>
      </Container>
    </Modal>
  );
}

export default DetailsModal;
