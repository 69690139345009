import {
  ButtonContainer,
  Content,
  Question,
  StyledButton,
  StyledModal,
} from './ConfirmationModal.style';

function ConfirmationModal({
  visible,
  setVisible,
  onOk,
  onOkParameter = {},
  onCancel,
  question,
  okText = 'Sim',
  cancelText = 'Cancelar',
}) {
  const finishHandler =
    (operation = () => {}, parameters = {}) =>
    () => {
      if (Object.keys(parameters).length > 0) {
        operation(parameters);
      } else {
        operation();
      }
      setVisible(false);
    };

  return (
    <StyledModal
      destroyOnClose
      visible={visible}
      footer={null}
      closeIcon={null}
    >
      <Content>
        <Question>{question}</Question>
        <ButtonContainer>
          <StyledButton color="gray" onClick={finishHandler(onCancel)}>
            {cancelText}
          </StyledButton>
          <StyledButton onClick={finishHandler(onOk, onOkParameter)}>
            {okText}
          </StyledButton>
        </ButtonContainer>
      </Content>
    </StyledModal>
  );
}

export default ConfirmationModal;
