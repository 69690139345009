import styled, { css } from 'styled-components';

import colors from 'styles/colors';
import { respondTo } from 'styles/mixins';

export const Card = styled.div`
  border-radius: 7px;
  width: 100%;
  height: 3.75rem;
  background-color: #f7f8fa;
  padding: 0.5rem 0.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-bottom: 2.625rem;
`;

export const TextContainer = styled.div`
  display: flex;
  gap: 2.375rem;
  align-items: center;
  max-width: 95%;

  ${respondTo('medium')(css`
    gap: 0.75rem;
  `)}
`;

export const DocumentName = styled.p`
  margin: 0;
  color: ${colors.smoothBlack};
  font-weight: 600;
  font-size: 1.125rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 5rem;
  max-width: 5rem;

  ${respondTo('medium')(css`
    font-size: 0.75rem;
  `)}
`;

export const DocumentIcon = styled.img`
  height: 2.8125rem;

  ${respondTo('medium')(css`
    height: 1.5625rem;
  `)}
`;

export const DocumentIconWithZoom = styled(DocumentIcon)``;

export const Download = styled.img`
  height: 1rem;

  ${respondTo('medium')(css`
    height: 0.6875rem;
  `)}
`;
