import { useResizeDetector } from 'react-resize-detector';

import { Spin, TitledPage } from 'components';
import { useUserInfo } from 'contexts/UserProvider';

import { Wrapper } from './Calendar.style';

function Calendar() {
  const { userData } = useUserInfo();
  const { width, height, ref } = useResizeDetector({
    refreshMode: 'debounce',
    refreshRate: 50,
  });

  const buttons = [];

  return (
    <TitledPage buttons={buttons} hasBack title="Agenda">
      <Wrapper ref={ref}>
        {userData?.calendarUrl ? (
          <iframe
            title="Test"
            src={userData?.calendarUrl}
            width={width}
            height={height}
          />
        ) : (
          <>
            <p>
              O seu calendário ainda está sendo preparado. Ele aparecerá assim
              que for gerado...
            </p>
            <Spin />
          </>
        )}
      </Wrapper>
    </TitledPage>
  );
}

export default Calendar;
