import { useEffect, useState } from 'react';

import TextField from 'components/inputs/TextField';

function EditModal({ setResult, item }) {
  const [text, setText] = useState(item?.name);

  useEffect(() => {
    setResult(text);
  }, [text]);

  return (
    <TextField
      label="Nome"
      width="100%"
      value={text}
      onChange={(e) => setText(e.target.value)}
    />
  );
}

export default EditModal;
