import styled, { css } from 'styled-components';

import colors from 'styles/colors';
import { respondTo } from 'styles/mixins';

export const Container = styled.div`
  display: flex;
  margin: 3.75rem 0;

  ${respondTo('medium')(css`
    margin: 1.8125rem 0;
  `)}
`;

export const Item = styled.div`
  position: relative;
  background-color: ${({ active }) => (active ? colors.green : 'transparent')};
  height: 0.625rem;
  width: 4.3625rem;
  border-radius: 50px;
  margin: 0 5px;

  &::after {
    position: absolute;
    bottom: -2px;
    right: -4px;
    height: 100%;
    width: 100%;
    border-radius: 50px;
    background-color: transparent;
    border: 1px solid black;
    content: '';
  }

  ${respondTo('medium')(css`
    width: 2.6875rem;
  `)}
`;
