import { useEffect, useRef, useState } from 'react';
import { Select } from 'antd';

import alertSvg from 'assets/icons/alert.svg';
import { thisFieldHasRequired } from 'helpers/lookForRequiredOnYupSchema';
import normalizeInputText from 'helpers/normalizeInputText';
import useDebounce from 'hooks/use-debounce';

import {
  Container,
  Error,
  ErrorIcon,
  FloatingLabel,
  StyledSelect,
} from './FloatingLabelSelect.style';

const { Option } = Select;

function FormSelect({
  options,
  onChange,
  value,
  disabled,
  label,
  error,
  mode,
  absoluteError,
  singleTag,
  name,
  schema,
  page,
  hasMore,
  loadMore,
  search,
  onSearch,
  dropdownClassName,
  ...props
}) {
  const isMultiple = mode === 'multiple';
  const [inputValue, setInputValue] = useState('');
  const [, setDisplayOptions] = useState([]);

  const debouncedTerm = useDebounce(inputValue, 1000);

  const [focused, setFocused] = useState(
    isMultiple ? value?.length > 0 : !!value
  );
  const [internValue, setInternValue] = useState(null);

  const selectRef = useRef(null);
  const pageRef = useRef(page);
  const hasMoreRef = useRef(hasMore);

  useEffect(() => {
    pageRef.current = page;
    hasMoreRef.current = hasMore;
  }, [page, hasMore]);

  useEffect(() => {
    setDisplayOptions(() =>
      options?.map((option) => ({
        value: option.id,
        label: option.label,
      }))
    );
  }, [options]);

  useEffect(() => {
    if (!value && inputValue) {
      setInputValue('');
    }
  }, [value]);

  useEffect(() => {
    const optionsIncludeValue =
      Array.isArray(options) &&
      options.find(({ value: optionValue }) =>
        optionValue.toLowerCase().includes(value)
      );

    if (optionsIncludeValue) {
      setInputValue(
        options.filter(({ optionValue }) => optionValue === value)[0]?.label
      );
    }
  }, [options]);

  useEffect(() => {
    if (debouncedTerm === inputValue && value && onSearch) {
      onSearch(debouncedTerm, page);
    }
  }, [debouncedTerm]);

  useEffect(() => {
    pageRef.current = page;
    hasMoreRef.current = hasMore;
  }, [page, hasMore]);

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    if (value === null || value === '' || (isMultiple && value.length === 0)) {
      setFocused(false);
    }
  };

  useEffect(() => {
    if (isMultiple ? value?.length > 0 : value) setFocused(true);
  }, [value]);

  const isSubstring = (text, option) =>
    normalizeInputText(option.children).indexOf(normalizeInputText(text)) !==
    -1;

  const getValue = () => {
    const validValues = options?.map((option) => option.value);

    if (validValues?.length === 0) {
      setInternValue(value);
      return;
    }

    if (mode === 'multiple') {
      if (!Array.isArray(value)) {
        setInternValue(value);
        return;
      }

      const filteredValues = value.filter((id) => validValues?.includes(id));

      setInternValue(() => [...filteredValues]);
      return;
    }

    if (value) {
      if (validValues?.includes(value)) {
        setInternValue(value);
        return;
      }
      if (Array.isArray(validValues)) {
        onChange('');
        setInternValue('');
      }

      return;
    }

    setInternValue(value);
  };

  useEffect(() => {
    getValue();
  }, [options, value]);

  const handleScroll = (e) => {
    const { scrollHeight, scrollTop, clientHeight } = e.target;
    const bottom = scrollHeight - scrollTop === clientHeight;
    if (bottom) loadMore?.({}, pageRef.current);
  };

  return (
    <Container>
      {label && (
        <FloatingLabel focused={focused} htmlFor="cano">
          {`${label} ${thisFieldHasRequired(schema, name) ? '*' : ''}`}
        </FloatingLabel>
      )}
      <StyledSelect
        ref={selectRef}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        name="cano"
        value={internValue}
        disabled={disabled}
        mode={mode}
        loading={!Array.isArray(options) || options?.length === 0}
        filterOption={isSubstring}
        onSearch={(e) => setInputValue(e)}
        $hasItem={value?.length}
        notFoundContent="Não há opções disponíveis"
        onPopupScroll={handleScroll}
        {...props}
      >
        {options?.map((item) => {
          const hidden = isMultiple && value?.includes(item.value);
          return (
            <Option hidden={hidden} key={item.value} value={item.value}>
              {item?.label}
            </Option>
          );
        })}
      </StyledSelect>
      {error && (
        <Error absoluteError={absoluteError}>
          <ErrorIcon src={alertSvg} alt="Ícone de erro" />
          {error}
        </Error>
      )}
    </Container>
  );
}

export default FormSelect;
