import { Row, Select, Tabs } from 'antd';
import styled, { css } from 'styled-components';

import colors from 'styles/colors';

export const Page = styled.div`
  height: 100vh;
  max-height: 100vh;
  padding: 2rem 1rem 2rem 2rem;
  background-color: #f8f8f8;
  margin-right: 0.2rem;
  overflow: hidden;

  ${({ mobile }) =>
    mobile &&
    css`
      padding: 0rem;
    `}
`;

export const NoDataText = styled.h3`
  font-size: 1rem;
  color: #727272;
`;

export const TabContainer = styled(Tabs)`
  margin-top: 0.5rem;
  .ant-tabs-nav-wrap {
    width: 80%;
  }

  .ant-tabs-nav-list {
    width: 70%;
    justify-content: space-between;
  }
  .ant-tabs-ink-bar {
    width: 100%;
    height: 0.2rem !important;
    background-color: ${colors.orange};
  }

  .ant-tabs-tab .ant-tabs-tab-btn,
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
  .ant-tabs-tab-btn:focus {
    color: ${colors.black};
    font-size: 1rem;
  }

  .ant-tabs-tab-disabled .ant-tabs-tab-btn {
    color: ${colors.gray} !important;
  }
`;

export const PageStructure = styled.div`
  display: flex;
  height: 80%;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 0.5rem;
  }

  ::-webkit-scrollbar-track {
    background: #cccccc;
    border-left: 3px solid #f8f8f8;
    border-right: 3px solid #f8f8f8;
  }

  ::-webkit-scrollbar-thumb {
    background: #f7951c;
    border-radius: 4px;
  }
`;

export const Cards = styled.div`
  display: flex;
  margin-bottom: 35px;
`;

export const Title = styled.p`
  font-size: 1.5rem;
  letter-spacing: 0.48px;
  margin: 0;
`;

export const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 22rem);
  grid-gap: 2rem;
  padding-top: 1rem;
  padding-bottom: 6.25rem;
  margin-top: 1.188rem;
  height: fit-content;
  overflow: auto;
`;
export const CardList = styled.div`
  height: fit-content;
  width: fit-content;
  margin: 2.188rem 1.25rem 2.188rem 0rem;
  border-radius: 15px;
`;

export const ScrollBar = styled.div`
  height: fit-content;
  width: fit-content;
  overflow: auto;
`;

export const StyledRow = styled(Row)`
  width: 100%;
`;

export const SideContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
`;

export const CustomMultiSelectWithGroups = styled(Select)`
  width: 80%;
  box-shadow: 8px 5px 10px #e2e2e2;
  border-radius: 15px;

  .ant-select-selector {
    border: none !important;
  }
`;
