import styled from 'styled-components';

import colors from 'styles/colors';

export const Wrapper = styled.div`
  background-color: ${colors.white};
  padding: 2rem 0;
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  gap: 0.8rem;
  padding: 0 1.09rem;
`;

export const Card = styled.div`
  background-color: ${colors.background};
  width: 6.25rem;
  padding: 1rem;
  text-align: center;
  border-radius: 10px;
  box-shadow: 4px 0px 10px ${colors.smoothGray};
`;

export const Title = styled.div`
  font-size: 0.875rem;
  font-weight: 900;
  color: ${colors.textBlue};
`;

export const Icon = styled.img`
  background-color: ${colors.primary};
  width: 1.688rem;
  height: 1.688rem;
  padding: 0.4rem;
  border-radius: 100%;
  margin-bottom: 0.648rem;
`;
