import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import moment from 'moment';
import validator from 'validator';
import * as Yup from 'yup';

import editIcon from 'assets/icons/edit-darker.svg';
import { DatePicker } from 'components/inputs';
import { useConfirmCancel } from 'contexts/ConfirmCancelProvider';
import { useNotification } from 'contexts/NotificationProvider';
import { useUserInfo } from 'contexts/UserProvider/UserProvider';
import getDiffBetweenObjects from 'helpers/getDiffBetweenObjects';
import useRequest from 'hooks/use-request';
import useTouched from 'hooks/use-touched';

import {
  ButtonContainer,
  EditButton,
  EditIcon,
  FormButtonContainer,
  Select,
  StyledFormButton,
  TopButton,
} from './CreateConfigurationModal.style';

function CreateConfigurationModal({
  closeModal,
  editData,
  isCreate = false,
  watchTouched = () => {},
  create = () => {},
  edit: editConfiguration = () => {},
  remove = () => {},
}) {
  const [internEmailValue, setInternEmailValue] = useState('');
  const { userData } = useUserInfo();
  const request = useRequest();
  const { addNotification } = useNotification();
  const confirmCancel = useConfirmCancel();
  const selectsInformations = [
    {
      fieldName: 'regionsIds',
      optionsPath: '/region',
    },
    {
      fieldName: 'userEmails',
      optionsPath: '/user',
    },
  ];

  const periodicityOptions = [
    {
      label: 'Mensal',
      value: 'monthly',
    },
    {
      label: 'Semanal',
      value: 'weekly',
    },
    {
      label: 'Diária',
      value: 'daily',
    },
  ];

  const [selectsOptions, setSelectsOptions] = useState({});
  const [edit, setEdit] = useState(false);

  const initialValues = {
    emails: editData ? editData?.emails : [],
    regionsIds: editData?.regions?.map(({ id }) => id) || [],
    period: editData?.period || '',
    date: editData?.date ? moment(editData?.date) : '',
  };

  useEffect(() => {
    const getOptions = async (fieldName, optionsPath) => {
      await request.get({
        url: optionsPath,
        onSuccess: (data) => {
          if (fieldName === 'userEmails') {
            const initialEmails = initialValues.emails;
            const jointArray = [
              ...new Set([...initialEmails, ...data.map(({ email }) => email)]),
            ];
            const itemsArr = jointArray.map((email) => ({
              value: email,
              label: email,
            }));
            setSelectsOptions((prevSelectsOptions) => ({
              ...prevSelectsOptions,
              [fieldName]: itemsArr,
            }));
          } else {
            const itemsArr = data.map(({ id, name }) => ({
              value: id,
              label: name,
            }));
            setSelectsOptions((prevSelectsOptions) => ({
              ...prevSelectsOptions,
              [fieldName]: itemsArr,
            }));
          }
        },
      });
    };

    selectsInformations.forEach((select) => {
      getOptions(select.fieldName, select.optionsPath);
    });
  }, []);

  const CreateConfigurationSchema = Yup.object().shape({
    emails: Yup.array().required('Este campo é obrigatório'),
    regionsIds: Yup.array().required('Este campo é obrigatório'),
    period: Yup.string().required('Este campo é obrigatório'),
    date: Yup.string().required('Este campo é obrigatório'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: CreateConfigurationSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      const submitValues = {
        ownerId: userData.id,
        ...values,
      };

      if (!edit && isCreate) {
        try {
          await create(submitValues, {});
          closeModal();
        } catch (err) {
          console.log(err);
        }
      } else {
        const firstObject = {
          ...initialValues,
          // eslint-disable-next-line no-underscore-dangle
          date: moment(initialValues.date).format('LLL'),
        };
        const secondObject = {
          ...values,
          // eslint-disable-next-line no-underscore-dangle
          date: moment(values.date).format('LLL'),
        };

        const alteredFields = getDiffBetweenObjects(firstObject, secondObject);

        if (Object.keys(alteredFields).length === 0) {
          setEdit(false);
        } else {
          const patchFields = {};
          Object.keys(alteredFields).forEach((key) => {
            patchFields[key] = submitValues[key];
          });
          try {
            await editConfiguration(editData?.id, patchFields);
            closeModal();
          } catch (err) {
            console.log(err);
          }
        }
      }
    },
  });

  const handleDeactivate = async () => {
    const action = editData?.status === 'active' ? 'disabled' : 'active';
    try {
      await editConfiguration(editData?.id, { status: action });
      closeModal();
    } catch (err) {
      console.log(err);
    }
  };

  const handleRemove = async () => {
    try {
      await remove(editData?.id);
      closeModal();
    } catch (err) {
      console.log(err);
    }
  };

  const onEmailsArrayChange = () => {
    setInternEmailValue('');
  };

  const addEmailOption = (e) => {
    const options = selectsOptions.userEmails?.filter(
      ({ value }) => value.indexOf(internEmailValue) !== -1
    );
    if (e.key === 'Enter') {
      if (internEmailValue && validator.isEmail(internEmailValue)) {
        setSelectsOptions((previous) => {
          if (
            previous?.userEmails?.filter(
              ({ value: val }) => val === internEmailValue
            ).length === 0
          ) {
            const newUserEmails = [
              ...previous?.userEmails,
              {
                value: internEmailValue,
                label: internEmailValue,
              },
            ];
            return {
              ...previous,
              userEmails: newUserEmails,
            };
          }
          return previous;
        });

        formik.setFieldValue('emails', [
          ...formik.values.emails,
          internEmailValue,
        ]);
        setInternEmailValue('');
      } else if (Array.isArray(options) && !options?.length) {
        addNotification({
          message: 'Esse não é um email válido',
          type: 'error',
          description:
            'Certifique-se que digitou um email válido e aperte enter para adicioná-lo ao campo de emails.',
        });
      }
    }
  };

  const touched = useTouched(formik.values, initialValues);
  watchTouched(touched);

  return (
    <form onSubmit={formik.handleSubmit}>
      {!isCreate && (
        <EditButton onClick={() => setEdit(!edit)}>
          <EditIcon src={editIcon} alt="icone-editar" />
          Editar
        </EditButton>
      )}
      {edit && (
        <ButtonContainer>
          <TopButton
            onClick={(e) => {
              e.preventDefault();
              handleDeactivate();
            }}
          >
            {editData?.status === 'active' ? 'Desativar' : 'Ativar'}
          </TopButton>
          <TopButton
            red
            onClick={(e) => {
              e.preventDefault();
              handleRemove();
            }}
          >
            Excluir
          </TopButton>
        </ButtonContainer>
      )}
      <Select
        mode="multiple"
        label="Email"
        name="emails"
        absoluteError
        onChange={(e) => {
          formik.setFieldValue('emails', e);
        }}
        error={formik.errors.emails}
        value={formik.values.emails}
        options={selectsOptions.userEmails}
        onBlur={formik.handleBlur}
        schema={CreateConfigurationSchema}
        disabled={!edit && editData}
        addOption={{
          enabled: true,
          validate: validator.isEmail,
        }}
        placeholder="Digite e pressione enter para e-mail externo"
        onInputKeyDown={addEmailOption}
        onSearch={(e) => setInternEmailValue(e)}
        onSelect={onEmailsArrayChange}
        onDeselect={onEmailsArrayChange}
        searchValue={internEmailValue}
      />
      <Select
        label="Regional"
        name="regionsIds"
        absoluteError
        onChange={(e) => formik.setFieldValue('regionsIds', e)}
        error={formik.errors.regionsIds}
        value={formik.values.regionsIds}
        options={selectsOptions.regionsIds}
        onBlur={formik.handleBlur}
        disabled={!edit && editData}
        mode="multiple"
        schema={CreateConfigurationSchema}
      />
      <Select
        label="Periodicidade"
        name="period"
        absoluteError
        onChange={(e) => formik.setFieldValue('period', e)}
        error={formik.errors.period}
        value={formik.values.period}
        options={periodicityOptions}
        onBlur={formik.handleBlur}
        disabled={!edit && editData}
        schema={CreateConfigurationSchema}
      />
      <DatePicker
        label="Data e Horário"
        name="date"
        single
        formik={formik}
        error={formik.errors.date}
        value={formik.values.date}
        onBlur={formik.handleBlur}
        disabled={!edit && editData}
        schema={CreateConfigurationSchema}
        showTime={{ format: 'HH:mm' }}
        format="YYYY-MM-DD HH:mm"
      />
      <FormButtonContainer>
        <StyledFormButton
          onClick={() => confirmCancel(closeModal, touched)}
          $cancel
        >
          Cancelar
        </StyledFormButton>
        {(edit || isCreate) && (
          <StyledFormButton loading={formik.isSubmitting} htmlType="submit">
            Salvar
          </StyledFormButton>
        )}
      </FormButtonContainer>
    </form>
  );
}

export default CreateConfigurationModal;
