import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';

import PersonImage from 'assets/images/userDefaultImage.svg';
import { Check, CloseOutlined } from 'components/icons/noise';
import { useUserInfo } from 'contexts/UserProvider/UserProvider';

import {
  CameraIcon,
  Picture,
  StyledIconsButton,
  StyledLabel,
  StyledUpdateUpload,
  UploadCallToAction,
  UploadImageFieldWrapper,
} from './ImageField.styles';

const UpdateImageField = ({
  value: fileList,
  setValue: setFileList,
  label,
  pictureUrl,
  onUpdate,
}) => {
  const [sizeFlag, setSizeFlag] = useState(true);
  const { userData } = useUserInfo();
  const history = useHistory();
  const handleChange = ({ fileList: newList }) => {
    if (sizeFlag) {
      setFileList(newList);
    } else {
      setSizeFlag(true);
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const beforeUpload = (file) => {
    const fileSize = file.size / 1024 / 1024;
    const isLessThan2M = fileSize < 2;
    if (!isLessThan2M) {
      message.error('  A imagem deve ter tamanho menor do que 2MB.');
      setSizeFlag(false);
    }
    return false;
  };

  return (
    <UploadImageFieldWrapper>
      <StyledLabel>{label}</StyledLabel>

      {!fileList.length &&
        history.location.pathname.split('/')[2] === userData?.id && (
          <CameraIcon />
        )}

      {!fileList.length && (
        <Picture src={pictureUrl || PersonImage} alt="Avatar do solicitante" />
      )}

      <StyledUpdateUpload
        listType="picture-card"
        className="upload-list-inline"
        fileList={fileList}
        onChange={handleChange}
        disabled={fileList.length}
        beforeUpload={beforeUpload}
        previewFile={toBase64}
        maxCount={1}
        accept="image/png, image/jpeg"
      >
        {!fileList.length &&
        history.location.pathname.split('/')[2] === userData?.id ? (
          <UploadCallToAction>Editar foto</UploadCallToAction>
        ) : (
          ''
        )}
      </StyledUpdateUpload>

      {fileList.length ? (
        <StyledIconsButton>
          <CloseOutlined onClick={() => setFileList([])} />
          <Check onClick={() => onUpdate(fileList)} />
        </StyledIconsButton>
      ) : (
        ''
      )}
    </UploadImageFieldWrapper>
  );
};

export default UpdateImageField;
