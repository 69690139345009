import styled from 'styled-components';

import colors from 'styles/colors';

export const Container = styled.section`
  padding: 2rem 3rem 0rem 3rem;
  height: 100vh;
`;

export const PageTitle = styled.h1`
  height: 2vh;
  color: ${colors.smoothBlack};
  font-size: 1.8rem;
  font-weight: 600;
`;

export const Divider = styled.div`
  height: 0.12rem;
  width: 100%;
  background-color: ${colors.orange};
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  overflow-x: auto;
  height: 72vh;
  ::-webkit-scrollbar {
    height: 6px;
  }

  ::-webkit-scrollbar-track {
    background: ${colors.darkGray};
    border-top: 2px solid ${colors.white};
    border-bottom: 2px solid ${colors.white};
  }

  ::-webkit-scrollbar-thumb {
    width: 0.313rem;
    background: ${colors.persianGreen};
    border-radius: 5px;
  }
`;
