import { useHistory } from 'react-router-dom';

import {
  Can,
  CreateConfigurationButton,
  FilterButton,
  PageTable,
  TitledPage,
} from 'components';
import CreateConfigurationModal from 'components/CreateConfigurationButton/CreateConfigurationModal';
import { useModal, withModalProvider } from 'contexts/ModalProvider';
import { useUserInfo } from 'contexts/UserProvider/UserProvider';
import useCrud from 'hooks/use-crud';
import useMobile from 'hooks/use-mobile';

import columns from './columns';
import { filters } from './filters';
import { ButtonContainer, OptionButton } from './Report.styles';

function Report() {
  const isMobile = useMobile();
  const history = useHistory();
  const { modal } = useModal();
  const { userData } = useUserInfo();
  const [reports, { create, edit, remove }] = useCrud('/report-config', {
    autoinit: true,
    trackQueryParameters: true,
    pagination: true,
    parameters:
      userData?.role === 'admin'
        ? {}
        : {
            ownerId: userData?.id,
          },
  });

  const titleButtons = [
    () =>
      !isMobile ? (
        <Can perform={['report:create']}>
          <CreateConfigurationButton create={create}>
            Criar nova configuração
          </CreateConfigurationButton>
        </Can>
      ) : null,
    () => <FilterButton filters={filters} />,
  ];

  const handleModal = (id) => {
    const modalProps = {
      title: 'Envio automático de relatório',
      footer: null,
      destroyOnClose: true,
      watchTouched: true,
      maskClosable: false,
      closable: false,
    };

    modal(
      (props) => (
        <CreateConfigurationModal
          create={create}
          edit={edit}
          remove={remove}
          editData={
            reports.filter(({ id: identifier }) => identifier === id)[0]
          }
          {...props}
        />
      ),
      modalProps
    );
  };

  const handleDetailButton = ({ id }) => {
    if (isMobile) {
      history.push(`/relatorio/${id}`);
    } else {
      handleModal(id);
    }
  };

  const seeDetailsButton = (props) => (
    <OptionButton onClick={() => handleDetailButton(props)}>
      Ver detalhes
    </OptionButton>
  );

  return (
    <TitledPage buttons={titleButtons} title="Envio automático de relatório">
      {isMobile && (
        <ButtonContainer>
          <Can perform={['report:create']}>
            <CreateConfigurationButton create={create} edit={edit}>
              Criar nova configuração
            </CreateConfigurationButton>
          </Can>
        </ButtonContainer>
      )}
      <PageTable
        dataSource={reports}
        columns={columns}
        mobileTitleColumns={['date', 'emails']}
        extras={{ details: seeDetailsButton }}
      />
    </TitledPage>
  );
}

export default withModalProvider(Report);
