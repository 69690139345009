import { useEffect, useRef, useState } from 'react';
import { Input } from 'antd';

import alertSvg from 'assets/icons/alert.svg';
import { thisFieldHasRequired } from 'helpers/lookForRequiredOnYupSchema';
import useDebounce from 'hooks/use-debounce';

import {
  Error,
  ErrorIcon,
  FloatingAutocompleteWrapper,
  FloatingLabel,
  FloatingLabelSearcher,
} from './AutocompleteInput.style';

const InfiniteScrollAutoComplete = ({
  label,
  onSelect,
  options,
  error,
  value: formValue,
  onBlur: customOnBlur,
  name,
  schema,
  absoluteError = false,
  placeholder = '',
  hasMore,
  loadMore,
  search,
  onSearch,
  page,
  dropdownClassName = '',
  customLabelFormatter = false,
  labelClass = '',
  disabled,
  ...props
}) => {
  const [inputValue, setInputValue] = useState('');
  const [focused, setFocused] = useState(!!inputValue);
  const [displayOptions, setDisplayOptions] = useState([]);
  const debouncedTerm = useDebounce(inputValue, 1000);
  const inputRef = useRef(null);
  const pageRef = useRef(page);
  const hasMoreRef = useRef(hasMore);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    pageRef.current = page;
    hasMoreRef.current = hasMore;
  }, [page, hasMore]);

  const [observer] = useState(
    new MutationObserver((mutations) => {
      if (mutations.length >= 1) {
        const { target } = mutations[0];
        const offset =
          parseFloat(target.style.height, 10) +
          parseFloat(target.style.top, 10);
        if (offset === 256 && hasMoreRef.current) {
          setIsLoading(true);
          loadMore({}, pageRef.current);
        }
      }
    })
  );

  useEffect(() => {
    if (inputValue) setFocused(true);
    else setFocused(false);
  }, [inputValue]);

  useEffect(() => {
    onSearch(debouncedTerm, page);
  }, [debouncedTerm]);

  useEffect(() => {
    if (
      Array.isArray(options) &&
      options.map(({ value }) => value).includes(formValue)
    ) {
      setInputValue(
        options.filter(({ value }) => value === formValue)[0].label
      );
      setIsLoading(false);
    }
  }, [options]);

  const handleBlur = (e) => {
    if (inputRef.current?.state?.value === '') setFocused(false);
    customOnBlur(e);
  };

  const handleSelect = (value, option) => {
    setInputValue(option.label);
    onSelect(value);
  };

  const handleFocus = () => {
    setFocused(true);
  };

  const onChange = (text) => {
    setInputValue(text);
    setIsLoading(true);
  };

  const onKeyDown = (e) => {
    if (e.code === 'Backspace' && formValue) {
      setInputValue('');
      onSelect('');
    }
  };

  useEffect(() => {
    setDisplayOptions(() =>
      options?.map((option) => ({
        value: option.id,
        label: customLabelFormatter
          ? customLabelFormatter(option)
          : option.label,
      }))
    );
    setIsLoading(false);
  }, [options]);

  const onFocus = () => {
    const dropdownComponent = document?.getElementsByClassName(
      `ant-select-dropdown ${dropdownClassName}`
    )[0];
    const scrollBar = dropdownComponent?.querySelector(
      '.rc-virtual-list-scrollbar-thumb'
    );

    if (scrollBar) {
      observer.observe(scrollBar, {
        attributes: true,
        attributeFilter: ['style'],
      });
    }
  };

  useEffect(() => {
    if (focused) {
      observer?.disconnect();
      onFocus();
    }
  }, [displayOptions]);

  useEffect(() => {
    if (!formValue && inputValue) {
      setInputValue('');
    }
  }, [formValue]);

  const onBlur = () => {
    observer?.disconnect();
  };

  useEffect(() => {
    if (disabled) {
      setInputValue(
        options?.filter(({ value }) => value === formValue)[0]?.label
      );
    }
  }, [disabled]);

  return (
    <FloatingAutocompleteWrapper>
      {label && (
        <FloatingLabel focused={focused} htmlFor="cano" className={labelClass}>
          {`${label} ${thisFieldHasRequired(schema, name) ? '*' : ''}`}
        </FloatingLabel>
      )}

      <FloatingLabelSearcher
        dropdownMatchSelectWidth
        disabled={disabled}
        onSelect={handleSelect}
        value={inputValue}
        loading={!Array.isArray(displayOptions) || isLoading}
        options={displayOptions}
        notFoundContent="Não há opções disponíveis"
        absoluteError={absoluteError}
        width={inputRef.width}
        onKeyDown={onKeyDown}
        dropdownClassName={dropdownClassName}
        onFocus={onFocus}
        onBlur={onBlur}
        {...props}
      >
        <Input.Search
          size="large"
          ref={inputRef}
          onChange={(e) => onChange(e.target.value)}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={placeholder}
          loading={!Array.isArray(displayOptions) || isLoading}
        />
      </FloatingLabelSearcher>
      {error && (
        <Error absoluteError={absoluteError}>
          <ErrorIcon src={alertSvg} alt="Ícone de erro" />
          {error}
        </Error>
      )}
    </FloatingAutocompleteWrapper>
  );
};

export default InfiniteScrollAutoComplete;
