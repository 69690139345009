import locale from 'antd/es/date-picker/locale/pt_BR';
import moment from 'moment-timezone';

import alertSvg from 'assets/icons/alert.svg';
import agenda from 'assets/icons/navbar/calendar.svg';
import { thisFieldHasRequired } from 'helpers/lookForRequiredOnYupSchema';

import {
  Border,
  Error,
  ErrorIcon,
  Icon,
  Label,
  SingleDatePicker,
  StyledDatePicker,
  Wrapper,
} from './DatePicker.style';

import 'moment/locale/pt-br';

function DatePicker({
  label,
  formik,
  value: initialValue,
  name,
  schema,
  error,
  absoluteError,
  single,
  disabled,
  disabledDates = () => {},
  renderLabel = true,
  defaultTimezone = true,
  customOnChange = null,
  ...props
}) {
  const handleFormat = (value) =>
    `${moment(value).locale('pt-br').format('DD[ de ]MMMM[ de ]YYYY')}`;

  return (
    <Wrapper>
      <Border>
        {!single ? (
          <>
            <Icon src={agenda} alt="agenda" />
            <StyledDatePicker
              value={initialValue}
              format={(value) => handleFormat(value)}
              locale={locale}
              bordered={false}
              onChange={
                customOnChange ||
                ((values) => {
                  formik.setFieldValue(
                    name,
                    defaultTimezone
                      ? moment.tz(
                          values.format('YYYY-MM-DD HH:mm:ss'),
                          'America/Sao_Paulo'
                        )
                      : moment.tz(
                          values.format('YYYY-MM-DD HH:mm:ss'),
                          'Etc/Greenwich'
                        )
                  );
                })
              }
              {...props}
            />
          </>
        ) : (
          <>
            {renderLabel && (
              <Label>
                {label
                  ? `${label} ${thisFieldHasRequired(schema, name) ? '*' : ''}`
                  : 'Data'}
              </Label>
            )}
            <SingleDatePicker
              value={initialValue}
              disabled={disabled}
              disabledDate={disabledDates}
              format={(value) => handleFormat(value)}
              locale={locale}
              bordered={false}
              onChange={
                customOnChange ||
                ((value) => {
                  formik.setFieldValue(
                    name,
                    defaultTimezone
                      ? moment.tz(
                          value.format('YYYY-MM-DD HH:mm:ss'),
                          'America/Sao_Paulo'
                        )
                      : moment.tz(
                          value.format('YYYY-MM-DD HH:mm:ss'),
                          'Etc/Greenwich'
                        )
                  );
                })
              }
              {...props}
            />
          </>
        )}
      </Border>
      {error && (
        <Error absoluteError={absoluteError}>
          <ErrorIcon src={alertSvg} alt="Ícone de erro" />
          {error}
        </Error>
      )}
    </Wrapper>
  );
}

export default DatePicker;
