import styled, { css, keyframes } from 'styled-components';

import FloatingLabelSelect from 'components/inputs/FormSelect/FloatingLabelSelect';
import colors from 'styles/colors';

const showOverflow = keyframes`
  0% {
    overflow: hidden;
    opacity: 0;
  }

  50% {
    overflow: visible;
  }

  100% {
    opacity: 1;
  }
`;

const hideOverflow = keyframes`
  0% {
    overflow: visible;
    opacity: 1;
  }

  100% {
    overflow: hidden;
    opacity: 0;
  }
`;

export const DropDown = styled.div`
  width: 100%;
  height: calc(2.5rem + 1.8125rem);
  max-height: 0rem;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
  animation: ${hideOverflow} 0.5s linear forwards;

  ${({ active }) =>
    active &&
    css`
      max-height: calc(2.5rem + 1.8125rem);
      overflow: visible;
      animation: ${showOverflow} 0.5s linear forwards;
    `}
`;

export const Select = styled(FloatingLabelSelect)`
  margin: 0;
`;

export const SectionTitle = styled.h2`
  font-size: 1rem;
  font-weight: 600;
  color: ${colors.smoothBlack};
  margin: 0;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  border-top: 1px solid #b1b1b1;
  display: flex;
  justify-content: space-around;
  padding: 1.5625rem 0 0 0;
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  height: 1.6875rem;
  width: 7.6875;
  margin: 0;
  padding: 0.3125rem 1.5rem;
  outline: none;
  border: none;
  border-radius: 50px;
  background-color: ${colors.gray};
  color: ${colors.smoothBlack};
  font-weight: 600;
  font-size: 0.875rem;

  ${({ submit }) =>
    submit &&
    css`
      background-color: ${colors.darkGreen};
      color: ${colors.white};
    `}

  ${({ next }) =>
    next &&
    css`
      background-color: ${colors.primary};
      color: ${colors.black};
    `}
`;
