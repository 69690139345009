import { ThemeProvider } from 'styled-components';

import theme from 'styles/theme';

import AuthProvider from './AuthProvider';
import ConfirmationProvider from './ConfirmationProvider';
import ConfirmCancelProvider from './ConfirmCancelProvider';
import NotificationProvider from './NotificationProvider';
import ToastProvider from './ToastProvider';
import UserProvider from './UserProvider';

function GlobalProvider({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <ConfirmCancelProvider>
        <NotificationProvider>
          <ToastProvider>
            <AuthProvider>
              <UserProvider>
                <ConfirmationProvider>{children}</ConfirmationProvider>
              </UserProvider>
            </AuthProvider>
          </ToastProvider>
        </NotificationProvider>
      </ConfirmCancelProvider>
    </ThemeProvider>
  );
}

export const withGlobalProvider = (Component) => (props) =>
  (
    <GlobalProvider>
      <Component {...props} />
    </GlobalProvider>
  );

export default GlobalProvider;
