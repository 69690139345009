module.exports =  ({
  white: '#FFFFFF',
  black: '#111111',
  background: '#f8f8f8',
  softOrange: '#ffe7c7',
  primary: '#FFCF8F',
  primaryLight: '#FFCF8F99',
  primaryDarker: '#F88122',
  secondary: '#F7951C',
  bodyBackground: '#FFFFFF',
  success: '#009B90',
  successAlert: '#52AF61',
  warning: '#AE8F0C',
  error: '#D94848',
  wine: '#ae2b0c',
  info: '#0CAEAE',
  text: '#000000',
  lessOpacityText: '#00000029',
  textSecondary: '#707070',
  textBlue: '#2C3E50',
  textBlueCustom: (opacity = 1) => `rgba(44, 62, 80, ${opacity})`,
  lightBlueText: '#5f6979',
  darkGray: '#E9EAF2',
  lightGray: '#727272',
  salmon: '#F5C1A7',
  roseRed: '#CB6969',
  grassGreen: '#558B2F',
  darkGreen: '#189B00',
  grayBlue: '#595F6E',
  gray: '#CCCCCC',
  green: '#009B90',
  yellow: '#EDC557',
  smoothGray: '#F0F0F0',
  shadow: '#e2e2e2',
  red: '#ED6857',
  transparentRed: (opacity = 1) => `rgba(237, 104, 87, ${opacity})`,
  orange: '#F7951C',
  lightOrange: '#FDBD66',
  aquaBlueLight: '#98CEC8',
  smoothBlack: '#2C3E50',
  persianGreen: '#009b90',
  disabledGray: '#ABB2B9',
  babyBlue: 'rgba(152, 206, 200, 0.15)',
  cyanGray: 'rgba(152, 206, 200, 0.1)',
  glassGray: (opacity = 1) => `rgba(247, 248, 250, ${opacity})`,
  cyanGreen: (opacity = 1) => `rgba(0, 155, 144, ${opacity})`,
  orangeRed: (opacity = 1 ) => `rgba(237, 104, 87, ${opacity})`,
  lightYellow: (opacity = 1) => `rgba(237, 197, 87, ${opacity})`,
  grayText: '#516579',
  shadowGray: '#D4D8E1',
  lighterGray: '#F1F3FA',
  smootherGray: '#E5E8F0',
  dividerColor: (opacity = 1) => `rgba(220, 224, 233, ${opacity})`
});
