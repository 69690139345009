import styled, { css } from 'styled-components';

import colors from 'styles/colors';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const Track = styled.div`
  position: relative;
  width: 80%;
  height: 0.625rem;
  background-color: #e6e6e6;
  margin-left: 0.6875rem;
  margin-bottom: 5.0625rem;

  ${({ progress = 0 }) => css`
    background-image: linear-gradient(
      90deg,
      ${colors.primary} ${progress}%,
      #e6e6e6 ${progress}%
    );
  `}
`;

export const ItemContainer = styled.div`
  position: absolute;
  top: -0.34375rem;
  width: 25%;

  ${({ amount, index }) => css`
    left: calc(${(100 / (amount - 1)) * index}% - 0.6875rem);
  `}
`;

export const ItemNumber = styled.p`
  margin: 0;
  border: 1px solid ${colors.green};
  background-color: #e6e6e6;
  color: ${colors.white};
  width: 1.375rem;
  height: 1.375rem;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;

  ${({ active }) =>
    active &&
    css`
      background-color: ${colors.green};
    `}
`;

export const ItemText = styled.p`
  margin: 0;
  font-size: 0.75rem;
  color: ${colors.smoothBlack};
  font-weight: 600;
`;
