import { Row } from 'antd';

import { TableAdder } from 'components';

import { Container, Content, Divider, PageTitle } from './Configurations.style';

function Configuration() {
  return (
    <Container>
      <Row gutter={[50, 35]}>
        <PageTitle>Cadastros da plataforma</PageTitle>
        <Divider />
        <Content>
          <TableAdder
            title="Regionais"
            label="Adicionar regional"
            path="region"
          />
          <TableAdder
            title="Tipos de demanda"
            label="Adicionar tipo de demanda"
            path="call-motive"
          />
          <TableAdder
            title="Cargo"
            label="Adicionar cargo"
            path="type-operator"
          />
          <TableAdder
            title="Perfil do solicitante"
            label="Adicionar tipo de solicitante"
            path="type-requester"
          />
          <TableAdder
            title="Tipos de documento"
            label="Adicionar tipo de documento"
            path="document-type"
          />
          <TableAdder
            title="Status do solicitante"
            label="Adicionar status do solicitante"
            path="requester-status"
          />
          <TableAdder
            title="Canais de Atendimento"
            label="Adicionar canais de atendimento"
            path="channel"
          />
        </Content>
      </Row>
    </Container>
  );
}

export default Configuration;
