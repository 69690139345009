import { useState } from 'react';

import {
  ButtonContainer,
  Content,
  Question,
  StyledButton,
  StyledModal,
} from '../../ConfirmationModal.style';

export const CustomConfirmationModal = ({
  children,
  question = '',
  cancelText = 'Cancelar',
  okText = 'Sim',
  handleOnConfirm = () => () => {},
  handleOnCancel = ({ onClose, setLoading }) =>
    () => {
      onClose();
      setLoading(false);
    },
}) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const onOpen = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const modalProps = {
    isOpen: visible,
    onOpen,
    onClose,
    loading,
    setLoading,
  };

  return (
    <>
      {children(modalProps)}

      <StyledModal
        destroyOnClose
        visible={visible}
        footer={null}
        closeIcon={null}
      >
        <Content>
          <Question>{question}</Question>
          <ButtonContainer>
            <StyledButton
              disabled={loading}
              color="gray"
              onClick={handleOnCancel(modalProps)}
            >
              {cancelText}
            </StyledButton>
            <StyledButton
              loading={loading}
              onClick={handleOnConfirm(modalProps)}
            >
              {okText}
            </StyledButton>
          </ButtonContainer>
        </Content>
      </StyledModal>
    </>
  );
};
