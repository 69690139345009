/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import { Bar } from 'react-chartjs-2';

import colors from 'styles/colors';

import {
  Card,
  Container,
  Option,
  ScrollBar,
  StyledSelect,
  StyledSpin,
  Title,
  TitleContainer,
} from './BarChart.style';
import {
  options,
  scrollOptions,
  secondaryOptions,
  unifiedAttendancesOptions,
  unifiedAttendancesScrollOptions,
  unifiedAttendancesSecondaryOptions,
} from './variables';

import './RoundedBar';

function BarChart({
  title,
  data,
  labels,
  secondary,
  height,
  filter,
  setSelectedGraph,
  selectedGraph,
  thickness,
  scroll,
  type,
}) {
  const chartData = {
    labels,
    datasets: [
      {
        backgroundColor: colors.aquaBlueLight,
        hoverBackgroundColor: colors.green,
        data,
        barThickness: thickness || 28,
      },
    ],
  };
  const handleGraphChange = (value) => {
    setSelectedGraph(value);
  };

  return (
    <Card>
      <TitleContainer>
        <Title>{title}</Title>
        {filter && (
          <StyledSelect
            dropdownMatchSelectWidth={false}
            placeholder="Filtrar"
            icon={null}
            onChange={handleGraphChange}
            // defaultValue={selectedGraph}
          >
            <Option value="mensal-volume">Volume mensal</Option>
            <Option value="weekly-volume">Volume semanal</Option>
            <Option value="hour-volume">Volume por horário na semana</Option>
          </StyledSelect>
        )}
      </TitleContainer>
      {scroll ? (
        <ScrollBar>
          <Bar
            data={chartData}
            options={
              type === 'unifiedAttendance'
                ? unifiedAttendancesScrollOptions
                : scrollOptions
            }
            height={260}
            width={1200}
          />
        </ScrollBar>
      ) : (
        <Container height={height}>
          {data === undefined ? (
            <StyledSpin />
          ) : (
            <Bar
              data={chartData}
              options={
                type === 'unifiedAttendances'
                  ? secondary
                    ? unifiedAttendancesSecondaryOptions
                    : unifiedAttendancesOptions
                  : secondary
                  ? secondaryOptions
                  : options
              }
            />
          )}
        </Container>
      )}
    </Card>
  );
}

export default BarChart;
