import { createContext, useContext, useState } from 'react';

import { ConfirmCancelModal } from 'components';
import { CloseIcon } from 'components/Modal';

const ConfirmCancelContext = createContext(null);

function ConfirmCancelProvider({ children }) {
  const [visible, setVisible] = useState(false);
  const [callback, setCallback] = useState(() => () => {});

  const closeModal = () => setVisible(false);

  const confirmCancel = (afterConfirm, touched) => {
    if (!touched) {
      afterConfirm();
      return;
    }

    setVisible(true);
    setCallback(() => afterConfirm);
  };

  return (
    <ConfirmCancelContext.Provider value={confirmCancel}>
      {children}
      <ConfirmCancelModal
        visible={visible}
        centered
        onCancel={closeModal}
        closeIcon={<CloseIcon />}
        zIndex={100000}
        width="20rem"
        okText="Sim"
        okButtonProps={{
          shape: 'round',
        }}
        cancelText="Cancelar"
        cancelButtonProps={{
          shape: 'round',
          type: 'primary',
          className: 'modal-button-cancel',
        }}
        title="Atenção!"
        closable={false}
        onOk={() => {
          closeModal();
          setTimeout(callback, 200);
        }}
      >
        Ao descartar você vai perder as informações que preencheu. Deseja
        descartar esta ação?
      </ConfirmCancelModal>
    </ConfirmCancelContext.Provider>
  );
}

export const useConfirmCancel = () => useContext(ConfirmCancelContext);

export default ConfirmCancelProvider;
