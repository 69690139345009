import { Button, Image } from 'antd';
import styled, { css } from 'styled-components';

import colors from 'styles/colors';
import { respondTo } from 'styles/mixins';

export const Card = styled.div`
  height: fit-content;
  background-color: white;
  max-width: 29.5rem;
  border-bottom: 1px solid ${colors.gray};
  border-radius: 15px;
  margin-bottom: 2rem;
  box-shadow: 8px 5px 10px ${colors.shadow};
`;

export const Content = styled.div`
  padding: 1.8rem 2.2rem;
  ${({ mobile }) =>
    mobile &&
    css`
      padding: 1.25rem;
    `}
`;

export const Text = styled.div`
  font-size: 0.875rem;
  font-family: 'Fira Sans';
  letter-spacing: 0.02rem;
  color: #727272;
  margin-bottom: 1.25rem;
`;

export const SecondaryText = styled.div`
  ${respondTo('medium')(css`
    display: none;
  `)}
  display: flex;
  font-size: 1.125rem;
  font-family: 'Fira Sans';
  letter-spacing: 0.023rem;
  color: #727272;
  margin-bottom: 1rem;
`;

export const Title = styled.div`
  display: flex;
  font-size: 1.125rem;
  font-weight: 600;
  letter-spacing: 0.023rem;
  color: #000000;
  margin-bottom: 0.5rem;
`;

export const SecondTitle = styled.div`
  font-size: 1.25rem;
  letter-spacing: 0.025rem;
  line-height: 1.7rem;
  margin-bottom: 0.8rem;
  font-weight: bold;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
`;

export const CitizenData = styled.div`
  margin-bottom: 1.25rem;
`;

export const CitizenImage = styled(Image)`
  border-radius: 100%;
  height: 2.8rem;
  width: 2.8rem;
`;

export const Name = styled.div`
  font-size: 1.125rem;
  font-weight: 600;
  letter-spacing: 0.023rem;
  margin-bottom: 0.5rem;
`;

export const CitizenIcon = styled.img`
  margin-right: 0.625rem;
`;

export const FooterButton = styled(Button)`
  height: 1.7rem;
  background-color: ${colors.green};
  border-radius: 20px;
  min-width: 7.5rem;
  font-weight: 600;
  font-size: 0.875rem;
  border: none;
  color: ${colors.white};

  &:hover,
  &:focus {
    background-color: ${colors.info};
    color: ${colors.white};
    outline: none;
  }
`;

export const InfoText = styled.div`
  font-size: 1rem;
  margin-bottom: 0.4rem;
  width: 90%;
  word-wrap: break-word;
`;

export const Bold = styled.span`
  font-weight: 600;
`;

export const Italic = styled.div`
  display: flex;
  font-size: 1rem;
  font-style: italic;
  font-weight: bold;
`;
