import { Checkbox, Drawer, Tag as AntdTag } from 'antd';
import styled, { css } from 'styled-components';

import colors from 'styles/colors';
import { respondTo } from 'styles/mixins';

export const LoaderContainer = styled.div`
  padding: 3px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    width: 25rem !important;

    ${respondTo('small')(css`
      width: 100% !important;
    `)}
  }
  .ant-drawer-content {
    overflow: visible;
  }
  .ant-drawer-content,
  .ant-drawer-header {
    background-color: #f1f3fa;
    overflow: visible;
  }

  .ant-drawer-header-no-title {
    background-color: transparent;
    & .anticon-close {
      color: ${colors.black};
      font-size: 1.2rem;
    }
  }
  .ant-drawer-close {
    position: relative;
    margin-left: auto;
  }

  .ant-drawer-header {
    height: 10.125rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-drawer-title {
      font-weight: 600;
      font-size: 1.5rem;
      letter-spacing: 0.06rem;
      color: ${colors.smoothBlack};
    }
  }

  .ant-drawer-body {
    padding-left: 0 !important;
    padding-right: 0 !important;
    position: relative;
    overflow-y: visible;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const HeaderContainer = styled.div`
  margin-top: 2.5rem;
  width: 100%;
  margin-bottom: 2.5rem;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;

  & > h1 {
    margin-bottom: 0;
    font-weight: 600;
    color: ${colors.textBlue};
    font-size: 1.2rem;
  }

  & > span {
    background-color: ${colors.success};
    font-size: 1.2rem;
    padding: 0.25rem;
    border-radius: 100%;
    position: absolute;
    left: 2rem;
    top: 50%;
    transform: translateY(-50%);

    & path {
      fill: ${colors.white};
    }
  }
`;

export const TagsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > h1:first-child {
    font-size: 0.9rem;
    padding-left: 1rem;
    font-weight: 600;
    color: ${colors.success};
    text-transform: uppercase;
  }
`;

export const TagsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: 1rem;
  margin-bottom: 2rem;
`;

export const Tag = styled(AntdTag)`
  background-color: ${colors.success};
  border-radius: 6px;
  padding: 0.25rem 2rem 0.25rem 1rem;
  color: ${colors.white};
  font-weight: 300;
  margin-bottom: 0.5rem;
  overflow: hidden;
  max-width: 20rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;

  & .filterType {
    font-weight: 600;
  }

  .ant-tag-close-icon {
    color: ${colors.white};
    margin-left: 1rem;
    font-size: 0.8rem;
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const FilterItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2rem;
  width: 100%;

  & > h1:first-child {
    font-size: 0.9rem;
    padding-left: 1rem;
    font-weight: 600;
    color: ${colors.black};
    text-transform: uppercase;
  }
`;

export const ItemButton = styled.button`
  border: none !important;
  cursor: pointer;
  width: 100%;
  font-size: 0.9rem;
  color: ${colors.black};
  background-color: transparent;
  outline: none !important;
  height: 2rem;
  text-align: start;
  padding: 0 1rem 0 2rem;
  transition: background-color 1s ease;

  &:hover {
    background-color: ${colors.shadowGray};
  }
`;

export const BoxContainer = styled.div`
  width: 18rem;
  height: auto;
  background-color: ${colors.lighterGray};
  position: fixed;
  right: 27rem;
  top: 50%;
  opacity: 1;
  transform: scale(0) translateY(-50%);
  z-index: 10;
  transition: opacity 0.25s ease-in, transform 0.25s ease-in;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 3px 3px 10px ${colors.gray};

  &.fadeOutClass {
    opacity: 0;
    transform: scale(0);
  }

  &.fadeInClass {
    transform: scale(1) translateY(-50%);
  }

  ${respondTo('medium')(css`
    right: 3.5rem;
  `)}

  ${respondTo('small')(css`
    right: initial;
    left: 50%;

    &.fadeInClass {
      transform: translate(-50%, -50%) scale(1) !important;
    }

    &.fadeOutClass {
      transform: translate(-50%, -50%) scale(0) !important;
    }
  `)}
`;

export const BoxTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background-color: ${colors.textBlueCustom(0.15)};
  padding: 0.5rem;

  & > h1 {
    font-size: 0.9rem;
    font-weight: 600;
    color: ${colors.textBlue};
    margin-bottom: 0;
  }
`;

export const BoxSearchInput = styled.input`
  width: 90%;
  align-self: center;
  margin-top: 1rem;
  background-color: ${colors.smootherGray};
  border: none;
  outline: none;
  padding: 0.5rem;
  border-radius: 4px;
  font-size: 0.8rem;
  color: ${colors.black};

  &::placeholder {
    color: ${colors.black} !important;
    opacity: 1;
  }
`;

export const CheckboxContainer = styled.div`
  padding: 1rem 0;
  width: 100%;

  & > div {
    padding-left: 1rem;
    padding-right: 1rem;
    max-height: 8rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: auto;
    width: 95%;
    ::-webkit-scrollbar {
      width: 0.25rem;
    }
    ::-webkit-scrollbar-track {
      background-color: ${colors.gray};
      border-left: 1.5px solid ${colors.lighterGray};
      border-right: 1.5px solid ${colors.lighterGray};
    }
    ::-webkit-scrollbar-thumb {
      background: ${colors.persianGreen};
      border-radius: 2px;
    }

    & > label {
      margin-left: 0 !important;
      margin-bottom: 0.3rem !important;

      & > span:nth-child(2) {
        color: ${colors.textBlue};
        font-size: 0.8rem;
      }
    }
  }
`;

export const AntdCheckbox = styled(Checkbox)`
  ${({ visibility = true }) => {
    if (!visibility)
      return css`
        display: none;
      `;
    return css`
      display: flex;
    `;
  }}
  .ant-checkbox {
    &::after {
      border: none !important;
    }

    &:hover .ant-checkbox-inner {
      border-color: ${colors.textBlue} !important;
    }
  }

  .ant-checkbox-inner {
    border-radius: 0;
    background-color: transparent;
    border: 1.5px solid ${colors.textBlue} !important;

    &::after {
      border-color: ${colors.textBlue};
    }
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${colors.textBlue} !important;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 0.0625rem;
  background-color: ${({ color = colors.dividerColor(0.75) }) => color};
  margin-bottom: ${({ marginBottom = '2rem' }) => marginBottom};
  margin-left: 1rem;

  & > h1 {
    font-size: 0.9rem;
    font-weight: 600;
    color: ${colors.black};
    text-transform: uppercase;
  }
`;

export const FiltersSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > h1:first-child {
    font-size: 0.9rem;
    padding-left: 1rem;
    font-weight: 600;
    color: ${colors.black};
    text-transform: uppercase;
  }
`;
