export const filters = [
  {
    sectionTitle: "Tempo",
    filterTypes: [
      {
        title: "Periodicidade",
        callToAction: "Seleciona a ou as periodicidades",
        boxTitle: "Periodicidades",
        hasSearchInput: false,
        name: "periods",
        options: [
          {
            value: "daily",
            label: "Diário",
          },
          {
            value: "weekly",
            label: "Semanal",
          },
          {
            value: "monthly",
            label: "Mensal",
          },
        ],
      },
    ],
  },
  {
    sectionTitle: "Território",
    filterTypes: [
      {
        title: "Regional",
        callToAction: "Selecione a ou as regiões",
        boxTitle: "Regiões",
        hasSearchInput: true,
        inputPlaceholder: "Pesquise aqui por uma região...",
        name: "regionsIds",
        options: "/region",
      },
    ],
  },
];
