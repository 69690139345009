import { TimePicker } from 'antd';
import styled, { css } from 'styled-components';

import colors from 'styles/colors';

export const SingleTimePicker = styled(TimePicker)`
  margin: 0.25rem 0;
  width: 100%;
  .ant-picker-suffix {
    display: none;
  }
  .ant-picker-input > input {
    padding-left: 0.5rem;
    text-align: left;
    font-weight: 500;
    font-size: 0.8rem;
    color: ${colors.textBlue};
  }
  .ant-picker-active-bar {
    background: ${colors.green};
  }

  a {
    color: ${colors.green};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      .ant-picker-input > input {
        color: ${colors.disabledGray};
      }
    `}
`;

export const Label = styled.label`
  position: absolute;
  transition: top 0.2s ease-in-out, left 0.2s ease-in-out,
    color 0.2s ease-in-out, font-size 0.2s ease-in-out;
  left: 1rem;
  transform: translateY(-50%);
  background-color: white;
  padding: 0 0.25rem;
  pointer-events: none;
  border-radius: 5px;
  font-size: 0.85rem;
  font-weight: 500;
  color: ${colors.smoothBlack};
`;

export const Wrapper = styled.div`
  margin: 1.825rem 0;
  position: relative;
`;

export const Border = styled.div`
  border-radius: 8px;
  border: 1px solid ${colors.green};
  margin-bottom: 0.5rem;
`;

export const Icon = styled.img`
  margin-left: 0.8rem;
`;

export const Error = styled.p`
  color: ${colors.textSecondary};
  opacity: 1;
  font-style: italic;
  font-size: 0.875rem;
  margin: 0;
  ${({ absoluteError }) =>
    absoluteError &&
    css`
      position: absolute;
      margin-top: 0;
      bottom: -0.125rem;
      transform: translateY(100%);
    `}
`;

export const ErrorIcon = styled.img`
  width: 0.875rem;
  margin-right: 0.625rem;
`;
