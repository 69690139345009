import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Button } from 'components/inputs';
import { gray, textSecondary } from 'styles/colors';
import { flex, respondTo } from 'styles/mixins';

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  max-height: 31.25rem;
  width: 80%;
  height: fit-content;
  margin-bottom: 6rem;

  ${respondTo('medium')(css`
    width: 100%;
  `)}
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: 2rem;
  width: 100%;
  text-align: center;
  line-height: 2.375rem;
  margin-bottom: 2rem;

  ${respondTo('large')(css`
    font-size: 1.5rem;
  `)}
  ${respondTo('medium')(css`
    font-size: 1.125rem;
  `)}
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 80%;
`;

export const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 0.5rem;
  ${flex('center', 'center', 'column')};
`;

export const SubText = styled.p`
  width: 100%;
  font-size: 0.9rem;
  letter-spacing: 0.36px;
  color: ${textSecondary};
  margin-top: ${({ marginTop }) => (marginTop ? '2.2rem' : 0)};
  text-align: ${({ center }) => (center ? 'center' : 'left')};
`;

export const TextContainer = styled.div`
  width: 95%;
  align-self: flex-start;
`;

export const StyledLink = styled(Link)`
  text-decoration: underline;
  color: inherit;
`;

export const StyledButton = styled(Button)`
  margin-top: 2rem;
`;

export const Next = styled(Button)`
  width: 11rem;
`;

export const Previous = styled(Button)`
  width: 11rem;
  background-color: ${gray};
  box-shadow: 0px 0px 10px ${gray};

  &:hover {
    background-color: ${gray};
  }

  margin-right: 2rem;
`;

export const Actions = styled.div`
  margin-top: 2rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
