import {
  AntdPagination,
  BodyInfo,
  Divider,
  ExtraContainer,
  ItemBody,
  ItemExtras,
  MobileItemContentTag,
  MobileItemTitleContainer,
  MobileItemTitleTag,
  MobileTableContainer,
  MobileTableItem,
} from './MobileTable.style';
import { Loading, LoadingRow, NoDataParagraph } from './PageTable.style';

function MobileTable({
  mobileTitleColumns,
  columns,
  extras,
  dataSource,
  itemsAmount = 0,
  handlePagination,
  currentPage,
  loading = false,
}) {
  const titleItemsInformation = [];
  const contentItemsInformation = [];
  const extraItemsInformation = [];

  columns.forEach((column, index) => {
    if (mobileTitleColumns.length === 0 && index === 0) {
      titleItemsInformation.push(column);
    } else if (mobileTitleColumns.includes(column.dataIndex)) {
      titleItemsInformation.push(column);
    } else if (Object.keys(extras).includes(column.dataIndex)) {
      extraItemsInformation.push(column);
    } else {
      contentItemsInformation.push(column);
    }
  });

  const Title = ({ content }) => (
    <MobileItemTitleContainer>
      {titleItemsInformation.map(
        ({ dataIndex, render, key, extraContent = [] }) => {
          const bonusContent = {};
          extraContent.forEach((field) => {
            bonusContent[field] = content[field];
          });
          return (
            <MobileItemTitleTag key={key}>
              {render
                ? render(content[dataIndex], bonusContent)
                : content[dataIndex].toString()}
            </MobileItemTitleTag>
          );
        }
      )}
    </MobileItemTitleContainer>
  );

  const RenderInstances = () =>
    dataSource.length > 0 ? (
      dataSource.map((data) => (
        <MobileTableItem key={data.id} title={<Title content={data} />}>
          <ItemBody>
            {contentItemsInformation.map(
              ({ dataIndex, render, key, title, extraContent = [] }) => {
                const bonusContent = {};
                extraContent.forEach((field) => {
                  bonusContent[field] = data[field];
                });
                return (
                  <BodyInfo key={key}>
                    <MobileItemContentTag>{title}</MobileItemContentTag>
                    <MobileItemContentTag>
                      {render
                        ? render(data[dataIndex], bonusContent)
                        : data[dataIndex].toString()}
                    </MobileItemContentTag>
                  </BodyInfo>
                );
              }
            )}
          </ItemBody>
          <ItemExtras>
            <Divider />
            {extraItemsInformation.map(({ dataIndex, key }) => (
              <ExtraContainer key={key}>
                {extras[dataIndex](data, key)}
              </ExtraContainer>
            ))}
          </ItemExtras>
        </MobileTableItem>
      ))
    ) : (
      <NoDataParagraph>Não há dados</NoDataParagraph>
    );

  return (
    <MobileTableContainer mode="inline">
      {!loading ? (
        <RenderInstances />
      ) : (
        <LoadingRow>
          <Loading />
        </LoadingRow>
      )}

      {itemsAmount > 0 && (
        <AntdPagination
          defaultCurrent={1}
          current={currentPage}
          total={itemsAmount}
          showSizeChanger={false}
          onChange={handlePagination}
        />
      )}
    </MobileTableContainer>
  );
}

export default MobileTable;
