import styled, { css } from 'styled-components';

import { ReactComponent as BallSvg } from 'assets/noise/big_upper_right.svg';
import colors from 'styles/colors';
import { respondTo } from 'styles/mixins';

import { positionCss, sizeCss } from './default.style';

const BigBall = styled(BallSvg)`
  ${positionCss}
  ${sizeCss}
  .big-ball-a {
    fill: ${({ $color }) => colors[$color] || $color || 'white'} !important;
  }
  transform: ${({ $rotatePos }) => $rotatePos && 'rotateZ(180deg)'};

  ${respondTo('medium')(css`
    display: none;
  `)}
`;

export default BigBall;
