import ProfilePicture from 'assets/images/profile.png';
import { BaseCard } from 'components';
import useMobile from 'hooks/use-mobile';

import {
  Button,
  CitizenData,
  CitizenImage,
  Container,
  Info,
  Name,
} from './CitizenCard.style';

function CitizenCard({ name, date, status }) {
  const isMobile = useMobile();
  return (
    <BaseCard resident={isMobile} padding="35px">
      <Container>
        <CitizenImage
          preview={false}
          src={ProfilePicture}
          alt="Avatar padrão do solicitante"
        />
        <CitizenData>
          <Name>{name}</Name>
          <Info>{`Cadastrado ${date}`}</Info>
          <Info>{`Status: ${status}`}</Info>
          <Button>Ver detalhes</Button>
        </CitizenData>
      </Container>
    </BaseCard>
  );
}

export default CitizenCard;
