import styled from 'styled-components';

import { Button as AntButton } from 'components/inputs';
import colors from 'styles/colors';

export const Button = styled(AntButton)`
  width: max-content;
  font-size: 1rem;
  margin: 0 0.875rem;
  padding: 0 2rem;
  height: 3.125rem;
`;

export const Bold = styled.p`
  margin: 0;
  color: ${colors.smoothBlack};
  font-weight: 600;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0.5rem;
  margin-bottom: 2rem;
`;

export const OptionButton = styled.button`
  background-color: ${colors.persianGreen};
  color: white;
  font-size: 0.875rem;
  border-radius: 50px;
  font-weight: 600;
  min-height: 1.6875rem;
  padding: 0.3125rem 1.25rem;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  line-height: 1.0625rem;
`;
