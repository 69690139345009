/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import * as Yup from 'yup';

import alertSvg from 'assets/icons/alert.svg';
import {
  DatePicker,
  InfiniteScrollAutoComplete,
  TimePicker,
} from 'components/inputs/';
import { useConfirmCancel, useNotification, useUserInfo } from 'contexts';
import {
  callMotiveAutocompleteLabelFormatter,
  callMotiveMatchFunction,
  techniqueAutocompleteLabelFormatter,
  techniqueAutocompleteMatchFunction,
} from 'helpers';
import { useCrud, useRequest, useTouched } from 'hooks';
import customNotificationMessages from 'hooks/use-request/services/notification/customNotificationMessages.json';

import { useLocalStorageState } from './hooks/useLocalStorageState';
import {
  disabledDates,
  disablePastHours,
  getReminderDatetime,
} from './helpers';
import {
  Error,
  ErrorIcon,
  FormButtonContainer,
  Input,
  RadioButton,
  RadioContainer,
  Select,
  StyledCheckbox,
  StyledFormButton,
  StyledSwitch,
} from './NewUnifiedAttendanceModal.style';

import 'moment/locale/pt-br';

export const NEW_UNIFIED_ATTENDANCE_LS_KEY = 'NEW_UNIFIED_ATTENDANCE_LS_KEY';

function NewUnifiedAttendanceModal({
  closeModal,
  watchTouched = () => {},
  updateList,
}) {
  const { userData } = useUserInfo();
  const { id: requesterParamId } = useParams();
  const confirmCancel = useConfirmCancel();
  const request = useRequest();
  const [selectsOptions, setSelectsOptions] = useState({});
  const { addNotification } = useNotification();

  const [
    callMotives,
    {
      get: getCallMotives,
      getMore: getMoreCallMotives,
      hasMore: hasMoreCallMotives,
      page: pageCallMotives,
    },
  ] = useCrud('/call-motive', {
    pagination: { itemsPerPage: 10 },
  });

  const [
    users,
    {
      get: getUsers,
      getMore: getMoreUsers,
      hasMore: hasMoreUsers,
      page: pageUsers,
    },
  ] = useCrud('/user', {
    parameters: {
      roles: 'technique',
    },
    pagination: { itemsPerPage: 10 },
  });

  const availableDates = (current) => {
    const currentDate = moment(current).format('LL');
    const momentsArray = selectsOptions.availableTimes?.map(({ startDate }) =>
      moment(startDate).format('LL')
    );

    const currentDateTimes = selectsOptions?.availableTimes?.filter(
      ({ startDate }) =>
        moment(startDate).format('LL') === currentDate &&
        moment().isBefore(moment(startDate))
    );

    return !momentsArray?.includes(currentDate) || currentDateTimes.length <= 0;
  };

  const UnifiedAttendanceSchema = Yup.object().shape({
    confidential: Yup.boolean(),
    date: Yup.string().required('Este campo é obrigatório'),
    requesterId: Yup.string().required('Este campo é obrigatório'),
    channelId: Yup.string().required('Este campo é obrigatório'),
    motiveId: Yup.string().required('Este campo é obrigatório'),
    userId: Yup.string().required('Este campo é obrigatório'),
    isOnline: Yup.string().required('Este campo é obrigatório'),
    time: Yup.string().required('Este campo é obrigatório'),
    usersIds: Yup.array().of(Yup.string()),
    reminderDate: Yup.string().when('hasReminder', {
      is: 'true',
      then: Yup.string().required('Este campo é obrigatório'),
    }),
    reminderTime: Yup.string().when('hasReminder', {
      is: 'true',
      then: Yup.string().required('Este campo é obrigatório'),
    }),
    reminderDescription: Yup.string().when('hasReminder', {
      is: 'true',
      then: Yup.string().required('Este campo é obrigatório'),
    }),
  });

  const getOptions = async (fieldName, optionsPath, config = {}) => {
    await request.get({
      url: optionsPath,
      config: {
        params: config,
      },
      onSuccess: (data) => {
        const itemsArr = data.map((item) => ({
          ...item,
          value: item.id,
          label: item.name,
        }));

        setSelectsOptions((prevSelectsOptions) => ({
          ...prevSelectsOptions,
          [fieldName]: itemsArr,
        }));
      },
    });
  };

  const initialValues = {
    requesterId: requesterParamId,
    userId: userData.id,
    channelId: '',
    motiveId: '',
    isOnline: false,
    date: '',
    time: '',
    forwarding: '',
    observation: '',
    confidential: false,
    usersIds: [],
    hasReminder: false,
    reminderDate: '',
    reminderTime: '',
    reminderDescription: '',
  };

  const [localStorageState, handleUpdateLocalStorageState] =
    useLocalStorageState({
      key: NEW_UNIFIED_ATTENDANCE_LS_KEY,
      defaultValue: initialValues,
    });

  const clearLocalStorage = () => {
    handleUpdateLocalStorageState(initialValues);
  };

  const formatTime = (time) =>
    moment(time, 'YYYY-MM-DDTHH:mm:ss').format('HH:mm');

  const getDatetime = (date, time) => {
    // date: '2021-09-01', time: '10:00'
    const [hours, minutes] = time.split(':');
    const datetime = moment(date).set({
      hour: hours,
      minute: minutes,
    });
    return datetime;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: localStorageState,
    validationSchema: UnifiedAttendanceSchema,
    validateOnChange: false,

    onSubmit: async (values) => {
      const submitValues = {
        ...values,
        isOnline: values?.isOnline === 'true',
        status: values?.checkbox ? 'concluded' : 'opened',
      };

      const date = moment(values.date).utc().format('YYYY-MM-DD'); // aqui porque só interessa a data sem as horas

      if (values.isOnline === 'true') {
        const startTime = values.time.split(' - ')[0];
        const endTime = values.time.split(' - ')[1];

        submitValues.startDate = startTime;
        submitValues.endDate = endTime;
      } else {
        const startTime = formatTime(values.time);
        const endTime = moment(values.time, 'YYYY-MM-DDTHH:mm:ss')
          .add(30, 'minutes')
          .format('HH:mm');

        const startDate = getDatetime(date, startTime);
        const endDate = getDatetime(date, endTime);

        submitValues.startDate = startDate;
        submitValues.endDate = endDate;
      }

      delete submitValues.time;
      delete submitValues.date;

      const hasReminder = submitValues.hasReminder === 'true';
      let reminderValues = {};
      if (hasReminder) {
        reminderValues = {
          ownerId: submitValues.userId,
          date: getReminderDatetime(
            submitValues.reminderDate,
            submitValues.reminderTime
          ),
          description: submitValues.reminderDescription,
        };
        delete submitValues.hasReminder;
        delete submitValues.reminderDate;
        delete submitValues.reminderTime;
        delete submitValues.reminderDescription;
      }

      const onSuccess = () => {
        if (updateList) updateList();
        clearLocalStorage();
        closeModal();
      };
      const response = await request.post({
        url: '/unified-attendance',
        body: submitValues,
        onSuccess: () => {
          if (hasReminder) return;
          onSuccess();
        },
        onError: (error) => {
          const errorMessage = error.response.data.error;
          const customMessage =
            customNotificationMessages.attendance[errorMessage];

          if (customMessage) {
            return { customMessage };
          }

          return {};
        },
      });
      const unifiedAttendanceId = response?.data?.id;
      if (unifiedAttendanceId && hasReminder) {
        await request.post({
          url: '/reminder',
          body: {
            ...reminderValues,
            unifiedAttendanceId,
          },
          onSuccess: () => {
            addNotification({
              type: 'success',
              message: 'Lembrete criado com sucesso',
            });
            onSuccess();
          },
          onError: (error) => {
            addNotification({
              type: 'error',
              message: 'Não foi possível criar o lembrete',
            });
            onSuccess();
          },
          notificationLevel: 'none',
        });
      }
    },
  });

  const parseValueToMoment = (value) => {
    if (!value) return value;

    if (typeof value === 'string') {
      return moment(value);
    }

    return value;
  };

  const updateLocalStorage = useCallback(
    _.debounce(() => {
      let time = '';
      if (formik.values.time) {
        if (typeof formik.values.time === 'string') {
          time = formik.values.time;
        }

        if (typeof formik.values.time === 'object') {
          time = formik.values.time.format();
        }
      }

      const newValues = {
        ...formik.values,
        requesterId: requesterParamId,
        time,
      };
      handleUpdateLocalStorageState(newValues);
    }, 500),
    [formik.values]
  );

  useEffect(() => {
    updateLocalStorage();

    return () => {
      updateLocalStorage.cancel();
    };
  }, [formik.values]);

  const touched = useTouched(formik.values, initialValues);
  watchTouched(touched);

  const techniqueLabel = () => {
    const selectedTechnique = users?.find(
      ({ id }) => id === formik?.values?.userId
    );

    const label = selectedTechnique
      ? techniqueAutocompleteLabelFormatter(selectedTechnique)
      : 'Digite o nome ou email do técnico';

    return label;
  };

  const channelLabel = () => {
    const selectedChannel = selectsOptions?.channelId?.find(
      ({ id }) => id === formik?.values?.channelId
    );

    const label = selectedChannel ? selectedChannel.name : 'Canal';

    return label;
  };

  const motiveLabel = () => {
    const selectedMotive = callMotives?.find(
      ({ id }) => id === formik?.values?.motiveId
    );
    const label = selectedMotive
      ? callMotiveAutocompleteLabelFormatter(selectedMotive)
      : 'Selecione o motivo do atendimento';
    return label;
  };

  useEffect(() => {
    formik.setFieldValue({
      isOnline: '',
      date: '',
      time: '',
    });
  }, [formik.values.userId]);

  useEffect(() => {
    if (formik.values.isOnline === 'false') {
      const { id } = userData;
      formik.setFieldValue('userId', id);
    }
  }, [formik.values.isOnline]);

  useEffect(() => {
    formik.setFieldValue({
      date: '',
      time: '',
    });

    if (formik.values.isOnline) {
      getOptions('availableTimes', `/available-time`, {
        userId: formik.values.userId || userData.id,
        isOnline: formik.values.isOnline === 'true',
      });
    }

    getOptions('motiveId', '/call-motive');
  }, [formik.values.isOnline, formik.values.userId]);

  useEffect(() => {
    formik.setFieldValue('time', '');

    const updateSelectsOptions = () => {
      if (formik.values.date) {
        const now = moment();

        const filteredTimes = selectsOptions.availableTimes?.filter(
          ({ startDate }) => {
            const startTime = moment(startDate);

            return (
              moment(startDate).format('LL') ===
                moment(formik.values?.date).format('LL') && now < startTime
            );
          }
        );

        const timeOptions = filteredTimes?.map(({ startDate, endDate }) => ({
          label: `${moment(startDate).format('HH[:]mm')} - ${moment(
            endDate
          ).format('HH[:]mm')}`,
          value: `${startDate} - ${endDate}`,
        }));

        setSelectsOptions((prev) => ({
          ...prev,
          time: timeOptions,
        }));
      }
    };

    updateSelectsOptions();
  }, [formik.values.date, selectsOptions.availableTimes]);

  useEffect(() => {
    const userId = formik?.values?.userId || '';
    const userEmail = formik?.values?.userEmail || '';

    const fetchOptions = async () => {
      await Promise.all([
        getOptions('channelId', '/channel'),
        getUsers({
          roles: 'technique',
          ...(userEmail ? { search: `${userEmail}` } : {}),
        }),
        ...(userId
          ? [
              getOptions('availableTimes', `/available-time`, {
                userId,
                isOnline: true,
              }),
            ]
          : []),
      ]);
    };

    fetchOptions();
  }, []);

  const [operatorsEmail, setOperatorsEmail] = useState([]);
  const handleUsersIds = (values) => {
    formik.setFieldValue('usersIds', values);
  };
  useEffect(() => {
    const emailToTag = (email) => {
      if (email?.length > 0 && email !== undefined)
        return `@${email.split('@')[0]}`;
      return email;
    };
    const getEmails = async () => {
      await request.get({
        url: '/user',
        onSuccess: (data) => {
          const emailsArr = [];
          data.forEach((user) => {
            emailsArr.push({
              value: user.id,
              label: emailToTag(user.email),
            });
          });
          setOperatorsEmail(emailsArr);
        },
      });
    };
    getEmails();
  }, []);

  const motiveOptions = [];

  return (
    <form onSubmit={formik.handleSubmit}>
      <div style={{ display: 'flex', gap: 10 }}>
        <StyledSwitch
          active={formik.values?.isOnline === 'true'}
          checked={formik.values?.isOnline === 'true'}
          onChange={(e) => {
            formik.setFieldValue('isOnline', e.toString());
            if (e === false) {
              formik.setFieldValue('userId', '');
              formik.setFieldValue('userEmail', '');
              formik.setFieldValue('confidential', false);
            }
          }}
        />
        Deseja agendar?
      </div>

      {formik.values?.isOnline === 'true' ? (
        <>
          <InfiniteScrollAutoComplete
            label={techniqueLabel()}
            name="userId"
            error={formik.errors.userId}
            options={users}
            onBlur={formik.handleBlur}
            value={formik.values.userId}
            customMatchFunction={techniqueAutocompleteMatchFunction}
            onSelect={(value) => {
              const user = users.find(({ id }) => id === value);
              if (!user) return;
              formik.setFieldValue('userId', value);
              formik.setFieldValue('userEmail', user.email);
            }}
            absoluteError
            schema={UnifiedAttendanceSchema}
            customLabelFormatter={techniqueAutocompleteLabelFormatter}
            disabled={formik.values.requesterId === ''}
            dropdownClassName="userId"
            hasMore={hasMoreUsers}
            loadMore={(query, page) => {
              getMoreUsers({ roles: 'technique' }, page);
            }}
            page={pageUsers}
            onSearch={(text) => {
              getUsers({
                search: text,
                roles: 'technique',
              });
            }}
          />

          <DatePicker
            formik={formik}
            single
            name="date"
            label="Data"
            value={formik.values.date}
            error={formik.errors.date}
            disabledDates={availableDates}
            disabled={formik.values?.isOnline === ''}
            schema={UnifiedAttendanceSchema}
            allowClear={false}
            defaultTimezone={false}
          />

          <Select
            label="Selecione o horário"
            name="time"
            onChange={(e) => formik.setFieldValue('time', e)}
            error={formik.errors.time}
            value={formik.values.time}
            disabled={!formik.values.date}
            options={selectsOptions.time || []}
            schema={UnifiedAttendanceSchema}
            margin
          />
        </>
      ) : (
        <>
          <DatePicker
            formik={formik}
            single
            name="date"
            label="Data"
            value={formik.values.date}
            error={formik.errors.date}
            schema={UnifiedAttendanceSchema}
            allowClear={false}
            defaultTimezone={false}
          />

          <TimePicker
            formik={formik}
            format="HH:mm"
            placeholder="Selecione o horário"
            single
            name="time"
            label="Horário"
            value={parseValueToMoment(formik.values.time)}
            error={formik.errors.time}
            schema={UnifiedAttendanceSchema}
            allowClear={false}
          />
        </>
      )}

      <Select
        mode="multiple"
        label="Responsáveis pelo atendimento"
        name="usersIds"
        absoluteError
        onChange={handleUsersIds}
        value={formik.values.usersIds}
        singleTag
        options={operatorsEmail}
        loadMore={() => {}}
      />

      <Select
        label={channelLabel()}
        name="channelId"
        absoluteError
        onChange={(e) => formik.setFieldValue('channelId', e)}
        error={formik.errors.channelId}
        value={formik.values.channelId}
        options={selectsOptions.channelId}
        onBlur={formik.handleBlur}
        schema={UnifiedAttendanceSchema}
      />

      <Select
        label="Selecione o motivo do atendimento"
        name="motiveId"
        error={formik.errors.motiveId}
        options={selectsOptions?.motiveId ?? []}
        onBlur={formik.handleBlur}
        value={formik.values.motiveId}
        onChange={(e) => formik.setFieldValue('motiveId', e)}
        absoluteError
        schema={UnifiedAttendanceSchema}
        disabled={selectsOptions?.motiveId?.length === 0}
      />

      <Input
        label="Escreva uma síntese para a demanda"
        name="observation"
        width="100%"
        absoluteError
        error={formik.errors.observation}
        value={formik.values.observation}
        onChange={(e) => formik.setFieldValue('observation', e.target.value)}
        onBlur={formik.handleBlur}
        schema={UnifiedAttendanceSchema}
      />

      <Input
        label="Encaminhamento"
        name="forwarding"
        width="100%"
        isLast
        absoluteError
        error={formik.errors.forwarding}
        value={formik.values.forwarding}
        onChange={(e) => formik.setFieldValue('forwarding', e.target.value)}
        onBlur={formik.handleBlur}
        schema={UnifiedAttendanceSchema}
      />

      <div style={{ display: 'flex', gap: 10, marginTop: 30 }}>
        <StyledSwitch
          active={formik.values?.hasReminder === 'true'}
          checked={formik.values?.hasReminder === 'true'}
          onChange={(e) => formik.setFieldValue('hasReminder', e.toString())}
        />
        Deseja adicionar um lembrete?
      </div>
      {formik.values?.hasReminder === 'true' && (
        <>
          <DatePicker
            formik={formik}
            single
            name="reminderDate"
            label="Data do lembrete"
            value={formik.values.reminderDate}
            error={formik.errors.reminderDate}
            schema={UnifiedAttendanceSchema}
            allowClear={false}
            defaultTimezone={false}
            minDate={new Date()}
            startDate={new Date()}
            disabledDates={disabledDates}
          />

          <TimePicker
            formik={formik}
            format="HH:mm"
            placeholder="Selecione o horário"
            single
            name="reminderTime"
            label="Horário do lembrete"
            value={formik.values.reminderTime}
            error={formik.errors.reminderTime}
            schema={UnifiedAttendanceSchema}
            allowClear={false}
            disabledTime={disablePastHours(formik.values.reminderDate)}
          />

          <Input
            label="Escreva a descrição do lembrete"
            name="reminderDescription"
            width="100%"
            absoluteError
            error={formik.errors.reminderDescription}
            value={formik.values.reminderDescription}
            onChange={
              (e) => formik.setFieldValue('reminderDescription', e.target.value)
              // eslint-disable-next-line react/jsx-curly-newline
            }
            onBlur={formik.handleBlur}
            schema={UnifiedAttendanceSchema}
          />
        </>
      )}

      <div style={{ paddingBottom: 10 }}>
        <StyledCheckbox
          name="confidential"
          onChange={
            (e) => formik.setFieldValue('confidential', e.target.checked)
            // eslint-disable-next-line react/jsx-curly-newline
          }
          onBlur={formik.handleBlur}
          checked={formik.values.confidential}
          schema={UnifiedAttendanceSchema}
        >
          Atendimento confidencial?
        </StyledCheckbox>
      </div>

      <div>
        <StyledCheckbox
          name="checkbox"
          onChange={(e) => formik.setFieldValue('checkbox', e.target.checked)}
          onBlur={formik.handleBlur}
          checked={formik.values.checkbox}
          schema={UnifiedAttendanceSchema}
        >
          Encerrar atendimento
        </StyledCheckbox>
      </div>

      <FormButtonContainer>
        <StyledFormButton
          onClick={() => {
            confirmCancel(() => {
              clearLocalStorage();
              formik.handleReset();
            }, touched);
          }}
          $cancel
        >
          Limpar
        </StyledFormButton>

        <StyledFormButton
          onClick={() => {
            confirmCancel(() => {
              clearLocalStorage();
              closeModal();
            }, touched);
          }}
          $cancel
        >
          Cancelar
        </StyledFormButton>
        <StyledFormButton loading={formik.isSubmitting} htmlType="submit">
          Salvar
        </StyledFormButton>
      </FormButtonContainer>
    </form>
  );
}

export default NewUnifiedAttendanceModal;
