import styled, { css } from 'styled-components';

import { respondTo } from 'styles/mixins';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InitialImage = styled.img`
  width: 100%;
  height: auto;
  position: relative;
  object-fit: contain;
  max-width: 60vw;
  max-height: 70vh;
  cursor: pointer;

  ${({ customPreview }) =>
    customPreview &&
    css`
      height: 2.8125rem;
      width: initial;

      ${respondTo('medium')(css`
        height: 1.5625rem;
      `)}
    `}
`;
