import { Calendar } from 'antd';
import styled from 'styled-components';

import colors from 'styles/colors';

export const Container = styled.div`
  max-width: 350px;
  max-height: 300px;
`;

export const StyledCalendar = styled(Calendar)`
  .ant-picker-content thead {
    display: none;
  }
  .ant-picker-content th {
    padding: 15px;
  }

  .ant-picker-calendar-header {
    display: none;
  }

  .ant-picker-panel {
    background: #fff;
    border: 0 !important;
    box-shadow: 1px 1px 10px ${colors.darkGray};
    border-radius: 10px !important;
  }

  .ant-picker-body {
    padding: 0px !important;
  }

  .ant-picker-cell-inner {
    border-radius: 0px !important;
    background: none !important;
  }
  .ant-picker-cell-disabled {
    color: gray;
    &:before {
      background: none;
    }
  }

  .ant-picker-cell-selected {
    background: linear-gradient(
      180deg,
      rgba(247, 149, 28, 1) 0%,
      rgba(255, 92, 155, 1) 100%
    );
    border-radius: 4px;
    box-shadow: 0px 0px 4px rgba(247, 149, 28, 0.396) !important;
  }

  .ant-picker-cell {
    color: rgba(0, 0, 0, 1);
  }
`;

export const CalendarHeader = styled.div`
  background-color: ${colors.primary};
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-around;
  text-align: center;
`;

export const HeaderItem = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
`;

export const Month = styled.div`
  display: flex;
  border-radius: 10px 10px 0px 0px;
  background-color: ${colors.primary};
  text-align: center;
  font-size: 1.125rem;
  font-weight: 600;
  padding: 10px;
  justify-content: space-between;
`;

export const MonthChanger = styled.button`
  padding: 0px 20px;
  background: none;
  border: none;
  &:focus {
    border: none;
    outline: none;
  }
`;

export const Day = styled.div`
  font-size: 0.7rem;
`;
