import { Col as AntCol, Row as AntRow, Tabs } from 'antd';
import styled, { css } from 'styled-components';

import { respondToMin } from 'styles/mixins';

export const Row = styled(AntRow)`
  width: 100%;
  flex-grow: 1;
`;

export const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    &::before {
      border: none;
    }
  }

  .ant-tabs-nav-wrap {
    justify-content: center;
  }

  .ant-tabs-tab {
    color: #585858;
    font-size: 1rem;
    padding: 12px;

    .ant-tabs-tab-btn {
      &:hover {
        color: black;
      }
    }

    &-active {
      color: black;
    }
  }
`;

export const Col = styled(AntCol)`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h2`
  font-size: 1.5rem;

  ${respondToMin('medium')(css`
    font-size: 1.3rem;
  `)}

  ${respondToMin('big')(css`
    font-size: 1.13rem;
  `)}

  ${respondToMin('large')(css`
    font-size: 1.5rem;
  `)}
`;

export const Content = styled.div`
  flex-grow: 1;
`;
