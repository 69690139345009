import styled from 'styled-components';

import colors from 'styles/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background-color: ${colors.white};
  position: relative;
  overflow: hidden;
`;

export const InputSkeleton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eee;
  padding: 1.1875rem 0.5rem;
  min-height: 5.9375rem;
`;
