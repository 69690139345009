import { Upload } from 'antd';

import UploadIcon from '../../assets/icons/upload.svg';

import * as S from './FileInput.styles';

const { Dragger } = Upload;

function FileInput({ fileType = '.geojson', ...props }) {
  return (
    <S.FileInputWrapper>
      <Dragger {...props}>
        <img
          className="ant-upload-drag-icon"
          src={UploadIcon}
          style={{ height: 70, marginBottom: 10 }}
          alt="Upload"
        />
        <p className="ant-upload-text">Arraste ou clique aqui para</p>
        <p className="ant-upload-text">
          enviar o arquivo
          <strong>{` ${fileType}`}</strong>
        </p>
      </Dragger>
    </S.FileInputWrapper>
  );
}

export default FileInput;
