import { useEffect, useState } from 'react';

import { BarChart } from 'components';

function UnifiedAttendancesRegions({ data: requestData }) {
  const [data, setData] = useState();

  useEffect(() => {
    const unifiedAttendancesRegionsData =
      requestData?.graphUnifiedAttendancesRegions;
    if (unifiedAttendancesRegionsData) {
      const graphJsObject = {
        labels: unifiedAttendancesRegionsData.map(({ name }) => name),
        amount: unifiedAttendancesRegionsData.map(({ amount }) => amount),
      };
      setData(graphJsObject);
    }
  }, [requestData]);

  return (
    <BarChart
      title="Regiões com mais atendimentos"
      data={data?.amount}
      labels={data?.labels}
      thickness={90 - data?.labels.length * 10}
      secondary
    />
  );
}

export default UnifiedAttendancesRegions;
