import * as Yup from 'yup';

const getFieldRules = (inputs) => {
  const newValidationObject = {};

  inputs.forEach((input) => {
    let response = Yup.string();
    const { rules } = input || {};

    if (rules) {
      if (rules.includes('required')) {
        response = response.required(`Esse campo é obrigatório.`);
      }

      if (rules.includes('email')) {
        response = response.email(`O valor digitado não é um e-mail válido.`);
      }

      if (rules.includes('cpf')) {
        response = response.matches(
          /(\d{3}).(\d{3}).(\d{3})-(\d{2})/g,
          'CPF inválido'
        );
      }

      if (rules.includes('phone')) {
        response = response.matches(
        /\((\d{2})\) \d?(\d{4})-(\d{4})/g,
        'Número de telefone inválido'
        );
      }

      if (rules.includes('number')) {
        response = response.matches(
          /^\d+$/g,
          'O valor desse campo deve ser numérico.'
        );
      }
    }

    if (input.name) {
      newValidationObject[input.name] = response;
    }
  });

  return newValidationObject;
};

export default getFieldRules;
