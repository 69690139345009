import { useEffect, useRef, useState } from 'react';
import { Input } from 'antd';

import alertSvg from 'assets/icons/alert.svg';
import { thisFieldHasRequired } from 'helpers/lookForRequiredOnYupSchema';
import normalizeInputText from 'helpers/normalizeInputText';

import {
  Error,
  ErrorIcon,
  FloatingAutocompleteWrapper,
  FloatingLabel,
  FloatingLabelSearcher,
} from './AutocompleteInput.style';

const AutocompleteInput = ({
  label,
  onSelect,
  options,
  error,
  value: formValue,
  onBlur: customOnBlur,
  name,
  schema,
  absoluteError = false,
  placeholder = '',
  customLabelFormatter = false,
  customMatchFunction = false,
  ...props
}) => {
  const [inputValue, setInputValue] = useState('');
  const [focused, setFocused] = useState(!!inputValue);
  const [usableOptions, setUsableOptions] = useState(null);
  const [emptyQueryOptions, setEmptyQueryOptions] = useState([]);
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputValue) setFocused(true);
    else setFocused(false);
  }, [inputValue]);

  useEffect(() => {
    if (
      Array.isArray(options) &&
      options.map(({ value }) => value).includes(formValue)
    )
      setInputValue(
        options.filter(({ value }) => value === formValue)[0].label
      );
  }, [options]);

  const handleBlur = (e) => {
    if (inputRef.current.state.value === '') setFocused(false);
    customOnBlur(e);
  };

  const handleSelect = (value, option) => {
    setInputValue(option.label);
    onSelect(value);
  };

  const suggestOptions = (text) => {
    if (customMatchFunction !== false)
      return customMatchFunction(text, options);
    const inputString = normalizeInputText(text);
    return options
      .filter(
        (option) => normalizeInputText(option.label).indexOf(inputString) !== -1
      )
      .slice(0, 3);
  };

  const onSearch = (searchText) => {
    setUsableOptions(
      !searchText ? emptyQueryOptions : suggestOptions(searchText)
    );
  };

  const handleFocus = () => {
    setFocused(true);
    onSearch();
  };

  const onChange = (text) => {
    setInputValue(text);
  };

  const onKeyDown = (e) => {
    if (e.code === 'Backspace' && formValue) {
      setInputValue('');
      onSelect('');
    }
  };

  useEffect(() => {
    if (Array.isArray(options)) {
      setEmptyQueryOptions(() =>
        options.slice(0, 3).map((option) => {
          let optionLabel = '';
          if (customLabelFormatter) {
            optionLabel = customLabelFormatter(option);
          } else {
            optionLabel = option.label;
          }
          return {
            value: option.value,
            label: optionLabel,
          };
        })
      );
      onSearch();
    }
  }, [options]);

  return (
    <FloatingAutocompleteWrapper>
      {label && (
        <FloatingLabel focused={focused} htmlFor="cano">
          {`${label} ${thisFieldHasRequired(schema, name) ? '*' : ''}`}
        </FloatingLabel>
      )}
      <FloatingLabelSearcher
        dropdownMatchSelectWidth
        onChange={onChange}
        onSelect={(value, option) => handleSelect(value, option)}
        onSearch={onSearch}
        value={inputValue}
        loading={!Array.isArray(usableOptions)}
        options={usableOptions}
        notFoundContent="Não há opções disponíveis"
        absoluteError={absoluteError}
        width={inputRef.width}
        onKeyDown={onKeyDown}
        {...props}
      >
        <Input.Search
          size="large"
          ref={inputRef}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={placeholder}
          loading={!Array.isArray(options)}
        />
      </FloatingLabelSearcher>
      {error && (
        <Error absoluteError={absoluteError}>
          <ErrorIcon src={alertSvg} alt="Ícone de erro" />
          {error}
        </Error>
      )}
    </FloatingAutocompleteWrapper>
  );
};

export default AutocompleteInput;
