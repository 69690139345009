import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import lockSvg from 'assets/icons/lock.svg';
import personSvg from 'assets/icons/person.svg';
import diagonalLogo from 'assets/images/logo-diagonal.png';
import sehabLogo from 'assets/images/logo-sehab.png';
import { TextInput } from 'components/inputs';

import {
  FirstAccess,
  FirstAccessLink,
  Form,
  InputWrapper,
  LoginButton,
  Logo,
  LogosContainer,
  PasswordForget,
  Title,
  Wrapper,
} from './LoginForm.style';

function LoginForm({ onSubmit, error }) {
  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email inválido').required('Campos Faltando'),
    password: Yup.string().required('Campos Faltando'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      onSubmit(values, formik);
    },
  });

  const renderPasswordIcon = (visible) =>
    visible ? <EyeOutlined /> : <EyeInvisibleOutlined />;

  return (
    <Wrapper>
      <Form onSubmit={formik.handleSubmit}>
        <Title>Portal do Morador</Title>
        <InputWrapper>
          <TextInput
            name="email"
            type="text"
            label="Login"
            placeholder="Use seu Email"
            icon={personSvg}
            onChange={formik.handleChange}
            value={formik.values.email}
            schema={LoginSchema}
            className="login-input"
          />
          <TextInput
            name="password"
            type="password"
            label="Senha"
            placeholder="Digite sua senha"
            icon={lockSvg}
            onChange={formik.handleChange}
            error={formik.errors.email || formik.errors.password || error}
            value={formik.values.password}
            iconRender={renderPasswordIcon}
            schema={LoginSchema}
            className="password-input"
          />
          <PasswordForget to="/redefinir-senha">
            Esqueci minha senha
          </PasswordForget>
        </InputWrapper>
        <LoginButton
          className="login-button"
          loading={formik.isSubmitting}
          htmlType="submit"
        >
          Entrar
        </LoginButton>
        <FirstAccess>
          Primeiro acesso?
          <FirstAccessLink to="/primeiro-acesso">Clique aqui</FirstAccessLink>
        </FirstAccess>
        <LogosContainer>
          <Logo src={diagonalLogo} alt="Logotipo da SEHAB" />
          <Logo src={sehabLogo} alt="Logotipo da Diagonal" />
        </LogosContainer>
      </Form>
    </Wrapper>
  );
}

export default LoginForm;
