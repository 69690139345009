import { Select as AntSelect, Tag } from 'antd';
import styled, { css } from 'styled-components';

import colors from 'styles/colors';
import { respondTo } from 'styles/mixins';

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const StyledSelect = styled(AntSelect)`
  position: relative;
  display: flex;
  border: 1px solid ${colors.persianGreen} !important;
  background: none !important;
  border-radius: 8px;
  font-size: 1.125rem;
  min-height: 2.5rem !important;
  height: fit-content;
  align-items: center;

  ${({ $hasItem }) =>
    $hasItem &&
    css`
      .ant-select-selection-overflow {
        padding: 0.5rem 0;
      }
    `}

  .ant-select-selection-item {
    height: 2rem;
    font-weight: 400 !important;
  }

  ${respondTo('medium')(css`
    margin-top: 0.875rem;
  `)}

  &.ant-select-disabled {
    cursor: not-allowed !important;
    & .ant-select-selection-item {
      cursor: not-allowed !important;
    }
    .ant-select-selection-item {
      color: ${colors.gray};
    }
  }

  .ant-select-selector {
    border: none !important;
    cursor: initial !important;
    box-shadow: none !important;
    padding: 0 !important;
    background-color: transparent !important;

    & .ant-select-selection-placeholder {
      font-size: 0.9rem !important;
    }
  }

  .ant-select-arrow {
    right: 1.25rem !important;
    top: 1rem !important;
    font-size: 0.75rem !important;
    color: ${colors.black} !important;
  }

  .ant-select-selection-item {
    width: 100% !important;
    font-size: 0.75rem;
    line-height: 2.0625rem;
    cursor: pointer;
    font-weight: 500;
    color: ${colors.grayBlue};
    padding-left: 0.93rem !important;

    ${respondTo('medium')(css`
      font-size: 0.875rem;
    `)}
  }

  .ant-select-arrow .anticon {
    top: 0.19rem !important;
    left: 0.81rem !important;
  }

  &.ant-select-multiple {
    .ant-select-selector {
      width: 100%;
      cursor: pointer !important;
    }

    .ant-select-selection-item-content,
    .ant-select-selection-item-remove {
      display: inline-flex;
      align-items: center;
    }

    .ant-select-selection-overflow-item {
      margin: 0 5px;
    }

    .ant-select-selection-item {
      background-color: ${colors.persianGreen};
      color: white;
      .anticon {
        display: flex;
        justify-content: center;
        align-items: center;
        position: unset;
        background-color: #57b0a7;
        padding: 3px;
        border-radius: 50px;

        svg {
          width: 9px;
          height: 9px;
        }

        path {
          fill: white;
        }
      }
    }
  }
`;

export const FloatingLabel = styled.label`
  position: absolute;
  transition: top 0.2s ease-in-out, left 0.2s ease-in-out,
    color 0.2s ease-in-out, font-size 0.2s ease-in-out;
  left: 0.625rem;
  top: 1.25rem;
  transform: translateY(-50%);
  background-color: transparent !important;
  padding: 0 0.25rem;
  pointer-events: none;
  border-radius: 5px;
  font-size: 0.7rem;
  font-weight: 500;
  z-index: 2;
  color: ${colors.textBlue};
  line-height: 1rem;

  ${({ focused }) =>
    focused &&
    css`
      left: 0.937rem;
      top: 0;
      color: ${colors.smoothBlack};
      font-size: 0.85rem;
      background-color: ${colors.white} !important;
    `}
`;

export const Error = styled.p`
  color: ${colors.textSecondary};
  opacity: 1;
  font-style: italic;
  font-size: 0.875rem;
  margin: 0;
  margin-top: 0.5rem;

  ${({ absoluteError }) =>
    absoluteError &&
    css`
      position: absolute;
      margin-top: 0;
      bottom: -0.125rem;
      transform: translateY(100%);
    `}
`;

export const ErrorIcon = styled.img`
  width: 0.875rem;
  margin-right: 0.625rem;
`;

export const CustomTag = styled(Tag)``;
