import { useHistory } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';

import {
  Arrow,
  CallToAction,
  StyledButton,
  StyledLink,
} from './BackPathButton.style';

const BackPathButton = ({
  enableHistory = true,
  path = '/home',
  arrowColor = null,
  textColor = null,
  hasText = true,
  isFromChat = false,
}) => {
  const history = useHistory();
  const lastLocation = useLastLocation();

  const backPath = () => {
    if (lastLocation?.pathname) {
      history.push(`${lastLocation.pathname}${lastLocation.search}`);
    } else {
      history.push(path);
    }
  };

  if (enableHistory)
    return (
      <StyledButton isFromChat={isFromChat} onClick={backPath}>
        <Arrow color={arrowColor} />
        {hasText && <CallToAction color={textColor}>Voltar</CallToAction>}
      </StyledButton>
    );
  return (
    <StyledLink isFromChat={isFromChat} to={{ pathname: path }}>
      <Arrow color={arrowColor} />
      {hasText && <CallToAction color={textColor}>Voltar</CallToAction>}
    </StyledLink>
  );
};

export default BackPathButton;
