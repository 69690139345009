import { createContext, useContext, useEffect, useState } from 'react';

import useRequest from 'hooks/use-request';

const SelectOptionsContext = createContext({});

function SelectOptionsProvider({ children, inputs }) {
  const [selectsOptions, setSelectsOptions] = useState({});
  const request = useRequest();

  const getOptions = async (fieldName, optionsPath) => {
    await request.get({
      url: optionsPath,
      onSuccess: (data) => {
        const itemsArr = data.map((item) => ({
          ...item,
          value: item.id,
          label: item.name,
        }));
        setSelectsOptions((prevSelectsOptions) => ({
          ...prevSelectsOptions,
          [fieldName]: itemsArr,
        }));
      },
    });
  };

  useEffect(() => {
    inputs.forEach((element) => {
      if (['select', 'conditional-select'].includes(element.type)) {
        if (!Array.isArray(element.options)) {
          getOptions(element.name, element.options);
        } else {
          setSelectsOptions((prevSelectsOptions) => ({
            ...prevSelectsOptions,
            [element.name]: element.options,
          }));
        }
      }
    });
  }, []);

  return (
    <SelectOptionsContext.Provider value={selectsOptions}>
      {children}
    </SelectOptionsContext.Provider>
  );
}

export const useSelectOptions = () => useContext(SelectOptionsContext);

export default SelectOptionsProvider;
