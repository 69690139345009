import styled from 'styled-components';

import { Spin } from 'components';

export const StyledSpinner = styled(Spin)`
  position: relative;
`;

export const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;
