import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Can, CreateRequesterModal, ProfileImage } from 'components';
import { useModal, withModalProvider } from 'contexts/ModalProvider';
import { useUserInfo } from 'contexts/UserProvider/UserProvider';
import useMobile from 'hooks/use-mobile';
import useRequest from 'hooks/use-request';

import {
  Button,
  Container,
  LinkText,
  MenuButton,
  ProfileContainer,
  Title,
  TitleContainer,
} from './Header.style';
import MenuModal from './MenuModal';

function Header() {
  const { userData } = useUserInfo();
  const history = useHistory();
  const isMobile = useMobile();
  const { modal } = useModal();
  const request = useRequest();
  const [menuOpen, setMenuOpen] = useState(false);

  const handleCreateRequester = () => {
    const create = (body) => {
      request.post({
        url: '/requester',
        body,
      });
    };
    const modalProps = {
      title: 'Cadastrar solicitante',
      footer: null,
      width: '80vw',
      watchTouched: true,
      maskClosable: false,
    };
    modal(
      (props) => <CreateRequesterModal create={create} {...props} />,
      modalProps
    );
  };

  return (
    <Container>
      <ProfileContainer>
        <ProfileImage path={userData?.profileImage} />
        <TitleContainer>
          {userData?.name && (
            <Title>
              Olá,
              {` ${userData.name}!`}
              <LinkText
                onClick={() => history.push(`/usuarios/${userData.id}`)}
              >
                Editar Perfil
              </LinkText>
            </Title>
          )}
        </TitleContainer>
      </ProfileContainer>
      {isMobile && (
        <Can perform={['home:visit']}>
          <MenuModal visible={menuOpen} setVisible={setMenuOpen} />
          <MenuButton onClick={() => setMenuOpen(true)}>Menu</MenuButton>
        </Can>
      )}
      <Can perform={['resident-center:create']}>
        <Button onClick={handleCreateRequester}>Cadastrar solicitante</Button>
      </Can>
    </Container>
  );
}

export default withModalProvider(Header);
