import { BaseCard, Counter, Title } from './CounterCard.style';

function CounterCard({ title = 'Chamados em aberto', count = 50 }) {
  return (
    <BaseCard>
      <Title>{title}</Title>
      <Counter>{count}</Counter>
    </BaseCard>
  );
}

export default CounterCard;
