import styled, { css } from 'styled-components';

import waveSvg from 'assets/waves/wave-dark.svg';
import { respondTo } from 'styles/mixins';

const Wave = styled.img`
  position: fixed;
  top: -50px;
  right: calc(100vw - 36vw);
  height: 120vh;

  ${respondTo('medium')(css`
    display: none;
  `)}
`;

const WaveDark = () => <Wave src={waveSvg} alt="" />;

export default WaveDark;
