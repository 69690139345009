import { useState } from 'react';

import request from 'api/request';
import downloadIcon from 'assets/icons/download.png';
import rg from 'assets/icons/rg.png';
import { ImageWithZoom } from 'components';

import {
  Card,
  DocumentIcon,
  DocumentName,
  Download,
  TextContainer,
} from './DownloadFile.style';

function DownloadFile({ url }) {
  const [imageZoomThrigger, setImageZoomThrigger] = useState(false);
  const parseName = url.split('?')[0].split('/')[5];
  const formattedName = parseName.substring(parseName.match(/[a-zA-Z]/).index);

  const isImage = ['jpg', 'png', 'jpeg', 'webp'].includes(
    formattedName.split('.')[1].toLowerCase()
  );

  const thriggerZoom = () => {
    setImageZoomThrigger(true);
  };

  const handleDownload = async () => {
    const res = await request.unauthorized().get(url, { responseType: 'blob' });

    if (!res.data) return;

    const reader = new FileReader();
    reader.onload = () => {
      const dataUrl = reader.result;
      const a = document.createElement('a');
      a.href = dataUrl;
      a.download = formattedName;

      document.body.appendChild(a);

      a.click();

      document.body.removeChild(a);
    };

    reader.readAsDataURL(res.data);
  };

  return (
    <Card onClick={!isImage ? handleDownload : null}>
      <TextContainer onClick={isImage ? thriggerZoom : null}>
        {isImage ? (
          <ImageWithZoom
            customPreview
            customPreviewUrl={rg}
            customPreviewThrigger={imageZoomThrigger}
            onClose={() => setImageZoomThrigger(false)}
            src={url}
            alt="Ícone do RG"
          />
        ) : (
          <DocumentIcon src={rg} alt="Ícone do RG" />
        )}
        <DocumentName>{formattedName}</DocumentName>
      </TextContainer>
      <Download
        onClick={isImage ? handleDownload : null}
        src={downloadIcon}
        alt="Ícone de Download"
      />
    </Card>
  );
}

export default DownloadFile;
