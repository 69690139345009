import { TabContainer, TabItem } from './ResidentEditTabs.style';

const ResidentEditTabs = ({ changeSection, activeSection }) => {
  const callback = (key) => {
    changeSection(key);
  };

  return (
    <TabContainer defaultActiveKey={activeSection} onChange={callback}>
      <TabItem tab="Central do Morador" key="unifiedAttendance" />
      <TabItem tab="Dados do solicitante" key="responsableData" />
      <TabItem tab="Dados do segundo titular" key="secondPersonData" />
      <TabItem tab="Documentos" key="documents" />
    </TabContainer>
  );
};

export default ResidentEditTabs;
