import { Marker } from 'react-map-gl';

import { CircleMark, InnerCircle } from './AdminMap.style';

const MapAreas = ({
  areas,
  markerConstant,
  higherCpp,
  lowerCpp,
  openPopup,
  closePopup,
}) => {
  if (areas?.length) {
    return areas?.map((area, index) => {
      let color = '';
      const addressCoords = {};

      if (area?.cpp <= markerConstant + lowerCpp) color = 'green';
      else if (
        area?.cpp <= higherCpp - markerConstant &&
        area?.cpp > markerConstant + lowerCpp
      )
        color = 'yellow';
      else if (area?.cpp > higherCpp - markerConstant) color = 'red';

      return (
        <Marker
          key={area.id}
          longitude={addressCoords.long || area.long}
          latitude={addressCoords.lat || area.lat}
        >
          <CircleMark
            onMouseEnter={() => {
              openPopup(index, color);
            }}
            onMouseLeave={closePopup}
            color={color}
          >
            <InnerCircle />
          </CircleMark>
        </Marker>
      );
    });
  }
  return null;
};
export default MapAreas;
