import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col } from 'antd';
import queryString from 'query-string';

import socket from 'api/socket';
import {
  AdminMap,
  AttendanceGraph,
  DownloadButton,
  FilterButton,
  InfoCardList,
  MobileFixedButtons,
  OperatorResolution,
  OperatorUnifiedAttendances,
  TitledPage,
  UnifiedAttendancesMultipleBarChart,
  UnifiedAttendancesRegions,
} from 'components';
import { Areas, DemandType, Operator } from 'components/Tables';
import useMobile from 'hooks/use-mobile';
import useRequest from 'hooks/use-request';

import { Container, StyledRow } from './Dashboard.style';
import filters from './filters';

function Dashboard() {
  const [pdfFile, setPdfFile] = useState(null);
  const isMobile = useMobile();
  const request = useRequest();
  const { location } = useHistory();

  const [dashboardData, setDashboardData] = useState(null);

  const titleButtons = [
    () => <div />,
    () => <FilterButton filters={filters} />,
  ];

  const fetchData = async (parameters) => {
    await request.get({
      url: '/dashboard',
      config: {
        params: {
          ...parameters,
        },
      },
      onSuccess: (data) => {
        setDashboardData(data);
      },
      onError: (error) => {
        console.log(error);
        setDashboardData(null);
      },
    });
  };

  useEffect(() => {
    const parameters = queryString.parse(location.search, {
      arrayFormat: 'separator',
      arrayFormatSeparator: ',',
    });

    fetchData(parameters);
  }, [location]);

  useEffect(() => {
    const mainLayout = document.getElementById('mainLayoutWrapper');
    if (mainLayout) {
      mainLayout.style.overflowY = 'hidden';
    }

    return () => {
      mainLayout.style.overflowY = 'visible';
    };
  }, []);

  const onDownloadClick = async () => {
    const query = window.location.search.split('?')[1];
    await request.post({
      url: '/dashboard/export-pdf',
      body: { query },
      onSuccess: () => {
        console.log('Iniciando download');
        return {
          customMessage: 'Iniciando download',
        };
      },
    });
  };

  socket.on('new-pdf', (data) => {
    console.log('Download concluido');
    setPdfFile(data);
  });

  useEffect(() => {
    if (pdfFile) {
      const downloadLink = document.createElement('a');
      downloadLink.target = '_blank';
      downloadLink.download = 'relatorio.pdf';
      const blob = new Blob([pdfFile.binary], { type: 'application/pdf' });
      const URL = window.URL || window.webkitURL;
      const downloadUrl = URL.createObjectURL(blob);
      downloadLink.href = downloadUrl;
      document.body.append(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(downloadUrl);
    }
  }, [pdfFile]);

  console.log(dashboardData);

  return (
    <div className="dashboard-container">
      <TitledPage buttons={titleButtons} noScroll title="Dashboard">
        {isMobile && <MobileFixedButtons />}
        <Container style={{ paddingBottom: '10rem' }}>
          <StyledRow gutter={[50, 35]}>
            <Col xl={12} lg={24}>
              <InfoCardList data={dashboardData} />
            </Col>
            <Col md={24} xl={12} lg={24}>
              <AdminMap data={dashboardData} />
            </Col>
            <Col xl={12} lg={24}>
              <UnifiedAttendancesMultipleBarChart data={dashboardData} />
            </Col>
          </StyledRow>
          <StyledRow gutter={[50, 35]}>
            <Col xxl={12} xl={24}>
              <DemandType data={dashboardData} />
            </Col>
            <Col xxl={6} xl={12} lg={12}>
              <UnifiedAttendancesRegions data={dashboardData} />
            </Col>
            <Col xxl={6} xl={12} lg={12}>
              <OperatorUnifiedAttendances data={dashboardData} />
            </Col>
          </StyledRow>
          <StyledRow gutter={[50, 35]}>
            <Col xxl={{ order: 1, span: 18 }} xl={{ order: 3, span: 24 }}>
              <Areas data={dashboardData} />
            </Col>
            <Col xxl={{ order: 1, span: 6 }} xl={{ order: 3, span: 24 }}>
              <AttendanceGraph data={dashboardData} />
            </Col>
            <Col xxl={{ span: 6 }} xl={{ order: 1, span: 12 }} lg={12}>
              <OperatorResolution data={dashboardData} />
            </Col>
          </StyledRow>
          <StyledRow gutter={[50, 35]}>
            <Col xxl={18} xl={24}>
              <Operator data={dashboardData} />
            </Col>
          </StyledRow>
          <DownloadButton extension="pdf" onClick={onDownloadClick} />
        </Container>
      </TitledPage>
    </div>
  );
}

export default Dashboard;
