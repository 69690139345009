import { Tabs as AntTabs } from 'antd';

import useMobile from 'hooks/use-mobile';

import { Col, Content, Title } from './Tabs.style';

function Tab({ tab, renderKey, children, span, ...props }) {
  const isMobile = useMobile();

  if (isMobile)
    return (
      <AntTabs.TabPane {...props} tab={tab} key={renderKey}>
        {children}
      </AntTabs.TabPane>
    );

  return (
    <Col span={span}>
      <Title>{tab}</Title>
      <Content>{children}</Content>
    </Col>
  );
}

export default Tab;
