/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import * as Yup from 'yup';

import { Can, ConfirmationModal } from 'components';
import { CustomConfirmationModal } from 'components/ConfirmationModal/components/CustomConfirmationModal/CustomConfirmationModal';
import { StyledCheckbox } from 'components/CreateConfigurationButton/CreateConfigurationModal.style';
import {
  DatePicker,
  FloatingLabelAutocomplete,
  TimePicker,
} from 'components/inputs';
import { useNotification, useUserInfo } from 'contexts';
import { useConfirmCancel } from 'contexts/ConfirmCancelProvider';
import {
  getDiffBetweenObjects,
  techniqueAutocompleteLabelFormatter,
  techniqueAutocompleteMatchFunction,
} from 'helpers';
import { useRequest, useTouched } from 'hooks';

import { CanTechniqueEdit } from './components/CanTechniqueEdit/CanTechniqueEdit';
import { UnifiedAttendanceReminder } from './components/UnifiedAttendanceReminder/UnifiedAttendanceReminder';
import { useIsTechniqueAbleToEdit } from './hooks/useIsTechniqueAbleToEdit';
import {
  AntdCol,
  Bold,
  CitizenData,
  FlexButtonsContainer,
  FormButtonContainer,
  InfoText,
  Inputs,
  Name,
  Select,
  Status,
  StyledFormButton,
  StyledTextArea,
} from './UnifiedAttendanceDetailModal.style';

function UnifiedAttendanceDetailModal({
  closeModal,
  detailInfo,
  get,
  watchTouched,
}) {
  const { addNotification } = useNotification();
  const [isSubmittingFromSaveBtn, setIsSubmittingFromSaveBtn] = useState(false);
  const memoizedDetailInfo = useMemo(() => detailInfo, [detailInfo]);
  const [firstFormValuesLoad, setFirstFormValuesLoad] = useState({
    date: false,
  });

  const request = useRequest();
  const confirmCancel = useConfirmCancel();
  const [initialInformation, setInitialInformation] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const [selectsOptions, setSelectsOptions] = useState({});
  const [edit, setEdit] = useState(false);

  const statusMapObject = {
    opened: 'Em aberto',
    concluded: 'Concluído',
    canceled: 'Cancelado',
  };

  const statusOptions = [
    { label: 'Em aberto', value: 'opened' },
    { label: 'Concluído', value: 'concluded' },
    { label: 'Cancelado', value: 'canceled' },
  ];

  const getOptions = async (fieldName, optionsPath, config = {}) => {
    await request.get({
      url: optionsPath,
      config: {
        params: {
          ...config,
        },
      },
      onSuccess: (data) => {
        const itemsArr = data.map((item) => ({
          ...item,
          value: item.id,
          label: item.name,
        }));
        setSelectsOptions((prevSelectsOptions) => ({
          ...prevSelectsOptions,
          [fieldName]: itemsArr,
        }));
      },
    });
  };

  useEffect(() => {
    if (Object.keys(memoizedDetailInfo).length) {
      const fetchOptions = async () => {
        const userId = memoizedDetailInfo?.userId;
        const isOnline = memoizedDetailInfo?.isOnline;

        await Promise.all([
          getOptions('userId', `/user?roles=technique`),
          getOptions(
            'channelId',
            `/channel?search=${memoizedDetailInfo?.channelId}`
          ),
          getOptions(
            'motiveId',
            `/call-motive?search=${memoizedDetailInfo?.motiveId}`
          ),

          ...(userId && isOnline
            ? [
                getOptions('availableTimes', `/available-time`, {
                  userId,
                  isOnline: true,
                }),
              ]
            : []),
        ]);
      };

      fetchOptions();
      const initialFormValues = {
        date: moment(memoizedDetailInfo.startDate),
        requesterId: memoizedDetailInfo.requester.id,
        userId: memoizedDetailInfo.userId,
        channelId: memoizedDetailInfo.channelId,
        motiveId: memoizedDetailInfo.motiveId,
        isOnline: memoizedDetailInfo.isOnline ? 'true' : 'false',
        status: memoizedDetailInfo.status,
        time: memoizedDetailInfo.isOnline
          ? `${memoizedDetailInfo.startDate} - ${memoizedDetailInfo.endDate}`
          : moment(memoizedDetailInfo.startDate),
        forwarding: memoizedDetailInfo?.forwarding,
        observation: memoizedDetailInfo?.observation,
        confidential: memoizedDetailInfo?.confidential,
        usersIds: memoizedDetailInfo?.users?.map((user) => user.id) ?? [],
      };

      setInitialInformation((prevInitialInformation) => ({
        ...prevInitialInformation,
        ...initialFormValues,
        solicitantName: memoizedDetailInfo.requester.name,
        cpf: memoizedDetailInfo.requester.cpf,
        area: memoizedDetailInfo.requester.area.name,
        regional: memoizedDetailInfo.requester.region.name,
        startDate: memoizedDetailInfo.startDate,
        endDate: memoizedDetailInfo.endDate,
      }));

      setInitialValues((prevInitialValues) => ({
        ...prevInitialValues,
        ...initialFormValues,
      }));
    }
  }, [memoizedDetailInfo]);

  const UnifiedAttendanceSchema = Yup.object().shape({
    confidential: Yup.boolean(),
    date: Yup.string().required('Este campo é obrigatório'),
    userId: Yup.string().required('Este campo é obrigatório'),
    isOnline: Yup.string().required('Este campo é obrigatório'),
    time: Yup.string().required('Este campo é obrigatório'),
    requesterId: Yup.string().required('Este campo é obrigatório'),
    channelId: Yup.string().required('Este campo é obrigatório'),
    motiveId: Yup.string().required('Este campo é obrigatório'),
  });

  const handleCloseModal = () => {
    get();
    setEdit(false);
    closeModal();
  };

  const onSubmit = useCallback(
    async (values) => {
      const changedData = getDiffBetweenObjects(initialValues, values);

      if (changedData?.content) {
        delete changedData.content;
      }

      if (changedData.isOnline) {
        changedData.isOnline = changedData.isOnline === 'true';
      }

      if (changedData.isOnline) {
        if (changedData.date || changedData.time) {
          const date = moment(values.date).format('YYYY-MM-DD');

          const formatTime = (time) =>
            moment(time, 'YYYY-MM-DDTHH:mm:ss').format('HH:mm');

          const startTime = formatTime(values.time.split(' - ')[0]);
          const endTime = formatTime(values.time.split(' - ')[1]);

          const startDate = moment(`${date}T${startTime}`).subtract(3, 'h');
          const endDate = moment(`${date}T${endTime}`).subtract(3, 'h');

          changedData.startDate = startDate;
          changedData.endDate = endDate;
        }
      } else if (changedData.date || changedData.time) {
        const date = moment(values.date).format('YYYY-MM-DD');

        const formatTime = (time) =>
          moment(time, 'YYYY-MM-DDTHH:mm:ss').format('HH:mm');

        const startTime = formatTime(values.time);
        const endTime = moment(values.time, 'YYYY-MM-DDTHH:mm:ss')
          .add(30, 'minutes')
          .format('HH:mm');

        const startDate = moment(`${date}T${startTime}`);
        const endDate = moment(`${date}T${endTime}`);

        changedData.startDate = startDate;
        changedData.endDate = endDate;
      }

      delete changedData.time;
      delete changedData.date;

      const isStartDateSame =
        changedData?.startDate?.format() === detailInfo?.startDate;
      const isEndDateSame =
        changedData?.endDate?.format() === detailInfo?.endDate;

      if (isStartDateSame) {
        delete changedData.startDate;
      }

      if (isEndDateSame) {
        delete changedData.endDate;
      }

      if (Object.keys(changedData).length) {
        return request.patch({
          url: `/unified-attendance/${detailInfo.id}`,
          body: changedData,
          onSuccess: (data) => {
            setInitialValues({
              ...data,
              isOnline: data.isOnline ? 'true' : 'false',
              date: moment(data.startDate),
              time: data.isOnline
                ? `${data.startDate} - ${data.endDate}`
                : moment(data.startDate),
              usersIds: data?.users?.map((user) => user.id) ?? [],
            });
            setFirstFormValuesLoad({
              date: false,
            });
            if (isSubmittingFromSaveBtn) {
              handleCloseModal();
            }
          },
        });
      }

      addNotification({
        message: 'Itens atualizados com sucesso!',
        type: 'success',
      });
      handleCloseModal();
    },
    [initialValues, isSubmittingFromSaveBtn]
  );

  const debounceSubmit = useCallback(_.debounce(onSubmit, 2000), [onSubmit]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: UnifiedAttendanceSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit,
  });

  useEffect(() => {
    if (edit && !_.isEqual(initialValues, formik.values) && formik.isValid) {
      debounceSubmit(formik.values);
    }

    if (isSubmittingFromSaveBtn) {
      debounceSubmit.cancel();
    }

    return () => {
      debounceSubmit.cancel();
    };
  }, [formik.values, isSubmittingFromSaveBtn]);

  const touched = useTouched(formik.values, initialValues);
  watchTouched(touched);

  const availableDates = (current) => {
    const currentDate = moment(current).locale('pt-br').format('LL');
    const momentsArray = selectsOptions.availableTimes?.map(({ date }) =>
      moment(date).format('LL')
    );

    const currentDateTimes = selectsOptions?.availableTimes?.filter(
      ({ date, startDate }) =>
        moment(date).format('LL') === moment(current).format('LL') &&
        moment().isBefore(moment(startDate))
    );

    return !momentsArray?.includes(currentDate) || currentDateTimes.length <= 0;
  };

  useEffect(() => {
    if (firstFormValuesLoad.userId) {
      formik.setFieldValue('date', '');
      formik.setFieldValue('time', '');
    } else if (formik.values.userId) {
      setFirstFormValuesLoad((prev) => ({
        ...prev,
        userId: true,
      }));
    }

    if (formik.values.isOnline === 'true' && !!formik.values.userId) {
      getOptions('availableTimes', `/available-time`, {
        userId: formik.values.userId,
        isOnline: formik.values.isOnline === 'true',
      });
    }
  }, [formik.values.userId]);

  useEffect(() => {
    if (firstFormValuesLoad.isOnline) {
      formik.setFieldValue('time', '');
      formik.setFieldValue('date', '');
    }

    if (!!formik.values.isOnline && !firstFormValuesLoad.isOnline) {
      setFirstFormValuesLoad((prev) => ({
        ...prev,
        isOnline: true,
      }));
    }

    if (formik.values.isOnline === 'true' && !!formik.values.userId) {
      getOptions('availableTimes', `/available-time`, {
        userId: formik.values.userId,
        isOnline: formik.values.isOnline === 'true',
      });
    }
  }, [formik.values.isOnline]);

  useEffect(() => {
    const updateSelectsOptions = () => {
      if (formik.values.date) {
        const now = moment().subtract(3, 'h');

        const filteredTimes = selectsOptions.availableTimes?.filter(
          ({ date, startDate }) => {
            const startTime = moment(startDate);

            return (
              moment(date).format('LL') ===
                moment(formik.values?.date).format('LL') && now < startTime
            );
          }
        );

        const timeOptions = filteredTimes?.map(({ startDate, endDate }) => ({
          label: `${moment(startDate).format('HH[:]mm')} - ${moment(
            endDate
          ).format('HH[:]mm')}`,
          value: `${startDate} - ${endDate}`,
        }));

        const isSameDate = moment(formik.values.date).isSame(
          initialInformation.startDate,
          'day'
        );

        const isSameOnlineStatus =
          formik.values.isOnline === initialInformation.isOnline;

        const filteredTimeOptions = [];

        const currentTime = {
          label: `${moment(initialInformation.startDate).format(
            'HH[:]mm'
          )} - ${moment(initialInformation.endDate).format('HH[:]mm')}`,
          value: `${initialInformation.startDate} - ${initialInformation.endDate}`,
        };

        timeOptions?.forEach((option) => {
          if (option.label !== currentTime.label) {
            filteredTimeOptions.push(option);
          }
        });

        filteredTimeOptions?.unshift(currentTime);

        setSelectsOptions((prev) => ({
          ...prev,
          time: filteredTimeOptions,
        }));
      }
    };

    updateSelectsOptions();
  }, [selectsOptions.availableTimes, formik.values.date]);

  useEffect(() => {
    if (formik.values.date && !firstFormValuesLoad.date) {
      setFirstFormValuesLoad((prev) => ({
        ...prev,
        date: true,
      }));
    } else {
      formik.setFieldValue('time', '');
    }
  }, [formik.values.date]);

  const unifiedAttendanceTypeOptions = [
    { label: 'Atendimento', value: 'false' },
    { label: 'Agendamento', value: 'true' },
  ];

  const [operatorsEmail, setOperatorsEmail] = useState([]);
  const handleUsersIds = (values) => {
    formik.setFieldValue('usersIds', values);
  };
  useEffect(() => {
    const emailToTag = (email) => {
      if (email?.length > 0 && email !== undefined)
        return `@${email.split('@')[0]}`;
      return email;
    };
    const getEmails = async () => {
      await request.get({
        url: '/user',
        onSuccess: (data) => {
          const emailsArr = [];
          data.forEach((user) => {
            emailsArr.push({
              value: user.id,
              label: emailToTag(user.email),
            });
          });
          setOperatorsEmail(emailsArr);
        },
      });
    };
    getEmails();
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Status status={initialInformation.status}>
          {statusMapObject[initialInformation.status]}
        </Status>
        <CitizenData>
          <Name onClick={() => setEdit(true)}>
            {initialInformation.solicitantName}
          </Name>
          <InfoText>
            <Bold>{'CPF: '}</Bold>
            {initialInformation.cpf}
          </InfoText>
          <InfoText>
            <Bold>{'Área/empreendimento: '}</Bold>
            {initialInformation.area}
          </InfoText>
          <InfoText>
            <Bold>{'Regional: '}</Bold>
            {initialInformation.regional}
          </InfoText>
        </CitizenData>
        <Inputs>
          <AntdCol span={24}>
            <Select
              label="Tipo"
              name="isOnline"
              absoluteError
              error={formik.errors.isOnline}
              value={formik.values.isOnline}
              onChange={(e) => formik.setFieldValue('isOnline', e)}
              options={unifiedAttendanceTypeOptions}
              onBlur={formik.handleBlur}
              schema={UnifiedAttendanceSchema}
              disabled={!edit}
            />
          </AntdCol>
          <AntdCol span={24}>
            <FloatingLabelAutocomplete
              label="Digite o nome ou email do técnico"
              name="userId"
              error={formik.errors.userId}
              options={selectsOptions?.userId}
              onBlur={formik.handleBlur}
              value={formik.values.userId}
              customMatchFunction={techniqueAutocompleteMatchFunction}
              onSelect={(data) => formik.setFieldValue('userId', data)}
              absoluteError
              schema={UnifiedAttendanceSchema}
              customLabelFormatter={techniqueAutocompleteLabelFormatter}
              disabled={!edit}
            />
          </AntdCol>

          {formik.values?.isOnline === 'true' ? (
            <>
              <AntdCol span={24}>
                <DatePicker
                  label="Data do atendimento"
                  name="date"
                  single
                  absoluteError
                  formik={formik}
                  error={formik.errors.date}
                  value={formik.values.date}
                  onBlur={formik.handleBlur}
                  disabledDates={availableDates}
                  disabled={!edit || formik.values.isOnline === ''}
                  allowClear={false}
                  schema={UnifiedAttendanceSchema}
                  defaultTimezone={false}
                />
              </AntdCol>

              <AntdCol span={24}>
                <Select
                  label="Selecione o horário"
                  name="time"
                  absoluteError
                  onChange={(e) => formik.setFieldValue('time', e)}
                  error={formik.errors.time}
                  value={formik.values.time}
                  disabled={!edit || formik.values.date === ''}
                  options={selectsOptions.time || []}
                  margin
                  schema={UnifiedAttendanceSchema}
                />
              </AntdCol>
            </>
          ) : (
            <>
              <AntdCol span={24}>
                <DatePicker
                  formik={formik}
                  single
                  name="date"
                  label="Data"
                  value={formik.values.date}
                  error={formik.errors.date}
                  schema={UnifiedAttendanceSchema}
                  allowClear={false}
                  defaultTimezone={false}
                />
              </AntdCol>
              <AntdCol span={24}>
                <TimePicker
                  formik={formik}
                  format="HH:mm"
                  placeholder="Selecione o horário"
                  single
                  name="time"
                  label="Horário"
                  value={formik.values.time}
                  error={formik.errors.time}
                  schema={UnifiedAttendanceSchema}
                  allowClear={false}
                />
              </AntdCol>
            </>
          )}

          <AntdCol span={24}>
            <Select
              mode="multiple"
              label="Responsáveis pelo atendimento"
              name="usersIds"
              absoluteError
              onChange={handleUsersIds}
              value={operatorsEmail?.length ? formik.values.usersIds : []}
              singleTag
              options={operatorsEmail}
              loadMore={() => {}}
              disabled={!edit}
            />
          </AntdCol>

          <AntdCol span={24}>
            <Select
              label="Canal"
              name="channelId"
              error={formik.errors.channelId}
              options={selectsOptions?.channelId}
              onBlur={formik.handleBlur}
              value={formik.values.channelId}
              onChange={(e) => formik.setFieldValue('channelId', e)}
              absoluteError
              schema={UnifiedAttendanceSchema}
              disabled={!edit}
            />
          </AntdCol>

          <AntdCol span={24}>
            <Select
              label="Motivo do atendimento"
              name="motiveId"
              error={formik.errors.motiveId}
              options={selectsOptions?.motiveId}
              onBlur={formik.handleBlur}
              value={formik.values.motiveId}
              onChange={(e) => formik.setFieldValue('motiveId', e)}
              absoluteError
              schema={UnifiedAttendanceSchema}
              disabled={!edit}
            />
          </AntdCol>

          <AntdCol span={12}>
            <Select
              label="Status"
              name="status"
              width="100%"
              absoluteError
              error={formik.errors.status}
              value={formik.values.status}
              onChange={(e) => formik.setFieldValue('status', e)}
              options={statusOptions}
              onBlur={formik.handleBlur}
              disabled={!edit}
              schema={UnifiedAttendanceSchema}
            />
          </AntdCol>

          <AntdCol span={24}>
            <StyledTextArea
              label="Síntese para a demanda"
              name="observation"
              width="100%"
              error={formik.errors.observation}
              value={formik.values.observation}
              onChange={formik.handleChange}
              schema={UnifiedAttendanceSchema}
              disabled={!edit}
            />
          </AntdCol>

          <AntdCol span={24}>
            <StyledTextArea
              label="Encaminhamento"
              name="forwarding"
              width="100%"
              error={formik.errors.forwarding}
              value={formik.values.forwarding}
              onChange={formik.handleChange}
              schema={UnifiedAttendanceSchema}
              disabled={!edit}
            />
          </AntdCol>

          <AntdCol span={24}>
            <StyledCheckbox
              name="confidential"
              value={formik.values.confidential}
              onChange={
                (e) => formik.setFieldValue('confidential', e.target.checked)
                // eslint-disable-next-line react/jsx-curly-newline
              }
              onBlur={formik.handleBlur}
              checked={formik.values.confidential}
              disabled={!edit}
              schema={UnifiedAttendanceSchema}
            >
              Atendimento confidencial?
            </StyledCheckbox>
          </AntdCol>
        </Inputs>
        {!edit && (
          <FlexButtonsContainer>
            <CanTechniqueEdit unifiedAttendance={memoizedDetailInfo}>
              <Can
                perform={['appointments:update', 'appointments.self:update']}
              >
                <FormButtonContainer style={{ margin: 0 }}>
                  <StyledFormButton onClick={() => setEdit(true)} $cancel>
                    Editar
                  </StyledFormButton>
                </FormButtonContainer>
              </Can>
            </CanTechniqueEdit>
            <Can perform={['appointments:delete', 'appointments.self:delete']}>
              <CustomConfirmationModal
                question="Você tem certeza que deseja deletar este atendimento?"
                handleOnConfirm={({ onClose, setLoading }) =>
                  async () => {
                    if (!memoizedDetailInfo?.id) return;
                    try {
                      setLoading(true);
                      await request.delete({
                        url: `/unified-attendance/${memoizedDetailInfo?.id}`,
                      });
                      get();
                      onClose();
                      closeModal();
                    } catch (err) {
                      console.error(err);
                    } finally {
                      setLoading(false);
                    }
                  }}
              >
                {({ onOpen }) => (
                  <FormButtonContainer style={{ margin: 0 }}>
                    <StyledFormButton onClick={() => onOpen()} $delete>
                      Deletar
                    </StyledFormButton>
                  </FormButtonContainer>
                )}
              </CustomConfirmationModal>
            </Can>
          </FlexButtonsContainer>
        )}

        {edit && (
          <FormButtonContainer>
            <StyledFormButton
              onClick={() => confirmCancel(handleCloseModal, touched)}
              $cancel
            >
              Cancelar
            </StyledFormButton>
            <StyledFormButton
              loading={formik.isSubmitting}
              onClick={() => {
                console.log(formik.errors);
                setIsSubmittingFromSaveBtn(true);
              }}
              htmlType="submit"
            >
              Salvar
            </StyledFormButton>
          </FormButtonContainer>
        )}
      </form>
      <UnifiedAttendanceReminder
        unifiedAttendanceId={detailInfo?.id}
        ownerId={formik?.values?.userId}
      />
    </>
  );
}

export default UnifiedAttendanceDetailModal;
