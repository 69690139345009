import { useState } from 'react';

import { Board } from './EditDataBoard.style';
import Header from './Header';
import InputsContainer from './InputsContainer';

const EditDataBoard = ({
  user,
  setUser,
  inputs,
  boardTitle,
  path,
  activeSection,
}) => {
  const [disableFields, setDisableFields] = useState(true);

  return (
    <Board>
      <Header
        disabled={disableFields}
        setDisable={setDisableFields}
        title={boardTitle}
        user={user}
        path={path}
      />
      <InputsContainer
        setDisabled={setDisableFields}
        disabled={disableFields}
        path={path}
        setUser={setUser}
        user={user}
        inputs={inputs}
        section={activeSection}
      />
    </Board>
  );
};
export default EditDataBoard;
