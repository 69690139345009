import alertSvg from 'assets/icons/alert.svg';
import { thisFieldHasRequired } from 'helpers/lookForRequiredOnYupSchema';

import {
  Container,
  Error,
  ErrorIcon,
  Icon,
  Input,
  InputPassword,
  Label,
} from './TextInput.style';

function TextInput({
  label,
  placeholder,
  type,
  onChange,
  schema,
  value,
  icon,
  name,
  className,
  error,
  ...otherProps
}) {
  const RenderInput = type === 'password' ? InputPassword : Input;

  return (
    <Container className={className}>
      <Label htmlFor={name}>
        {`${label} ${thisFieldHasRequired(schema, name) ? '*' : ''}`}
      </Label>
      <RenderInput
        placeholder={placeholder}
        value={value}
        type={type}
        onChange={onChange}
        name={name}
        prefix={<Icon src={icon} alt="" />}
        {...otherProps}
      />
      {error && (
        <Error>
          <ErrorIcon src={alertSvg} alt="Ícone de erro" />
          {error}
        </Error>
      )}
    </Container>
  );
}

export default TextInput;
