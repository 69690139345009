import moment from 'moment';
import { register } from 'timeago.js';

import 'moment/locale/pt-br';

moment.locale('pt-br', {
  weekdays: 'Domingo_Segunda_Terça_Quarta_Quinta_Sexta_Sábado'.split('_'),
});

const localeFunc = (number, index, totalSec) => [
  ['agora', 'agora'],
  ['%s segundos atrás', 'daqui a %s segundos'],
  ['1 minuto atrás', 'daqui a 1 minute'],
  ['%s minutos atrás', 'daqui a %s minutes'],
  ['1 hora atrás', 'daqui a 1 hora'],
  ['%s horas atrás', 'daqui a %s horas'],
  ['1 dia atrás', 'daqui a 1 dia'],
  ['%s dias atrás', 'daqui a %s dias'],
  ['1 semana atrás', 'daqui a 1 semana'],
  ['%s semanas atrás', 'daqui a %s semanas'],
  ['1 mês atrás', 'daqui a 1 mês'],
  ['%s meses atrás', 'daqui a %s meses'],
  ['1 ano atrás', 'daqui a 1 ano'],
  ['%s anos atrás', 'daqui a %s anos']
][index];;

register('en_US', localeFunc);
