import request from 'api/request';
import { useNotification } from 'contexts/NotificationProvider';

import { instanceWrapper, notification } from './services';

function useRequest() {
  const { addNotification } = useNotification();
  const authorizedInstance = request.authorized();
  const unauthorizedInstance = request.unauthorized();

  const notificationService = notification(addNotification);

  const authorizedMethods = instanceWrapper(
    authorizedInstance,
    notificationService
  );

  const unauthorizedMethods = instanceWrapper(
    unauthorizedInstance,
    notificationService
  );

  return {
    ...authorizedMethods,
    unauthorized: unauthorizedMethods,
  };
}

export default useRequest;
