import { Popup } from 'react-map-gl';

import { TooltipContent, TooltipTitle } from './AdminMap.style';

const CustomPopup = ({ marker, color }) => (
  <Popup
    latitude={marker?.lat}
    longitude={marker?.long}
    offsetLeft={135}
    offsetTop={15}
  >
    <TooltipTitle>{marker.name}</TooltipTitle>
    <TooltipContent color={color}>
      Atendimentos em aberto:
      <span>{` ${marker?.amount}\n`}</span>
      Média de atendimentos por habitante:
      <span>{` ${marker?.cpp?.toFixed(2)}`}</span>
    </TooltipContent>
  </Popup>
);

export default CustomPopup;
