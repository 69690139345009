import { Select as AntSelect } from 'antd';
import styled, { css } from 'styled-components';

import { respondTo } from 'styles/mixins';

export const StyledSelect = styled(AntSelect)`
  position: relative;
  display: flex;
  border: none;
  box-shadow: 0px 0px 20px #cccaca;
  border-radius: 15px;

  .ant-select-selector {
    height: 40px !important;
    border-radius: 15px !important;
    border-color: #ffffff !important;
  }

  .ant-select-selection-placeholder {
    color: #383737;
    font-size: 1.125rem;
    letter-spacing: 0.36px;
    margin-left: 30px;
  }
  .ant-select-arrow {
    right: 30px !important;
    top: 10px !important;
    font-size: 1.25rem !important;
    color: black !important;
  }

  ${respondTo('large')(css`
    font-size: 1.25rem;
  `)}

  ${respondTo('medium')(css`
    font-size: 1.125rem;
    .ant-select-selector {
      box-shadow: none !important;
    }
    ::after {
      position: absolute;
      bottom: -1px;
      height: 1px;
      width: 100%;
      background: transparent linear-gradient(94deg, #ffcf8f 0%, #f88122 100%)
        0% 0% no-repeat padding-box;
      content: '';
    }
  `)}
`;

export const PreffixIcon = styled.img`
  width: 1.1875rem;
  top: 30px;
  left: 10px;
  position: relative;
  z-index: 10;

  ${respondTo('medium')(css`
    width: 1.090625rem;
    top: 30px;
    left: 0px;
  `)}
`;
