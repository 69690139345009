import { Redirect, Route, Switch } from 'react-router-dom';

import { MainLayout, ReportDetailPage } from 'components';
import { useUserInfo } from 'contexts/UserProvider/UserProvider';
import {
  AreaDetail,
  Areas,
  AssistantProfile,
  Calendar,
  Configurations,
  Dashboard,
  ManagerResidentEdit,
  ManagerUserEdit,
  Report,
  Residents,
  Users,
} from 'views';

import ProtectedRoute from './ProtectedRoute';

function Protected() {
  const { userData } = useUserInfo();

  const decideRedirectPath = (role) => {
    if (['attendant', 'technique'].includes(role)) return '/central-do-morador';
    return '/central-do-morador';
  };

  return (
    <Switch>
      <MainLayout>
        <Switch>
          <ProtectedRoute
            exact
            path="/dashboard"
            component={Dashboard}
            action={['dashboard:visit']}
          />
          <ProtectedRoute
            exact
            path="/home"
            component={AssistantProfile}
            action={['home:visit']}
          />
          <ProtectedRoute
            path="/configuracoes"
            component={Configurations}
            action={['configurations:visit']}
          />
          <ProtectedRoute
            exact
            path="/usuarios"
            component={Users}
            action={['users:visit']}
          />
          <ProtectedRoute
            exact
            path="/relatorio"
            component={Report}
            action={['report:visit']}
          />
          <ProtectedRoute
            path="/relatorio/:id"
            component={ReportDetailPage}
            action={['report:visit']}
          />
          <ProtectedRoute
            path="/usuarios/:id"
            component={ManagerUserEdit}
            action={['users:visit']}
          />
          <ProtectedRoute
            exact
            path="/central-do-morador"
            component={Residents}
            action={['resident-center:visit']}
          />
          <ProtectedRoute
            path="/central-do-morador/:id"
            component={ManagerResidentEdit}
            action={['resident-center:read']}
          />
          <ProtectedRoute
            path="/detalhamento-area"
            component={AreaDetail}
            action={['users:visit']}
          />
          <ProtectedRoute
            path="/areas"
            component={Areas}
            action={['areas:visit']}
          />
          <ProtectedRoute
            path="/agenda"
            component={Calendar}
            action={['schedule:visit']}
          />
          <Route>
            <Redirect to={decideRedirectPath(userData?.role)} />
          </Route>
        </Switch>
      </MainLayout>
    </Switch>
  );
}

export default Protected;
