import { Col } from 'antd';
import styled, { css } from 'styled-components';

import colors from 'styles/colors';
import { respondTo } from 'styles/mixins';

export const Container = styled.section`
  margin: 2.25rem 3rem;
  overflow: visible;
`;

export const AntdCol = styled(Col)`
  padding: 0 !important;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

export const TableWrapper = styled.div`
  width: 100%;
  margin-top: 2rem;

  & > table {
    margin-top: 2rem;
  }
`;

export const OptionButton = styled.button`
  background-color: ${colors.persianGreen};
  color: white;
  font-size: 0.875rem;
  border-radius: 50px;
  font-weight: 600;
  min-height: 1.6875rem;
  padding: 0.3125rem 1.25rem;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  line-height: 1.0625rem;
`;

export const PhotoContainer = styled.div`
  width: 100%;
  padding-top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  gap: 2rem;

  ${respondTo('medium')(css`
    flex-direction: column;
    align-items: flex-start;
  `)}
`;

export const Bold = styled.p`
  margin: 0;
  color: ${colors.smoothBlack};
  font-weight: 600;
`;
