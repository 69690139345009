import { useParams } from 'react-router-dom';

import { DocumentInfo } from 'components';
import { useModal, withModalProvider } from 'contexts/ModalProvider';
import useCrud from 'hooks/use-crud';

import {
  Button,
  Card,
  DocumentsContainer,
  StyledCol,
  StyledRow,
  Title,
} from './DocumentList.style';

function DocumentList() {
  const { modal } = useModal();
  const { id } = useParams();
  const [documents, { create, edit, remove }] = useCrud('/document', {
    autoinit: true,
    parameters: { requestersIds: id },
  });
  const [documentTypes] = useCrud('/document-type', { autoinit: true });

  const handleModal = () => {
    const modalProps = {
      title: 'Adicionar Documento',
      footer: null,
      destroyOnClose: true,
      watchTouched: true,
      maskClosable: false,
    };

    modal(
      (props) => (
        <DocumentInfo
          upload
          footer
          documentTypes={documentTypes}
          submitDocument={create}
          {...props}
        />
      ),
      modalProps
    );
  };

  return (
    <>
      <Button onClick={handleModal}>Adicionar documento</Button>
      <Card>
        <Title>Documentos</Title>
        <DocumentsContainer>
          <StyledRow gutter={50}>
            {documents.map((document) => (
              <StyledCol key={document.id} xl={12} lg={24} sm={24} xs={24}>
                <DocumentInfo
                  initialValues={document}
                  documentTypes={documentTypes}
                  removeDocument={remove}
                  editDocument={edit}
                />
              </StyledCol>
            ))}
          </StyledRow>
        </DocumentsContainer>
      </Card>
    </>
  );
}

export default withModalProvider(DocumentList);
