import styled, { css } from 'styled-components';

import colors from 'styles/colors';
import { respondTo } from 'styles/mixins';

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;

  ${respondTo('medium')(css`
    margin-left: 0;
    margin-bottom: 2rem;
  `)}
`;

export const UserName = styled.h1`
  font-size: 1rem;
  font-weight 600;
  color: ${colors.black};
`;

export const HighlightTag = styled.div`
  min-width: 10rem;
  padding: 0.4rem 3rem;
  border-radius: 10px;
  background-color: ${colors.smoothBlack};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HighlightTitle = styled(UserName)`
  color: ${colors.white};
  margin-bottom: 0;
`;
