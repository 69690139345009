import styled, { css } from 'styled-components';

import waveSvg from 'assets/waves/wave-light.svg';
import { respondTo } from 'styles/mixins';

const Wave = styled.img`
  position: fixed;
  top: -50px;
  right: calc(100vw - 40vw);
  height: 120vh;

  ${respondTo('medium')(css`
    display: none;
  `)}
`;

const WaveLight = () => <Wave src={waveSvg} alt="" />;

export default WaveLight;
