import { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';

import { MultiSelect } from 'components/EditDataBoard/EditDataBoard.style';
import { FormButton } from 'components/inputs';
import { useConfirmCancel } from 'contexts/ConfirmCancelProvider';
import useRequest from 'hooks/use-request';
import useTouched from 'hooks/use-touched';

import {
  DropDown,
  FormButtonContainer,
  Input,
  Select,
} from './CreateUserModal.style';
import { hasArea, hasRegion } from './helpers';
import { CreateUserSchema, validate } from './validation';

function CreateUserModal({ closeModal, watchTouched = () => {}, create }) {
  const [operatorTypeOptions, setOperatorTypeOptions] = useState([]);
  const [regionOptions, setRegionOptions] = useState([]);
  const [areasOptions, setAreasOptions] = useState([]);
  const confirmCancel = useConfirmCancel();
  const request = useRequest();
  const [areasFieldInformations, setAreasFieldInformations] = useState({
    hasMore: true,
    page: 0,
    search: '',
  });

  const areasSearchRef = useRef('');

  const getAreasOptions = async (params = {}, callback = () => {}) => {
    await request.get({
      url: '/area',
      config: {
        params,
      },
      onSuccess: (data, res) => {
        callback(data, res.data.totalItems);
      },
    });
  };

  const initialValues = {
    name: '',
    regionId: '',
    role: '',
    typeOperatorId: '',
    email: '',
    areasIds: [],
  };

  const formik = useFormik({
    initialValues,
    validationSchema: CreateUserSchema,
    validate,
    onSubmit: async (values) => {
      const body = { ...values };
      if (!hasArea(body.role)) {
        delete body.areasIds;
      }
      if (body.role !== 'technique') {
        delete body.typeOperatorId;
      }
      try {
        await create(body);
        closeModal();
      } catch (err) {
        console.log(err);
      }
    },
  });

  const touched = useTouched(formik.values, initialValues);
  watchTouched(touched);

  useEffect(() => {
    const fetchData = async () => {
      const reqMap = ['/type-operator', '/region'];

      const [operatorTypes, regions] = await Promise.all(
        reqMap.map(async (url) => (await request.get({ url })).data)
      );

      const turnIntoOptions = ({ name, id }) => ({ value: id, label: name });

      setOperatorTypeOptions(operatorTypes.map(turnIntoOptions));
      setRegionOptions(regions.map(turnIntoOptions));
    };

    fetchData();
  }, []);

  const handleUpdateAreas = (page, search) => {
    if (!page && !search) {
      const params = {
        regionsIds: [formik.values.regionId],
        skip: 0,
        take: 10,
        name: '',
      };

      const callback = (data, totalItems) => {
        setAreasFieldInformations((prev) => ({
          ...prev,
          hasMore: totalItems > prev.page * 10 + 10,
          page: 1,
        }));

        setAreasOptions(() =>
          data.map((area) => ({
            value: area.id,
            label: area.name,
            regionId: area.regionId,
          }))
        );

        areasSearchRef.current = search;
      };

      return getAreasOptions(params, callback);
    }

    const params = {
      regionsIds: [formik.values.regionId],
      skip: page ? (page + 1) * 10 : 0,
      take: 10,
      name: search || areasFieldInformations.search,
    };

    const callback = (data, totalItems) => {
      setAreasFieldInformations((prev) => ({
        ...prev,
        hasMore: page ? totalItems > (page + 1) * 10 + 10 : totalItems > 10,
        page: page ? page + 1 : 0,
        ...(search && { search }),
      }));

      setAreasOptions((prev) => {
        const newItems = data.map((area) => ({
          value: area.id,
          label: area.name,
          regionId: area.regionId,
        }));

        console.log(search, areasSearchRef.current);

        if (search === areasSearchRef.current) {
          if (page === 0) {
            return newItems;
          }
          return [...prev, ...newItems];
        }
        return newItems;
      });

      areasSearchRef.current = search;
    };

    return getAreasOptions(params, callback);
  };

  useEffect(() => {
    getAreasOptions(
      {
        regionsIds: [formik.values.regionId],
        skip: 0,
        take: 10,
        name: '',
      },
      () => {
        setAreasFieldInformations((prev) => ({
          ...prev,
          hasMore: true,
        }));

        handleUpdateAreas();
      }
    );
  }, []);

  const filteredAreas = formik.values.regionId
    ? areasOptions.filter(({ regionId }) => regionId === formik.values.regionId)
    : areasOptions;

  return (
    <form onSubmit={formik.handleSubmit}>
      <Input
        label="Nome do profissional"
        name="name"
        width="100%"
        absoluteError
        error={formik.errors.name}
        value={formik.values.name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        schema={CreateUserSchema}
      />
      <Select
        label="Categoria do profissional"
        name="role"
        absoluteError
        onChange={(value) => formik.setFieldValue('role', value)}
        onBlur={formik.handleBlur}
        error={formik.errors.role}
        value={formik.values.role}
        schema={CreateUserSchema}
        options={[
          { value: 'admin', label: 'Admin' },
          { value: 'technique', label: 'Equipe Técnica' },
          { value: 'supervisor', label: 'Supervisor' },
          { value: 'coordinator', label: 'Coordenador' },
          { value: 'attendant', label: 'Atendente' },
        ]}
      />
      <DropDown active={formik.values.role === 'technique'}>
        <Select
          label="Cargo"
          name="typeOperatorId"
          absoluteError
          onChange={(value) => formik.setFieldValue('typeOperatorId', value)}
          onBlur={formik.handleBlur}
          error={formik.errors.typeOperatorId}
          value={formik.values.typeOperatorId}
          options={operatorTypeOptions}
          schema={CreateUserSchema}
        />
      </DropDown>
      <Input
        label="E-mail"
        name="email"
        type="email"
        width="100%"
        absoluteError
        error={formik.errors.email}
        value={formik.values.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        schema={CreateUserSchema}
      />
      <DropDown active={hasRegion(formik.values.role)}>
        <Select
          label="Regional"
          name="regionId"
          absoluteError
          onChange={(value) => formik.setFieldValue('regionId', value)}
          onBlur={formik.handleBlur}
          error={formik.errors.regionId}
          value={formik.values.regionId}
          options={regionOptions}
          schema={CreateUserSchema}
        />
      </DropDown>
      <DropDown active={hasArea(formik.values.role)}>
        <MultiSelect
          label="Área/empreendimento"
          name="areasIds"
          mode="multiple"
          absoluteError
          showArrow
          error={formik.errors.areasIds}
          options={filteredAreas}
          onBlur={formik.handleBlur}
          value={formik.values.areasIds}
          onChange={(value) => formik.setFieldValue('areasIds', value)}
          disabled={filteredAreas?.length === 0}
          schema={CreateUserSchema}
          dropdownClassName="areasIds"
          hasMore={areasFieldInformations.hasMore}
          loadMore={(_, page) => handleUpdateAreas(page)}
          page={areasFieldInformations.page}
          onSearch={(search) => handleUpdateAreas(null, search)}
          labelClass="autocompleteLabel"
        />
      </DropDown>
      <FormButtonContainer>
        <FormButton onClick={() => confirmCancel(closeModal, touched)} $cancel>
          Cancelar
        </FormButton>
        <FormButton loading={formik.isSubmitting} htmlType="submit">
          Salvar
        </FormButton>
      </FormButtonContainer>
    </form>
  );
}

export default CreateUserModal;
