import io from 'socket.io-client';

const endpoint = process.env.REACT_APP_SOCKET_URL ;

const socket = io(endpoint);

socket.on('connect', () => {
  console.info('Socket connected');
});

export default socket;
