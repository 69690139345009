import {
  Divider,
  MessageContainer,
  MessageRow,
  Text,
  Title,
} from './Toast.styled';

const Message = ({ title, text, error, children }) => (
  <MessageRow>
    <MessageContainer>
      <Title error={error}>{title}</Title>
      <Divider />
      <Text>{text}</Text>
      {children}
    </MessageContainer>
  </MessageRow>
);

export default Message;
