import { Button as AntButton, Col } from 'antd';
import styled, { css } from 'styled-components';

import { TextArea } from 'components/inputs';
import FloatingLabelSelect from 'components/inputs/FormSelect/FloatingLabelSelect';
import TextField from 'components/inputs/TextField';
import colors from 'styles/colors';
import { respondTo } from 'styles/mixins';

export const Input = styled(TextField)`
  margin: 1.75rem 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const StyledTextArea = styled(TextArea)`
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

export const FormButtonContainer = styled.div`
  width: 100%;
  margin: 1.875rem 0 0.375rem;
  display: flex;
  justify-content: space-around;
`;

export const Select = styled(FloatingLabelSelect)`
  margin: 1.8125rem 0;
`;

export const AntdCol = styled(Col)`
  margin-bottom: 2.5rem;
`;

export const StyledFormButton = styled(AntButton)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  border: none;
  height: 2.5rem;
  padding: 0.625rem 4.625rem;
  background-color: ${colors.darkGreen};
  color: white;
  font-size: 1rem;

  .anticon-loading {
    position: unset;
  }

  &:hover,
  &:focus {
    background-color: ${colors.darkGreen};
    color: white;
    opacity: 0.9;
  }

  ${({ $cancel }) =>
    $cancel &&
    css`
      background-color: ${colors.gray};
      color: ${colors.smoothBlack};

      &:hover,
      &:focus {
        background-color: ${colors.gray};
        color: ${colors.smoothBlack};
        opacity: 0.9;
      }
    `}

  ${({ $delete }) =>
    $delete &&
    css`
      background-color: ${colors.red};
      color: ${colors.white};
      width: fit-content;
      padding: unset;
      padding-right: 1rem;
      padding-left: 1rem;

      &:hover,
      &:focus {
        background-color: ${colors.red};
        color: ${colors.white};
        opacity: 0.9;
      }
    `}

  ${respondTo('medium')(css`
    padding: 0.625rem 2rem;
  `)}
`;

export const CitizenData = styled.div``;

export const Name = styled.div`
  font-size: 1.125rem;
  font-weight: 600;
  letter-spacing: 0.023rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
`;

export const CitizenIcon = styled.img`
  margin-right: 0.625rem;
`;

export const InfoText = styled.div`
  font-size: 1rem;
  margin-bottom: 0.4rem;
  width: 100%;
`;

export const Bold = styled.span`
  font-weight: 600;
`;

export const Inputs = styled.div`
  width: 90%;
`;

export const Status = styled.div`
  color: ${colors.white};
  background-color: ${({ status }) =>
    status === 'opened' ? colors.orange : colors.green};
  font-size: 1.125rem;
  font-weight: 500;
  width: fit-content;
  padding: 0 0.6rem;
  border-radius: 6px;
  margin-bottom: 1rem;
`;

export const FlexButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
  width: fit-content;
  margin: 0 auto;
`;
