export const filters = [
  {
    sectionTitle: "Território",
    filterTypes: [
      {
        title: "Regional",
        callToAction: "Selecione a ou as regiões",
        boxTitle: "Regiões",
        hasSearchInput: true,
        inputPlaceholder: "Pesquise aqui por uma região...",
        name: "regionsIds",
        options: '/region',
      },
    ],
  },
];

export const searchParameters = [
  'name',
];
