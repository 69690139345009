import { Link } from 'react-router-dom';
import { CloseOutlined } from '@ant-design/icons';
import { Drawer } from 'antd';
import styled, { css } from 'styled-components';

import colors from 'styles/colors';
import { respondTo } from 'styles/mixins';

export const Wrapper = styled.div`
  ${({ hidden }) =>
    hidden &&
    css`
      display: hidden;
    `}
`;

export const NotificationButton = styled.div`
  height: fit-content;
  z-index: 99;
  ${({ button }) =>
    button &&
    css`
      cursor: pointer;
      position: absolute;
      right: 2.5rem;
      top: 1.5rem;
    `}

  ${respondTo('medium')(css`
    display: ${({ button }) => (button ? 'none' : 'block')};
  `)}
`;

export const Icon = styled.img`
  padding: 1rem;
  width: fit-content;
  background-color: white;
  box-shadow: 4px 4px 10px ${colors.darkGray};
  border-radius: 1.6rem;
`;

export const Count = styled.div`
  border: 2px solid ${colors.wine};
  font-size: 0.6rem;
  font-weight: bold;
  width: fit-content;
  color: ${colors.wine};
  border-radius: 100%;
  position: relative;
  top: -3.3rem;
  left: 2.5rem;
  min-width: 1.1rem;
  text-align: center;
`;

export const Title = styled.div`
  font-size: 1.375rem;
  letter-spacing: 0.055rem;
  text-align: center;
  font-weight: 600;
  margin-top: 1.75rem;
  margin-bottom: 5.031rem;
`;

export const Container = styled.div`
  border-top: 1px solid ${colors.darkGray};
  margin-top: 2rem;
  margin-bottom: 3.125rem;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TextBlock = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Date = styled.div`
  font-size: 1.125rem;
  letter-spacing: 0.045rem;
  margin-top: 0.906rem;
  margin-left: 3rem;
  margin-bottom: 0.813rem;
  color: ${colors.lightBlueText};
`;

export const Circle = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  border: 3px solid ${colors.primaryDarker};
`;

export const Close = styled(CloseOutlined)`
  cursor: pointer;
`;

export const Text = styled.p`
  font-size: 1.125rem;
  font-weight: 500;
  letter-spacing: 0.045rem;
  margin-bottom: 1.25rem;
  width: calc(100% - 3rem);
`;

export const StyledLink = styled(Link)`
  font-size: 1rem;
  font-weight: 500;
  color: ${colors.secondary};
  letter-spacing: 0.04rem;
  margin-left: 4rem;
`;

export const ArrowIcon = styled.img``;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 1rem;
`;

export const StyledDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    max-width: 28.125rem;
  }

  ${respondTo('medium')(css`
    .ant-drawer-content-wrapper {
      max-width: 100vw;
    }
  `)}

  .ant-drawer-body {
    overflow: hidden;
    padding-left: 0;
    padding-left: 0;
  }

  .ant-drawer-close {
    position: relative;
    font-size: 1.2rem;
    color: ${colors.black};
  }
`;

export const Center = styled.div`
  margin-top: 1rem;
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const Arrow = styled.img`
  width: 1.5rem;
  margin-left: 1rem;
  background-color: ${colors.softOrange};
  padding: 0.35rem;
  border-radius: 100%;
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 1rem;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ScrollableContainer = styled.div`
  height: 53.125rem;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 0.5rem !important;
  }

  ::-webkit-scrollbar-track {
    background: ${colors.gray} !important;
    border-left: 2px solid ${colors.background} !important;
    border-right: 2px solid ${colors.background} !important;
  }

  ::-webkit-scrollbar-thumb {
    background: ${colors.secondary} !important;
    border-radius: 5px !important;
  }
`;
