export const options = {
  responsive: true,
  maintainAspectRatio:false,
    cornerRadius: 6,
    legend: {
      display: false,
    },
    tooltips: {
      custom(tooltip) {
        if (!tooltip) return;
        tooltip.displayColors = false;
      },
      intersect: false,
      fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      yPadding: 15,
      xPadding: 20,
      caretSize: 5,
      titleFontSize: 10,
      titleAlign: 'right',
      bodyFontSize: 25,
      bodyFontStyle: 'bold',
      titleMarginBottom: 20,
      callbacks: {
        label(tooltipItem) {
          return ` ${Number(tooltipItem.yLabel)}  atendimentos    `;
        },
      },
    },
    scales: {
      yAxes: [
        {
          gridLines: false,
          ticks: {
            beginAtZero: true,
            padding: 15,
            fontStyle: 'bold',
          },
        },
      ],
      xAxes: [
        {
          gridLines: false,
          ticks: {
            padding: 10,
            fontStyle: '800',
            fontColor: 'black',
            fontSize: 10,
          },
        },
      ],
    },
}

export const scrollOptions = {
    responsive: false,
    cornerRadius: 6,
    legend: {
      display: false,
    },
    tooltips: {
      custom(tooltip) {
        if (!tooltip) return;
        tooltip.displayColors = false;
      },
      intersect: false,
      fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      yPadding: 15,
      xPadding: 20,
      caretSize: 5,
      titleFontSize: 10,
      titleAlign: 'right',
      bodyFontSize: 25,
      bodyFontStyle: 'bold',
      titleMarginBottom: 20,
      callbacks: {
        label(tooltipItem) {
          return ` ${Number(tooltipItem.yLabel)}  atendimentos    `;
        },
      },
    },
    scales: {
      yAxes: [
        {
          gridLines: false,
          ticks: {
            beginAtZero: true,
            padding: 15,
            fontStyle: 'bold',
          },
        },
      ],
      xAxes: [
        {
          gridLines: false,
          ticks: {
            padding: 10,
            fontStyle: '800',
            fontColor: 'black',
            fontSize: 10,
          },
        },
      ],
    },
}

export const secondaryOptions = {
  responsive: true,
  maintainAspectRatio:false,
  cornerRadius: 6,
  legend: {
    display: false,
  },
  tooltips: {
    custom(tooltip) {
      if (!tooltip) return;
      tooltip.displayColors = false;
    },
    intersect: false,
    fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
    yPadding: 10,
    xPadding: 20,
    caretSize: 5,
    titleFontSize: 12,
    titleFontStyle: 'normal',
    bodyFontSize: 18,
    bodyFontStyle: 'bold',
    titleMarginBottom: 15,
    callbacks: {
      label(tooltipItem){
        return ` ${Number(tooltipItem.yLabel)}`;
      },
      afterTitle(){
        return "_____________";
      },
      title() {
        return "atendimentos";
      },
    },
  },
  scales: {
    yAxes: [
      {
        gridLines: false,
        ticks: {
          beginAtZero: true,
          padding: 15,
          fontStyle: 'bold',
        },
      },
    ],
    xAxes: [
      {
        gridLines: false,
        ticks: {
          padding: 10,
          fontStyle: '800',
          fontColor: 'black',
          fontSize: 10,
        },
      },
    ],
  },
}

export const unifiedAttendancesOptions = {
  responsive: true,
  maintainAspectRatio:false,
    cornerRadius: 6,
    legend: {
      display: false,
    },
    tooltips: {
      custom(tooltip) {
        if (!tooltip) return;
        tooltip.displayColors = false;
      },
      intersect: false,
      fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      yPadding: 15,
      xPadding: 20,
      caretSize: 5,
      titleFontSize: 10,
      titleAlign: 'right',
      bodyFontSize: 15,
      bodyFontStyle: 'bold',
      titleMarginBottom: 20,
      callbacks: {
        label(tooltipItem) {
          return ` ${Number(tooltipItem.yLabel)}  atendimentos    `;
        },
      },
    },
    scales: {
      yAxes: [
        {
          gridLines: false,
          ticks: {
            beginAtZero: true,
            padding: 15,
            fontStyle: 'bold',
          },
        },
      ],
      xAxes: [
        {
          gridLines: false,
          ticks: {
            padding: 10,
            fontStyle: '800',
            fontColor: 'black',
            fontSize: 10,
          },
        },
      ],
    },
}

export const unifiedAttendancesScrollOptions = {
    responsive: false,
    cornerRadius: 6,
    legend: {
      display: false,
    },
    tooltips: {
      custom(tooltip) {
        if (!tooltip) return;
        tooltip.displayColors = false;
      },
      intersect: false,
      fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      yPadding: 15,
      xPadding: 20,
      caretSize: 5,
      titleFontSize: 10,
      titleAlign: 'right',
      bodyFontSize: 15,
      bodyFontStyle: 'bold',
      titleMarginBottom: 20,
      callbacks: {
        label(tooltipItem) {
          return ` ${Number(tooltipItem.yLabel)}  atendimentos    `;
        },
      },
    },
    scales: {
      yAxes: [
        {
          gridLines: false,
          ticks: {
            beginAtZero: true,
            padding: 15,
            fontStyle: 'bold',
          },
        },
      ],
      xAxes: [
        {
          gridLines: false,
          ticks: {
            padding: 10,
            fontStyle: '800',
            fontColor: 'black',
            fontSize: 10,
          },
        },
      ],
    },
}

export const unifiedAttendancesSecondaryOptions = {
  responsive: true,
  maintainAspectRatio:false,
  cornerRadius: 6,
  legend: {
    display: false,
  },
  tooltips: {
    custom(tooltip) {
      if (!tooltip) return;
      tooltip.displayColors = false;
    },
    intersect: false,
    fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
    yPadding: 10,
    xPadding: 20,
    caretSize: 5,
    titleFontSize: 12,
    titleFontStyle: 'normal',
    bodyFontSize: 18,
    bodyFontStyle: 'bold',
    titleMarginBottom: 15,
    callbacks: {
      label(tooltipItem){
        return ` ${Number(tooltipItem.yLabel)}`;
      },
      afterTitle(){
        return "_____________";
      },
      title() {
        return "Atendimentos";
      },
    },
  },
  scales: {
    yAxes: [
      {
        gridLines: false,
        ticks: {
          beginAtZero: true,
          padding: 15,
          fontStyle: 'bold',
        },
      },
    ],
    xAxes: [
      {
        gridLines: false,
        ticks: {
          padding: 10,
          fontStyle: '800',
          fontColor: 'black',
          fontSize: 10,
        },
      },
    ],
  },
}