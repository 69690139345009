export const filters = [
  {
    sectionTitle: "Cargo",
    filterTypes: [
      {
        title: "Cargo",
        callToAction: "Selecione o ou os cargos",
        boxTitle: "Cargos",
        hasSearchInput: false,
        name: "roles",
        options: [
          {
            value: "admin",
            label: "Administrador",
          },
          {
            value: "supervisor",
            label: "Supervisor",
          },
          {
            value: "coordinator",
            label: "Coordenador",
          },
          {
            value: "attendant",
            label: "Atendente",
          },
          {
            value: "technique",
            label: 'Equipe Técnica',
          },
        ],
      },
    ],
  },
  {
    sectionTitle: "Operador",
    filterTypes: [
      {
        title: "Operador",
        callToAction: "Selecione o ou os tipos de operador",
        boxTitle: "Tipos de Operador",
        hasSearchInput: true,
        inputPlaceholder: "Pesquise aqui por um tipo...",
        name: "typesOperatorsIds",
        options: '/type-operator',
      },
    ],
  },
  {
    sectionTitle: "Território",
    filterTypes: [
      {
        title: "Regional",
        callToAction: "Selecione a ou as regiões",
        boxTitle: "Regiões",
        hasSearchInput: true,
        inputPlaceholder: "Pesquise aqui por uma região...",
        name: "regionsIds",
        options: '/region',
      },
      {
        title: "Área / empreendimento",
        callToAction: "Selecione a área / empreendimento",
        boxTitle: "Área / empreendimento(s)",
        hasSearchInput: true,
        hasAutocomplete: true,
        hasInfinityScroll: true,
        inputPlaceholder: "Pesquise aqui por uma área / empreendimento...",
        name: "areasIds",
        options: '/area',
      },
    ],
  },
];

export const searchParameters = [
  'search',
];
