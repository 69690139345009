import { Can } from 'components';

import {
  NavigationItem,
  NavigationText,
  StyledLink,
} from './MobileNavbarDrawer.style';

function DrawerNavItem({ key, path, action, label, icon }) {
  const NavigationImage = icon;

  return (
    <Can perform={action}>
      <NavigationItem key={key}>
        <StyledLink to={path}>
          <NavigationImage />
          <NavigationText>{label}</NavigationText>
        </StyledLink>
      </NavigationItem>
    </Can>
  );
}

export default DrawerNavItem;
