import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';

import { withGlobalProvider } from 'contexts/GlobalProvider';
import Main from 'routes/Main';

import 'react-chat-elements/dist/main.css';
import './dateOverride';

function App() {
  return (
    <BrowserRouter>
      <LastLocationProvider>
        <Suspense fallback={<>Loading...</>}>
          <Main />
        </Suspense>
      </LastLocationProvider>
    </BrowserRouter>
  );
}

export default withGlobalProvider(App);
