import styled, { css } from 'styled-components';

import authSvg from 'assets/images/auth.svg';
import { respondTo } from 'styles/mixins';

const SvgImage = styled.img`
  position: fixed;
  top: 0;
  left: 1.875rem;
  height: 100vh;

  ${respondTo('medium')(css`
    display: none;
  `)}
`;

const Houses = () => <SvgImage src={authSvg} alt="" />;

export default Houses;
