import { useState } from 'react';
import { Select } from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import commentSvg from 'assets/icons/comment.svg';
import { NotificationModal } from 'components';
import { FormInput, FormSelect } from 'components/inputs';
import { cpf, phoneNumber } from 'helpers/maskRegex';
import useMask from 'hooks/use-mask';

import { Button, Modal, Title } from './RegisterResidentModal.style';

const { Option } = Select;

function RegisterResidentModal({ visible, setVisible }) {
  const [notification, setNotification] = useState(false);

  const RegisterSchema = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório'),
    cpf: Yup.string()
      .matches(/(\d{3}).(\d{3}).(\d{3})-(\d{2})/g, 'CPF inválido')
      .required('CPF é obrigatório'),
    rg: Yup.string().required('RG é obrigatório'),
    phoneNumber: Yup.string()
      .matches(/\((\d{2})\) \d?(\d{4})-(\d{4})/g, 'Número de telefone inválido')
      .required('Número de telefone é obrigatório'),
    email: Yup.string()
      .email('E-mail inválido')
      .required('E-mail é obrigatório'),
    status: Yup.string().required('Status é obrigatório'),
    synthesis: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      cpf: '',
      rg: '',
      phoneNumber: '',
      email: '',
      status: '',
      synthesis: '',
    },
    validationSchema: RegisterSchema,
    validateOnChange: false,
    onSubmit: () => {
      setNotification(true);
    },
  });

  const numberMask = useMask({
    mask: phoneNumber,
    defaultOnChange: formik.handleChange,
    defaultValue: formik.values.phoneNumber,
  });

  const cpfMask = useMask({
    mask: cpf,
    defaultOnChange: formik.handleChange,
    defaultValue: formik.values.cpf,
  });

  return (
    <>
      <Modal
        width="fit-content"
        visible={visible}
        destroyOnClose
        onCancel={() => setVisible(false)}
        footer={false}
      >
        <Title>Cadastrar Morador</Title>
        <form onSubmit={formik.handleSubmit}>
          <FormInput
            id="name"
            name="name"
            label="Nome Completo"
            type="text"
            placeholder="Digite o nome do morador"
            onChange={formik.handleChange}
            value={formik.values.name}
            error={formik.errors.name}
            schema={RegisterSchema}
          />
          <FormInput
            id="cpf"
            name="cpf"
            label="CPF"
            type="text"
            placeholder="Digite o CPF do morador"
            error={formik.errors.cpf}
            {...cpfMask}
            schema={RegisterSchema}
          />
          <FormInput
            id="rg"
            name="rg"
            label="RG"
            type="text"
            placeholder="Digite o RG do morador"
            onChange={formik.handleChange}
            error={formik.errors.rg}
            value={formik.values.rg}
            schema={RegisterSchema}
          />
          <FormInput
            id="phoneNumber"
            name="phoneNumber"
            label="Telefone"
            type="text"
            placeholder="(ddd) 9_ _ _ _ - _ _ _ _"
            error={formik.errors.phoneNumber}
            {...numberMask}
            schema={RegisterSchema}
          />

          <FormInput
            id="email"
            name="email"
            label="E-mail"
            type="text"
            placeholder="Digite o e-mail do munícipe"
            onChange={formik.handleChange}
            value={formik.values.email}
            error={formik.errors.email}
            schema={RegisterSchema}
          />
          <FormSelect
            label="Status do Morador"
            placeholder="Selecione o status do morador"
            onChange={(value) => formik.setFieldValue('status', value)}
            {...(formik.values.status ? { value: formik.values.status } : {})}
            error={formik.errors.status}
            schema={RegisterSchema}
          >
            <Option value="1">Status 1</Option>
            <Option value="2">Status 2</Option>
            <Option value="3">Status 3</Option>
          </FormSelect>
          <FormInput
            id="synthesis"
            name="synthesis"
            type="text"
            label="Observação"
            placeholder="Síntese do caso"
            icon={commentSvg}
            $showIcon
            onChange={formik.handleChange}
            value={formik.values.synthesis}
            error={formik.errors.synthesis}
            schema={RegisterSchema}
          />
          <Button htmlType="submit">Cadastrar</Button>
        </form>
      </Modal>
      <NotificationModal
        titleText="Morador cadastrado com sucesso!"
        text=""
        buttonText="Ok!"
        setVisible={setNotification}
        visible={notification}
      />
    </>
  );
}

export default RegisterResidentModal;
