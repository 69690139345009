import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Exit, Expand } from 'assets/icons/navbar';
import house from 'assets/images/house.png';
import { useAuth, useConfirmation, useUserInfo } from 'contexts';
import useMobile from 'hooks/use-mobile';

import {
  BottomAsset,
  BottomAssetContainer,
  ButtonText,
  CollapseButton,
  CollapseMenuButtonContainer,
  MicIcon,
  NavigationContainer,
  NavigationItem,
  NavigationText,
  StyledNavbar,
  Text,
  TitleContainer,
  VoiceButton,
} from './Navbar.style';
import { items } from './NavbarItems';
import NavItem from './NavItem';

function Navbar() {
  const history = useHistory();
  const [browserWidth, setBrowserWidth] = useState(null);
  const [active, setActive] = useState(history.location.pathname);
  const [chat, setChat] = useState(false);
  const [collapseNavbar, setCollapseNavbar] = useState(true);
  const isMobile = useMobile(900);
  const { confirm } = useConfirmation();
  const { logout } = useAuth();
  const { userData } = useUserInfo();

  useEffect(() => {
    const updateBrowserWidth = () => {
      setBrowserWidth(window.innerWidth * 0.14);
    };

    window.addEventListener('resize', updateBrowserWidth);

    updateBrowserWidth();

    return () => window.removeEventListener('resize', updateBrowserWidth);
  }, []);

  useEffect(() => {
    setActive(history.location.pathname);
    if (
      history.location.pathname.toString().substring(0, 10) === '/chamados/' &&
      history.location.pathname.length > 10 &&
      isMobile
    ) {
      setChat(true);
    } else {
      setChat(false);
    }
  }, [history.location.pathname, isMobile]);

  const voiceButton = (
    <VoiceButton key="mic">
      <MicIcon />
      <ButtonText>Pesquise por voz!</ButtonText>
    </VoiceButton>
  );

  const handleExit = () => {
    confirm({
      question: 'Tem certeza que deseja sair?',
      okText: 'Sair',
      onOk: logout,
    });
  };

  return (
    <>
      {!chat && (
        <StyledNavbar
          className="navbar-container"
          collapseNavbar={collapseNavbar}
        >
          <CollapseMenuButtonContainer>
            <CollapseButton
              type="button"
              onClick={() => setCollapseNavbar(!collapseNavbar)}
              className={!collapseNavbar && 'hideTextPartOnCollapse'}
            >
              <Expand />
              <span>MENU</span>
            </CollapseButton>
          </CollapseMenuButtonContainer>
          <TitleContainer className={!collapseNavbar && 'disappearOnCollapse'}>
            <Text>Portal do Morador</Text>
          </TitleContainer>
          <NavigationContainer
            className={!collapseNavbar && 'hideTextOnCollapse'}
          >
            {userData &&
              items(isMobile, userData, voiceButton).map((item) => (
                <NavItem
                  key={item.path}
                  path={item.path}
                  action={item.action}
                  label={item.label}
                  icon={item.icon}
                  active={active}
                  setActive={setActive}
                />
              ))}

            {!isMobile && (
              <NavigationItem onClick={handleExit}>
                <Exit setActive={false} />
                <NavigationText>Sair</NavigationText>
              </NavigationItem>
            )}
          </NavigationContainer>
          <BottomAssetContainer
            className={!collapseNavbar && 'parcialHideOnCollapse'}
          >
            <BottomAsset
              width={browserWidth}
              height={browserWidth * 0.6504}
              src={house}
              alt="Uma moradia iluminada e colorida"
            />
          </BottomAssetContainer>
        </StyledNavbar>
      )}
    </>
  );
}

export default Navbar;
