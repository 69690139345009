import queryString from 'query-string';

import { getParamModifier, shouldNotificate } from 'hooks/use-request/helpers';

const wrapMethod = (instance, method, notificationService) => {
  const methodHandler = instance[method];

  const wrappedMethod = async ({
    url,
    body,
    config,
    onSuccess = () => {},
    onError = () => {},
    notificationLevel = 'all',
  }) => {
    try {
      const paramModifier = getParamModifier(method);
      const urlParams = config?.params || {};

      const queryStringOptions = {
        arrayFormat: 'separator',
        arrayFormatSeparator: ',',
      };

      const prevParameters = url.includes('?')
        ? queryString.parse(`?${url.split('?')[1]}`, queryStringOptions)
        : {};

      const queryParameters = queryString.stringify(
        { ...urlParams, ...prevParameters },
        queryStringOptions
      );

      const params = paramModifier(
        `${url.split('?')[0]}${queryParameters && '?'}${queryParameters}`,
        body,
        { ...config, params: null }
      );

      const res = await methodHandler(...params);

      const successConfig = onSuccess(
        method === 'get' ? res.data.data : res.data,
        res
      );

      if (
        shouldNotificate(notificationLevel, 'all') &&
        notificationService &&
        res.status !== 207
      ) {
        notificationService.success(method, successConfig?.customMessage);
      }

      return method === 'get' ? res.data : res;
    } catch (err) {
      const errorConfig = onError(err);
      if (shouldNotificate(notificationLevel, 'error') && notificationService) {
        notificationService.error(method, errorConfig?.customMessage);
      }

      return err;
    }
  };

  return wrappedMethod;
};

export default wrapMethod;
