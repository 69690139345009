import * as Yup from 'yup';

export const deactivateBodyContent = (user, status) => {
    if(status)
        return `${user?.name} será desativado do sistema, todos os novos dados cadastrados nesta plataforma não
         podem ser relacionados a ele, mas seu histórico será preservado. Você pode reativar se desejar.`
    return `${user?.name} será reativado no sistema. Você pode desativá-lo novamente se desejar.`
};

export const deleteBodyContent = (user) => {
    return `${user?.name} será apagado permanentemente do sistema, todos os novos dados cadastrados nesta plataforma não podem ser relacionados a ele.`
}

export const phoneRegex = (val) => {
    const telephoneRegex = /^((\+?55\ ?[1-9]{2}\ ?)|(\+?55\ ?\([1-9]{2}\)\ ?)|(0[1-9]{2}\ ?)|(\([1-9]{2}\)\ ?)|([1-9]{2}\ ?))((\d{4}\-?\d{4})|(\d[1-9]{1}\d{3}\-?\d{4}))$/;
    return telephoneRegex.test(val);
}

export const cpfIsValid = (cpf) => {
    if (!cpf) return true;
    if (typeof cpf !== 'string') return false;
    if (cpf === '111.111.111-11' || cpf === '999.999.999-99') return true;

    cpf = cpf.replace(/[\s.-]*/igm, '');

    if (cpf.length !== 11 || !Array.from(cpf).filter(e => e !== cpf[0]).length) {
      return false;
    };

    let sum = 0;
    let rest;

    for (let i = 1; i <= 9; i++) { 
        sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i) ;
    };

    rest = (sum * 10) % 11;

    if ((rest === 10) || (rest === 11)) rest = 0;
    if (rest !== parseInt(cpf.substring(9, 10))) return false;

    sum = 0;

    for (let i = 1; i <= 10; i++) { 
        sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i) ;
    };

    rest = (sum * 10) % 11;

    if ((rest === 10) || (rest === 11)) rest = 0;
    if (rest !== parseInt(cpf.substring(10, 11))) return false;

    return true;
}

export const setFieldRules = (rules, initialValue) => {
    let response = initialValue;
    if(rules){
        if (rules.indexOf('required') !== -1) {
            response = response.required(`Esse campo é obrigatório.`);
        }
        if (rules.indexOf('multiselect') !== -1) {
            response = Yup.array().min(1, 'Esse campo é obrigatório.');
        }
        if (rules.indexOf('email') !== -1) {
            response = response.email(`O valor digitado não é um e-mail válido.`);
        }
        if (rules.indexOf('cpf') !== -1) {
            response = response.matches(
            /(\d{3}).?(\d{3}).?(\d{3})-?(\d{2})/g,
            'CPF inválido'
            );
        }
        if (rules.indexOf('rg') !== -1) {
          response = response.matches(
            /^[0-9.-]*$/g,
            'Esse campo possui caracteres inválidos'
          )
        }
        if (rules.indexOf('phone') !== -1) {
            response = response.matches(
            /\((\d{2})\) \d?(\d{4})-(\d{4})/g,
            'Número de telefone inválido'
            );
        }
        if (rules.indexOf('number') !== -1) {
            response = response.matches(/^\d+$/g, 'O valor desse campo deve ser numérico.');
        }
    }
    return response;
};

export const cpfMask = (val) =>
    val
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');

export const phoneMask = (val) =>
    val
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
      .replace(/(-\d{4})\d+?$/, '$1');
      