import { useUserInfo } from 'contexts/UserProvider/UserProvider';
import {
  admin,
  attendant,
  coordinator,
  supervisor,
  technician,
} from 'helpers/PermissionRules';

const ruleMap = {
  admin,
  attendant,
  coordinator,
  supervisor,
  technique: technician,
};

const check = (role, actions) => {
  const permissions = [ruleMap[role]];
  if (!permissions) {
    // role is not present in the rules
    return false;
  }
  const staticPermissions = permissions[0].static;

  let allow = false;
  actions.map((action) => {
    if (staticPermissions && staticPermissions.includes(action)) {
      allow = true;
    }
    return null;
  });

  return allow;
};

function Can(props) {
  const { perform, data, no, children } = props;
  const { userData } = useUserInfo();
  // Check if user exists on context
  if (!userData) {
    return () => null;
  }
  return check(userData.role, perform, data) ? <>{children}</> : no();
}

Can.defaultProps = {
  no: () => null,
};

export default Can;
