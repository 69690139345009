import { Button } from 'antd';
import styled, { css } from 'styled-components';

import send from 'assets/icons/send-white.svg';
import { Spin } from 'components';
import colors from 'styles/colors';

export const Container = styled.div`
  padding: 1.25rem;
  padding-bottom: 0;
  width: 28.12rem;
  max-height: 50%;
`;

export const Title = styled.h1`
  margin-left: 1.25rem;
  font-size: 1.25rem;
  font-weight: bold;
  letter-spacing: 0.8px;
  color: ${colors.smoothBlack};
`;

export const Table = styled.div`
  background-color: white;
  padding: 0.625rem;
  width: 100%;
  height: fit-content;
  margin-bottom: 0.312rem;
  max-height: 100%;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 0px;
  }
`;

export const Header = styled.div`
  background-color: ${colors.babyBlue};
  font-size: 0.75rem;
  padding: 0.312rem 0.937rem;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 5px;
  color: ${colors.smoothBlack};
  width: 23.75rem;
  margin-top: 0.312rem;
`;

export const FieldContainer = styled.div`
  justify-content: space-between;
  display: flex;
  padding: 0.625rem 1.875rem;
  background-color: white;
`;

export const TableContent = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-right: ${({ last }) => (last ? 0 : '1rem')};
  padding: 0.312rem 0;
  border-radius: 20px;

  ${({ index }) => {
    if (index % 2 === 0) {
      return css`
        background-color: ${colors.white};
      `;
    }
    return css`
      background-color: ${colors.glassGray(0.8)};
    `;
  }}
`;

export const ItemText = styled.div`
  margin-left: 1.25rem;
  margin-top: 0.312rem;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${colors.grayBlue};
  font-weight: 400;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TableButton = styled(Button)`
  font-size: 0.8rem !important;
  padding: 0 1.162rem !important;
  margin-left: 0.5rem;
  font-size: 0.875rem;
  font-weight: 400;
  color: ${({ color }) => (color === 'green' ? colors.green : colors.red)};
  border: none;
  border-radius: 20px;
  background-color: ${({ color }) =>
    color === 'green' ? colors.cyanGray : colors.transparentRed(0.1)};
  :focus {
    outline: none !important;
    background-color: ${({ color }) =>
      color === 'green' ? colors.green : colors.red};
    color: ${colors.white};
  }
  :hover {
    background-color: ${({ color }) =>
      color === 'green' ? colors.green : colors.red};
    color: ${colors.white};
  }
`;

export const SubmitButton = styled.button`
  border: none;
  background-image: url(${send});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 0.937rem;
  background-color: ${colors.green};
  border-radius: 50%;
  min-width: 2.5rem;
  height: 2.5rem;
  margin-left: 0.625rem;
  :focus {
    outline: none;
    background-color: ${colors.green};
    color: white;
  }
  :hover {
    background-color: ${colors.green};
  }
`;

export const HeaderContainer = styled.div`
  background-color: white !important;
  border-radius: 10px 10px 0px 0px;
  padding: 0.312rem 0.937rem;
  width: fit-content;
  height: fit-content;
`;

export const ErrorMessage = styled.div``;

export const Loading = styled(Spin)`
  display: flex;
  justify-content: center;
`;
