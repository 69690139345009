import { useEffect, useRef, useState } from 'react';

import useDebounce from 'hooks/use-debounce';
import useRequest from 'hooks/use-request';

import { ItemButton } from './Filter.style';
import FilterBox from './FilterBox';

function FilterItemButton({
  resolveParameterChanges,
  activeFilters,
  itemProperties,
  initialParameterValues,
}) {
  const [boxVisible, setBoxVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [boxOptions, setBoxOptions] = useState([]);
  const [boxCheckboxGroupValue, setBoxCheckboxGroupValue] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState('');
  const request = useRequest();
  const [filterInformations, setFilterInformations] = useState({
    page: 0,
    search: '',
    hasMore: true,
  });
  const searchRef = useRef('');
  const debouncedSearch = useDebounce(filterInformations.search, 500);

  const getOptions = async (optionsPath) => {
    if (
      filterInformations.hasMore ||
      searchRef.current !== filterInformations.search
    ) {
      setIsLoading(true);
      const config = {
        params: {
          ...(itemProperties?.hasInfinityScroll && {
            skip: filterInformations.page * 10,
            take: 10,
          }),
          name: filterInformations.search,
        },
      };

      await request.get({
        url: optionsPath,
        config,
        onSuccess: (data, res) => {
          setFilterInformations((prev) => ({
            ...prev,
            hasMore: res.data.totalItems > prev.page * 10 + 10,
          }));

          const itemsArr = data.map(({ id, name }) => ({
            value: id,
            label: name,
          }));

          setBoxOptions((prevBoxOptions) => {
            if (filterInformations.search === searchRef.current) {
              if (filterInformations.page === 0) {
                return itemsArr;
              }
              return [...prevBoxOptions, ...itemsArr];
            }
            return itemsArr;
          });
          setIsLoading(false);
        },
        onError: () => setIsLoading(false),
      });

      searchRef.current = filterInformations.search;
    }
  };

  useEffect(() => {
    if (Array.isArray(itemProperties.options))
      setBoxOptions(itemProperties.options);
  }, []);

  useEffect(() => {
    if (
      itemProperties.isDateFilter &&
      initialParameterValues[itemProperties.name]
    ) {
      setBoxCheckboxGroupValue([initialParameterValues[itemProperties.name]]);
    }
  }, []);

  useEffect(() => {
    if (boxOptions.length && initialParameterValues[itemProperties.name]) {
      const initialCheckedValues = boxOptions.filter((option) =>
        initialParameterValues[itemProperties.name].includes(option.value)
      );
      resolveParameterChanges(
        itemProperties.name,
        initialCheckedValues,
        itemProperties.title
      );
    }
  }, [boxOptions]);

  useEffect(() => {
    if (selectedOptions !== '') {
      resolveParameterChanges(
        itemProperties.name,
        selectedOptions,
        itemProperties.title
      );
    }
  }, [selectedOptions]);

  useEffect(() => {
    if (Array.isArray(activeFilters?.options)) {
      const arr = activeFilters.options.map(({ value }) => value);
      setBoxCheckboxGroupValue(arr);
    }
  }, [activeFilters]);

  const displayBox = () => {
    setBoxVisible(true);
  };

  const closeBox = () => {
    setBoxVisible(false);
  };

  useEffect(() => {
    getOptions(itemProperties.options);
  }, [filterInformations.page, debouncedSearch]);

  return (
    <>
      <FilterBox
        isLoading={isLoading}
        hasAutocomplete={!!itemProperties.hasAutocomplete}
        setFilterInformations={setFilterInformations}
        options={boxOptions}
        setSelectedOptions={setSelectedOptions}
        closeBox={closeBox}
        boxVisible={boxVisible}
        hasSearchInput={itemProperties.hasSearchInput}
        inputPlaceholder={itemProperties.inputPlaceholder}
        isDateFilter={itemProperties.isDateFilter}
        boxTitle={itemProperties.boxTitle}
        checkboxGroupValue={boxCheckboxGroupValue}
      />
      <ItemButton disabled={boxVisible} onClick={displayBox}>
        {itemProperties.callToAction}
      </ItemButton>
    </>
  );
}

export default FilterItemButton;
