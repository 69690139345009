import { useFormik } from 'formik';
import * as Yup from 'yup';

import lockSvg from 'assets/icons/lock.svg';
import personSvg from 'assets/icons/person.svg';
import { TextInput } from 'components/inputs';

import {
  Actions,
  Form,
  InputWrapper,
  Next,
  Previous,
  Title,
  Wrapper,
} from './ChangePasswordForm.style';

function ChangePasswordForm({ onSubmit, error, renderEmail, setStep }) {
  const ChangePasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email inválido')
      .required('Email não pode ser vazio'),
    code: Yup.string().required('Código não pode ser vazio'),
    newPassword: Yup.string().required('Nova senha não pode ser vazia'),
    confirmNewPassword: Yup.string().required('Confirme a nova senha'),
  });

  const formik = useFormik({
    initialValues: {
      email: renderEmail || '',
      code: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    validationSchema: ChangePasswordSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      onSubmit(values, formik);
    },
  });

  return (
    <Wrapper>
      <Form onSubmit={formik.handleSubmit}>
        <Title>Redefinição de senha</Title>
        {!renderEmail && (
          <InputWrapper>
            <TextInput
              name="email"
              label="Email"
              placeholder="Digite o email recebido por email"
              icon={personSvg}
              onChange={formik.handleChange}
              error={formik.errors.email}
              value={formik.values.email}
              schema={ChangePasswordSchema}
            />
          </InputWrapper>
        )}
        <InputWrapper>
          <TextInput
            name="code"
            label="Código"
            placeholder="Digite o código recebido por email"
            icon={personSvg}
            onChange={formik.handleChange}
            error={formik.errors.code}
            value={formik.values.code}
            schema={ChangePasswordSchema}
          />
        </InputWrapper>
        <InputWrapper>
          <TextInput
            name="newPassword"
            label="Nova senha"
            type="password"
            placeholder="Digite sua nova senha"
            icon={lockSvg}
            onChange={formik.handleChange}
            error={formik.errors.newPassword || error}
            value={formik.values.newPassword}
            schema={ChangePasswordSchema}
          />
        </InputWrapper>
        <InputWrapper>
          <TextInput
            name="confirmNewPassword"
            label="Confirme sua nova senha"
            placeholder="Digite sua nova senha"
            type="password"
            icon={lockSvg}
            onChange={formik.handleChange}
            error={formik.errors.confirmNewPassword || error}
            value={formik.values.confirmNewPassword}
            schema={ChangePasswordSchema}
          />
        </InputWrapper>
        <Actions>
          <Previous onClick={() => setStep(0)}>Voltar</Previous>
          <Next loading={formik.isSubmitting} htmlType="submit">
            Avançar
          </Next>
        </Actions>
      </Form>
    </Wrapper>
  );
}

export default ChangePasswordForm;
