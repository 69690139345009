import React from 'react';
import { Picker } from 'emoji-mart';

import { Wrapper } from './EmojiDrawer.style';

import 'emoji-mart/css/emoji-mart.css';

function EmojiDrawer({ visible, onSelect }) {
  return (
    <Wrapper visible={visible}>
      <Picker
        i18n={{
          search: 'Pesquisar',
          categories: {
            search: 'Resultados da pesquisa',
            recent: 'Mais usados',
            people: 'Smileys e pessoas',
            nature: 'Animais e Natureza',
            foods: 'Comidas e Bebidas',
            activity: 'Atividades',
            places: 'Viagens e lugares',
            objects: 'Objetos',
            symbols: 'Símbolos',
            flags: 'Bandeiras',
            custom: 'Custom',
          },
        }}
        color="#07965a"
        onSelect={onSelect}
      />
    </Wrapper>
  );
}

export default EmojiDrawer;
