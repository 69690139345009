import { useEffect, useState } from 'react';

import { BarChart } from 'components';

import { Container } from './UnifiedAttendancesMultipleBarChart.style';

function UnifiedAttendancesMultipleBarChart({ data }) {
  const [selectedGraph, setSelectedGraph] = useState('weekly-volume');
  const [graphData, setGraphData] = useState(null);

  useEffect(() => {
    setGraphData(data?.graphUnifiedAttendances);
  }, [data]);

  return (
    <Container>
      {selectedGraph === 'mensal-volume' && (
        <BarChart
          title="Quantidade de atendimentos por mês"
          data={graphData?.amountUnifiedAttendancesPerMonth}
          labels={graphData?.monthLabels}
          filter
          setSelectedGraph={setSelectedGraph}
          selectedGraph={selectedGraph}
          height="20rem"
          type="unifiedAttendance"
        />
      )}
      {selectedGraph === 'weekly-volume' && (
        <BarChart
          title="Quantidade de atendimentos por dia da semana"
          data={graphData?.amountUnifiedAttendancesPerDay}
          labels={graphData?.dayLabels}
          filter
          setSelectedGraph={setSelectedGraph}
          selectedGraph={selectedGraph}
          thickness={70}
          height="20rem"
          type="unifiedAttendance"
        />
      )}
      {selectedGraph === 'hour-volume' && (
        <BarChart
          title="Horários com mais atendimentos por semana"
          data={graphData?.amountUnifiedAttendancesPerHour}
          labels={graphData?.hourLabels}
          width={1200}
          filter
          setSelectedGraph={setSelectedGraph}
          selectedGraph={selectedGraph}
          height="20rem"
          scroll
          type="unifiedAttendance"
        />
      )}
    </Container>
  );
}

export default UnifiedAttendancesMultipleBarChart;
