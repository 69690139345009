import { useState } from 'react';

import bell from 'assets/icons/dark-bell.svg';
import hamburguer from 'assets/icons/dark-hamburguer.svg';
import { MobileNavbarDrawer, NotificationDrawer } from 'components';

import { Icon, IconContainer } from './MobileFixedButtons.style';

function MobileFixedButtons() {
  const [open, setOpen] = useState(false);
  const [openNavbarDrawer, setOpenNavbarDrawer] = useState(false);
  return (
    <>
      <NotificationDrawer open={open} setOpen={setOpen} />
      <MobileNavbarDrawer
        open={openNavbarDrawer}
        setOpen={setOpenNavbarDrawer}
      />
      <IconContainer>
        <Icon onClick={() => setOpen(true)} src={bell} alt="notificações" />
        <Icon
          onClick={() => setOpenNavbarDrawer(true)}
          src={hamburguer}
          alt="botões da barra de navegação"
        />
      </IconContainer>
    </>
  );
}

export default MobileFixedButtons;
