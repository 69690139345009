import styled from 'styled-components';

import { Button as AntButton } from 'components/inputs';

export const StyledButton = styled(AntButton)`
  height: 2.9375rem;
  width: fit-content;
  font-size: 1rem;
  margin-right: 1.5rem;
`;
