import { Button, Checkbox, Radio, Switch } from 'antd';
import styled, { css } from 'styled-components';

import { Button as AntButton } from 'components/inputs';
import FloatingLabelSelect from 'components/inputs/FormSelect/FloatingLabelSelect';
import TextField from 'components/inputs/TextField';
import colors from 'styles/colors';

export const StyledSwitch = styled(Switch)`
  margin-bottom: 1.75rem;
  background-color: ${(props) => props.active && `${colors.green} !important`};
`;

export const StyledButton = styled(AntButton)`
  height: 2.9375rem;
  width: fit-content;
  font-size: 1rem;
  margin-right: 1.5rem;
`;

export const Select = styled(FloatingLabelSelect)`
  margin-bottom: 1.75rem;
`;

export const Input = styled(TextField)`
  margin-bottom: 1rem;
  margin-top: ${(props) => props.isLast && '1.75rem'};

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const RadioButton = styled(Radio)`
  margin-right: 1rem;
  font-weight: bold;
  color: ${colors.textBlue};
  .ant-radio-inner {
    border-color: ${colors.green} !important;
  }

  .ant-radio-inner::after {
    background-color: ${colors.green};
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  font-size: 1rem;
  letter-spacing: 0.04rem;
  .ant-checkbox-inner {
    border-radius: 4px !important;
  }
`;

export const RadioContainer = styled(Radio.Group)`
  margin-bottom: 1.5rem;
  display: flex;
`;

export const FormButtonContainer = styled.div`
  width: 100%;
  margin: 1.875rem 0 0.375rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
`;

export const StyledFormButton = styled(Button)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  border: none;
  height: 2.5rem;
  width: 100%;
  padding: 0.625rem 2rem;
  background-color: ${colors.darkGreen};
  color: white;
  font-size: 1rem;

  .anticon-loading {
    position: unset;
  }

  &:hover,
  &:focus {
    background-color: ${colors.darkGreen};
    color: white;
    opacity: 0.9;
  }

  ${({ $cancel }) =>
    $cancel &&
    css`
      background-color: ${colors.gray};
      color: ${colors.smoothBlack};

      &:hover,
      &:focus {
        background-color: ${colors.gray};
        color: ${colors.smoothBlack};
        opacity: 0.9;
      }
    `}
`;

export const Error = styled.p`
  color: ${colors.textSecondary};
  opacity: 1;
  font-style: italic;
  font-size: 0.875rem;
  margin: 0;
`;

export const ErrorIcon = styled.img`
  width: 0.875rem;
  margin-right: 0.625rem;
`;
