import { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import moment from 'moment';

import { MobileHeader, ScheduleCard, TitledPage } from 'components';
import { useUserInfo } from 'contexts/UserProvider/UserProvider';
import useMobile from 'hooks/use-mobile';
import useRequest from 'hooks/use-request';

import {
  Grid,
  HeaderContainer,
  NoDataText,
  SubTitle,
  TabContainer,
  Title,
} from './Schedule.style';

const { TabPane } = Tabs;

function Schedule() {
  const [mobileRange, setMobileRange] = useState('today');
  const [scheduledAttendances, setScheduledAttendances] = useState([]);
  const request = useRequest();

  const { userData } = useUserInfo();
  const isMobile = useMobile();
  const getAttendances = async () => {
    await request.get({
      url: `/attendance`,
      config: {
        params: {
          userId: userData?.id,
        },
      },
      onSuccess: (data) => {
        setScheduledAttendances([...data]);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  useEffect(() => {
    getAttendances();
  }, []);

  const getTabSchedulesInRange = (range) => {
    const now = moment(new Date());

    let compareRange = 1;

    if (range === 'today') {
      compareRange = 1;
    } else if (range === 'week') {
      compareRange = 7;
    } else if (range === 'month') {
      compareRange = 30;
    }

    return scheduledAttendances.filter(({ startDate: date }) => {
      const dateDifference = moment(date).diff(now, 'days');
      if (range === 'upcoming') return dateDifference >= 0;
      return dateDifference < compareRange && dateDifference >= 0;
    });
  };

  const pageContent = (
    <>
      <HeaderContainer>
        <MobileHeader showButtons={false} />
        <TabContainer onChange={setMobileRange}>
          <TabPane tab="Hoje" key="today" />
          <TabPane tab="Semana" key="week" />
          <TabPane tab="Mês" key="month" />
        </TabContainer>
      </HeaderContainer>
      <Title>
        Plantões sociais agendados
        {isMobile && (
          <SubTitle>
            {`${getTabSchedulesInRange('today').length} `}
            para hoje
          </SubTitle>
        )}
      </Title>
      <Grid>
        {isMobile &&
          (getTabSchedulesInRange(mobileRange).length ? (
            getTabSchedulesInRange(mobileRange).map((attendance) => (
              <ScheduleCard
                {...attendance}
                getSchedules={getAttendances}
                key={attendance.id}
              />
            ))
          ) : (
            <NoDataText>
              Não há plantões sociais agendados nesse filtro.
            </NoDataText>
          ))}
        {!isMobile &&
          (getTabSchedulesInRange('upcoming').length ? (
            getTabSchedulesInRange('upcoming').map((attendance) => (
              <ScheduleCard
                {...attendance}
                getSchedules={getAttendances}
                key={attendance.id}
              />
            ))
          ) : (
            <NoDataText>Não há plantões sociais agendados.</NoDataText>
          ))}
      </Grid>
    </>
  );

  if (isMobile) return pageContent;

  return (
    <TitledPage
      title="Plantões Sociais"
      justifyButtons="flex-start"
      buttons={[]}
    >
      {pageContent}
    </TitledPage>
  );
}

export default Schedule;
