import { useEffect, useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { Row } from 'antd';

import { BackPathButton, EditDataBoard, ProfilePhotoEdit } from 'components';
import { useUserInfo } from 'contexts/UserProvider/UserProvider';
import compareArrays from 'helpers/compareArrays';
import getDiffBetweenObjects from 'helpers/getDiffBetweenObjects';
import useRequest from 'hooks/use-request';

import { usersInputs } from './inputs';
import { AntdCol, Container, PhotoContainer } from './ManagerUserEdit.style';

const ManagerUserEdit = ({ history }) => {
  const { userData, updateUserInfo } = useUserInfo();
  const { id } = useParams();
  const request = useRequest();
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (user?.id === userData?.id) {
      const usableUser = { ...user };
      const usableUserData = { ...userData };
      const usableUserAreasMap = usableUser?.areas.map((area) => area.id);
      const usableUserDataAreasMap = usableUserData?.areas.map(
        (area) => area.id
      );
      if (compareArrays(usableUserAreasMap, usableUserDataAreasMap)) {
        delete usableUser.areas;
        delete usableUserData.areas;
      }
      const difference = getDiffBetweenObjects(usableUser, usableUserData);
      if (Object.keys(difference).length > 0) updateUserInfo(user);
    }
  }, [user]);

  useEffect(() => {
    const fetchData = async () => {
      await request.get({
        url: `/user/${id}`,
        onSuccess: (data) => {
          setUser(data[0]);
        },
        onError: () => {
          history.push('/usuarios');
        },
      });
    };

    fetchData();
  }, []);

  return (
    <Container>
      <Row gutter={[50, 35]}>
        <BackPathButton path="/usuarios" />
      </Row>
      <Row gutter={[50, 35]}>
        <PhotoContainer>
          <ProfilePhotoEdit user={user} path={`/user/${user?.id}`} />
        </PhotoContainer>
      </Row>
      <Row justify="start" gutter={[50, 35]}>
        <AntdCol xs={{ span: 24 }} lg={{ span: 22 }}>
          <EditDataBoard
            user={user}
            setUser={setUser}
            path={`/user/${user?.id}`}
            boardTitle="Dados do usuário"
            inputs={usersInputs}
          />
        </AntdCol>
      </Row>
    </Container>
  );
};

export default withRouter(ManagerUserEdit);
