import styled from 'styled-components';

import { ReactComponent as StringSvg } from 'assets/noise/short_strings.svg';

import { colorCss, positionCss, sizeCss } from './default.style';

const ShortStrings = styled(StringSvg)`
  ${positionCss}
  ${sizeCss}
  ${colorCss}
`;

export default ShortStrings;
