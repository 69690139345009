import { Link } from 'react-router-dom';
import { Drawer } from 'antd';
import styled from 'styled-components';

import colors from 'styles/colors';

export const StyledDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    width: 50% !important;
    max-width: 320px;
    height: 60vh;
  }

  .ant-drawer-body {
    padding: 0;
    & > div {
      height: auto;
    }
  }

  .ant-drawer-close {
    color: ${colors.smoothBlack} !important;
  }
`;

export const ItemsContainer = styled.div`
  width: 100%;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  padding: 3rem 1.5rem 0 1.5rem;
`;

export const NavigationItem = styled.span`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;

  & > svg {
    width: 1.5rem;
  }
`;

export const ExitButtonContainer = styled.div`
  width: 100%;
  margin-top: auto;
  margin-bottom: 1rem;
`;

export const StyledLink = styled(Link)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 2px solid ${colors.background};
  padding-bottom: 0.2rem;
  gap: 1rem;

  & > svg {
    width: 1.5rem;
  }
`;

export const NavigationText = styled.p`
  font-weight: 400;
  font-size: 1rem;
  color: ${colors.black};
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 60%;
`;
