import { Image } from 'antd';
import styled, { css } from 'styled-components';

import colors from 'styles/colors';
import { respondTo } from 'styles/mixins';

export const CitizenImage = styled(Image)`
  margin-top: 20px;
  border-radius: 100%;
  width: 86px;
`;

export const Container = styled.div`
  display: flex;
  ${respondTo('medium')(css`
    width: '60vw';
  `)}
`;

export const CitizenData = styled.div`
  margin-left: 30px;
  margin-right: 1.5vw;
`;

export const Name = styled.div`
  font-size: 1.125rem;
  font-weight: 600;
  letter-spacing: 0.36px;
  margin-bottom: 15px;
`;

export const Info = styled.div`
  font-size: 1rem;
  letter-spacing: 0.32px;
  width: fit-content;
`;

export const Button = styled.button`
  outline: none;
  border: none;
  background-color: ${colors.primary};
  border-radius: 50px;
  padding: 5px 25px;
  font-weight: 900;
  margin-top: 15px;
`;
