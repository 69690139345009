import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Button } from 'components/inputs';
import { flex, respondTo } from 'styles/mixins';

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  max-height: 31.25rem;
  width: 100%;
  max-width: 30rem;

  ${respondTo('medium')(css`
    width: 100%;
    max-width: initial;
  `)}
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: 2rem;
  width: 100%;
  text-align: center;
  line-height: 2.375rem;

  ${respondTo('large')(css`
    font-size: 1.5rem;
  `)}
  ${respondTo('medium')(css`
    font-size: 1.125rem;
  `)}
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 80%;
`;

export const InputWrapper = styled.div`
  width: 100%;
  ${flex('center', 'center', 'column')};
`;

export const LogosContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  width: 80%;
  margin-top: 2rem;
  align-items: flex-start;
`;

export const Logo = styled.img`
  height: auto;

  :first-child {
    max-width: 8rem;
  }
  :last-child {
    max-width: 10rem;
    position: relative;
    top: -1rem;
  }
`;

export const LoginButton = styled(Button)`
  margin: 2rem 0;
`;

export const PasswordForget = styled(Link)`
  color: #707070;
  text-decoration: underline;
  font-size: 1.125rem;
  margin-top: 1.687rem;

  ${respondTo('large')(css`
    font-size: 1rem;
  `)}
  ${respondTo('medium')(css`
    font-size: 1rem;
    margin-top: 0.8125rem;
  `)}
`;

export const FirstAccess = styled.section`
  color: #707070;
  font-size: 1.125rem;
  display: flex;

  ${respondTo('large')(css`
    font-size: 1rem;
  `)}
  ${respondTo('medium')(css`
    font-size: 1rem;
  `)}
`;

export const FirstAccessLink = styled(Link)`
  color: #707070;
  text-decoration: underline;
  font-size: 1.125rem;
  margin-left: 0.4rem;

  ${respondTo('large')(css`
    font-size: 1rem;
  `)}
  ${respondTo('medium')(css`
    font-size: 1rem;
  `)}
`;
