import decreaseValueSvg from 'assets/icons/decrease-value.svg';
import increaseValueSvg from 'assets/icons/increase-value.svg';

import {
  Beacon,
  Icon,
  Item,
  Max,
  Percentage,
  StyledCard,
  Text,
  TextContainer,
  Title,
  Value,
} from './InfoCard.style';

function InfoCard({
  title,
  value,
  percentage,
  max,
  measurementUnit,
  hasBeacon,
}) {
  const replaceDotWithComma = (number) =>
    number !== null ? number.toString().replace('.', ',') : 'Sem dados';
  const removeZeros = (text) => text.replace(',00', '');

  const intervals = [5, 10];
  const beaconMap = {
    [value < intervals[0]]: 'green',
    [value >= intervals[0] && value <= intervals[1]]: 'yellow',
    [value > intervals[1]]: 'red',
  };

  return (
    <Item>
      <StyledCard>
        <Title>{title}</Title>
        <TextContainer>
          {hasBeacon && <Beacon color={beaconMap.true} />}
          <Value>
            {`${replaceDotWithComma(value)}${
              measurementUnit && value !== null ? ` ${measurementUnit}` : ''
            }`}
          </Value>
          {max && <Max>{`/${max}`}</Max>}
          {percentage !== undefined && (
            <>
              {percentage !== 0 ? (
                <>
                  <Percentage positive={percentage >= 0}>
                    {removeZeros(
                      replaceDotWithComma(
                        `${Number((percentage * 100).toFixed(2)).toString()}%`
                      )
                    )}
                  </Percentage>
                  <Icon
                    src={percentage > 0 ? increaseValueSvg : decreaseValueSvg}
                    alt={
                      percentage > 0
                        ? 'Ícone seta para cima'
                        : 'Ícone seta para baixo'
                    }
                  />
                  <Text>Referente ao mês passado</Text>
                </>
              ) : (
                <Text>Sem crescimento referente ao mês passado</Text>
              )}
            </>
          )}
        </TextContainer>
      </StyledCard>
    </Item>
  );
}

export default InfoCard;
