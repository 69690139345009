import { Table } from 'antd';
import styled from 'styled-components';

import { BaseCard } from 'components';
import colors from 'styles/colors';

export const StyledCard = styled(BaseCard)`
  display: flex;
  flex-direction: column;
  padding: 0rem;
  box-shadow: 10px 10px 15px #00000014;
  max-height: 31.25rem;
  overflow: hidden;
`;

export const Title = styled.p`
  font-size: 1.125rem;
  letter-spacing: 0.0225rem;
  color: black;
  margin: 2.0625rem 1rem 1.1875rem;
`;

export const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    background-color: #f1f3fa;
    font-size: 0.875rem;
    font-weight: 600;
    color: black;
    letter-spacing: 0.0175rem;
    border-radius: 0 !important;
  }
  .ant-table-body {
    min-height: unset !important;

    ::-webkit-scrollbar {
      width: 6px;
    }
    ::-webkit-scrollbar-track {
      background-color: ${colors.gray};
      border-left: 2px solid ${colors.white};
      border-right: 2px solid ${colors.white};
    }
    ::-webkit-scrollbar-thumb {
      background: ${colors.persianGreen};
      border-radius: 4px;
    }
  }
`;
