import { useEffect, useState } from 'react';

import { BaseTable } from 'components';

function Areas({ data: requestData }) {
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    const data = requestData?.areaTable;
    if (Array.isArray(data)) {
      const tableData = data?.map((item, index) => ({
        ...item,
        key: index.toString(),
      }));
      setDataSource(tableData);
    }
  }, [requestData]);

  const columns = [
    {
      title: 'Área/empreendimento',
      dataIndex: 'name',
      key: 'area',
    },
    {
      title: 'Atendimentos em aberto',
      dataIndex: 'openUnifiedAttendancesCount',
      key: 'open-unified-attendances-count',
    },
    {
      title: 'Total',
      dataIndex: 'amount',
      key: 'open-unified-attendances-total',
    },
  ];

  return (
    <BaseTable
      title="Áreas/empreendimentos"
      dataSource={dataSource}
      columns={columns}
    />
  );
}

export default Areas;
