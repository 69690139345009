import styled, { css } from 'styled-components';

import colors from 'styles/colors';
import { respondTo } from 'styles/mixins';

export const Container = styled.div`
  padding: 80px;
  height: 90vh;
  width: 900px;
  overflow: auto;
  ${respondTo('medium')(css`
    width: 80vw;
    padding: 40px;
    height: fit-content;
  `)}
`;

export const Title = styled.p`
  font-weight: 900;
  font-size: 1.5rem;
  letter-spacing: 0.48px;
`;

export const SubTitle = styled.div`
  font-size: 1.5rem;
  letter-spacing: 0.48px;
  width: 75%;
  ${respondTo('medium')(css`
    width: 100%;
  `)}
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  ${respondTo('medium')(css`
    display: block;
  `)}
`;

export const Text = styled.p`
  font-size: 1rem;
  letter-spacing: 0.32px;
  color: #585858;
  font-style: ${({ textStyle }) => textStyle ?? 'normal'};
`;

export const HighlightedText = styled.div`
  width: 100%;
  font-size: 1.125rem;
  font-weight: 900;
  border-bottom: 1px solid ${colors.primaryLight};
  letter-spacing: 0.32px;
  color: #585858;
  margin-bottom: 20px;
`;
export const Data = styled.div`
  margin-top: 40px;
  width: 50%;
  ${respondTo('medium')(css`
    width: 100%;
  `)}
`;

export const Card = styled.div`
  box-shadow: 0px 0px 15px #cacaca;
  justify-content: center;
  padding: 60px 30px;
  border-radius: 20px;
  width: fit-content;
  height: fit-content;
  ${respondTo('medium')(css`
    margin-top: 50px;
  `)}
`;

export const CardTitle = styled.div`
  font-size: 1.125rem;
  font-weight: 900;
  letter-spacing: 0.32px;
  color: black;
  margin-bottom: 10px;
`;

export const CardSubTitle = styled.div`
  font-style: italic;
  font-weight: 600;
  font-size: 0.875rem;
  letter-spacing: 0.28px;
  color: #000000;
  margin-bottom: 20px;
`;
