import { Can } from 'components';

import { NavigationItem, NavigationText, StyledLink } from './Navbar.style';

function NavItem({ path, action, label, icon, active, setActive }) {
  const handleNavigation = () => {
    setActive(path);
  };
  const NavigationImage = icon;
  return (
    <Can perform={action}>
      <NavigationItem setActive={!!(path === active)}>
        <StyledLink to={path} onClick={() => handleNavigation()}>
          <NavigationImage setActive={!!(path === active)} />
          <NavigationText>{label}</NavigationText>
        </StyledLink>
      </NavigationItem>
    </Can>
  );
}

export default NavItem;
