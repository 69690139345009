import { createContext, useContext } from 'react';

const CallDataContext = createContext(null);

function CallDataProvider({ children, value }) {
  return (
    <CallDataContext.Provider value={value}>
      {children}
    </CallDataContext.Provider>
  );
}

export const useCallData = () => useContext(CallDataContext);

export default CallDataProvider;
