import { Input as AntInput } from 'antd';
import styled, { css } from 'styled-components';

import colors from 'styles/colors';
import { respondTo } from 'styles/mixins';

export const Container = styled.div`
  position: relative;
  margin: 10px 0;
  width: 100%;
`;

export const Label = styled.label`
  color: ${colors.lightGray};
  font-size: 1.5rem;
  ${respondTo('large')(css`
    font-size: 1.125rem;
  `)}
  ${respondTo('medium')(css`
    font-size: 1rem;
  `)}
`;

const inputStyles = css`
  margin-top: 1.687rem;
  padding: 0;
  position: relative;
  ::after {
    position: absolute;
    bottom: -1px;
    height: 1px;
    width: 100%;
    background: transparent linear-gradient(94deg, #ffcf8f 0%, #f88122 100%) 0%
      0% no-repeat padding-box;
    content: '';
  }
  .ant-input-prefix {
    margin: 0;
  }
  .ant-input {
    font-size: 1.125rem;
    &::placeholder {
      font-size: 1.125rem;
    }
  }
  ${respondTo('large')(css`
    margin-top: 1rem;
    .ant-input {
      font-size: 1rem;
      &::placeholder {
        font-size: 1rem;
      }
    }
  `)}
  ${respondTo('medium')(css`
    margin-top: 1.1875rem;
    .ant-input {
      font-size: 1rem;
      &::placeholder {
        font-size: 1rem;
      }
    }
  `)}
`;

export const Input = styled(AntInput)`
  ${inputStyles}
`;

export const InputPassword = styled(AntInput.Password)`
  ${inputStyles}

  .ant-input-suffix {
    .anticon {
      position: unset !important;
    }
  }
`;

export const Icon = styled.img`
  width: 1.1875rem;
  margin-bottom: 0.75rem;
  margin-right: 0.75rem;
  ${respondTo('medium')(css`
    width: 1.090625rem;
    margin-bottom: 0.485625rem;
    margin-right: 0.485625rem;
  `)}
`;

export const Error = styled.p`
  position: absolute;
  color: #707070;
  opacity: 1;
  font-style: italic;
  font-size: 0.875rem;
  margin: 0;
  bottom: -10px;
  transform: translateY(100%);
`;

export const ErrorIcon = styled.img`
  width: 0.875rem;
  margin-right: 0.625rem;
`;
