import styled, { css } from 'styled-components';

import { respondTo } from 'styles/mixins';

export const Container = styled.div`
  padding: 50px;
  ${respondTo('medium')(css`
    padding: 70px 10px 10px 10px;
  `)}
`;

export const Title = styled.p`
  margin: 0 auto;
  text-align: center;
  font-size: 1.25rem;
  font-weight: 600;
  font-family: 'Fira Sans';
  letter-spacing: 0.48px;
  opacity: 1;
  width: 90%;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 80px;
  margin-bottom: 50px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  transform: scale(0.6);
  margin-top: 80px;
`;
