import { useEffect, useRef, useState } from 'react';
import MapGL, { FlyToInterpolator } from 'react-map-gl';

import { MAP_DEFAULT_COORDINATE, MAP_STYLE } from 'variables';

import MapAreas from './MapAreas';
import CustomPopup from './MarkerTooltip';

import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css';

const MapComponent = ({
  containerWidth: width,
  containerHeight: height,
  setInputFilterList,
  setOptions,
  setEmptyQueryOptions,
  setTagTitle,
  areaToFly,
  requestData,
  ...props
}) => {
  const [viewport, setViewport] = useState(MAP_DEFAULT_COORDINATE);
  const [res, setRes] = useState([]);
  const [popupIndex, setPopupIndex] = useState(null);
  const [popupColor, setPopupColor] = useState('');
  const [markerTierConstant, setMarkerTierConstant] = useState(0);
  const [higherCpp, setHigherCpp] = useState(0);
  const [lowerCpp, setLowerCpp] = useState(0);
  const mapRef = useRef();

  useEffect(() => {
    const data = requestData?.map;

    if (data) {
      const formattedData = data?.map((object, index) => ({
        ...object,
        cpp: object.unifiedAttendancesPerPerson,
        id: index.toString(),
      }));
      setRes(formattedData);
      const filterArray = data?.map(({ name }) => ({
        value: name,
      }));
      setInputFilterList(filterArray);
      setOptions(filterArray.slice(0, 3));
      setEmptyQueryOptions(filterArray.slice(0, 3));
    }
  }, [requestData]);

  useEffect(() => {
    let high = 0;
    let low = res[0]?.cpp || 0;

    for (let aux = 0; aux < res.length; aux += 1) {
      if (high < res[aux].cpp) high = res[aux].cpp;
      if (low > res[aux].cpp) low = res[aux].cpp;
    }
    setMarkerTierConstant((high - low) / 3);
    setHigherCpp(high);
    setLowerCpp(low);
  }, [res]);

  useEffect(() => {
    if (areaToFly === 'default') {
      setViewport(MAP_DEFAULT_COORDINATE);
      return;
    }
    for (let aux = 0; aux < res.length; aux += 1) {
      if (res[aux].name === areaToFly) {
        setViewport({
          latitude: res[aux].lat,
          longitude: res[aux].long,
          zoom: 14,
          bearing: 0,
          pitch: 0,
        });
        break;
      }
    }
  }, [areaToFly]);

  const openPopup = (index, color) => {
    setPopupIndex(index);
    setPopupColor(color);
  };

  const closePopup = () => {
    setPopupIndex(null);
  };

  const getPointName = (e) => {
    const labelArr = mapRef.current.queryRenderedFeatures(e.point, {
      layers: ['settlement-major-label', 'settlement-subdivision-label'],
    });
    let pointName = '';
    if (labelArr.length) {
      const tags = labelArr[0]?.properties?.iso_3166_2?.split('-');
      pointName = `${labelArr[0]?.properties?.name}, ${tags[1]}, ${tags[0]}`;
      setTagTitle(pointName);
    }
  };

  return (
    <MapGL
      onViewportChange={setViewport}
      mapStyle={MAP_STYLE}
      ref={mapRef}
      id="map-container"
      mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
      attributionControl={false}
      transitionDuration={1000}
      transitionInterpolator={new FlyToInterpolator()}
      onMouseDown={getPointName}
      dragRotate={false}
      onWheel={getPointName}
      {...viewport}
      width={width}
      height={height}
      {...props}
    >
      {popupIndex !== null && (
        <CustomPopup marker={res[popupIndex]} color={popupColor} />
      )}

      <MapAreas
        areas={res}
        higherCpp={higherCpp}
        lowerCpp={lowerCpp}
        markerConstant={markerTierConstant}
        openPopup={openPopup}
        closePopup={closePopup}
      />
    </MapGL>
  );
};

export default MapComponent;
