import styled, { css } from 'styled-components';

import ballSvg from 'assets/noise/big_upper_right.svg';
import { respondTo } from 'styles/mixins';

const Ball = styled.img`
  position: fixed;
  top: 33px;
  right: 40px;

  ${respondTo('medium')(css`
    display: none;
  `)}
`;

const BigOrangeBall = () => <Ball src={ballSvg} alt="" />;

export default BigOrangeBall;
