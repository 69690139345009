import { Modal as StyledModal } from 'antd';
import styled, { css } from 'styled-components';

import colors from 'styles/colors';
import { respondTo, respondToMin } from 'styles/mixins';

export const Modal = styled(StyledModal)`
  top: 20px;
`;

export const Container = styled.div`
  padding: 40px 80px;
  height: fit-content;
  width: 900px;
  overflow: auto;
  ${respondTo('medium')(css`
    width: 80vw;
    padding: 20px;
  `)}
`;

export const Title = styled.p`
  font-weight: 900;
  font-size: 1.5rem;
  letter-spacing: 0.48px;
  margin-bottom: 60px;
  ${respondTo('medium')(css`
    text-align: center;
    margin-bottom: 20px;
  `)}
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  ${respondTo('medium')(css`
    display: block;
  `)}
`;

export const Text = styled.p`
  font-size: 1.125rem;
  letter-spacing: 0.36px;
  color: #585858;
  ${respondTo('medium')(css`
    text-align: center;
  `)}
`;

export const HighlightedText = styled.div`
  width: 100%;
  font-size: 1.125rem;
  font-weight: 900;
  border-bottom: 1px solid ${colors.primaryLight};
  letter-spacing: 0.32px;
  color: #585858;
  margin-bottom: 20px;
`;
export const Data = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
  width: 50%;
  ${respondTo('medium')(css`
    width: 100%;
    margin-bottom: 80px;
  `)}
`;

export const Card = styled.div`
  cursor: pointer;
  width: 100%;
  text-align: center;
  height: fit-content;
  padding: 20px 0px;
  margin-bottom: 20px;
  border-radius: 5px;
  font-weight: 600;
  ${({ isSelected }) =>
    isSelected &&
    css`
      background: linear-gradient(
        90deg,
        rgba(247, 149, 28, 1) 0%,
        rgba(255, 92, 155, 1) 100%
      );
      color: white;
    `}
  border: 1px solid rgba(247, 149, 28, 1);
`;

export const Warning = styled.div`
  display: flex;
  width: fit-content;
  justify-content: space-between;
  font-style: italic;
  font-weight: 300;
  font-size: 1rem;
  letter-spacing: 0.32px;
  color: #9c9c9c;
  margin-bottom: 10px;
  margin-left: 10px;
`;
export const Options = styled.div`
  margin-top: 40px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  transform: scale(0.7);
  margin-top: 80px;
  ${respondTo('medium')(css`
    width: 100%;
    transform: scale(1);
  `)}
`;

export const Audio = styled.div`
  display: flex;
  height: fit-content;
  margin-bottom: 20px;
  ${respondToMin('medium')(css`
    display: none;
  `)}
`;

export const AudioText = styled.div`
  color: #585858;
  font-weight: bold;
  font-size: 1.125rem;
  height: max-content;
  text-align: left;
  margin-top: 10px;
  margin-left: 20px;
`;

export const Wrapper = styled.div``;

export const Image = styled.img`
  cursor: pointer;
`;
