import { useModal, withModalProvider } from 'contexts/ModalProvider';

import { StyledButton } from './CreateConfigurationButton.style';
import CreateConfigurationModal from './CreateConfigurationModal';

function CreateConfigurationButton(buttonProps) {
  const { modal } = useModal();

  const handleModal = () => {
    const modalProps = {
      title: 'Envio automático de relatório',
      footer: null,
      destroyOnClose: true,
      watchTouched: true,
      maskClosable: false,
    };

    modal(
      (props) => (
        <CreateConfigurationModal isCreate {...props} {...buttonProps} />
      ),
      modalProps
    );
  };

  return (
    <StyledButton onClick={handleModal}>Criar nova configuração</StyledButton>
  );
}

export default withModalProvider(CreateConfigurationButton);
