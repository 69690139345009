import { Dash, Modal, ModalItem, ModalList } from './Header.style';

function MenuModal({ visible, setVisible }) {
  return (
    <Modal
      onCancel={() => setVisible(false)}
      destroyOnClose
      visible={visible}
      style={{ top: 20, right: 0 }}
      mask={false}
      footer={null}
      closeIcon={null}
    >
      <ModalList>
        <ModalItem>Portal de Divulgação</ModalItem>
        <Dash />
        <ModalItem>Postar Conteúdo</ModalItem>
        <Dash />
        <ModalItem>Postar comunicação institucional</ModalItem>
        <Dash />
        <ModalItem>Cadastrar empreendimentos</ModalItem>
        <Dash />
        <ModalItem>Sair</ModalItem>
      </ModalList>
    </Modal>
  );
}

export default MenuModal;
