import styled from 'styled-components';

import { BaseCard } from 'components';
import colors from 'styles/colors';

export const Item = styled.li`
  list-style: none;
`;

export const StyledCard = styled(BaseCard)`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0.75rem 0.9375rem;
  box-shadow: 8px 8px 15px #00000020;
`;

export const Title = styled.p`
  font-size: 0.8rem;
  letter-spacing: 0.0175rem;
  color: ${colors.textBlue};
  line-height: 1.25rem;
  margin: 0;
  margin-bottom: 1rem;
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const Value = styled.p`
  font-size: 1.7rem;
  font-weight: 600;
  letter-spacing: 0.04rem;
  color: ${colors.textBlue};
  line-height: 1.8125rem;
  margin: 0;
`;

export const Percentage = styled.p`
  font-size: 1rem;
  font-weight: 600;
  color: ${({ positive }) => (positive ? colors.green : colors.red)};
  margin: 0;
  margin-left: 0.8125rem;
  line-height: 1.1875rem;
`;

export const Icon = styled.img`
  margin: 0.25rem;
  margin-right: 0rem;
`;

export const Max = styled.p`
  font-size: 0.875rem;
  letter-spacing: 0.0175rem;
  font-weight: 600;
  color: ${colors.textBlue};
  line-height: 1.0625rem;
  margin: 0;
`;

export const Text = styled.p`
  font-size: 0.8rem;
  letter-spacing: 0.0175rem;
  color: ${colors.textBlue};
  line-height: 1rem;
  margin: 0;
  margin-left: 0.8125rem;
`;

export const Beacon = styled.div`
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50px;
  background-color: ${({ color }) => color && colors[color]};
  margin: auto 0.625rem auto 0;
`;
