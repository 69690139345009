import { Modal } from 'antd';
import styled, { css } from 'styled-components';

import { Button, TextInput as BaseTextInput } from 'components/inputs';
import { respondTo, respondToMin } from 'styles/mixins';

export const TextInput = styled(BaseTextInput)`
  margin-bottom: 4rem;

  label {
    font-size: 0.8rem;
  }

  .ant-input-affix-wrapper {
    background: #ffffff !important;
    margin: 0 0 1.15vw !important;
  }

  .ant-input-prefix {
    display: none;
  }

  .ant-input {
    background: #ffffff !important;
    font-weight: 600;
    font-size: 0.88rem;
    color: #585858;
  }

  ${respondToMin('medium')(css`
    width: 100%;
  `)}

  ${respondToMin('big')(css`
    .ant-input-affix-wrapper {
      margin: 0 0 0.5vw !important;
    }
  `)}

  ${respondToMin('large')(css`
    label {
      font-size: 1rem;
    }

    .ant-input {
      font-size: 1.13rem;
    }

    .ant-input-affix-wrapper {
      margin: 0.75vw 0 !important;
    }
  `)}
  
  ${respondTo('medium')(css`
    margin-bottom: 0.2rem;
  `)}
`;

export const StyledModal = styled(Modal)`
  top: 2rem;
  width: 60rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4rem;
  height: 100%;
  width: 100%;

  ${respondTo('medium')(css`
    padding: 1rem;
  `)}
`;

export const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: 0.48px;
  margin-bottom: 1.188rem;

  ${respondTo('medium')(css`
    margin-right: 3rem;
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
  `)}
`;

export const Subtitle = styled.p`
  text-align: left;
  font-size: 1rem;
  letter-spacing: 0.32px;
  color: #727272;
  width: 50%;
  margin-bottom: 3.5rem;

  ${respondTo('medium')(css`
    width: 100%;
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
  `)}
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  ${respondTo('medium')(css`
    margin-top: 1.875rem;
  `)}
`;

export const StyledButton = styled(Button)`
  margin: 0 2.65rem;
  width: 9.25rem;
  height: 2.5rem;
  font-size: 1rem;

  ${respondTo('medium')(css`
    margin: 1rem 0;
  `)}
`;
