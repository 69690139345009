import styled, { keyframes } from 'styled-components';

const showAnimation = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

export const Wrapper = styled.div`
  display: ${({ visible }) => (visible ? 'block' : 'none !important')};
  animation: ${showAnimation} 0.5s linear forwards;
  position: absolute;
  bottom: 4rem;
  left: 3rem;
  z-index: 999;
`;
