import { Tabs } from 'antd';
import styled, { css } from 'styled-components';

import colors from 'styles/colors';
import { respondTo } from 'styles/mixins';

const { TabPane } = Tabs;

export const TabContainer = styled(Tabs)`
  .ant-tabs-nav-list {
    border: 1px solid #f8f8f8;
  }

  .ant-tabs-ink-bar {
    width: 3rem !important;
    height: 0.2rem !important;
    background-color: ${colors.persianGreen};

    ${respondTo('medium')(css`
      background-color: ${colors.secondary};
      width: 5rem !important;
    `)}
  }

  .ant-tabs-tab {
    padding-bottom: 0.2rem !important;
  }

  .ant-tabs-tab .ant-tabs-tab-btn,
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
  .ant-tabs-tab-btn:focus {
    color: ${colors.black};
  }

  .ant-tabs-tab-disabled .ant-tabs-tab-btn {
    color: ${colors.gray} !important;
  }

  ${respondTo('medium')(css`
    width: 100%;
  `)}
`;

export const TabItem = styled(TabPane)``;
