import styled from 'styled-components';

import colors from 'styles/colors';

const Badge = styled.p`
  width: max-content;
  font-size: 1rem;

  margin: 0.8125rem 0 0 0;
  background-color: ${colors.green};
  border-radius: 5px;
  padding: 5px 20px;
  font-weight: 600;
  font-style: italic;
`;

export default Badge;
