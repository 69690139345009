export default [
  {
    sectionTitle: "Território",
    filterTypes: [
      {
        title: "Regional",
        callToAction: "Selecione a ou as regiões",
        boxTitle: "Regiões",
        hasSearchInput: true,
        inputPlaceholder: "Pesquise aqui por uma região...",
        name: "regionsIds",
        options: '/region',
      },
      {
        title: "Área / empreendimento",
        callToAction: "Selecione a área / empreendimento",
        boxTitle: "Áreas",
        hasSearchInput: true,
        hasAutocomplete: true,
        hasInfinityScroll: true,
        inputPlaceholder: "Pesquise aqui por uma área / empreendimento...",
        name: "areasIds",
        options: '/area',
      },
    ],
  },
  {
    sectionTitle: "Atendimento",
    filterTypes: [
      {
        title: "Solicitante",
        callToAction: "Selecione o ou os solicitantes",
        boxTitle: "Solicitante",
        hasSearchInput: true,
        hasAutocomplete: true,
        hasInfinityScroll: true,
        inputPlaceholder: "Pesquise aqui por um solicitante...",
        name: "requestersIds",
        options: '/requester',
      },
      {
        title: "Técnica",
        callToAction: "Selecione a ou as técnicas",
        boxTitle: "Técnica",
        hasSearchInput: true,
        hasAutocomplete: true,
        hasInfinityScroll: true,
        inputPlaceholder: "Pesquise aqui por uma técnica...",
        name: "usersIds",
        options: '/user?roles=technique',
      },
      {
        title: "Atendente",
        callToAction: "Selecione a ou as atendentes",
        boxTitle: "Atendente",
        hasSearchInput: true,
        hasAutocomplete: true,
        hasInfinityScroll: true,
        inputPlaceholder: "Pesquise aqui por uma atendente...",
        name: "usersIds",
        options: '/user?roles=attendant',
      },
      {
        title: "Motivo",
        callToAction: "Selecione o ou os motivos",
        boxTitle: "Motivos",
        hasSearchInput: true,
        hasAutocomplete: true,
        hasInfinityScroll: true,
        inputPlaceholder: "Pesquise aqui por um motivo...",
        name: "motivesIds",
        options: "/call-motive",
      },
      {
        title: "Status",
        callToAction: "Selecione o ou os status",
        boxTitle: "Status",
        hasSearchInput: false,
        name: "status",
        options: [
          {
            value: "opened",
            label: "Em aberto",
          },
          {
            value: "concluded",
            label: "Concluído"
          },
          {
            value: "canceled",
            label: "Cancelado",
          },
          {
            value: "hold",
            label: "Em espera",
          }
        ]
      },
      {
        title: "Canal",
        callToAction: "Selecione o ou os canais",
        boxTitle: "Canais",
        hasSearchInput: true,
        inputPlaceholder: "Pesquise aqui por um canal...",
        name: "channelsIds",
        options: "/channel",
      },
      { 
        title: "Data",
        callToAction: "Selecione as datas", 
        boxTitle: "Datas", hasSearchInput: false,
        isDateFilter: true,
        name: 'createdAt',
       },
    ],
  },
  {
    sectionTitle: "Técnica",
    filterTypes: [
      {
        title: "Tipo de Técnica",
        callToAction: "Selecione o ou os tipos de técnica",
        boxTitle: "Tipos de Técnicas",
        hasSearchInput: true,
        inputPlaceholder: "Pesquise aqui por um tipo...",
        name: "typesOperatorsIds",
        options: '/type-operator',
      },
    ],
  },
];