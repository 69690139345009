import {
  ItemContainer,
  ItemNumber,
  ItemText,
  Track,
  Wrapper,
} from './SteppedHeader.style';

function SteppedHeader({ titles, activeIndex = 0 }) {
  const total = titles.length;
  const progress = (activeIndex * 100) / (total - 1);

  return (
    <Wrapper>
      <Track progress={progress}>
        {titles.map((title, index) => (
          <ItemContainer amount={titles.length} index={index} key={title}>
            <ItemNumber
              active={
                activeIndex >= index ||
                progress >= (100 / (titles.length - 1)) * index
              }
            >
              {index + 1}
            </ItemNumber>
            <ItemText>{title}</ItemText>
          </ItemContainer>
        ))}
      </Track>
    </Wrapper>
  );
}

export default SteppedHeader;
