import { Center, StyledSpinner } from './Loading.style';

function Loading() {
  return (
    <Center>
      <StyledSpinner font={100} />
    </Center>
  );
}

export default Loading;
