import { Modal } from 'antd';
import styled, { css } from 'styled-components';

import { Button } from 'components/inputs';
import { respondTo } from 'styles/mixins';

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    width: 37.5rem;
    height: 26.5rem;

    .ant-modal-body {
      height: 100%;
      padding: 0;
    }
  }
  .ant-modal-close {
    display: none;
  }

  ${respondTo('medium')(css`
    width: 100% !important;
    .ant-modal-content {
      width: 90%;
      height: auto;
      margin: 0 auto;
    }
  `)}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 6.25rem 2rem;
  height: 100%;
  width: 100%;
`;

export const Question = styled.h1`
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
`;

export const ButtonContainer = styled.div`
  display: flex;

  ${respondTo('medium')(css`
    flex-direction: column-reverse;
    margin-top: 1.875rem;
  `)}
`;

export const StyledButton = styled(Button)`
  margin: 0 2.65rem;
  width: 9.25rem;
  height: 2.5rem;
  font-size: 1rem;

  ${respondTo('medium')(css`
    margin: 1.875rem 2.65rem;
  `)}
`;
