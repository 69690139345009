import { useModal } from 'contexts/ModalProvider';

import CloseButton from './CloseButton';
import { OptionButton } from './CreateAreaModal.style';
import DetailAreaModal from './DetailAreaModal';

function DetailAreaButton({ edit, remove, disabled = false, ...area }) {
  const { type } = area;
  const { modal, setResult } = useModal();

  const handleDetails = () => {
    const modalProps = {
      title: type === 'area' ? 'Área' : 'Empreendimento',
      footer: null,
      closeIcon: (
        <CloseButton
          handleEdit={() => setResult({ disabled: false })}
          remove={remove}
          {...area}
        />
      ),
      destroyOnClose: true,
      afterClose: () => setResult({ disabled: true }),
      watchTouched: true,
      maskClosable: false,
    };

    modal(
      (props) => (
        <DetailAreaModal
          area={area}
          edit={edit}
          isEdit
          type={type}
          {...props}
        />
      ),
      modalProps
    );
  };

  return (
    <OptionButton onClick={handleDetails} disabled={disabled}>
      Ver detalhes
    </OptionButton>
  );
}

export default DetailAreaButton;
