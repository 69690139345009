import { Select } from 'antd';
import styled from 'styled-components';

import { BaseCard, Spin } from 'components';
import colors from 'styles/colors';

export const Card = styled(BaseCard)`
  cursor: pointer;
  box-shadow: 10px 10px 15px #00000014;
  width: 100%;
  height: 100%;
`;

export const Container = styled.div`
  padding: 1.5rem 1.5rem 3rem 0rem;
  border-radius: 10px;
  height: ${({ height }) => height || '27rem'};
  overflow-y: hidden;
  overflow-x: auto;

  ::-webkit-scrollbar {
    height: 6px;
  }

  ::-webkit-scrollbar-track {
    background: ${colors.darkGray};
    border-top: 2px solid ${colors.white};
    border-bottom: 2px solid ${colors.white};
  }

  ::-webkit-scrollbar-thumb {
    width: 0.313rem;
    background: ${colors.persianGreen};
    border-radius: 5px;
  }
`;

export const Title = styled.h1`
  color: ${colors.textBlue};
  letter-spacing: 0.72px;
  font-size: 1.125rem;
  margin-left: 2rem;
`;

export const StyledSelect = styled(Select)`
  width: 10rem;
  box-shadow: none;
  .ant-select-arrow {
    top: 0.626rem;
    color: black;
    padding: 0 0.5rem;
  }
  .ant-select-selector {
    border: 2px solid ${colors.lightGray} !important;
    border-radius: 5px !important;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Option = styled(Select.Option)``;

export const StyledSpin = styled(Spin)`
  position: relative;
  margin: 0 45%;
`;

export const ScrollBar = styled.div`
  padding: 1.5rem 1.5rem 3rem 0rem;
  border-radius: 10px;
  height: fit-content;
  width: 100%;
  overflow: auto;

  ::-webkit-scrollbar {
    height: 6px;
  }
  ::-webkit-scrollbar-track {
    background: ${colors.darkGray};
    border-top: 2px solid ${colors.white};
    border-bottom: 2px solid ${colors.white};
  }
  ::-webkit-scrollbar-thumb {
    width: 0.313rem;
    background: ${colors.persianGreen};
    border-radius: 5px;
  }
`;
