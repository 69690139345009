import styled from 'styled-components';

export const BaseCard = styled.div`
  padding: 0.938rem 2.188rem;
  width: 20vw;
  border-radius: 15px;
  box-shadow: 8px 5px 10px #e2e2e2;
  background-color: white;
  margin-right: 1.6vw;
  max-width: 24.375rem;
`;

export const Title = styled.p`
  font-size: 0.875rem;
  letter-spacing: 0.03rem;
  margin: 0;
`;

export const Counter = styled.h1`
  margin: 0;
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 0.05rem;
`;
