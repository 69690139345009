import { Menu, Pagination } from 'antd';
import styled from 'styled-components';

import colors from 'styles/colors';
import { customScrollBar } from 'styles/mixins';

const { SubMenu } = Menu;

export const MobileTableContainer = styled(Menu)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 30rem;
  margin: auto;
  background-color: transparent;
  padding: 1rem;
  overflow-y: auto;
  border: none;

  ${customScrollBar(8)};
`;

export const AntdPagination = styled(Pagination)`
  display: flex;
  margin-top: 1rem !important;
  justify-content: center;

  .ant-pagination-item a {
    &::after {
      content: '';
      position: absolute;
      top: 1.5rem;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0.2rem;
      background-color: ${colors.secondary};
      opacity: 0;
      transition: opacity 0.3s linear, width 0.1s linear;
    }
  }

  .ant-pagination-item-active a {
    &::after {
      opacity: 1 !important;
      width: 0.8rem;
    }
  }

  .ant-pagination-item,
  .ant-pagination-item * {
    position: relative !important;
    background-color: transparent !important;
    border: none !important;
    min-width: 1rem !important;
    font-weight: 400 !important;
    color: ${colors.black} !important;

    &:hover,
    &:active,
    &:focus {
      color: ${colors.black} !important;
    }
  }

  .ant-pagination-item-ellipsis {
    color: ${colors.black} !important;
  }

  .anticon-double-left,
  .anticon-double-right {
    color: ${colors.black} !important;
  }

  .ant-pagination-item-active,
  .ant-pagination-item-active * {
    font-weight: 600 !important;
  }

  .ant-pagination-next,
  .ant-pagination-prev {
    min-width: initial !important;
    width: 1.5rem !important;
    height: 1.5rem !important;

    & button {
      border: none !important;
      outline: none !important;
      box-shadow: 1px 1px 10px ${colors.darkGray};
      display: flex;
      align-items: center;
      justify-content: center;

      & span {
        color: ${colors.black} !important;
      }
    }
  }
`;

export const MobileTableItem = styled(SubMenu)`
  width: 100%;
  margin-bottom: 0.25rem;
  background-color: ${colors.white};

  .ant-menu-submenu-title {
    height: auto !important;

    .ant-menu-submenu-arrow {
      color: ${colors.black} !important;
    }
  }
`;

const ContentTag = styled.h2`
  color: ${colors.grayText} !important;
  font-size: 0.9rem !important;
  font-weight: 400 !important;
  line-height: 1.5rem;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  width: 100%;
`;

export const MobileItemTitleTag = styled(ContentTag)`
  &:first-child,
  &:first-child * {
    color: ${colors.black} !important;
    font-size: 0.9rem !important;
    font-weight: 600 !important;
  }
`;

export const MobileItemContentTag = styled(ContentTag)`
  &:last-child,
  &:last-child * {
    color: ${colors.black} !important;
    font-size: 0.9rem !important;
    font-weight: 600 !important;
  }
`;

export const MobileItemTitleContainer = styled.div`
  width: 100%;
  padding: 0.5rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const ItemBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.white};
  padding: 0rem 1rem 1rem 2rem;
`;

export const BodyInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  & > h2 {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.9rem;
    white-space: nowrap;
  }

  & > h2:first-child {
    color: ${colors.grayText};
    font-weight: 400;
    line-height: 1.5rem;
    max-width: 65%;
  }

  & > h2:last-child {
    color: ${colors.black};
    font-weight: 600;
    line-height: 1.5rem;
    text-align: end;
    min-width: 20%;
  }
`;

export const ItemExtras = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.white};
  padding: 1rem;
`;

export const ExtraContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const Divider = styled.div`
  width: 100%;
  height: 0.0625rem;
  background-color: ${({ color = colors.dividerColor(0.75) }) => color};
  margin-bottom: ${({ marginBottom = '1rem' }) => marginBottom};
`;
