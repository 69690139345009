import styled from 'styled-components';

import { BaseCard } from 'components';

export const Count = styled.h3`
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 2.1875rem;
`;

export const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: -1.40875rem;
`;

export const Item = styled.li`
  list-style: none;
  width: 16.25rem;
  margin: 1.40875rem;
`;

export const Card = styled(BaseCard)`
  height: 100%;
  padding: 30px;
`;

export const CardTitle = styled.h4`
  font-size: 1.125rem;
  font-weight: 600;
  margin: 0;
  line-height: 1.375rem;
`;

export const CountType = styled.p`
  font-size: 1rem;
  margin-top: 1.625rem;
  margin-bottom: 0.8125rem;
  line-height: 1.475rem;
`;

export const CardCount = styled.p`
  font-size: 1.125rem;
  font-weight: 600;
  margin: 0;
  line-height: 1.375rem;
`;
