import { useEffect, useState } from 'react';

import { DownloadButton } from 'components';
import useMobile from 'hooks/use-mobile';

import MobileTable from './MobileTable';
import {
  AntdPagination,
  Body,
  Cell,
  Header,
  HeaderCell,
  Loading,
  LoadingRow,
  NoDataParagraph,
  Row,
  Table,
} from './PageTable.style';

function PageTable({
  dataSource = [],
  columns = [],
  extras = {},
  mobileTitleColumns = [],
  itemsAmount = 0,
  loading = false,
  get = () => {},
}) {
  const isMobile = useMobile();
  const [currentPage, setCurrentPage] = useState(1);
  const [localItemsAmount, setLocalItemsAmount] = useState(0);

  useEffect(() => {
    if (itemsAmount !== localItemsAmount) {
      setLocalItemsAmount(itemsAmount);
      setCurrentPage(1);
    }
  }, [itemsAmount]);

  const handlePagination = (pageNumber) => {
    setCurrentPage(pageNumber);
    get({}, pageNumber - 1);
  };

  const RenderInstances = () =>
    dataSource.length > 0 ? (
      dataSource?.map((data) => (
        <Row key={data.id}>
          {columns.map(
            ({ dataIndex, key, render, span, align, extraContent = [] }) => {
              const content = data[dataIndex];
              const bonusContent = {};
              extraContent.forEach((field) => {
                bonusContent[field] = data[field];
              });
              const extraComponent = extras[dataIndex] || (() => null);

              return (
                <Cell
                  span={span}
                  columnSpans={columns.map((column) => column.span)}
                  justify={align}
                  key={`cell-${key}`}
                >
                  {(render
                    ? render(content, bonusContent)
                    : content?.toString()) ||
                    extraComponent(data) ||
                    null}
                </Cell>
              );
            }
          )}
        </Row>
      ))
    ) : (
      <NoDataParagraph>Não há dados</NoDataParagraph>
    );

  if (isMobile)
    return (
      <MobileTable
        mobileTitleColumns={mobileTitleColumns}
        dataSource={dataSource}
        columns={columns}
        extras={extras}
        currentPage={currentPage}
        handlePagination={handlePagination}
        itemsAmount={localItemsAmount}
        loading={loading}
      />
    );
  return (
    <>
      <Table>
        <Header>
          <Row>
            {columns.map(({ title, key, span, align }) => (
              <HeaderCell
                span={span}
                justify={align}
                columnSpans={columns.map((column) => column.span)}
                key={key}
              >
                {title}
              </HeaderCell>
            ))}
          </Row>
        </Header>
        <Body>
          {!loading ? (
            <RenderInstances />
          ) : (
            <LoadingRow>
              <Loading />
            </LoadingRow>
          )}
        </Body>
      </Table>
      {extras.download && (
        <DownloadButton extension="xlsx" onClick={extras.download} />
      )}
      {itemsAmount > 0 && (
        <AntdPagination
          defaultCurrent={1}
          current={currentPage}
          total={localItemsAmount}
          showSizeChanger={false}
          onChange={handlePagination}
        />
      )}
    </>
  );
}

export default PageTable;
