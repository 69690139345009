import { useEffect, useState } from 'react';

import colors from 'styles/colors';

import { Divider, Tag, TagsContainer, TagsWrapper } from './Filter.style';

function FilterTagsContainer({ activeFilters = {}, resolveParameterChanges }) {
  const [tags, setTags] = useState([]);

  useEffect(() => {
    if (Object.keys(activeFilters).length === 0) return;
    const tagsArr = [];

    Object.entries(activeFilters).forEach(([filterType, filter]) => {
      filter.options.forEach((value) => {
        tagsArr.push({
          ...value,
          filterSection: filter.title,
          queryIdentifier: filterType,
        });
      });
    });

    setTags(tagsArr);
  }, [activeFilters]);

  const handleTagClose = (removedTag) => {
    const newArr = activeFilters[removedTag.queryIdentifier].options.filter(
      (item) => item.value !== removedTag.value
    );
    resolveParameterChanges(
      removedTag.queryIdentifier,
      newArr,
      removedTag.filterSection
    );
  };

  return (
    <TagsWrapper>
      <h1>Filtros Ativos</h1>
      <Divider marginBottom="1rem" color={colors.black} />
      <TagsContainer>
        {tags.map((tag) => (
          <Tag key={tag.value} closable onClose={() => handleTagClose(tag)}>
            <span className="filterType">{`${tag.filterSection}: `}</span>
            {tag.label}
          </Tag>
        ))}
      </TagsContainer>
    </TagsWrapper>
  );
}

export default FilterTagsContainer;
