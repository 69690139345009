import styled from 'styled-components';
import colors from 'styles/colors';

export const FormButtonsContainer = styled.div`
  margin-top: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
`;

export const RoundButton = styled.button`
  background-color: ${({ backgroundColor }) => backgroundColor || colors.gray};
  color: ${({ textColor }) => textColor || colors.smoothBlack};
  border-radius: 40px;
  font-weight: 600;
  font-size: 0.85rem;
  letter-spacing: 0.4px;
  padding: 0.25rem 1.6rem;
  border: none;
  outline: none;
  margin: 0 0.5rem;
  min-height: 2rem;
  cursor:  ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};

  @media screen and (max-width: 900px) {
    margin-left: 0;
    margin-bottom: 1rem;
  }
`;

export const FileInputWrapper = styled.div`
  width: 100%;
  height: 20rem;
  border-color: ${colors.green};
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='11' ry='11' stroke='#009B90' stroke-width='4' stroke-dasharray='10' stroke-dashoffset='10' stroke-linecap='round'/%3e%3c/svg%3e");
  background-color: rgba(0, 156, 144, 0.3);
  color: ${colors.green};
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
`;