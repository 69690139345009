import { Upload } from 'antd';
import styled, { css } from 'styled-components';

import {
  AddPhoto as AddPhotoIcon,
  Upload as UploadIcon,
} from 'components/icons/noise';
import colors from 'styles/colors';
import { respondTo } from 'styles/mixins';

export const ImageFieldWrapper = styled.form`
  margin-bottom: 1rem;

  .ant-upload-list {
    margin-top: ${({ multi }) => (multi ? '0' : '-1.75rem')};
  }
`;

export const UploadImageFieldWrapper = styled.form`
  margin-bottom: 1rem;

  display: flex;
  width: 6rem;
  height: 6rem;
  flex-direction: column;
  align-items: center;
  position: relative;

  .ant-upload-list-item {
    height: 6rem;
    width: 6rem;
    object-fit: cover;
    padding: 0;
    pointer-events: none;
    border: none;
  }

  .ant-upload-list-item-info {
    width: 6rem;
    height: 6rem;
    border-radius: 100%;
  }

  .ant-upload-list-item-thumbnail {
    opacity: 1;
  }

  .ant-upload-picture-card-wrapper {
    width: initial !important;
  }

  & span {
    &.ant-upload {
      cursor: pointer !important;
    }
  }

  .anticon-picture {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    path:first-child,
    path:last-child {
      fill: ${colors.smoothBlack};
    }
  }

  .ant-upload-list-item-name {
    font-size: 0.7rem;
  }

  .ant-upload {
    background-color: transparent;
    margin-top: 3.2rem;
    width: 6rem;
    height: auto !important;
    border: none !important;
    cursor: initial;
  }

  ${respondTo('medium')(css`
    width: 6rem;
    height: 3rem;

    .ant-upload {
      margin-top: 1.7rem;
    }
    .ant-upload-list-item-info {
      width: 4rem;
      height: 4rem;
      border-radius: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  `)}
`;

export const CameraIcon = styled(AddPhotoIcon)`
  font-size: 1.25rem;
  position: absolute;
  top: 0;
  left: 0;

  ${respondTo('medium')(css`
    left: 3.7rem;
    font-size: 0.7rem;
  `)}
`;

export const StyledUpload = styled(Upload)`
  flex-grow: 1;
`;

export const StyledUpdateUpload = styled(Upload)`
  flex-grow: 1;
  position: absolute;
  left: 0;
`;

export const UploadCallToAction = styled.p`
  font-size: 0.7rem;
  color: ${colors.black};
  text-decoration: underline;
  font-style: italic;
  font-weight: 600;
`;

export const StyledLabel = styled.label`
  margin-bottom: 0.75rem;
  display: block;
  color: ${({ red }) => (red ? colors.primary : colors.black)};
  margin-bottom: 0;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0.6rem;

  cursor: pointer;

  color: ${({ red }) => (red ? colors.primary : colors.black)};
  border: 1px solid ${({ red }) => (red ? colors.primary : colors.black)};
  min-width: 12rem;
  height: 1rem;
  border-radius: 0.3rem;

  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  margin-bottom: ${({ multi }) => (multi ? '0' : '2rem')};
`;

export const StyledIcon = styled(UploadIcon)`
  font-size: 1.2rem;

  path {
    fill: ${({ red }) => (red ? colors.primary : colors.black)};
  }
`;

export const StyledIconButton = styled.div`
  background: #ffffff66;
  border: none;
  border-radius: 50%;
  padding: 0.5rem;
  position: absolute;
  left: 3rem;
  top: 4.5rem;
  height: 2rem;
  width: 2rem;
  cursor: pointer;

  svg {
    stroke: ${colors.black};
    fill: none;
    height: 1rem;
    width: 1rem;
  }
`;

export const StyledIconsButton = styled.div`
  border: none;
  border-radius: 1rem;
  padding: 0.5rem;
  height: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.75rem;
  z-index: 3;
  position: absolute;
  top: 6rem;

  ${respondTo('medium')(css`
    top: 3.5rem;
  `)}

  span {
    position: relative;
    top: initial;
    left: initial;
  }

  svg {
    stroke: ${colors.black};
    fill: none;
    height: 1rem;
    width: 1rem;
  }
`;

export const Picture = styled.img`
  height: 6rem;
  width: 6rem;
  object-fit: cover;
  border-radius: 100%;

  ${respondTo('medium')(css`
    width: 3rem;
    height: 3rem;
  `)}
`;
