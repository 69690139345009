import styled, { css } from 'styled-components';

import { ArrowBack } from 'components/icons/noise';
import FloatingLabelSelect from 'components/inputs/FormSelect/FloatingLabelSelect';
import TextField from 'components/inputs/TextField';
import colors from 'styles/colors';

export const Wrapper = styled.div`
  margin-bottom: 8rem;
`;

export const Arrow = styled(ArrowBack)`
  position: relative;
  top: initial;
  left: initial;
  font-size: 1.5rem;
  & path:first-child {
    ${({ color }) => {
      if (color)
        return css`
          fill: blue;
        `;
      return null;
    }}
  }
`;

export const ArrowContainer = styled.div`
  display: flex;
  cursor: pointer;
  width: fit-content;
`;

export const BackText = styled.div`
  font-size: 1rem;
  font-weight: bold;
  margin-left: 1rem;
`;

export const PageHeader = styled.section`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: ${colors.white};
  padding: 2.25rem 1rem 1rem 2.25rem;
`;

export const Select = styled(FloatingLabelSelect)`
  margin: 1.8125rem 0;
`;

export const Input = styled(TextField)`
  margin: 1.75rem 0;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

export const Content = styled.section`
  background-color: ${colors.white};
  padding: 1rem;
  margin: 0.5rem;
`;

export const Edit = styled.button`
  outline: none;
  border: none;
  border-radius: 25px;
  padding: 0.2rem 2rem;
  cursor: pointer;
  background-color: ${({ save }) => (save ? colors.grassGreen : colors.gray)};
  color: ${({ save }) => (save ? colors.white : colors.glassGray)};
`;

export const ButtonsContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: space-evenly;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

export const TopButton = styled.button`
  padding: 0.15rem 0;
  outline: none;
  border: none;
  background-color: ${({ red }) => (red ? colors.red : colors.gray)};
  color: ${({ red }) => (red ? colors.white : colors.glassGray)};
  width: 6rem;
  border-radius: 15px;
`;
