import { Navbar, NotificationDrawer, Page } from 'components';

import { MainLayoutContentWrapper } from './MainLayout.style';

function MainLayout({ children }) {
  return (
    <Page>
      <Navbar />
      <NotificationDrawer />
      <MainLayoutContentWrapper id="mainLayoutWrapper">
        {children}
      </MainLayoutContentWrapper>
    </Page>
  );
}

export default MainLayout;
