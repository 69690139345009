import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import moment from 'moment/moment';
import * as Yup from 'yup';

import Can from 'components/Can';
import { CustomConfirmationModal } from 'components/ConfirmationModal/components/CustomConfirmationModal/CustomConfirmationModal';
import { DatePicker, TimePicker } from 'components/inputs';
import {
  disabledDates,
  disablePastHours,
  getReminderDatetime,
} from 'components/NewUnifiedAttendanceButton/helpers';
import { useRequest } from 'hooks';

import { Input } from '../../../NewUnifiedAttendanceButton/NewUnifiedAttendanceModal.style';
import {
  AntdCol,
  FormButtonContainer,
  StyledFormButton,
} from '../../UnifiedAttendanceDetailModal.style';
import { CanTechniqueEdit } from '../CanTechniqueEdit/CanTechniqueEdit';

export const UnifiedAttendanceReminder = ({ unifiedAttendanceId, ownerId }) => {
  const request = useRequest();
  const [edit, setEdit] = useState(false);
  const UnifiedAttendanceReminderSchema = Yup.object().shape({
    date: Yup.date().required('Campo obrigatório'),
    time: Yup.date().required('Campo obrigatório'),
    reminderDescription: Yup.string().required('Campo obrigatório'),
  });
  const formik = useFormik({
    validationSchema: UnifiedAttendanceReminderSchema,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {},
    onSubmit: async (values) => {
      const body = {
        date: getReminderDatetime(values.date, values.time),
        description: values.reminderDescription,
        unifiedAttendanceId,
        ownerId,
      };

      if (values.id) {
        await request.patch({
          url: `/reminder/${values.id}`,
          body,
          onSuccess: () => {
            setEdit(false);
          },
        });
      } else {
        await request.post({
          url: '/reminder',
          body,
          onSuccess: () => {
            setEdit(false);
          },
        });
      }
    },
  });
  const [unifiedAttendance, setUnifiedAttendance] = useState({});

  useEffect(async () => {
    const response = await request.get({
      url: '/reminder',
      config: { params: { unifiedAttendanceId } },
    });
    const reminderValues = response?.data?.[0];
    if (reminderValues) {
      formik.resetForm({
        values: {
          reminderDescription: reminderValues.description,
          date: moment(reminderValues.date),
          time: moment(reminderValues.date),
          id: reminderValues.id,
        },
      });
      setUnifiedAttendance(reminderValues);
    }
  }, [unifiedAttendanceId]);

  return (
    <form onSubmit={formik.handleSubmit} style={{ marginTop: '3rem' }}>
      <AntdCol
        span={24}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h3>Lembrete</h3>
        {edit && formik.values?.id && (
          <CustomConfirmationModal
            question="Você tem certeza que deseja deletar este lembrete?"
            handleOnConfirm={({ onClose, setLoading }) =>
              async () => {
                if (!formik.values?.id) return;
                try {
                  setLoading(true);
                  await request.delete({
                    url: `/reminder/${formik.values?.id}`,
                  });
                  onClose();
                } catch (err) {
                  console.error(err);
                } finally {
                  setLoading(false);
                }
              }}
          >
            {({ onOpen }) => (
              <StyledFormButton onClick={onOpen} $delete>
                Excluir lembrete
              </StyledFormButton>
            )}
          </CustomConfirmationModal>
        )}
      </AntdCol>
      <AntdCol span={24}>
        <DatePicker
          formik={formik}
          single
          name="date"
          label="Data do lembrete"
          value={formik.values.date}
          error={formik.errors.date}
          schema={UnifiedAttendanceReminderSchema}
          allowClear={false}
          defaultTimezone={false}
          minDate={new Date()}
          startDate={new Date()}
          disabledDates={disabledDates}
          disabled={!edit}
        />
      </AntdCol>
      <AntdCol span={24}>
        <TimePicker
          formik={formik}
          format="HH:mm"
          placeholder="Selecione o horário"
          single
          name="time"
          label="Horário do lembrete"
          value={formik.values.time}
          error={formik.errors.time}
          schema={UnifiedAttendanceReminderSchema}
          allowClear={false}
          disabledTime={disablePastHours(formik.values.date)}
          disabled={!edit}
        />
      </AntdCol>
      <AntdCol span={24}>
        <Input
          label="Escreva a descrição do lembrete"
          name="reminderDescription"
          width="100%"
          absoluteError
          error={formik.errors.reminderDescription}
          value={formik.values.reminderDescription}
          onChange={
            (e) => formik.setFieldValue('reminderDescription', e.target.value)
            // eslint-disable-next-line react/jsx-curly-newline
          }
          onBlur={formik.handleBlur}
          schema={UnifiedAttendanceReminderSchema}
          disabled={!edit}
        />

        {!edit && (
          <CanTechniqueEdit unifiedAttendance={unifiedAttendance}>
            <Can perform={['appointments:update', 'appointments.self:update']}>
              <FormButtonContainer>
                <StyledFormButton onClick={() => setEdit(true)} $cancel>
                  Editar
                </StyledFormButton>
              </FormButtonContainer>
            </Can>
          </CanTechniqueEdit>
        )}
        {edit && (
          <FormButtonContainer>
            <StyledFormButton
              onClick={() => {
                setEdit(false);
              }}
              $cancel
            >
              Cancelar
            </StyledFormButton>
            <StyledFormButton
              loading={formik.isSubmitting}
              onClick={() => console.log(formik.errors)}
              htmlType="submit"
            >
              Salvar
            </StyledFormButton>
          </FormButtonContainer>
        )}
      </AntdCol>
    </form>
  );
};
