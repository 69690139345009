import { css } from 'styled-components';

import colors from 'styles/colors';

export const positionCss = css`
  ${({ $position }) => {
    if ($position) {
      const { top, right, bottom, left } = $position;

      return css`
        position: fixed;
        top: ${top || 'unset'};
        right: ${right || 'unset'};
        bottom: ${bottom || 'unset'};
        left: ${left || 'unset'};
      `;
    }
    return '';
  }}
`;

export const sizeCss = css`
  ${({ $size }) => {
    if ($size) {
      const { width, height } = $size;

      return css`
        width: ${width || 'unset'};
        height: ${height || 'unset'};
      `;
    }
    return '';
  }}
`;

export const colorCss = css`
  path {
    stroke: ${({ $color }) => colors[$color] || $color || 'white'};
  }
`;
