import { ScheduleCard } from 'components';

import { List } from './ScheduleList.style';

function ScheduleList() {
  const data = [
    {
      title: 'Dúvida sobre documentação do auxílio aluguel',
      link: 'https://meet.google.com/isz-anfg-oir',
      synthesis:
        'Texto descrevendo brevemente a síntese do caso, deixando claro o motivo do plantão social para que as técnicas tenham a noção',
      date: 'Amanhã, 28 de Agosto de 2020',
    },
    {
      title: 'Dúvida sobre documentação do auxílio aluguel',
      link: 'https://meet.google.com/isz-asag-oir',
      synthesis:
        'Texto descrevendo brevemente a síntese do caso, deixando claro o motivo do plantão social para que as técnicas tenham a noção',
      date: 'Amanhã, 28 de Agosto de 2020',
    },
    {
      title: 'Dúvida sobre documentação do auxílio aluguel',
      link: 'https://meet.google.com/isz-adfg-oir',
      synthesis:
        'Texto descrevendo brevemente a síntese do caso, deixando claro o motivo do plantão social para que as técnicas tenham a noção',
      date: 'Amanhã, 28 de Agosto de 2020',
    },
  ];

  return (
    <List>
      {data.map((props) => (
        <ScheduleCard key={props.link} {...props} />
      ))}
    </List>
  );
}

export default ScheduleList;
