import moment from 'moment';

import arrow from 'assets/icons/right-arrow.svg';

import { Arrow, StyledLink } from './NotificationDrawer.style';

const contentMap = {
  reminder: ({ metadata }) => ({
    text: `Lembrete de chamado: ${metadata.description}`,
    callToAction: (
      <StyledLink to={`/chamados/${metadata.callId}`}>
        Ir para chamado
        <Arrow src={arrow} />
      </StyledLink>
    ),
  }),
  'new-open-call': ({ metadata }) => ({
    text: 'Chamado aberto agora, responda a ele imediatamente',
    callToAction: (
      <StyledLink to={`/chamados/${metadata.callId}`}>
        Ir para chamado
        <Arrow src={arrow} />
      </StyledLink>
    ),
  }),
  'added-to-call': ({ metadata }) => ({
    text: `${metadata.user.name} adicionou você a um chamado`,
    callToAction: (
      <StyledLink to={`/chamados/${metadata.callId}`}>
        Ir para chamado
        <Arrow src={arrow} />
      </StyledLink>
    ),
  }),
  'new-message': ({ metadata }) => ({
    text: `${metadata.requester.name} falou ${metadata.message.content}`,
    callToAction: (
      <StyledLink to={`/chamados/${metadata.callId}`}>
        Ir para chamado
        <Arrow src={arrow} />
      </StyledLink>
    ),
  }),
  'removed-from-call': ({ metadata }) => ({
    text: `Você foi desalocada do chamado ${metadata?.call?.protocolNumber}`,
    callToAction: null,
  }),
  'call-ended': ({ metadata }) => ({
    text: `O chamado ${metadata?.call?.protocolNumber} foi encerrado`,
    callToAction: null,
  }),
  'responsible-call': ({ metadata }) => ({
    text: `Você se tornou responsável pelo chamado ${metadata?.call?.protocolNumber}`,
    callToAction: (
      <StyledLink to={`/chamados/${metadata?.call?.id}`}>
        Ir para chamado
        <Arrow src={arrow} />
      </StyledLink>
    ),
  }),
  'new-attendance': ({ metadata }) => ({
    text: `${metadata.user.name} criou um plantão social para você`,
    callToAction: (
      <StyledLink to={`/plantao-social/${metadata.attendanceId}`}>
        Ir para plantão social
        <Arrow src={arrow} />
      </StyledLink>
    ),
  }),
  'attendance-canceled': ({ metadata }) => ({
    text: `O seu plantão social no dia ${moment(metadata.attendance.startDate)
      .locale('pt-br')
      .format('DD [de] MMMM [às] HH:mm')} foi cancelado`,
  }),
};

export default contentMap;
