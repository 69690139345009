import { createContext, useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

import useRequest from 'hooks/use-request';
import { USER_INFO } from 'variables';

const UserContext = createContext({
  userData: {},
  updateUserInfo: {},
  loading: false,
});

function UserProvider({ children }) {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [cookies, setCookie] = useCookies([USER_INFO]);
  const request = useRequest();

  const updateUserInfo = (userInfo = { ...userData }) => {
    const fourteenDaysMs = Date.now() + 1000 * 60 * 60 * 24 * 14;
    const fourteenDays = new Date(fourteenDaysMs);
    setCookie(USER_INFO, userInfo, { expires: fourteenDays, path: '/' });
  };

  useEffect(() => {
    const userInfo = cookies[USER_INFO];
    if (userInfo?.id) {
      request.get({
        url: `/user/${userInfo?.id}`,
        onSuccess: (res) => {
          setUserData(res[0]);
          setLoading(false);
        },
      });
    }
  }, [cookies[USER_INFO]]);

  return (
    <UserContext.Provider value={{ userData, loading, updateUserInfo }}>
      {children}
    </UserContext.Provider>
  );
}

export const useUserInfo = () => useContext(UserContext);

export default UserProvider;
