import { useEffect, useRef, useState } from 'react';

import blockSvg from 'assets/icons/block.svg';
import editSvg from 'assets/icons/edit-darker.svg';
import trashSvg from 'assets/icons/trash.svg';
import { Can } from 'components';
import { useModal } from 'contexts/ModalProvider';

import {
  ModalClose,
  ModalCloseContainer,
  ModalOptions,
  OptionsIcon,
  OptionsItem,
} from './CreateAreaModal.style';

function CloseButton({ name, type, id, remove, handleEdit }) {
  const [showOptions, setShowOptions] = useState(false);
  const optionsRef = useRef(null);
  const optionsModalRef = useRef(null);
  const { modal, closeModal } = useModal();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (optionsRef.current) {
        if (!optionsRef.current.contains(event.target)) {
          setShowOptions(false);
        }
      }
    };

    window.addEventListener('click', handleOutsideClick);
    return () => window.addEventListener('click', handleOutsideClick);
  }, []);

  const handleDeleteItem = () => {
    const modalParams = {
      title: `Deseja deletar "${name}"?`,
      okText: 'Deletar',
      onOk: () => {
        remove(id);
        closeModal();
      },
      footerButtonColor: 'rgba(237, 104, 87, 1)',
    };

    modal(
      () =>
        `Tem certeza que você deseja deletar ${
          type === 'area' ? 'a área' : 'o empreendimento'
        } ${name}?`,
      modalParams
    );
  };

  const handleDeactivateItem = () => {
    const modalParams = {
      title: `Deseja desativar "${name}"?`,
      okText: 'Desativar',
      onOk: () => {},
    };

    modal(
      () =>
        `Tem certeza que você deseja desativar ${
          type === 'area' ? 'a área' : 'o empreendimento'
        } ${name}?`,
      modalParams
    );
  };

  const handleClick = (handler, disabled) => () => {
    if (!disabled) {
      setShowOptions(false);
      handler();
    }
  };

  return (
    <Can perform={['areas:update', 'areas:delete']}>
      <ModalCloseContainer
        onClick={(e) => {
          e.stopPropagation();
          if (
            optionsRef.current.contains(e.target) &&
            !optionsModalRef.current.contains(e.target)
          ) {
            setShowOptions(true);
          }
        }}
        ref={optionsRef}
      >
        <ModalClose />
        <ModalOptions ref={optionsModalRef} active={showOptions}>
          <Can perform={['areas:update']}>
            <OptionsItem onClick={handleClick(handleEdit)}>
              <OptionsIcon src={editSvg} alt="Ícone de lápis" />
              Editar
            </OptionsItem>
          </Can>
          <Can perform={['areas:delete']}>
            <OptionsItem
              $disabled
              onClick={handleClick(handleDeactivateItem, true)}
            >
              <OptionsIcon src={blockSvg} alt="Ícone de desativar" />
              Desativar
            </OptionsItem>
            <OptionsItem $delete onClick={handleClick(handleDeleteItem)}>
              <OptionsIcon src={trashSvg} alt="Ícone de lixeira" />
              Deletar
            </OptionsItem>
          </Can>
        </ModalOptions>
      </ModalCloseContainer>
    </Can>
  );
}

export default CloseButton;
