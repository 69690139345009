import { Tabs } from 'antd';

import { EnterpriseList } from 'components';

import { Container, Title } from './AreaDetail.style';

function AreaDetail() {
  return (
    <Container>
      <Title>Regional centro detalhamento</Title>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Empreendimentos" key="1">
          <EnterpriseList />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Lista de moradores" key="2">
          Resident.List!
        </Tabs.TabPane>
        <Tabs.TabPane tab="Lista de técnicas(os)" key="3">
          Technician.List!
        </Tabs.TabPane>
      </Tabs>
    </Container>
  );
}

export default AreaDetail;
