import { Link } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';

import mic from 'assets/icons/mic.svg';
import bubbles from 'assets/images/bubbles.png';
import colors from 'styles/colors';
import { respondTo, respondToMin } from 'styles/mixins';

const heightReduceDelay = keyframes`
  0% {
    height: auto;
  }
  100% {
    height: 0;
  }
`;

const hideTextDelay = keyframes`
  0% {
    display: block;
  }
  99% {
    display: none;
  }
  100% {
    display: none;
  }
`;

export const StyledNavbar = styled.nav`
  height: 4.38rem;
  width: 100vw;
  position: fixed;
  bottom: 0;
  border-radius: 4.17vw 4.17vw 0 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0 0 8.33vw ${colors.lessOpacityText};
  background-color: ${colors.white};
  z-index: 10;

  ${respondToMin('medium')(css`
    position: relative;
    height: 100%;
    overflow: hidden;
    width: 14%;
    max-width: 13rem;
    border-radius: 0;
    padding: 1vw 0 0 0;
    font-size: 1.13rem;
    flex-direction: column;
    justify-content: flex-start;
    box-shadow: 0 0 0.31vw ${colors.lessOpacityText};
    transition: width 0.75s ease-in-out;

    ${({ collapseNavbar }) => {
      if (!collapseNavbar)
        return css`
          width: 7%;

          & .disappearOnCollapse {
            visibility: hidden;
            opacity: 0;
            height: 0;
            min-height: 0;
            animation: ${heightReduceDelay} 0.75s linear;

            & > p {
              white-space: nowrap;
            }
          }

          & .parcialHideOnCollapse > img {
            ${({ width, height }) => {
              if (width)
                return css`
                  width: ${(width * 2) / 16}rem;
                  height: ${(height * 2) / 16}rem;
                `;
              return ``;
            }}
            right: 0;
          }

          & .hideTextOnCollapse > li {
            border-radius: 4px;
            margin: 0.5rem auto;
            min-height: 3rem;
            padding: 0;
            justify-content: center;
            width: 80%;
            max-width: 3.5rem;

            & > a {
              justify-content: center;
            }

            & p {
              display: none;
              opacity: 0;
              animation: ${hideTextDelay} 0.75s linear;
            }
          }

          & .hideTextPartOnCollapse {
            flex-direction: column;
            align-items: center;

            & > span {
              margin: 0.5rem 0 0 0;
            }
          }
        `;
      return ``;
    }}
  `)};
`;

export const VoiceButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -6rem -10vw 0;

  ${respondToMin('medium')(css`
    display: none;
  `)}
`;

export const ButtonText = styled.div`
  font-weight: 500;
  font-size: 0.88rem;
  position: absolute;
  margin: 0 0 0 55vw;
  padding: 0 0 0 10vw;
  width: 50%;
  background-color: transparent;
  z-index: -2;
`;

export const MicIcon = styled.button`
  background-image: url(${mic});
  background-size: contain;
  background-color: transparent;
  background-repeat: no-repeat;
  width: 4.32rem;
  height: 4.32rem;
  z-index: -1;
  border: none;
  &:focus {
    outline: none;
  }
`;

export const NavigationText = styled.p`
  margin: 0;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;

  ${respondTo('medium')(css`
    text-align: center;
  `)}
`;

export const NavigationContainer = styled.ul`
  display: flex;
  flex-direction: row;
  margin: 0;
  width: 100%;

  ${respondToMin('medium')(css`
    flex-direction: column;
  `)}
`;

export const NavigationItem = styled.li`
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
  width: 100%;
  display: ${(props) => (props.exit ? 'none' : 'flex')};

  img {
    width: 7.05vw;
  }

  path {
    ${({ setActive }) =>
      setActive &&
      css`
        fill: white;

        ${respondTo('medium')(css`
          fill: ${colors.orange};
        `)}
      `}
  }
  ${respondToMin('medium')(css`
    justify-content: left;
    flex-direction: row;
    gap: 1vw;
    padding: 0 1.5vw;
    width: 100%;
    margin: 0.2rem 0;
    height: 2.92vw;
    border-radius: 0 2.08vw 2.08vw 0;
    display: flex;
    font-size: 0.81rem;
    cursor: pointer;

    & p {
      transition: opacity 0.75s linear;
    }
    ${({ setActive }) =>
      setActive &&
      css`
        background-color: ${colors.persianGreen};
        border-radius: 0 2.08vw 2.08vw 0;
        color: white;
        font-weight: 600;
      `}

    img {
      width: 1.53vw;
    }
  `)};

  ${respondToMin('big')(css`
    font-size: 1rem;
  `)}

  ${respondToMin('large')(css`
    font-size: 1.13rem;
  `)}
`;

export const StyledLink = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
  width: 100%;
  color: inherit;
  transition: none;
  padding: 0;
  margin: 0;

  &:hover {
    color: inherit;
  }

  ${respondToMin('medium')(css`
    justify-content: left;
    flex-direction: row;
    gap: 1rem;
    width: 100%;
  `)};
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  background-image: url(${bubbles});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 2rem;
  ${respondToMin('medium')(css`
    display: flex;
    opacity: 1;
    transition: height 1s linear, visibility 0.75s linear, opacity 0.375s linear;
  `)}
`;

export const Text = styled.p`
  padding: 2vw 0;
  margin: 0;
  display: none;
  font-size: 0.81rem;
  font-weight: 500;

  ${respondToMin('medium')(css`
    display: block;
    font-size: 0.81rem;
  `)}

  ${respondToMin('big')(css`
    font-size: 1rem;
  `)}

  ${respondToMin('large')(css`
    font-size: 1.13rem;
  `)}
`;

export const CollapseMenuButtonContainer = styled.div`
  width: 100%;
  display: none;
  justify-content: center;
  display: none;

  & > button {
    cursor: pointer;
  }

  ${respondToMin('medium')(css`
    display: flex;
  `)}
`;

export const BottomAssetContainer = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  margin-top: auto;
  width: 100%;
  overflow: hidden;
  min-height: 12rem;

  ${respondToMin('medium')(css`
    display: flex;
  `)}
`;

export const BottomAsset = styled.img`
  ${({ width, height }) => {
    if (width)
      return css`
        width: ${width}px;
        height: ${height}px;
      `;
    return css`
      width: 100%;
      height: auto;
    `;
  }}
  position: absolute;
  right: 0;
`;

export const CollapseButton = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  width: 80%;
  display: flex;
  justify-content: center;

  & > span {
    font-size: 1rem;
    font-weight: 600;
    margin-left: 1rem;
    color: ${colors.black};
  }
`;
