import { useEffect, useState } from 'react';

function useTouched(formikValues, initialValues) {
  const [touched, setTouched] = useState(false);
  useEffect(() => {
    setTouched(formikValues !== initialValues);
  }, [formikValues]);

  return touched;
}

export default useTouched;
