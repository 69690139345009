import { Modal } from 'antd';

import Check from 'assets/icons/check.svg';
import { Button } from 'components/inputs';
import history from 'helpers/history';

import {
  ButtonWrapper,
  Container,
  Title,
  Wrapper,
} from './NotificationModal.style';

function NotificationModal({
  visible,
  setVisible,
  titleText,
  text,
  buttonText,
  redirect,
}) {
  const redirectTo = () => {
    history.push(redirect);
    setVisible(false);
  };
  return (
    <Modal
      onCancel={() => setVisible(false)}
      destroyOnClose
      visible={visible}
      onOk={redirectTo}
      footer={null}
      closeIcon={null}
    >
      <Container>
        <Title>{titleText}</Title>
        <Wrapper>
          <img src={Check} alt="check-icon" />
        </Wrapper>
        <Title>{text}</Title>
        <ButtonWrapper>
          <Button onClick={redirectTo}>{buttonText}</Button>
        </ButtonWrapper>
      </Container>
    </Modal>
  );
}

export default NotificationModal;
