import { useState } from 'react';

import {
  Badge,
  ConfirmPasswordModal,
  DownloadFile,
  FullscreenLayout,
  NotificationModal,
  ProfileForm,
  Tabs,
} from 'components';
import useMobile from 'hooks/use-mobile';

import {
  Button,
  ButtonWrapper,
  Documents,
  DownloadsContainer,
  ProfileHeader,
  Title,
} from './ProfileInfo.style';

function ProfileInfo() {
  const [passwordModal, setPasswordModal] = useState(false);
  const [validated, setValidated] = useState(false);
  const [notificationModal, setNotificationModal] = useState(false);
  const isMobile = useMobile();

  const handleEdit = () => {
    // inputs validation
    setValidated(false);
    setNotificationModal(true);
  };

  return (
    <FullscreenLayout>
      <NotificationModal
        titleText="Dados atualizados com sucesso!"
        buttonText="Ok!"
        visible={notificationModal}
        setVisible={setNotificationModal}
      />
      <ConfirmPasswordModal
        visible={passwordModal}
        setVisible={setPasswordModal}
        onOk={setValidated}
      />
      {isMobile ? (
        <>
          <Title>Olá, Maria!</Title>
          <Badge>Em auxílio aluguel</Badge>
          {validated ? (
            <ButtonWrapper>
              <Button onClick={() => handleEdit()}>Salvar</Button>
              <Button color="gray" onClick={() => setValidated(false)}>
                Cancelar
              </Button>
            </ButtonWrapper>
          ) : (
            <Button onClick={() => setPasswordModal(true)}>
              Atualizar meus dados
            </Button>
          )}
          <ProfileForm edit={validated} />
          <Documents>
            Documentos anexados
            <DownloadsContainer>
              <DownloadFile />
            </DownloadsContainer>
          </Documents>
          {validated && (
            <ButtonWrapper>
              <Button onClick={() => handleEdit()}>Salvar</Button>
              <Button color="gray" onClick={() => setValidated(false)}>
                Cancelar
              </Button>
            </ButtonWrapper>
          )}
        </>
      ) : (
        <>
          <ProfileHeader>
            <Title>
              Camila Santos
              <Badge>Em locação social</Badge>
            </Title>
            {validated ? (
              <>
                <Button onClick={() => handleEdit()}>Salvar</Button>
                <Button color="gray" onClick={() => setValidated(false)}>
                  Cancelar
                </Button>
              </>
            ) : (
              <Button onClick={() => setPasswordModal(true)}>
                Atualizar meus dados
              </Button>
            )}
          </ProfileHeader>
          <Tabs>
            <Tabs.Tab tab="Dados gerais" key="1" span={12}>
              <ProfileForm edit={validated} />
            </Tabs.Tab>
            <Tabs.Tab tab="Documentos anexados" key="2" span={12}>
              <DownloadsContainer>
                <DownloadFile />
              </DownloadsContainer>
            </Tabs.Tab>
          </Tabs>
        </>
      )}
    </FullscreenLayout>
  );
}

export default ProfileInfo;
