export default [
  {
    title: 'Dados do Responsável',
    inputs: [
      {
        type: 'text',
        label: 'Nome completo',
        name: 'name',
        rules: ['required'],
      },
      {
        type: 'text',
        label: 'RG',
        name: 'rg',
        rules: ['number'],
      },
      {
        type: 'text',
        label: 'CPF',
        name: 'cpf',
        rules: ['required', 'cpf'],
        mask: 'cpf',
      },
      {
        type: 'text',
        label: 'Telefone',
        name: 'phone',
        rules: ['phone', 'required'],
        mask: 'phone',
      },
      {
        type: 'text',
        label: 'Whatsapp',
        name: 'phoneWhatsapp',
        rules: ['phone'],
        mask: 'phone',
      },
      {
        type: 'email',
        label: 'E-mail',
        name: 'email',
        rules: ['email'],
      },
      {
        type: 'select',
        label: 'Tipo de solicitante',
        name: 'typeId',
        rules: ['required'],
        options: '/type-requester',
      },
      {
          type: 'text', 
          label: 'Nome do contato',
          name: 'contactName',
          rules: [],
      },
      {
        type: 'text',
        label: 'Telefone do contato',
        name: 'contactPhone',
        rules: ['phone'],
        mask: 'phone',
      },
      {
        type: 'select',
        label: 'Status',
        name: 'requesterStatusId',
        rules: ['required'],
        span: 6,
        options: '/requester-status',
      },
    ],
  },
  {
    title: 'Dados do Imóvel',
    inputs: [
      {
        type: 'select',
        label: 'Regional',
        name: 'regionId',
        rules: ['required'],
        options: '/region',
      },
      {
        type: 'select',
        label: 'Área / empreendimento',
        name: 'areaId',
        rules: ['required'],
        filter: (items, context) => {
          if (context.regionId) {
            return items.filter(({ regionId }) => context.regionId === regionId);
          }
          return items;
        },
        options: '/area',
      },
      {
        type: 'text',
        label: 'Lote',
        name: 'batch',
        rules: [],
      },
      {
        type: 'text',
        label: 'Setor',
        name: 'sector',
        rules: [],
      },
      {
        type: 'text',
        label: 'Selo',
        name: 'stamp',
        rules: [],
      },
      {
        type: 'text',
        label: 'SEI',
        name: 'sei',
        span: 6,
        rules: [],
      },
      {
        type: 'text',
        label: 'Endereço',
        name: 'address',
        rules: [],
      },
      {
        type: 'text',
        label: 'Bairro',
        name: 'neighborhood',
        rules: [],
      },
      {
        type: 'text',
        label: 'Cidade',
        name: 'city',
        rules: [],
      },
    ],
  },
  {
    title: 'Dados do segundo titular',
    inputs: [
      {
        type: 'text',
        label: 'Nome completo do segundo titular',
        name: 'relativeName',
        rules: [],
      },
      {
        type: 'text',
        label: 'RG',
        name: 'relativeRg',
        rules: ['number'],
      },
      {
        type: 'text',
        label: 'CPF',
        name: 'relativeCpf',
        rules: ['cpf'],
        mask: 'cpf',
      },
      {
        type: 'textarea',
        label: 'Observações',
        name: 'relativeObservation',
        rules: [],
        placeholder: 'Observação sobre o munícipe sendo cadastrado na plataforma. Aqui deve conter informações que são relevantes para o conhecimento de todo o time.'
      },
      {
          type: 'text',
          label: 'Telefone',
          name: 'relativePhone',
          span: 6,
          rules: ['phone'],
          mask: 'phone',
      },
      {
          type: 'text',
          label: 'Endereço atual',
          name: 'relativeAddress',
          rules: [],
          span: 12,
      },
    ],
  },
];
