import downloadIcon from 'assets/icons/download-button.svg';

import {
  Background,
  Icon,
  SecondButton,
  Text,
} from '../../DownloadButton.style';

export const CustomDownloadButton = (props) => {
  const { onClick, extension, withoutDot, bottom } = props;
  return (
    <>
      <SecondButton
        onClick={onClick}
        bottom={bottom}
        style={{ right: '4rem !important' }}
      >
        <Text>{`Download ${!withoutDot ? '.' : ''}${extension}`}</Text>
        <Icon
          src={downloadIcon}
          alt="download"
          style={{ width: '2rem', height: '2rem' }}
        />
        <Background style={{ width: '1.5rem', height: '1.5rem' }} />
      </SecondButton>
    </>
  );
};
