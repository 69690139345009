import { useState } from 'react';
import { Radio } from 'antd';

import alertSvg from 'assets/icons/alert.svg';
import balloonSvg from 'assets/icons/balloon.svg';
import commentSvg from 'assets/icons/comment.svg';
import microphoneSvg from 'assets/icons/microphone.svg';
import { Calendar, NotificationModal } from 'components';
import { Button, Select, TextInput } from 'components/inputs';

import {
  Audio,
  AudioText,
  ButtonWrapper,
  Card,
  Container,
  Content,
  Data,
  Image,
  Modal,
  Options,
  Text,
  Title,
  Warning,
  Wrapper,
} from './ScheduleModal.style';

function ScheduleModal({ visible, setVisible, call = false }) {
  const [notification, setNotification] = useState(false);
  const [radio, setRadio] = useState(2);
  const [time, setTime] = useState();

  const handleSubmit = () => {
    setNotification(true);
    setVisible(false);
  };

  return (
    <>
      <Modal
        width="fit-content"
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={false}
      >
        <Container>
          <Title>{call ? 'Abrir Chamado' : 'Agendar Conversa'}</Title>
          <Audio>
            <Image src={microphoneSvg} alt="check-icon" width={50} />
            <AudioText>Pressione o botão para usar o modo “áudio”</AudioText>
          </Audio>
          {call && (
            <Select
              placeholder="Qual o motivo do chamado?"
              style={{ marginBottom: 30 }}
              icon={balloonSvg}
            />
          )}
          <TextInput
            name="synthesis"
            type="text"
            placeholder={
              call
                ? 'Explique a situação para que as técnicas possam te ajudar melhor'
                : 'Descreva o motivo do seu agendamento'
            }
            icon={commentSvg}
            style={{ marginBottom: 40 }}
          />
          {call ? (
            <>
              <Text>Deseja agendar chamada online com técnica social?</Text>
              <Radio.Group
                onChange={(e) => setRadio(e.target.value)}
                value={radio}
              >
                <Radio value={1}>Sim</Radio>
                <Radio value={2}>Não</Radio>
              </Radio.Group>
            </>
          ) : (
            <Title style={{ fontSize: '1.125rem', marginBottom: 0 }}>
              Selecione uma data para a conversa!
            </Title>
          )}
          {(radio === 1 || !call) && (
            <>
              <Content>
                <Data>
                  <Wrapper>
                    <Warning>
                      <img
                        src={alertSvg}
                        alt="check-icon"
                        style={{ marginRight: 10 }}
                      />
                      As datas em cinza estão indisponíveis
                    </Warning>
                    <Calendar />
                  </Wrapper>
                </Data>
                <Options>
                  <Text style={{ marginBottom: 40 }}>
                    Selecione o melhor horário para você
                  </Text>
                  <Card onClick={() => setTime(0)} isSelected={time === 0}>
                    8:00h
                  </Card>
                  <Card onClick={() => setTime(1)} isSelected={time === 1}>
                    10:00h
                  </Card>
                  <Card onClick={() => setTime(2)} isSelected={time === 2}>
                    12:00h
                  </Card>
                </Options>
              </Content>
              <ButtonWrapper>
                <Button onClick={() => handleSubmit()}>Finalizar</Button>
              </ButtonWrapper>
            </>
          )}
        </Container>
      </Modal>
      {call ? (
        <NotificationModal
          titleText="Chamado realizado com sucesso!"
          text="Você pode encontrar seu chamado no botão “Chamados”"
          buttonText="Ok!"
          setVisible={setNotification}
          visible={notification}
        />
      ) : (
        <NotificationModal
          titleText="Agendamento realizado com sucesso!"
          buttonText="Ok!"
          setVisible={setNotification}
          visible={notification}
        />
      )}
    </>
  );
}

export default ScheduleModal;
