import { useMemo, useState } from 'react';

export const LocalFilterWrapper = ({
  children,
  data = [],
  filterFn = () => () => {},
  defaultValue,
}) => {
  const [selected, setSelected] = useState(defaultValue);

  const filteredData = useMemo(() => {
    if (!data) return [];
    if (!filterFn) return data;

    return data.filter(filterFn(selected));
  }, [data, filterFn, selected]);

  const filterProps = {
    filteredData,
    selected,
    setSelected,
  };
  return <>{children(filterProps)}</>;
};
