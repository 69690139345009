const removeDuplicateObjectsByProperty = (array, targetProperty) => {
  return array.filter((object, objectIndex) => {
    const targetValue = object[targetProperty];

    const duplicates = array
      .map((eachArray, index) => ({ ...eachArray, itemIndex: index }))
      .filter((eachArray) => eachArray[targetProperty] === targetValue);

    if (duplicates.length <= 1) return true;

    const duplicatesIndexes = duplicates.map(({ itemIndex }) => itemIndex);

    return objectIndex === Math.max(...duplicatesIndexes);
  });
};

export default removeDuplicateObjectsByProperty;
