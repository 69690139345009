import styled, { css } from 'styled-components';

import { respondTo } from 'styles/mixins';

import TextInput from './TextInput';

const FormInput = styled(TextInput)`
  margin-bottom: 1.5rem;
  max-width: 25.375rem;

  ${respondTo('medium')(css`
    margin-bottom: 1.125rem;
  `)}

  label {
    font-size: 1rem;
    color: #585858;
    letter-spacing: 0.02rem;

    ${respondTo('medium')(css`
      font-size: 0.875rem;
    `)}
  }

  .ant-input-affix-wrapper {
    margin-top: 1.375rem;

    ${respondTo('medium')(css`
      margin-top: 0.875rem;
    `)}

    .ant-input-prefix {
      display: ${({ $showIcon }) => !$showIcon && 'none'};

      img {
        width: 0.8125rem;
        margin-bottom: 0rem;
        margin-right: 1rem;
      }
    }

    .ant-input {
      font-size: 1.125rem;
      color: #585858;
      font-weight: 600;

      ${respondTo('medium')(css`
        font-size: 0.875rem;
      `)}
    }

    .ant-input::placeholder {
      color: #585858 !important;
      opacity: 1 !important;

      ${respondTo('medium')(css`
        font-size: 0.875rem;
      `)}
    }
  }

  > p {
    bottom: 0;
  }
`;

export default FormInput;
