import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import queryString from 'query-string';

import { Filter as FilterIcon } from 'components/icons/noise';
import colors from 'styles/colors';
import { USER_INFO } from 'variables';

import {
  Divider,
  FilterItemContainer,
  FiltersSection,
  HeaderContainer,
  StyledDrawer,
} from './Filter.style';
import FilterItemButton from './FilterItemButton';
import FilterTagsContainer from './FilterTagsContainer';
import { calculateNewSearchParameter } from './helpers';

function Filter({ isDrawerOpen, filters, ...props }) {
  const [activeFilters, setActiveFilters] = useState({});
  const [initialParameterValues, setInitialParameterValues] = useState({});
  const history = useHistory();

  const resolveParameterChanges = (
    filterName,
    options,
    tagTitle = '',
    action = 'update'
  ) => {
    const { location } = history;
    let searchParam = '';
    if (action === 'update') {
      searchParam = calculateNewSearchParameter(location, filterName, options);
      setActiveFilters((prevFilter) => ({
        ...prevFilter,
        [filterName]: {
          options,
          title: tagTitle,
        },
      }));
    }
    history.push({
      pathname: history.location.pathname,
      search: searchParam,
    });
  };

  useEffect(() => {
    const { search } = history.location;
    const filterObj = queryString.parse(search, { arrayFormat: 'comma' });
    setInitialParameterValues(filterObj);

    const userCookies = Cookies.get(USER_INFO);
    const parsedUserData = userCookies && JSON.parse(userCookies);

    if (parsedUserData?.region && parsedUserData?.role !== 'attendant') {
      setActiveFilters((prevFilter) => ({
        ...prevFilter,
        regionsIds: {
          options: [
            {
              value: parsedUserData?.region?.id,
              label: parsedUserData?.region?.name,
            },
          ],
          title: 'Regional',
        },
      }));
    }
  }, []);

  return (
    <StyledDrawer visible={isDrawerOpen} {...props}>
      <HeaderContainer>
        <FilterIcon />
        <h1>Filtros</h1>
      </HeaderContainer>
      <FilterTagsContainer
        resolveParameterChanges={resolveParameterChanges}
        activeFilters={activeFilters}
        filters={filters}
      />
      {filters.map((filter) => (
        <FiltersSection key={filter.sectionTitle}>
          <h1>
            Filtros de
            {` ${filter.sectionTitle}`}
          </h1>
          <Divider />
          {filter.filterTypes.map((type) => (
            <FilterItemContainer key={type.name}>
              <h1>
                Filtrar por
                {` ${type.title}`}
              </h1>
              <Divider marginBottom="1rem" color={colors.black} />
              <FilterItemButton
                resolveParameterChanges={resolveParameterChanges}
                activeFilters={activeFilters[type.name]}
                itemProperties={type}
                initialParameterValues={initialParameterValues}
              />
            </FilterItemContainer>
          ))}
        </FiltersSection>
      ))}
    </StyledDrawer>
  );
}

export default Filter;
