import { useIsTechniqueAbleToEdit } from 'components/UnifiedAttendanceDetailButton/hooks/useIsTechniqueAbleToEdit';

export const CanTechniqueEdit = ({ unifiedAttendance, children }) => {
  const { isUserTechnique, isTechniqueAbleToEdit } =
    useIsTechniqueAbleToEdit(unifiedAttendance);

  if (!isUserTechnique || isTechniqueAbleToEdit) {
    return <>{children}</>;
  }

  return null;
};
