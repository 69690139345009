import * as Yup from 'yup';
import { hasArea, hasRegion } from './helpers';

export const CreateUserSchema = Yup.object().shape({
  name: Yup.string().required('Este campo é obrigatório'),
  regionId: Yup.string(),
  role: Yup.string().required('Este campo é obrigatório'),
  typeOperatorId: Yup.string(),
  email: Yup.string()
    .email('Email inválido')
    .required('Este campo é obrigatório'),
});

export const validate = (values) => {
  const errors = {};

  if (values.role === 'technique' && !values.typeOperatorId) {
    errors.typeOperatorId = 'Este campo é obrigatorio';
  }

  if (
    !values.regionId &&
    hasRegion(values.role)
  ) {
    errors.regionId = 'Este campo é obrigatório';
  }

  if (
    values.areasIds.length === 0 &&
    hasArea(values.role)
  ) {
    errors.areasIds = 'Este campo é obrigatório';
  }

  return errors;
};
