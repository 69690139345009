import { useEffect, useState } from 'react';

import { BaseTable } from 'components';

function Operator({ data: requestData }) {
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    const data = requestData?.tableOperators;
    if (data) {
      const tableData = data.map((item, i) => ({
        ...item,
        key: i.toString(),
      }));

      setDataSource(tableData);
    }
  }, [requestData]);

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Categorias profissionais',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Atendimentos em aberto',
      dataIndex: 'openUnifiedAttendancesCount',
      key: 'open-unified-attendances-count',
    },
    {
      title: 'Total',
      dataIndex: 'amount',
      key: 'open-unified-attendances-total',
    },
    {
      title: 'Regional',
      dataIndex: 'region',
      key: 'region',
    },
  ];

  return (
    <BaseTable title="Operador" dataSource={dataSource} columns={columns} />
  );
}

export default Operator;
