import { AutoComplete } from 'antd';
import styled, { css } from 'styled-components';

import colors from 'styles/colors';

export const AutoCompleteSearcher = styled(AutoComplete)`
  ${({ width }) => {
    if (width)
      return css`
        width: ${width};
      `;
    return css`
      width: 100%;
    `;
  }}
  margin-bottom: 1.5rem;
  margin-top: 1rem;

  & [ant-click-animating-without-extra-node]:after {
    animation: 0s !important;
  }

  & .ant-select-dropdown {
    display: none !important;
  }

  & input {
    height: 2.5rem;
    border-radius: 5px;
    font-size: 0.8rem;
    color: ${colors.smoothBlack} !important;

    &::placeholder {
      color: ${colors.smoothBlack} !important;
      opacity: 1;
    }

    &.ant-input:focus {
    }
  }

  & button {
    border: none !important;
    border-radius: 5px;

    &.ant-btn-lg {
      border: none;
    }

    &.ant-btn:active > span {
      position: absolute;
    }
  }
`;

export const FloatingAutocompleteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  position: relative;
`;

export const FloatingLabelSearcher = styled(AutoCompleteSearcher)`
  margin-bottom: ${({ absoluteError }) => (absoluteError ? '2rem' : '0')};

  & .ant-input {
    border: 1px solid ${colors.persianGreen} !important;
    border-radius: 10px;
    border-right: none !important;
    height: 2.63rem;
    outline: none !important;

    &:focus {
      box-shadow: none !important;
    }
  }

  .certain-category-search-dropdown {
    color: blue !important;
  }

  & .ant-input-disabled {
    background-color: transparent !important;
  }

  & .ant-input-focused {
    display: none !important;
  }

  & .ant-input-group-addon {
    background-color: transparent !important;
    border-radius: 10px !important;
    border: 1px solid ${colors.persianGreen} !important;
    border-left: none !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    height: 2.63rem;
    cursor: inherit !important;

    * {
      cursor: initial !important;
      outline: none !important;
      border: none !important;
    }
  }

  & .ant-input-search-button {
    background-color: transparent !important;
    border-left-color: transparent !important;

    & .anticon-search {
      visibility: hidden !important;
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: transparent !important;
    `}
`;

export const FloatingLabel = styled.label`
  position: absolute;
  transition: top 0.2s ease-in-out, left 0.2s ease-in-out,
    color 0.2s ease-in-out, font-size 0.2s ease-in-out;
  left: 0.625rem;
  top: 2.25rem;
  transform: translateY(-50%);
  background-color: transparent !important;
  padding: 0 0.25rem;
  pointer-events: none;
  border-radius: 5px;
  font-size: 0.7rem;
  font-weight: 500;
  z-index: 2;
  color: ${colors.textBlue};
  line-height: 4px;

  ${({ className }) =>
    className === 'autocompleteLabel' &&
    css`
      top: 1.25rem;
    `}

  ${({ focused, className }) =>
    focused &&
    css`
      left: 0.937rem;
      ${className === 'autocompleteLabel' ? 'top: 0rem;' : 'top: 1rem;'}
      color: ${colors.smoothBlack};
      font-size: 0.85rem;
      background-color: ${colors.white} !important;
    `}
`;

export const Error = styled.p`
  color: ${colors.textSecondary};
  opacity: 1;
  font-style: italic;
  font-size: 0.875rem;
  margin: 0;
  margin-top: 0.5rem;

  ${({ absoluteError }) =>
    absoluteError &&
    css`
      position: absolute;
      margin-top: 0;
      bottom: 1.75rem;
      transform: translateY(100%);
    `}
`;

export const ErrorIcon = styled.img`
  width: 0.875rem;
  margin-right: 0.625rem;
`;
