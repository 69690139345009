import { useEffect, useState } from 'react';

import InfoCard from './InfoCard';
import { List } from './InfoCardList.style';

function InfoCardList({ data }) {
  const [cards, setCards] = useState(null);

  useEffect(() => {
    setCards(data?.cards);
  }, [data]);

  console.log(cards);

  return (
    <List>
      {cards?.map((props) => (
        <InfoCard key={props.title} {...props} />
      ))}
    </List>
  );
}

export default InfoCardList;
