import { Container, Item } from './DotList.style';

function DotList({ total, current, ...props }) {
  return (
    <Container>
      {[...Array(total)].map((e, i) => (
        <Item {...props} key={Math.random()} active={current >= i} />
      ))}
    </Container>
  );
}

export default DotList;
