import { Input } from 'antd';
import styled, { css } from 'styled-components';

import { respondTo } from 'styles/mixins';

export const StyledSearchBar = styled(Input)`
  border-radius: 10px;
  box-shadow: 1px 1px 20px #d6d6d6 !important;
  line-height: 40px;
  background-color: white !important;
  font-size: 1rem;
  margin-bottom: 30px;
  width: 75vw;

  ${respondTo('medium')(css`
    border-radius: 50px;
    background: transparent !important;
    .ant-input {
      background: transparent !important;
    }
    box-shadow: none !important;
    border: 1px solid black !important;
    width: 100%;
  `)}
`;
