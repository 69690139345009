import { LoadingOutlined } from '@ant-design/icons';
import { Checkbox, Col, Row, Spin } from 'antd';
import styled, { css, keyframes } from 'styled-components';

import { Pencil } from 'components/icons/noise';
import InfiniteScrollAutoComplete from 'components/inputs/AutocompleteInput/InfiniteScrollAutoComplete';
import FloatingLabelSelect from 'components/inputs/FormSelect/FloatingLabelSelect';
import colors from 'styles/colors';

const showOverflow = keyframes`
  0% {
    overflow: hidden;
    opacity: 0;
  }

  50% {
    overflow: visible;
  }

  100% {
    opacity: 1;
  }
`;

const hideOverflow = keyframes`
  0% {
    overflow: visible;
    opacity: 1;
  }

  100% {
    overflow: hidden;
    opacity: 0;
  }
`;
export const StyledCheckbox = styled(Checkbox)`
  font-size: 1rem;
`;

export const AntdCol = styled(Col)`
  padding-left: 1.56rem !important;
  padding-top: 0rem !important;
  padding-right: 1.56rem !important;

  @media screen and (max-width: 1200px) {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
`;

export const AntdRow = styled(Row)`
  margin-bottom: 2rem;
`;

export const Board = styled.div`
  background-color: ${colors.white};
  margin-top: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 2px 2px 10px ${colors.darkGray};
  min-height: 40rem;

  @media screen and (max-width: 900px) {
    padding: 1rem;
    margin-bottom: 5rem;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 0 1.56rem;
  margin-bottom: 3rem;
  justify-content: space-between;

  @media screen and (max-width: 900px) {
    flex-direction: column-reverse;
    align-items: flex-start;
    padding: 0 0.75rem;
  }
`;

export const HeaderTitle = styled.h2`
  font-size: 1rem;
  color: ${colors.smoothBlack};
  margin-bottom: 0;
  font-weight: 600;
`;

export const BoardSubTitle = styled(HeaderTitle)`
  width: 100%;
`;

export const HeaderButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media screen and (max-width: 900px) {
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 1rem;
  }
`;

export const HeaderEditButton = styled.button`
  background-color: transparent;
  outline: none;
  border: none;
  color: ${colors.smoothBlack};
  margin-right: 0.5rem;
  padding: 0.35rem 1rem;
  transition: 0.5s background-color ease;
  border-radius: 8px;
  letter-spacing: 0.8px;
  font-weight: 600;

  &:hover {
    background-color: ${colors.gray};
  }

  @media screen and (max-width: 900px) {
    padding-left: 0;
    margin-bottom: 1rem;
  }
`;

export const RoundButton = styled.button`
  background-color: ${({ backgroundColor }) => backgroundColor || colors.gray};
  color: ${({ textColor }) => textColor || colors.smoothBlack};
  border-radius: 40px;
  font-weight: 600;
  font-size: 0.85rem;
  letter-spacing: 0.4px;
  padding: 0.25rem 1.6rem;
  border: none;
  outline: none;
  margin: 0 0.5rem;
  min-height: 2rem;

  ${({ disabled }) => {
    if (disabled)
      return css`
        cursor: not-allowed;
      `;
    return ``;
  }}

  @media screen and (max-width: 900px) {
    margin-left: 0;
    margin-bottom: 1rem;
  }
`;

export const SpinIcon = styled(LoadingOutlined)`
  font-size: 0.9rem;
  position: relative;
  top: 0.15rem;
  left: initial;
  right: 0.5rem;
`;

export const AntdSpin = styled(Spin)`
  color: ${colors.white};
`;

export const PencilIcon = styled(Pencil)`
  position: relative;
  top: initial;
  left: initial;
  font-size: 0.85rem;
  margin-right: 1rem;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  min-height: 30rem;

  & > div {
    width: 100%;
  }
`;

export const FormButtonsContainer = styled.div`
  margin-top: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
  margin-bottom: 2rem;
`;

export const DropDown = styled.div`
  width: 100%;
  height: calc(2.5rem + 1.8125rem);
  max-height: 0rem;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
  animation: ${hideOverflow} 0.5s linear forwards;

  ${({ active }) =>
    active &&
    css`
      max-height: calc(2.5rem + 1.8125rem);
      overflow: visible;
      animation: ${showOverflow} 0.5s linear forwards;
    `}
`;

export const MultiSelect = styled(FloatingLabelSelect)`
  ${({ singleTag }) =>
    singleTag &&
    css`
      .ant-select-selection-item-remove {
        display: none !important;
      }
    `}

  ${({ disabled }) => {
    if (disabled)
      return css`
        cursor: not-allowed;
        * {
          cursor: not-allowed;
        }
        .ant-select-selection-item {
          background-color: #f5f5f5 !important;
          color: rgba(0, 0, 0, 0.25) !important;
        }
      `;
    return css``;
  }}
`;

export const InfiniteAutocomplete = styled(InfiniteScrollAutoComplete)`
  margin-top: 0 !important;
`;
