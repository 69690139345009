/* eslint-disable react/jsx-one-expression-per-line */
import { FormContainer } from './ImportResidentsErrorModal.style';

function ImportResidentsErrorModal({ errors }) {
  const errorTreatment = () => {
    const possibleKeys = {
      name: 'Nome',
      cpf: 'Cpf',
      phone: 'Telefone',
    };

    const possibleResponses = {
      'Requester with this cpf alredy exists':
        'Solicitante com cpf já cadastrado',
      MissingParamError: 'é obrigatório para cadastrar solicitante',
    };

    return errors?.map(({ error, row }) => {
      const errorMessage = () => {
        if (typeof error === 'string') {
          return possibleResponses[error];
        }

        const key = Object.keys(error)[0];
        const keyMessage = error[key];

        return `${possibleKeys[key]} ${possibleResponses[keyMessage]}`;
      };
      return {
        row: `Erro na linha ${row}:`,
        message: `${errorMessage()}.`,
      };
    });
  };

  const errorMessages = errorTreatment().reverse();

  return (
    <FormContainer>
      {errorMessages?.map(({ row, message }) => (
        <p key={row}>
          <b>{row}</b> {message}
        </p>
      ))}
      <p style={{ fontSize: '12px', fontWeight: 'bold', textAlign: 'center' }}>
        Os demais itens foram cadastrados com sucesso!
      </p>
    </FormContainer>
  );
}

export default ImportResidentsErrorModal;
