import { StyledButton } from './Button.style';

function Button({ children, ...props }) {
  return (
    <StyledButton {...props} size="large">
      {children}
    </StyledButton>
  );
}

export default Button;
