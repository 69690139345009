import styled from 'styled-components';

export const Container = styled.div`
  width: 95%;
  margin: auto;
`;

export const Title = styled.h1`
  font-size: 1.5rem;
  line-height: 2.125rem;
  letter-spacing: 0.03rem;
  font-weight: normal;
`;
