import * as axios from 'axios';
import Cookies from 'js-cookie';

import { ACCESS_TOKEN, REFRESH_TOKEN, USER_ID, USER_INFO } from 'variables';
import history from 'helpers/history';

const { REACT_APP_API_URL } = process.env;

const defaultConfig = {
  baseURL: REACT_APP_API_URL,
  headers: {
    'content-type': 'application/json',
  },
};

export const instance = axios.create(defaultConfig);
export const authenticatedInstance = axios.create(defaultConfig);

export const logout = () => {
  Cookies.remove(ACCESS_TOKEN, { path: '/', });
  Cookies.remove(REFRESH_TOKEN, { path: '/', });
  Cookies.remove(USER_ID, { path: '/', });
  Cookies.remove(USER_INFO, { path: '/' });
  history.push('/login');
};

export default {
  /**
   * Makes an unauthenticated request
   * @returns {import('axios').AxiosInstance}
   */
  unauthorized() {
    return instance;
  },

  /**
   * Makes a authenticated request
   * @returns {import('axios').AxiosInstance}
   */
  authorized() {
    authenticatedInstance.defaults.headers.common.Authorization = (
      `Bearer ${Cookies.get(ACCESS_TOKEN)}`
    );
    authenticatedInstance.interceptors.response.use(
      (response) => (response),
      async (error) => {
        if (error.response?.status === 401) {
          if (error.response?.config?.url !== '/refresh-token') {
            try {
              const res = await authenticatedInstance.post('/refresh-token', {
                token: Cookies.get(REFRESH_TOKEN),
              })
              if (res.data) {
                Cookies.set(ACCESS_TOKEN, res.data.accessToken, { path: '/', });
                Cookies.set(REFRESH_TOKEN, res.data.refreshToken, { path: '/', });
                authenticatedInstance.defaults.headers.common.Authorization = (
                  `Bearer ${Cookies.get(ACCESS_TOKEN)}`
                );

                try {
                  const newConfig = { ...error.response.config };
                  newConfig.headers.Authorization = `Bearer ${res.data.accessToken}`;
                  const newResponse = authenticatedInstance(newConfig);
                  return newResponse;
                } catch (err) {
                  logout();
                  return null;
                }
              } else {
                logout();
                return null;
              }
            } catch (err) {
              logout();
              return null;
            }
          } else {
            logout();
            return null;
          }
        }

        throw error;
      },
    );

    return authenticatedInstance;
  },
};
