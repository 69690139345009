export const filters = [
  {
    sectionTitle: "Tempo",
    filterTypes: [
      { 
        title: "Data",
        callToAction: "Selecione as datas", 
        boxTitle: "Datas", hasSearchInput: false,
        isDateFilter: true,
        name: 'rangeStartDate',
      },
    ],
  },
  {
    sectionTitle: "Solicitante",
    filterTypes: [
      {
        title: "Tipo de Solicitante",
        callToAction: "Selecione o ou os tipos de solicitante",
        boxTitle: "Tipos de Solicitante",
        hasSearchInput: true,
        hasAutocomplete: true,
        hasInfinityScroll: true,
        inputPlaceholder: "Pesquise aqui por um tipo...",
        name: "typesIds",
        options: '/type-requester',
      },
    ],
  },
  {
    sectionTitle: "Território",
    filterTypes: [
      {
        title: "Regional",
        callToAction: "Selecione a ou as regiões",
        boxTitle: "Regiões",
        hasSearchInput: true,
        inputPlaceholder: "Pesquise aqui por uma região...",
        name: "regionsIds",
        options: '/region',
      },
      {
        title: "Área / Empreendimento",
        callToAction: "Selecione a área / empreendimento",
        boxTitle: "Área / empreendimento(s)",
        hasSearchInput: true,
        hasAutocomplete: true,
        hasInfinityScroll: true,
        inputPlaceholder: "Pesquise aqui por uma área / empreendimento...",
        name: "areasIds",
        options: '/area',
      },
    ],
  },
];

export const searchParameters = [
  'search',
];
