import Cookies from 'js-cookie';

import {
  Home,
  Building,
  Cog,
  Calendar,
  Citizens,
  Dashboard,
  Speech,
  Users,
  Report,
} from 'assets/icons/navbar';

import { USER_INFO } from 'variables';

export const drawerItems = () => {
  const userData = Cookies.get(USER_INFO)
  const parsedUserData = userData && JSON.parse(userData);

  const formatPath = (route) => {
    if (parsedUserData?.regionId && parsedUserData?.role !== 'attendant') {
      return `${route}?regionsIds=${parsedUserData?.regionId}`
    }

    return route
  }

  const mobileNavbarDrawerItems = [
    { label: 'Relatório', icon: Report, path: '/relatorio', action: ['report:visit'] },
    { label: 'Áreas / empreendimentos', icon: Building, path: formatPath('/areas'), action: ['areas:visit'] },
    { label: 'Usuários', icon: Users, path: formatPath('/usuarios'), action: ['users:visit'] },
    { label: 'Agenda', icon: Calendar, path: '/agenda', action: ['schedule:visit'] },
    { label: 'Configurações', icon: Cog, path: '/configuracoes', action: ['configurations:visit'] },
    { label: 'Dashboard', icon: Dashboard, path: formatPath('/dashboard'), action: ['dashboard:visit'] },
  ];

  return mobileNavbarDrawerItems;
}

export const items = (isMobile) => {
  const userData = Cookies.get(USER_INFO)
  const parsedUserData = userData && JSON.parse(userData);

  const formatPath = (route) => {
    if (parsedUserData?.regionId && parsedUserData?.role !== 'attendant') {
      return `${route}?regionsIds=${parsedUserData?.regionId}`
    }

    return route
  }

  const navbarItems = [
      { label: 'Home', icon: Home, path: '/home', action: ['home:visit'] },
      { label: 'Relatório', icon: Report, path: '/relatorio', action: ['report:visit'] },
      { label: 'Central do Morador', icon: Citizens, path: '/central-do-morador', action: ['resident-center:visit'] },
      { label: 'Áreas / empreendimentos', icon: Building, path: formatPath('/areas'), action: ['areas:visit'] },
      { label: 'Usuários', icon: Users, path: formatPath('/usuarios'), action: ['users:visit'] },,
      { label: 'Agenda', icon: Calendar, path: '/agenda', action: ['schedule:visit'] },
      { label: 'Configurações', icon: Cog, path: '/configuracoes', action: ['configurations:visit'] },
      { label: 'Dashboard', icon: Dashboard, path: formatPath('/dashboard'), action: ['dashboard:visit'] },
  ];

  const mobileNavbarItems = [
    { label: 'Home', icon: Home, path: '/home', action: ['home:visit'] },
    { label: 'Central do Morador', icon: Citizens, path: formatPath('/central-do-morador'), action: ['resident-center:visit'] },
];

  if (isMobile) {
    return mobileNavbarItems;
  }

  return navbarItems;
};
