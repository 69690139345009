import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { ArrowBack } from 'components/icons/noise';
import colors from 'styles/colors';
import { respondTo } from 'styles/mixins';

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  z-index: 1;
`;

export const StyledButton = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  z-index: 1;

  ${({ isFromChat }) =>
    isFromChat &&
    css`
      z-index: 0;
      position: absolute;
      top: initial;
      left: 1rem;
      display: none;

      ${respondTo('medium')(css`
        display: block;
      `)}
    `}
`;

export const Arrow = styled(ArrowBack)`
  position: relative;
  font-size: 1.5rem;
  top: initial;
  left: initial;

  & path:first-child {
    ${({ color }) => {
      if (color)
        return css`
          fill: blue;
        `;
      return null;
    }}
  }
`;

export const CallToAction = styled.span`
  font-size: 1rem;
  font-weight: 600;
  margin-left: 1rem;
  color: ${({ color }) => color || colors.black};
`;
