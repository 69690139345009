import styled, { css } from 'styled-components';

import { Button as BaseButton } from 'components/inputs';
import { respondToMin } from 'styles/mixins';

export const Title = styled.h2`
  text-align: center;
  font-size: 1.13rem;

  ${respondToMin('medium')(css`
    font-size: 1.13rem;
    font-weight: 600;
    padding: 0 2.14vw;
  `)}

  ${respondToMin('big')(css`
    font-size: 1.5rem;
  `)}
`;

export const Documents = styled.section`
  width: 100%;
  font-size: 1rem;
  font-weight: 600;
`;

export const DownloadsContainer = styled.div`
  padding: 9.72vw 0;

  ${respondToMin('medium')(css`
    padding: 0.83vw 0;
  `)}
`;

export const ProfileHeader = styled.div`
  width: 100%;
  display: flex;
  padding: 2.6vw 0;
  justify-content: flex-start;
  align-items: center;

  ${respondToMin('medium')(css`
    padding: 0.5vw 0;
  `)}
`;

export const Button = styled(BaseButton)`
  width: 42.22vw;
  height: 2rem;
  font-size: 0.88rem;
  margin: 2rem 1rem;
  &::after {
    bottom: -0.2rem !important;
    left: 0.2rem;
  }

  ${respondToMin('medium')(css`
    width: 12.34vw;
    height: 2.45vw;
    margin: 0;
    margin-right: 1rem;
    font-size: 0.8rem;
  `)}

  ${respondToMin('large')(css`
    font-size: 1.13rem;
    margin-right: 1rem;
  `)}
`;

export const ButtonWrapper = styled.div`
  display: flex;
`;
