import { Button as AntButton } from 'antd';
import styled, { css } from 'styled-components';

import colors from 'styles/colors';
import { respondTo } from 'styles/mixins';

export const StyledButton = styled(AntButton)`
  position: relative;
  padding: 20px;
  font-size: 1.5rem;
  width: 15.5625rem;
  height: 4.0625rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  font-weight: 600;
  background-color: ${({ color }) => colors[color] || color || colors.primary};
  box-shadow: 0px 0px 15px
    ${({ color }) => colors[color] || color || colors.primary};
  transition: background-color 0.2s ease-in-out;
  border: none;
  &:focus,
  &:hover {
    background-color: ${({ color }) =>
      colors[color] || color || colors.primary};
    color: black;
  }
  &::after {
    position: absolute;
    top: unset !important;
    bottom: 0.25rem !important;
    left: 0.375rem;
    background-color: transparent;
    width: 100%;
    height: 100%;
    border-radius: 50px;
    border: 1px solid black;
    content: '';
    opacity: 1 !important;
    animation: none;
    pointer-events: none;
  }
  ${respondTo('large')(css`
    font-size: 1.25rem;
  `)}
  ${respondTo('medium')(css`
    font-size: 1.125rem;
  `)}
`;
