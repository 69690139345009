import styled, { css, keyframes } from 'styled-components';

import { TextField } from 'components/inputs';
import InfiniteScrollAutoComplete from 'components/inputs/AutocompleteInput/InfiniteScrollAutoComplete';
import FloatingLabelSelect from 'components/inputs/FormSelect/FloatingLabelSelect';

const showOverflow = keyframes`
  0% {
    overflow: hidden;
  }

  99% {
    overflow: hidden;
  }

  100% {
    overflow: visible;
  }
`;

export const Input = styled(TextField)`
  margin: 1.8125rem 0;

  &:first-child {
    margin-top: 0;
  }

  &:nth-child(2n) {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  & > p {
    position: absolute;
    margin-top: 0;
    bottom: -0.125rem;
    transform: translateY(100%);
  }
`;

export const Select = styled(FloatingLabelSelect)`
  margin: 1.8125rem 0;
`;

export const DropDown = styled.div`
  width: 100%;
  height: calc(2.5rem + 1.8125rem);
  max-height: 0rem;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;

  ${({ active }) =>
    active &&
    css`
      max-height: calc(2.5rem + 1.8125rem);
      animation: ${showOverflow} 0.5s linear forwards;
    `}
`;

export const FormButtonContainer = styled.div`
  width: 100%;
  margin: 1.875rem 0 0.375rem;
  display: flex;
  justify-content: space-around;
`;

export const InfiniteAutocomplete = styled(InfiniteScrollAutoComplete)`
  margin-top: 0 !important;
`;
