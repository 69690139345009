import { useEffect, useRef, useState } from 'react';

import assetIcon from 'assets/icons/attach.svg';
import audioIcon from 'assets/icons/audio.svg';
import check from 'assets/icons/check.svg';
import emojiIcon from 'assets/icons/emoji.svg';
import { EmojiDrawer } from 'components';
import { useCallData } from 'contexts/CallDataProvider';
import { useUserInfo } from 'contexts/UserProvider';

import {
  ButtonIcon,
  CancelAudio,
  Container,
  Counter,
  EmojiContainer,
  InputButton,
  MessageInput,
  RecordButtons,
  SendAudio,
  StyledUpload,
  Wrapper,
} from './Input.style';
import useRecorder from './useRecorder';

function Input({ submitMessage }) {
  const callData = useCallData();
  const { userData } = useUserInfo();
  const emojiRef = useRef();
  const emojiButtonRef = useRef();
  const [input, setInput] = useState('');
  const [emojiDrawer, setEmojiDrawer] = useState(false);
  const [counter, setCounter] = useState({ seconds: 0, minutes: 0 });
  const [audioURL, isRecording, startRecording, stopRecording, setAudioURL] =
    useRecorder();

  const handleSubmit = async ({ content, type, cleanUp, resetCleanup }) => {
    try {
      cleanUp();
      await submitMessage(
        {
          senderRole: 'user',
          senderId: userData.id,
          callId: callData.id,
          requesterId: callData.requesterId,
          content,
          type,
        },
        {
          notificationLevel: 'error',
          onError: () => ({
            customMessage:
              'Ocorreu um erro ao enviar a mensagem, por favor tente novamente',
          }),
        }
      );
    } catch (err) {
      resetCleanup();
      console.log(err);
    }
  };

  const handleSubmitFile = async (form) => {
    try {
      await submitMessage(form, {
        notificationLevel: 'none',
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleTextChange = (e) => {
    if (e.key === 'Enter') {
      handleSubmit({
        content: input,
        type: 'text',
        cleanUp: () => {
          setInput('');
        },
        resetCleanup: () => {
          setInput(input);
        },
      });
    }
  };

  useEffect(() => {
    if (counter.seconds > 1 || counter.minutes > 0) {
      stopRecording();
      setCounter({ seconds: 0, minutes: 0 });
      const file = new File([audioURL], 'audio.mp3', {
        type: 'audio/mp3',
        lastModified: new Date(),
      });

      const audioFile = new FormData();
      audioFile.append('content', file);
      audioFile.set('type', 'audio');
      audioFile.set('senderRole', 'user');
      audioFile.set('senderId', userData.id);
      audioFile.set('callId', callData.id);
      audioFile.set('requesterId', callData.requester.id);

      handleSubmitFile(audioFile);
    }
  }, [audioURL]);

  const handleSendAudio = () => {
    if (counter.seconds > 1 || counter.minutes > 0) {
      stopRecording();
    }
  };

  const handleEmoji = (e) => {
    setInput(input.concat(e));
  };

  const handleCancelAudio = () => {
    stopRecording();
    setCounter({ seconds: 0, minutes: 0 });
    setAudioURL(null);
  };
  const handleStartAudio = () => {
    startRecording();
    setCounter({ seconds: 0, minutes: 0 });
  };

  const handleTimer = () => {
    let { minutes } = counter;
    let { seconds } = counter;
    if (counter.minutes < 10) {
      minutes = `0${counter.minutes}`;
      if (counter.seconds < 10) {
        seconds = `0${counter.seconds}`;
      }
    }
    return `${minutes}:${seconds}`;
  };

  useEffect(() => {
    const handleTime = () => {
      if (isRecording) {
        if (counter.seconds === 59) {
          setCounter({ seconds: 0, minutes: counter.minutes + 1 });
        } else {
          setCounter({
            seconds: counter.seconds + 1,
            minutes: counter.minutes,
          });
        }
      }
    };
    if (isRecording) {
      setTimeout(handleTime, 1000);
    }
  }, [isRecording, counter]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        emojiRef.current &&
        !emojiRef.current.contains(event.target) &&
        emojiButtonRef.current &&
        !emojiButtonRef.current.contains(event.target)
      ) {
        setEmojiDrawer(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [emojiRef, setEmojiDrawer]);

  const handleFile = (file) => {
    const data = new FormData();

    data.append('content', file);
    data.set('type', 'document');
    data.set('senderRole', 'user');
    data.set('senderId', userData.id);
    data.set('callId', callData.id);
    data.set('requesterId', callData.requester.id);

    handleSubmitFile(data);
  };

  return (
    <>
      <Wrapper>
        <InputButton>
          <Container ref={emojiButtonRef}>
            <ButtonIcon
              src={emojiIcon}
              onClick={() => setEmojiDrawer(!emojiDrawer)}
            />
          </Container>
          <EmojiContainer ref={emojiRef}>
            <EmojiDrawer
              visible={emojiDrawer}
              onSelect={(e) => handleEmoji(e.native)}
            />
          </EmojiContainer>
        </InputButton>
        <StyledUpload
          name="file"
          showUploadList={false}
          beforeUpload={handleFile}
        >
          <InputButton>
            <ButtonIcon src={assetIcon} />
          </InputButton>
        </StyledUpload>
        <MessageInput
          value={input}
          onKeyDown={handleTextChange}
          onChange={(e) => setInput(e.target.value)}
          isRecording={isRecording}
          type="text"
        />
        <InputButton>
          {!isRecording && (
            <ButtonIcon src={audioIcon} onClick={handleStartAudio} />
          )}
        </InputButton>
        {isRecording && (
          <RecordButtons>
            <CancelAudio onClick={handleCancelAudio}>X</CancelAudio>
            <Counter>{handleTimer()}</Counter>
            <SendAudio onClick={handleSendAudio} src={check} alt="enviar" />
          </RecordButtons>
        )}
      </Wrapper>
    </>
  );
}

export default Input;
