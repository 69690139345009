import moment from 'moment';

import { Bold } from './Report.styles';

const columns = [
  {
    title: 'Criado por',
    dataIndex: 'owner',
    key: 'owner',
    render: ({ name }) => <Bold>{name}</Bold>,
    span: 0.75,
  },
  {
    title: 'Lista de e-mails',
    dataIndex: 'emails',
    key: 'emails',
    render: (emails = []) => {
      const emailLists = emails.join(', ');
      return emailLists;
    },
    span: 1.5,
  },
  {
    title: 'Periodicidade',
    dataIndex: 'period',
    key: 'period',
    render: (period) =>
      ({
        daily: 'Diário',
        monthly: 'Mensal',
        weekly: 'Semanal',
      }[period]),
    span: 0.75,
  },
  {
    title: 'Regionais',
    dataIndex: 'regions',
    key: 'regions',
    render: (regions) => {
      const regionsList = regions.map(({ name }) => name).join(', ');
      return regionsList;
    },
    span: 1,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status) =>
      ({
        active: 'Ativo',
        disabled: 'Desativado',
      }[status]),
    span: 0.5,
  },
  {
    title: 'Data / Horário',
    dataIndex: 'date',
    key: 'date',
    extraContent: ['period'],
    render: (date, { period }) => {
      if (period === 'daily') {
        return `Todo dia às ${moment(date).format('HH:mm')}`;
      }
      if (period === 'weekly') {
        return `${moment(date).format('dddd')}s às ${moment(date).format(
          'HH:mm'
        )}`;
      }
      return `Todo dia ${moment(date).format('D [às] HH:mm')}`;
    },
    span: 1,
  },
  {
    title: '',
    dataIndex: 'details',
    key: 'details',
    align: 'middle',
  },
];

export default columns;
