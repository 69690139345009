import { Row } from 'antd';
import styled from 'styled-components';

import colors from 'styles/colors';

export const Container = styled.section`
  height: 95vh;
  padding-right: 1rem;
  overflow-x: hidden;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background-color: ${colors.gray};
    border-left: 3px solid ${colors.background};
    border-right: 3px solid ${colors.background};
  }
  ::-webkit-scrollbar-thumb {
    background: ${colors.persianGreen};
    border-radius: 4px;
  }
`;

export const StyledRow = styled(Row)`
  margin-bottom: 2.2rem;
`;
