export const filters = [
  {
    sectionTitle: "Confidencialidade",
    filterTypes: [
      { 
        type: 'select',
        title: "Tipo",
        callToAction: "Selecione o filtro dos atendimentos", 
        boxTitle: "Opções", 
        hasSearchInput: false,
        label: 'Tipo de Usuário',
        name: 'confidential',
        options: [{
          value: "true",
          label: "Confidenciais",
        }]
      },
    ],
  },
  {
    sectionTitle: "Tempo",
    filterTypes: [
      { 
        title: "Data",
        callToAction: "Selecione as datas", 
        boxTitle: "Datas", hasSearchInput: false,
        isDateFilter: true,
        name: 'rangeStartDate',
      },
    ],
  },
];
