import styled, { keyframes } from 'styled-components';

import colors from 'styles/colors';

const buttonAnimation = keyframes`
  0% {
    opacity: 0;
    bottom: 2rem;
  }
  100% {
    bottom: 6rem;
    opacity: 1;
  }
`;

export const Button = styled.a`
  font-size: 0.8rem;
  display: flex;
  background-color: ${colors.green};
  position: fixed;
  bottom: ${({ bottom }) => bottom ?? '2rem'};
  right: 2rem;
  cursor: pointer;
  padding: 0px;
  border-radius: 40px;
  justify-content: center;
  align-items: center;
  color: ${colors.white};
  box-shadow: 4px 0px 5px #00000014;
  animation: ${buttonAnimation} 0.2s linear;
`;

export const SecondButton = styled.a`
  font-size: 0.8rem;
  display: flex;
  background-color: ${colors.green};
  position: fixed;
  bottom: ${({ bottom }) => bottom ?? '6rem'};
  right: 2rem;
  cursor: pointer;
  padding: 0px;
  border-radius: 40px;
  justify-content: center;
  align-items: center;
  color: ${colors.white};
  box-shadow: 4px 0px 5px #00000014;
  animation: ${buttonAnimation} 0.2s linear;
`;

export const Text = styled.div`
  padding: 0rem 0.625rem 0rem 1.875rem;
  letter-spacing: 0.023rem;
`;

export const Icon = styled.img`
  width: 3rem;
  position: relative;
  top: 0.1rem;
  right: -0.2rem;
`;

export const Background = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  background-color: ${colors.white};
  position: absolute;
  right: 0.25rem;
  z-index: -1;
  border-radius: 40px;
`;
