import { useEffect, useRef, useState } from 'react';
import moment from 'moment';

import DatePicker from 'components/inputs/DatePicker';
import Spin from 'components/Spin';
import normalizeText from 'helpers/normalizeInputText';
import usePreviousState from 'hooks/use-previous-state';

import {
  AntdCheckbox,
  BoxContainer,
  BoxSearchInput,
  BoxTitle,
  CheckboxContainer,
  LoaderContainer,
} from './Filter.style';

import 'moment/locale/pt-br';

function FilterBox({
  closeBox,
  setSelectedOptions,
  boxVisible,
  options = [],
  hasSearchInput = true,
  inputPlaceholder,
  boxTitle,
  checkboxGroupValue,
  isDateFilter = false,
  setFilterInformations,
  hasAutocomplete = false,
  isLoading = false,
}) {
  const boxRef = useRef();
  const [inputValue, setInputValue] = useState('');
  const [startDateValue, setStartDateValue] = useState('');
  const [endDateValue, setEndDateValue] = useState('');
  const [filterOptions, setFilterOptions] = useState([]);
  const [initialOptions, setInitialOptions] = useState([]);
  const [transitionClass, setTransitionClass] = useState('');
  const previousStartDate = usePreviousState(startDateValue);

  useEffect(() => {
    if (Array.isArray(options)) {
      setFilterOptions(options.slice());
      setInitialOptions(options.slice());
    }
  }, [options]);

  useEffect(() => {
    const handleClickOut = (e) => {
      const datePickerDOMContainers = isDateFilter
        ? Object.values(document.getElementsByClassName('ant-picker-panel'))
        : [];
      if (boxRef.current && !boxRef.current.contains(e.target)) {
        if (
          isDateFilter &&
          datePickerDOMContainers?.filter((element) =>
            element.contains(e.target)
          ).length > 0
        ) {
          return;
        }
        setTransitionClass('fadeOutClass');
        setTimeout(() => {
          setInputValue('');
          closeBox();
        }, 250);
      }
    };

    window.addEventListener('mousedown', handleClickOut);
    return () => window.removeEventListener('mousedown', handleClickOut);
  }, []);

  useEffect(() => {
    if (boxVisible && !!boxRef.current) {
      setTransitionClass('fadeInClass');
      setFilterOptions(initialOptions);
    }
  }, [boxVisible]);

  const onCheckboxChange = (checkedValues) => {
    setSelectedOptions(
      initialOptions.filter((option) => checkedValues.includes(option.value))
    );
  };

  const updateOptions = (text, optionsArr) => {
    const inputString = normalizeText(text);
    const filteredOptions = optionsArr.filter(
      (option) => normalizeText(option.label).indexOf(inputString) !== -1
    );
    setFilterOptions(filteredOptions);
  };

  const onInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleAutocompleteInputChange = (e) => {
    setFilterInformations((prev) => ({
      ...prev,
      search: e,
      page: 0,
    }));
  };

  const disabledDates = (currentDate) => {
    if (!startDateValue) return false;
    return currentDate.isBefore(startDateValue);
  };

  useEffect(() => {
    if (previousStartDate) setEndDateValue('');
  }, [startDateValue]);

  useEffect(() => {
    if (startDateValue && endDateValue) {
      setSelectedOptions([
        {
          label: `${moment(startDateValue)
            .locale('pt-br')
            .format('LL')} - ${moment(endDateValue)
            .locale('pt-br')
            .format('LL')}`,
          value: [
            startDateValue.format('YYYY[-]MM[-]DD[T00:00:00.000Z]'),
            endDateValue.format('YYYY[-]MM[-]DD[T23:59:59.000Z]'),
          ],
        },
      ]);
    }
  }, [endDateValue]);

  useEffect(() => {
    if (isDateFilter) {
      if (checkboxGroupValue.length === 0) {
        setStartDateValue('');
        setEndDateValue('');
      } else if (!(startDateValue || endDateValue)) {
        const value = checkboxGroupValue[0];
        const newStartDate = value[0]?.split(',')[0];
        const newEndDate = value[1]?.split(',')[1];

        setStartDateValue(moment(newStartDate));
        setEndDateValue(moment(newEndDate));
      }
    }
  }, [checkboxGroupValue]);

  if (!boxVisible) return null;

  const handleSearchInput = (e) => {
    onInputChange(e);

    if (!hasAutocomplete) {
      return updateOptions(e.target.value, initialOptions);
    }

    return handleAutocompleteInputChange(e.target.value);
  };

  const handleScroll = (e) => {
    const { scrollHeight, clientHeight, scrollTop } = e.target;

    if (scrollHeight - clientHeight - Math.ceil(scrollTop + 1) < 1) {
      setFilterInformations((prev) => ({
        ...prev,
        page: prev.page + 1,
      }));
    }
  };

  return (
    <BoxContainer ref={boxRef} className={transitionClass}>
      <BoxTitle>
        <h1>{boxTitle}</h1>
      </BoxTitle>
      {isDateFilter && (
        <>
          <DatePicker
            value={startDateValue}
            label="Data inicial"
            single
            allowClear={false}
            defaultTimezone={false}
            customOnChange={(value) => {
              setStartDateValue(value);
            }}
          />
          <DatePicker
            value={endDateValue}
            label="Data final"
            single
            allowClear={false}
            defaultTimezone={false}
            disabled={!startDateValue}
            disabledDates={disabledDates}
            customOnChange={(value) => {
              setEndDateValue(value);
            }}
          />
        </>
      )}
      {hasSearchInput && (
        <BoxSearchInput
          type="text"
          onChange={handleSearchInput}
          value={inputValue}
          placeholder={inputPlaceholder}
        />
      )}
      <CheckboxContainer>
        <AntdCheckbox.Group
          isLoading={isLoading}
          onScroll={handleScroll}
          value={checkboxGroupValue}
          onChange={onCheckboxChange}
        >
          {initialOptions.length === 0 && !isLoading && hasSearchInput ? (
            <>Sem resultados</>
          ) : (
            initialOptions.map((option) => (
              <AntdCheckbox
                visibility={filterOptions.includes(option)}
                key={option.label}
                value={option.value}
              >
                {option.label}
              </AntdCheckbox>
            ))
          )}
          {isLoading && (
            <LoaderContainer>
              <Spin />
            </LoaderContainer>
          )}
        </AntdCheckbox.Group>
      </CheckboxContainer>
    </BoxContainer>
  );
}

export default FilterBox;
