import styled, { css } from 'styled-components';

import { AddPhoto as AddPhotoIcon } from 'components/icons/noise';

export const Picture = styled.img`
  height: 4.5rem;
  width: 4.5rem;
  object-fit: cover;
  border-radius: 100%;

  ${({ mobile }) =>
    mobile &&
    css`
      height: 3rem;
      width: 3rem;
    `}
`;

export const CameraIcon = styled(AddPhotoIcon)`
  font-size: 1.25rem;
  position: absolute;
  top: 0rem;
  left: -0.2rem;

  ${({ mobile }) =>
    mobile &&
    css`
      font-size: 0.9rem;
      top: 0rem;
      left: 2.8rem;
    `}
`;

export const IconWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
`;

export const Wrapper = styled.div`
  width: fit-content;
  margin-left: 1rem;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const AddPhoto = styled.div`
  margin-top: 0.2rem;
  text-decoration: underline;
  font-style: italic;
  font-size: 0.6rem;
  font-weight: 900;
`;
