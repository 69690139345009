const technicianRules = { 
    static: [
        // PAGES can visit
        'home:visit',
        'resident-center:visit',
        'calls:visit',
        'appointments:visit',
        'configurations:visit',
        'users:visit',
        'areas:visit',
        'schedule:visit',
        'report:visit',

        // users R
        'users:read',
        'users.self:update',

        // resident-center
        'resident-center:create',
        'resident-center:read',
        'resident-center:update',
        'resident-center:delete',

        // areas R
        'areas:read',
        
        'areas.pdf:read',

        // configurations R
        'configurations:read',

        // calls CRU
        'calls:create',
        'calls:read',
        'calls:update',

        'calls.pdf:read',

        
        'configurations:read',

        // appointments CRUD
        'appointments.pdf:read',
        'appointments:create',
        'appointments:update',
        // 'appointments:delete',
        
        // dashboard R
        'dashboard:read',
        'dashboard.pdf:read',
        'dashboard:visit',

        // report CRUD
        'report:create',
        'report:read',
        'report:update',
        'report:delete',
    ]
}

export default technicianRules;
