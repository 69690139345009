import { useHistory } from 'react-router-dom';

import { BackButton, Page } from './FullscreenLayout.style';

function FullscreenLayout({ children }) {
  const history = useHistory();

  return (
    <Page>
      <BackButton onClick={() => history.goBack()}>Voltar</BackButton>
      {children}
    </Page>
  );
}

export default FullscreenLayout;
