import moment from 'moment';

import contentMap from './contentMap';
import {
  Circle,
  Close,
  Container,
  Date,
  Header,
  IconContainer,
  Text,
  TextBlock,
} from './NotificationDrawer.style';

function NotificationItem({ notification, handleDeleteNotification }) {
  const { timestamp, status, type } = notification;

  const { text, callToAction } = contentMap[type]
    ? contentMap[type](notification)
    : {};

  return (
    <Container>
      <Header>
        <Date>{moment(timestamp).locale('pt-br').format('DD [de] MMMM')}</Date>
        <Close onClick={() => handleDeleteNotification(notification.id)} />
      </Header>
      <TextBlock>
        {status === 'not-seen' && (
          <IconContainer>
            <Circle />
          </IconContainer>
        )}
        <Text>{text}</Text>
      </TextBlock>
      {callToAction}
    </Container>
  );
}

export default NotificationItem;
