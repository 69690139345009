import { useState } from 'react';

import FormSection from './FormSection';
import SelectOptionsProvider from './SelectOptionsProvider';
import { Wrapper } from './SteppedForm.style';
import SteppedHeader from './SteppedHeader';

function SteppedForm({
  sections,
  onSubmit = () => {},
  onCancel = () => {},
  watchTouched = () => {},
}) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [fields, setFields] = useState(sections.map(() => ({})));
  const [touched, setTouched] = useState(false);

  const mergedInputs = [].concat(...sections.map(({ inputs }) => inputs));

  const createUser = (newFields) => {
    const mergedFields = Object.assign({}, ...newFields);

    mergedInputs.forEach((input) => {
      if (input.rules?.includes('phone') && mergedFields[input.name]) {
        mergedFields[input.name] = `+55${mergedFields[input.name]
          .replaceAll('(', '')
          .replaceAll(')', '')
          .replaceAll('-', '')
          .replaceAll(' ', '')}`;
      }
    });

    onSubmit(mergedFields);
  };

  const handleSubmitStep = (result, index) => {
    const newFields = [...fields];

    newFields[index] = result;

    setFields(newFields);
    if (index < sections.length - 1) setActiveIndex(index + 1);
    if (index === sections.length - 1) createUser(newFields);
  };

  const handleGoBack = (result, index) => {
    if (activeIndex === 0) {
      onCancel(Object.keys(fields[0]).length !== 0 || touched);
      return;
    }

    const newFields = [...fields];

    newFields[index] = result;

    setFields(newFields);
    setActiveIndex((prevIndex) => {
      if (prevIndex > 0) return prevIndex - 1;
      return prevIndex;
    });
  };

  return (
    <Wrapper>
      <SteppedHeader
        titles={sections.map(({ title }) => title)}
        activeIndex={activeIndex}
      />
      <SelectOptionsProvider inputs={mergedInputs}>
        {sections.map(
          (section, index) =>
            activeIndex === index && (
              <FormSection
                key={section.title}
                orderInfo={{ total: sections.length, current: index }}
                section={section}
                values={fields[index]}
                goBack={(result) => handleGoBack(result, index)}
                onSubmit={(result) => handleSubmitStep(result, index)}
                setTouched={setTouched}
                watchTouched={watchTouched}
              />
            )
        )}
      </SelectOptionsProvider>
    </Wrapper>
  );
}

export default SteppedForm;
