import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';

import removeDuplicateObjectsByProperty from 'helpers/removeDuplicateObjectsByProperty';

import useRequest from './use-request';

function useCrud(
  url,
  {
    authorized = true,
    autoinit = false,
    pagination = false,
    trackQueryParameters = false,
    invertedList = false,
    parameters = {},
  } = {}
) {
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [newItems, setNewItems] = useState(0);
  const [itemsAmount, setItemsAmount] = useState(0);
  const [page, setPage] = useState(0);
  const request = useRequest();
  const api = authorized ? request : request.unauthorized;
  const { location, push } = useHistory();

  const get = useCallback(
    async (query = {}, callbackFunction = () => {}) => {
      const queryParameters = queryString.parse(location.search, {
        arrayFormat: 'separator',
        arrayFormatSeparator: ',',
      });

      const config = {
        params: {
          ...queryParameters,
          ...query,
          ...(pagination
            ? { skip: 0, take: pagination.itemsPerPage || 10 }
            : {}),
        },
      };

      setLoading(true);
      const res = await api.get({ url, config });

      setHasMore(res.data?.length > 0);

      callbackFunction();
      setData(res.data || []);
      setItemsAmount(res.totalItems);
      setLoading(false);
      return res;
    },
    [api, url, pagination]
  );

  const addNewItem = (item) => {
    setNewItems(newItems + 1);
    setItemsAmount(itemsAmount + 1);

    if (invertedList) {
      setData((prevItems) =>
        removeDuplicateObjectsByProperty([item, ...prevItems], 'id')
      );
    } else {
      setData((prevItems) =>
        removeDuplicateObjectsByProperty([...prevItems, item], 'id')
      );
    }
  };

  const create = async (body, config) => {
    const res = await api.post({ url, body, ...config });

    if (!res.data) return;

    addNewItem(res.data);

    if (url === '/call') {
      push(`/chamados/${res.data.id}`);
    }
  };

  const getOne = async (id, config = {}) => {
    const res = await api.get({ url: `${url}/${id}` });

    if (config.concatenate) {
      addNewItem(res.data[0]);
    }
  };

  const remove = async (id) => {
    await api.delete({ url: `${url}/${id}` });

    setItemsAmount(itemsAmount - 1);
    setData((prevData) => prevData.filter((item) => item.id !== id));
  };

  const edit = async (id, body) => {
    const res = await api.patch({ url: `${url}/${id}`, body });
    setData((prevData) =>
      prevData.map((item) => {
        if (item.id === id) {
          return res.data;
        }
        return item;
      })
    );
  };

  const getSpecificPage = useCallback(async (query, pageNumber) => {
    setPage(pageNumber);

    const queryParameters = queryString.parse(location.search, {
      arrayFormat: 'separator',
      arrayFormatSeparator: ',',
    });

    const config = {
      params: {
        ...queryParameters,
        ...query,
        ...(pagination
          ? {
              skip: pageNumber * (pagination.itemsPerPage || 10),
              take: pagination.itemsPerPage || 10,
            }
          : {}),
      },
    };

    const res = await api.get({ url, config });

    setData(() => [...res.data]);
  });

  const getMore = useCallback(
    async (query, currentPage = null) => {
      setPage((prevPage) => (currentPage || prevPage) + 1);

      const config = {
        params: {
          ...query,
          ...(pagination
            ? {
                skip:
                  ((currentPage || page) + 1) *
                    (pagination.itemsPerPage || 10) +
                  newItems,
                take: pagination.itemsPerPage || 10,
              }
            : {}),
        },
      };

      const res = await api.get({ url, config });

      setHasMore(res.data?.length > 0);
      setData((prevData) =>
        removeDuplicateObjectsByProperty([...prevData, ...res.data], 'id')
      );
      return res;
    },
    [api, page, url, pagination]
  );

  useEffect(() => {
    if (autoinit) {
      get(parameters);
    }
  }, []);

  useEffect(() => {
    if (trackQueryParameters) {
      get(parameters);
    }
  }, [location.search]);

  return [
    data,
    {
      get,
      create,
      getOne,
      remove,
      edit,
      hasMore,
      itemsAmount,
      getMore,
      loading,
      getSpecificPage,
      setData,
      setPage,
      page,
    },
  ];
}

export default useCrud;
