import styled from 'styled-components';

import { ReactComponent as StringSvg } from 'assets/noise/long_strings.svg';

import { colorCss, positionCss, sizeCss } from './default.style';

const LongStrings = styled(StringSvg)`
  ${positionCss}
  ${sizeCss}
  ${colorCss}
`;

export default LongStrings;
