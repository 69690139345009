import {
  Card,
  CardCount,
  CardTitle,
  Count,
  CountType,
  Item,
  List,
} from './EnterpriseList.style';
import mockedData from './mockedData';

function EnterpriseList() {
  return (
    <>
      <Count>25 empreendimentos listados</Count>
      <List>
        {mockedData?.map(({ name, id, residentCount, technicianCount }) => (
          <Item key={id}>
            <Card>
              <CardTitle>{name}</CardTitle>
              <CountType>Moradores cadastrados</CountType>
              <CardCount>{`${residentCount} moradores`}</CardCount>
              <CountType>Técnicas(os) cadastrados</CountType>
              <CardCount>{`${technicianCount} técnicas(os)`}</CardCount>
            </Card>
          </Item>
        ))}
      </List>
    </>
  );
}

export default EnterpriseList;
