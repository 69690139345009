/* eslint-disable no-unused-vars */
import { useEffect, useMemo, useState } from 'react';
import { Col, Select, Tabs } from 'antd';

import socket from 'api/socket';
import {
  CallTabs,
  CounterCard,
  Header,
  MobileHeader,
  ScheduleCard,
  UnifiedAttendanceCard,
} from 'components';
import { useUserInfo } from 'contexts/UserProvider/UserProvider';
import useCrud from 'hooks/use-crud';
import useMobile from 'hooks/use-mobile';

import { LocalFilterWrapper } from './components/LocalFilterWrapper/LocalFilterWrapper';
import {
  CardGrid,
  CardList,
  Cards,
  CustomMultiSelectWithGroups,
  NoDataText,
  Page,
  PageStructure,
  ScrollBar,
  StyledRow,
  TabContainer,
  Title,
} from './AssistantProfile.style';

const { TabPane } = Tabs;

const reconstructObjectFromArray = (array) =>
  array.reduce((acc, str) => {
    const [key, value] = str.split(':').map((item) => item.trim());
    acc[key] = value;
    return acc;
  }, {});

const removeDuplicateKeysFromArray = (array) => {
  const filteredArray = [];
  const keyValueMap = {};

  array.forEach((str) => {
    const [key, value] = str.split(':');
    keyValueMap[key] = value;
  });

  Object.keys(keyValueMap).forEach((key) => {
    filteredArray.push(`${key}:${keyValueMap[key]}`);
  });

  return filteredArray;
};
function AssistantProfile() {
  const { userData } = useUserInfo();

  const [unifiedAttendances, { get }] = useCrud('/unified-attendance', {
    autoinit: true,
    parameters: {
      userId: userData?.id,
    },
  });

  useEffect(() => {
    socket.on('new-unified-attendance', ({ userId }) => {
      if (userId === userData.id) {
        get();
      }
    });
    return () => {
      socket.off('new-unified-attendance');
    };
  }, [unifiedAttendances]);

  useEffect(() => {
    socket.on('updated-unified-attendance', () => {
      get();
    });
    return () => {
      socket.off('updated-unified-attendance');
    };
  }, [unifiedAttendances]);

  const {
    onlineSchedules,
    socialSchedules,
    openedSocialSchedules,
    concludedSchedules,
    openedSchedules,
  } = unifiedAttendances.reduce(
    (acc, current) => {
      const { isOnline, status } = current ?? {};

      if (isOnline) {
        acc.onlineSchedules.push(current);
        if (status === 'opened') {
          acc.openedSocialSchedules.push(current);
        }
      } else {
        acc.socialSchedules.push(current);
      }

      if (status === 'opened' || status === 'hold') {
        acc.openedSchedules.push(current);
      }

      if (status === 'concluded') {
        acc.concludedSchedules.push(current);
      }

      return acc;
    },
    {
      openedSocialSchedules: [],
      concludedSchedules: [],
      onlineSchedules: [],
      socialSchedules: [],
      openedSchedules: [],
    }
  );

  [
    onlineSchedules,
    socialSchedules,
    openedSocialSchedules,
    concludedSchedules,
    openedSchedules,
  ].forEach((scheduleList) => {
    scheduleList.sort((a, b) => b.startDate.localeCompare(a.startDate));
  });

  const isMobile = useMobile();

  return (
    <Page mobile={isMobile}>
      {!isMobile && (
        <>
          <Header />
          <PageStructure>
            <StyledRow gutter={[20]}>
              <Col xxl={17} xl={16} lg={12} md={12}>
                <Cards>
                  <CounterCard
                    title="Seus atendimentos em aberto"
                    count={openedSchedules.length}
                  />
                  <CounterCard
                    title="Seus atendimentos concluídos"
                    count={concludedSchedules.length}
                  />
                </Cards>
                <Title>Seus agendamentos em aberto</Title>
                <CardGrid>
                  {openedSocialSchedules.length ? (
                    openedSocialSchedules.map((props) => (
                      <UnifiedAttendanceCard key={props.id} {...props} />
                    ))
                  ) : (
                    <NoDataText>Não há atendimentos em aberto</NoDataText>
                  )}
                </CardGrid>
              </Col>
              <Col xxl={7} xl={8} lg={12} md={12}>
                <Title>Seus atendimentos por tipo</Title>
                <span style={{ marginRight: 10 }}>Filtro: </span>
                <LocalFilterWrapper
                  data={unifiedAttendances}
                  filterFn={(selected) => (ua) => {
                    const filter = reconstructObjectFromArray(selected);
                    return Object.entries(filter).every(
                      ([key, value]) => String(ua[key]) === value
                    );
                  }}
                  defaultValue={[]}
                >
                  {({ filteredData, selected, setSelected }) => (
                    <>
                      <CustomMultiSelectWithGroups
                        mode="tags"
                        value={selected}
                        onChange={(value) => {
                          const valueWithNoDuplicates =
                            removeDuplicateKeysFromArray(value);
                          setSelected(valueWithNoDuplicates);
                        }}
                        filterOption={
                          (input, option) =>
                            option.label
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          // eslint-disable-next-line react/jsx-curly-newline
                        }
                        options={[
                          {
                            label: 'Por tipo',
                            options: [
                              { label: 'Agendamento', value: 'isOnline:true' },
                              { label: 'Atendimento', value: 'isOnline:false' },
                            ],
                          },
                          {
                            label: 'Por status',
                            options: [
                              { label: 'Em aberto', value: 'status:opened' },
                              { label: 'Concluído', value: 'status:conclued' },
                              { label: 'Cancelado', value: 'status:canceled' },
                            ],
                          },
                        ]}
                      />
                      <ScrollBar>
                        <CardList>
                          {filteredData.length ? (
                            filteredData.map((props) => (
                              <ScheduleCard
                                key={props.id}
                                scheduleContent={props}
                                getSchedules={get}
                                {...props}
                                margin
                              />
                            ))
                          ) : (
                            <NoDataText>Não há atendimentos.</NoDataText>
                          )}
                        </CardList>
                      </ScrollBar>
                    </>
                  )}
                </LocalFilterWrapper>
              </Col>
            </StyledRow>
          </PageStructure>
        </>
      )}
      {isMobile && (
        <>
          <MobileHeader />
          <CallTabs
            schedules={openedSchedules}
            concluded={concludedSchedules}
          />
        </>
      )}
    </Page>
  );
}

export default AssistantProfile;
