import { useHistory } from 'react-router-dom';

import { useModal, withModalProvider } from 'contexts/ModalProvider';
import useMobile from 'hooks/use-mobile';

import { StyledButton } from './UnifiedAttendanceDetailButton.style';
import UnifiedAttendanceDetailModal from './UnifiedAttendanceDetailModal';

function UnifiedAttendanceDetailButton({ content = {}, get = () => {} }) {
  const { modal } = useModal();
  const isMobile = useMobile();
  const history = useHistory();

  const handleModal = () => {
    const modalProps = {
      title: 'Atendimento',
      footer: null,
      destroyOnClose: true,
      watchTouched: true,
      maskClosable: false,
      width: '50%',
      onClose: () => {
        get();
      },
    };

    modal(
      (props) => (
        <UnifiedAttendanceDetailModal
          detailInfo={content}
          get={get}
          {...props}
        />
      ),
      modalProps
    );
  };

  return (
    <StyledButton
      onClick={() => {
        if (isMobile) history.push(`/central-do-morador/${content?.id}`);
        else handleModal();
      }}
    >
      Ver detalhes
    </StyledButton>
  );
}

export default withModalProvider(UnifiedAttendanceDetailButton);
