import styled from 'styled-components';

import colors from 'styles/colors';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2rem 0rem 1rem 0rem;
  background-color: ${colors.white};
`;

export const Title = styled.div`
  font-size: 1.125rem;
  font-weight: bold;
  padding-top: 0.7rem;
  color: ${colors.textBlue};
`;

export const Icon = styled.img`
  margin: 0rem 0.8rem;
  position: relative;
  top: -0.6rem;
  cursor: pointer;
  margin-left: auto;

  &:last-child {
    margin-left: 0;
  }
`;

export const UserInfo = styled.div`
  display: flex;
`;
