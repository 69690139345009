import { Upload } from 'antd';
import styled, { css } from 'styled-components';

import { Button as AntButton } from 'components/inputs';
import colors from 'styles/colors';
import { respondTo } from 'styles/mixins';

export const Bold = styled.p`
  margin: 0;
  color: ${colors.smoothBlack};
  font-weight: 600;
`;

export const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;

  & > span {
    margin-bottom: 0;
  }
`;

export const OptionButton = styled.button`
  background-color: ${colors.persianGreen};
  color: white;
  font-size: 0.875rem;
  border-radius: 50px;
  font-weight: 600;
  min-height: 1.6875rem;
  padding: 0.3125rem 1.25rem;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  line-height: 1.0625rem;
`;

export const Text = styled.p`
  font-size: 1.125rem;
  margin-bottom: 1.875rem;
`;

export const Button = styled(AntButton)`
  height: 2.9375rem;
  width: 12.3125rem;
  font-size: 1rem;
  margin-right: 1.5rem;

  ${respondTo('medium')(css`
    margin-bottom: 1.2rem;
    margin-top: 1rem;
  `)}
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0.5rem;
  margin-bottom: 2rem;
`;

export const StyledUpload = styled(Upload)`
  flex-grow: 1;
`;

export const StyledUpdateUpload = styled(Upload)`
  flex-grow: 1;
  position: absolute;
  left: 0;
`;

export const UploadCallToAction = styled.p`
  font-size: 0.7rem;
  color: ${colors.black};
  text-decoration: underline;
  font-style: italic;
  font-weight: 600;
`;

export const StyledButton = styled(AntButton)`
  height: 2.9375rem;
  width: fit-content;
  font-size: 1rem;
  margin-right: 1.5rem;
`;

export const ButtonsGroup = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;
