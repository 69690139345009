import { Exit } from 'assets/icons/navbar';
import { drawerItems } from 'components/Navbar/NavbarItems';
import { useAuth, useConfirmation, useUserInfo } from 'contexts';

import DrawerNavItem from './DrawerNavItem';
import {
  ExitButtonContainer,
  ItemsContainer,
  NavigationItem,
  NavigationText,
  StyledDrawer,
} from './MobileNavbarDrawer.style';

function MobileNavbarDrawer({ open, setOpen }) {
  const { userData } = useUserInfo();
  const { confirm } = useConfirmation();
  const { logout } = useAuth();

  const onClose = () => {
    setOpen(false);
  };

  const handleExit = () => {
    confirm({
      question: 'Tem certeza que deseja sair?',
      okText: 'Sair',
      onOk: logout,
    });
  };

  return (
    <StyledDrawer onClose={onClose} visible={open}>
      {userData && (
        <ItemsContainer>
          {drawerItems().map((item) => (
            <DrawerNavItem
              key={item.key}
              path={item.path}
              action={item.action}
              label={item.label}
              icon={item.icon}
            />
          ))}
          <ExitButtonContainer>
            <NavigationItem onClick={handleExit}>
              <Exit />
              <NavigationText>Sair</NavigationText>
            </NavigationItem>
          </ExitButtonContainer>
        </ItemsContainer>
      )}
    </StyledDrawer>
  );
}

export default MobileNavbarDrawer;
