import colors from 'styles/colors';
import width from './widths';
import { btnTypes } from './button';

export default ({
  fontSizes: [12, 14, 16, 20, 24, 32],
  space: [0, 4, 8, 16, 32, 64],
  radius: [0, 4, 8],
  btnTypes,
  width,
  colors,
});
