import { Tabs } from 'antd';
import styled, { css } from 'styled-components';

import { Button as AntButton } from 'components/inputs';
import colors from 'styles/colors';
import { respondTo, respondToMin } from 'styles/mixins';

export const HeaderContainer = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  ${respondToMin('medium')(css`
    display: none;
  `)}
`;

export const Title = styled.h1`
  color: ${colors.textBlue};
  font-size: 2rem;
  letter-spacing: 0.04rem;
  line-height: 2.375rem;

  ${respondTo('medium')(css`
    text-align: center;
    font-size: 1.25rem;
    margin: 0;
    color: ${colors.black};
  `)}
`;

export const SubTitle = styled.p`
  margin: 0;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 22rem);
  grid-gap: 2rem;
  padding-top: 1rem;
  padding-bottom: 6.25rem;
  margin-top: 1.188rem;
  height: fit-content;
  overflow: auto;
  align-content: stretch;
  align-items: stretch;

  & > div {
    height: 100%;
  }

  ${respondTo('medium')(css`
    grid-template-columns: 100%;
    justify-items: center;
    margin: 0 auto;
  `)}
`;

export const NoDataText = styled.h3`
  font-size: 1rem;
  color: #727272;
`;

export const Button = styled(AntButton)`
  height: 2.9375rem;
  width: fit-content;
  font-size: 1rem;
  margin-right: 1.5rem;

  ${respondTo('medium')(css`
    margin-bottom: 1.1875rem;
  `)}
`;

export const HeaderButtons = styled.div`
  display: flex;

  ${respondTo('medium')(css`
    margin-top: 1.875rem;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  `)}
`;

export const TabContainer = styled(Tabs)`
  .ant-tabs-nav-wrap {
    background-color: ${colors.white};
    justify-content: center;
  }
  .ant-tabs-nav-list {
    font-size: 0.4rem;
  }

  .ant-tabs-nav {
    margin: 0;
  }

  .ant-tabs-ink-bar {
    width: 100%;
    height: 0.2rem !important;
    background-color: ${colors.orange};
  }

  .ant-tabs-tab {
    padding-bottom: 0.2rem !important;
  }

  .ant-tabs-tab .ant-tabs-tab-btn,
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
  .ant-tabs-tab-btn:focus {
    color: ${colors.black};
    font-size: 0.8rem;
  }

  .ant-tabs-tab-disabled .ant-tabs-tab-btn {
    color: ${colors.gray} !important;
  }
`;
