import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { AuthLayout, LoginForm } from 'components';
import { useAuth } from 'contexts';

function Login() {
  const { login } = useAuth();
  const history = useHistory();

  const [error, setError] = useState('');

  const handleSubmit = async ({ email, password }, formik) => {
    try {
      const info = await login(email, password);
      formik.setSubmitting(false);
      if (info.data.user.role === 'admin') {
        const formatPath = (route) => {
          if (info.data.user?.region) {
            return `${route}?regionsIds=${info.data.user?.region?.id}`;
          }

          return route;
        };

        history.push(formatPath('/central-do-morador'));
      } else history.push('/');
    } catch (err) {
      formik.setSubmitting(false);
      if (err.response?.status === 404) {
        setError('Usuário não encontrado');
        return;
      }
      if (err.response?.status === 401) {
        setError('Usuário ou senha incorretos');
        return;
      }
      if (err.response?.status === 400) {
        setError('Senha inválida');
        return;
      }
      setError('Erro ao se conectar com o servidor');
    }
  };

  return (
    <AuthLayout>
      <LoginForm error={error} setError={setError} onSubmit={handleSubmit} />
    </AuthLayout>
  );
}

export default Login;
