import { css } from 'styled-components';
import colors from './colors';

export const flexcc = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const flex = (justify, align, direction, wrap) => css`
  display: flex;
  justify-content: ${justify || 'unset'};
  align-items: ${align || 'unset'};
  flex-direction: ${direction || 'unset'};
  flex-wrap: ${wrap || 'unset'};
`;

const breakpoints = {
  small: 600,
  medium: 900,
  big: 1300,
  large: 1800,
};

export const respondTo = (breakpoint) => (...code) => css`
  @media only screen and (max-width: ${breakpoints[breakpoint] || 600}px) {
    ${code}
  }
`;

export const customScrollBar = (thickness, customColors = {}) => css`
  ::-webkit-scrollbar {
    width: ${thickness / 16}rem;
  }
  ::-webkit-scrollbar-track {
    background-color: ${customColors.background || colors.gray};
    border-left: ${thickness * 0.375}px solid ${customColors.border || colors.white};
    border-right: ${thickness * 0.375}px solid ${customColors.border || colors.white};
  }
  ::-webkit-scrollbar-thumb {
    background: ${customColors.scroller || colors.persianGreen};
    border-radius: ${thickness / 2}px;
  }
`;

export const respondToMin = (breakpoint) => (...code) => css`
  @media only screen and (min-width: ${breakpoints[breakpoint] || 600}px) {
    ${code}
  }
`;

export const translateBackground = (beforeCode) => css`
  position: relative;

  &::before {
    position: absolute;
    ${beforeCode}
    border-radius: inherit;
    content: '';
    z-index: -1;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: inherit;
    border: 1px solid black;
    content: '';
  }
`;
