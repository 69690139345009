import styled, { css } from 'styled-components';

import colors from 'styles/colors';
import { respondTo } from 'styles/mixins';

export const Button = styled.button`
  background-color: white;
  border: none;
  margin: 0;
  padding: 0;
  width: 10rem;
  margin-left: 1.5rem;
  height: 2.5rem;
  border-radius: 6px;
  box-shadow: 1px 1px 20px #d6d6d6 !important;
  outline: none;
  position: relative;
  font-weight: 600;

  & > span:first-child {
    background-color: ${colors.success};
    font-size: 1.2rem;
    padding: 0.25rem;
    border-radius: 100%;
    position: absolute;
    left: 0.5rem;
    top: 50%;
    transform: translateY(-50%);

    & path {
      fill: ${colors.white};
    }
  }

  ${respondTo('medium')(css`
    width: auto;
    background-color: transparent;
    height: auto;

    & > span:first-child {
      position: relative;
      left: initial;
      top: initial;
      transform: none;
    }
  `)}
`;

export const ButtonText = styled.span`
  ${respondTo('medium')(css`
    display: none;
  `)}
`;
