/* eslint-disable no-unused-vars */
import { createContext, useContext, useState } from 'react';

import { CloseIcon, Modal } from 'components/Modal';
import { useConfirmCancel } from 'contexts/ConfirmCancelProvider';

const ModalContext = createContext({
  modal: null,
  visible: false,
});

function ModalProvider({ children }) {
  const [visible, setVisible] = useState(false);
  const [params, setParams] = useState(null);
  const [content, setContent] = useState(() => {});
  const [result, setResult] = useState(() => {});

  const [touched, setTouched] = useState(null);
  const [cancelIfTouched, setCancelIfTouched] = useState(false);

  const [customClassname, setCustomClassname] = useState('');
  const confirmCancel = useConfirmCancel();

  const modal = (newContent, newParams, className) => {
    const { watchTouched = () => {}, ...otherParams } = newParams;

    if (watchTouched) {
      setTouched(false);
      setCancelIfTouched(true);
    }

    setContent((() => newContent) || (() => {}));
    setParams(() => ({ ...otherParams }));

    setCustomClassname(className || '');

    setVisible(true);
  };

  const closeModal = () => {
    setVisible(false);
    if (params?.onClose) params?.onClose();
  };

  const ContentToRender = content || (() => {});

  const watchTouched = (formikTouched) => {
    if (!cancelIfTouched) return null;
    setTouched(formikTouched);
    return formikTouched;
  };

  return (
    <ModalContext.Provider
      value={{
        visible,
        modal,
        closeModal,
        setResult,
      }}
    >
      {children}

      <Modal
        {...params}
        visible={visible}
        centered
        onCancel={closeModal}
        closeIcon={<CloseIcon />}
        className={customClassname}
        okText="Confirmar"
        okButtonProps={{
          shape: 'round',
        }}
        cancelText="Cancelar"
        cancelButtonProps={{
          shape: 'round',
          type: 'primary',
          className: 'modal-button-cancel',
        }}
        onOk={() => {
          params.onOk(closeModal, result);
        }}
        {...(cancelIfTouched
          ? { onCancel: () => confirmCancel(closeModal, touched) }
          : {})}
      >
        <ContentToRender
          result={result}
          setResult={setResult}
          closeModal={closeModal}
          watchTouched={watchTouched}
        />
      </Modal>
    </ModalContext.Provider>
  );
}

export const withModalProvider = (Component) => (props) =>
  (
    <ModalProvider>
      <Component {...props} />
    </ModalProvider>
  );

export const useModal = () => useContext(ModalContext);

export default ModalProvider;
