import search from 'assets/icons/search.svg';

import { StyledSearchBar } from './SearchBar.style';

function SearchBar({ ...props }) {
  return (
    <StyledSearchBar
      suffix={<img src={search} alt="Ícone de busca" width={25} />}
      {...props}
    />
  );
}
export default SearchBar;
