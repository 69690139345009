import { useState } from 'react';

import pencil from 'assets/icons/placeholder-pencil.png';

import { TextInput } from './ProfileForm.style';

function ProfileForm({ edit }) {
  const [form, setForm] = useState({
    address: 'Rua Alberto Santana, 146 CEP 51123-223',
    phone: '(041) 9 9820-8901',
    email: 'roberto.aguiar@gmail.com',
  });

  return (
    <>
      <TextInput label="Nome" value="Maria Soares" disable={edit} />
      <TextInput label="CPF" value="133.487.098-99" disable={edit} />
      <TextInput label="RG" value="9.786.876" disable={edit} />
      <TextInput label="Área" value="Zona Leste" disable={edit} />
      <TextInput label="Estado civil" value="Solteiro" disable={edit} />
      <TextInput
        label="Endereço"
        value={form.address}
        onChange={(e) => edit && setForm({ ...form, address: e.target.value })}
        suffix={edit && <img src={pencil} alt="pencil" width={10} />}
      />
      <TextInput
        label="Celular"
        value={form.phone}
        onChange={(e) => edit && setForm({ ...form, phone: e.target.value })}
        suffix={edit && <img src={pencil} alt="pencil" width={10} />}
      />
      <TextInput
        label="E-mail"
        value={form.email}
        onChange={(e) => edit && setForm({ ...form, email: e.target.value })}
        suffix={edit && <img src={pencil} alt="pencil" width={10} />}
      />
      {edit && (
        <>
          <TextInput label="Coloque a nova senha" type="password" />
          <TextInput label="Confirme a nova senha" type="password" />
        </>
      )}
    </>
  );
}

export default ProfileForm;
