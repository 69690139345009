import styled, { css } from 'styled-components';

import { respondToMin } from 'styles/mixins';

export const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #eee;
  width: 100%;
  min-height: 7.5rem;
  max-height: 7.5rem;
`;

export const RequesterNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 2.8125rem;
`;

export const RequesterName = styled.h3`
  color: #434446;
  font-size: 1.25rem;
  font-weight: 600;
  margin: 1.5rem 0 0;
  line-height: 1.5rem;
`;

export const IconContainer = styled.div`
  display: flex;
  margin: 0 1.875rem;
`;

export const Icon = styled.img`
  height: 1.5rem;
  margin: 0 0 0 1.25rem;
`;

export const Button = styled.button`
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;

  ${({ hideOnLarge }) =>
    hideOnLarge &&
    css`
      ${respondToMin('medium')(css`
        display: none;
      `)}
    `}
`;
