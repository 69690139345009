import styled, { css } from 'styled-components';

import colors from 'styles/colors';
import { respondTo } from 'styles/mixins';

export const PageContainer = styled.section`
  height: 100vh;
  padding: 2rem 1rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  flex-flow: column;
  margin-right: 0.2rem;
  overflow: hidden;

  ${respondTo('medium')(css`
    padding: 2rem 1rem 5rem 1rem;
  `)}
`;

export const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  padding-right: 6rem;
  align-items: center;
  margin-bottom: 1rem;
  gap: 2rem;

  ${respondTo('medium')(css`
    padding-right: 0;
  `)}
`;

export const Title = styled.h1`
  color: ${colors.textBlue};
  font-size: 1.8rem;
  letter-spacing: 0.04rem;
  line-height: 2.375rem;
  display: flex;
  margin-bottom: 0;

  ${respondTo('medium')(css`
    font-size: 1.5rem;
  `)}

  ${respondTo('small')(css`
    font-size: 1.1rem;
    line-height: 1.5rem;
  `)}
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: ${({ justifyButtons }) =>
    `${justifyButtons || 'space-between'}`};
  width: max-content;
  flex: 1;
  align-items: center;

  ${respondTo('medium')(css`
    justify-content: flex-end;
  `)}
`;

export const Dash = styled.div`
  height: 0px;
  width: 100%;
  border: 1px solid ${colors.orange};
  margin-bottom: 2rem;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  flex-grow: 1;
  flex-shrink: 1;

  ${({ noScroll }) =>
    !noScroll &&
    css`
      overflow: auto;
      ::-webkit-scrollbar {
        width: 0.5rem;
      }

      ::-webkit-scrollbar-track {
        background: #cccccc;
        border-left: 3px solid #f8f8f8;
        border-right: 3px solid #f8f8f8;
      }

      ::-webkit-scrollbar-thumb {
        background: #f7951c;
        border-radius: 4px;
      }
    `}
`;
