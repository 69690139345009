import { useModal, withModalProvider } from 'contexts/ModalProvider';

import NewUnifiedAttendanceModal from './NewUnifiedAttendanceModal';
import { StyledButton } from './NewUnifiedAttendanceModal.style';

function NewUnifiedAttendanceButton({
  updateList = () => {},
  title = 'Novo atendimento',
}) {
  const { modal } = useModal();

  const handleModal = () => {
    const modalProps = {
      title: 'Novo atendimento',
      footer: null,
      destroyOnClose: true,
      watchTouched: false,
      onClose: () => localStorage.removeItem('@unified-attendance'),
    };

    modal(
      (props) => (
        <NewUnifiedAttendanceModal updateList={updateList} {...props} />
      ),
      modalProps
    );
  };

  return <StyledButton onClick={handleModal}>{title}</StyledButton>;
}

export default withModalProvider(NewUnifiedAttendanceButton);
