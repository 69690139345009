import { Redirect, Route, Router, Switch } from 'react-router-dom';

import { Loading as LoadingSpinner } from 'components';
import { useAuth } from 'contexts';
import history from 'helpers/history';
import { DataConfirmation, ForgotPassword, Login, Residents } from 'views';

import Protected from './Protected';

function Main() {
  const { authenticated } = useAuth();

  if (authenticated === null) {
    return <LoadingSpinner />;
  }

  if (authenticated === false) {
    return (
      <Router history={history}>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/central-do-morador" component={Residents} />
          <Route exact path="/data-confirmation" component={DataConfirmation} />
          <Route path="/redefinir-senha" component={ForgotPassword} />
          <Route path="/primeiro-acesso" component={DataConfirmation} />
          <Route>
            <Redirect to="/login" />
          </Route>
        </Switch>
      </Router>
    );
  }

  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/login">
          <Redirect to="/" />
        </Route>
        <Route exact path="/primeiro-acesso">
          <Redirect to="/" />
        </Route>
        <Protected />
      </Switch>
    </Router>
  );
}

export default Main;
