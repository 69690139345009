import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
  Actions,
  Container,
  Form,
  Next,
  Previous,
  StyledTextInput,
  Title,
} from './DataConfirmation.style';

function Step2({ onSubmit, error }) {
  const history = useHistory();
  const FirstAccess = Yup.object().shape({
    email: Yup.string()
      .email('Email inválido')
      .required('Email não pode ser vazio'),
    temporaryPassword: Yup.string().required(
      'Senha temporária não pode ser vazia'
    ),
    newPassword: Yup.string().required('Nova senha não pode ser vazia'),
    confirmNewPassword: Yup.string().required('Confirme a nova senha'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      temporaryPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    validationSchema: FirstAccess,
    validateOnChange: false,
    onSubmit: (values) => {
      onSubmit(values, formik);
    },
  });

  return (
    <Container>
      <Form onSubmit={formik.handleSubmit}>
        <Title>Confirme suas informações para entrar</Title>
        <StyledTextInput
          name="email"
          label="Email"
          placeholder="Digite seu Email"
          onChange={formik.handleChange}
          value={formik.values.email}
          error={formik.errors.email}
          schema={FirstAccess}
        />
        <StyledTextInput
          label="Senha temporária"
          name="temporaryPassword"
          type="password"
          placeholder="Digite sua senha temporária"
          onChange={formik.handleChange}
          value={formik.values.temporaryPassword}
          error={formik.errors.temporaryPassword}
          schema={FirstAccess}
        />
        <StyledTextInput
          label="Nova senha"
          name="newPassword"
          type="password"
          placeholder="Digite sua nova senha"
          onChange={formik.handleChange}
          value={formik.values.newPassword}
          error={formik.errors.newPassword || error}
          schema={FirstAccess}
        />
        <StyledTextInput
          label="Confirme a nova senha"
          name="confirmNewPassword"
          type="password"
          placeholder="Confirme sua nova senha"
          onChange={formik.handleChange}
          value={formik.values.confirmNewPassword}
          error={formik.errors.confirmNewPassword || error}
          schema={FirstAccess}
        />
        <Actions>
          <Previous onClick={() => history.goBack()}>Voltar</Previous>
          <Next loading={formik.isSubmitting} htmlType="submit">
            Avançar
          </Next>
        </Actions>
      </Form>
    </Container>
  );
}

export default Step2;
