import styled, { css } from 'styled-components';

import colors from 'styles/colors';
export const FileInputWrapper = styled.div`
  ${( ) => css`
    div.ant-upload.ant-upload-drag {
      height: 20rem;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='11' ry='11' stroke='#009B90' stroke-width='4' stroke-dasharray='10' stroke-dashoffset='10' stroke-linecap='round'/%3e%3c/svg%3e");
      background-color: rgba(0, 156, 144, 0.3);

      p {
        color: ${colors.green};

        &.ant-upload-drag-icon {
          svg {
            width: 5rem;
            height: 5rem;
            margin-bottom: -1rem;
          }
        }
      }
    }

    div.ant-upload-list-text {
      color: ${colors.green};
      svg {
        color: ${colors.green};
      }
    }
  `}
`;
