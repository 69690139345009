import styled, { css } from 'styled-components';

import { BaseCard, Spin } from 'components';
import colors from 'styles/colors';

export const Card = styled(BaseCard)`
  cursor: pointer;
  box-shadow: 10px 10px 15px #00000014;
  width: 100%;
  height: 100%;
  min-height: 30rem;
`;

export const Container = styled.div`
  height: 13rem;
`;

export const Title = styled.h1`
  color: ${colors.textBlue};
  letter-spacing: 0.72px;
  font-size: 1.125rem;
  margin-left: 2rem;
`;

export const StyledSpin = styled(Spin)`
  position: relative;
  margin: 0 45%;
`;

export const CenterContainer = styled.div`
  position: relative;
  top: 65%;
  display: flex;
  font-size: 2.5rem;
  font-weight: 600;
  color: ${({ color }) => color || colors.success};
  justify-content: center;
`;

export const PercentageSign = styled.div`
  font-size: 1rem;
  position: relative;
  top: 1.5rem;
  :after {
    content: '%';
  }
`;

export const Labels = styled.div`
  margin-top: 6rem;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, 5rem);
  column-gap: 2rem;
  row-gap: 0.5rem;

  ${({ flex }) =>
    flex &&
    css`
      display: flex;
    `}
  justify-content: center;
`;

export const Label = styled.div`
  display: flex;
  justify-content: ${({ justify }) => justify};

  @media (max-width: 1200px) {
    justify-content: left;
  }
`;

export const Color = styled.div`
  position: relative;
  top: 0.4rem;
  border-radius: 100%;
  min-width: 0.7rem;
  height: 0.7rem;
  background-color: ${({ color }) => color};
  margin-right: 0.5rem;
`;

export const TextBlock = styled.div`
  letter-spacing: 0.64px;
  font-size: 1rem;
  color: ${({ disabled }) =>
    disabled ? colors.disabledGray : colors.textBlue};
`;

export const LabelText = styled.div``;

export const Percentage = styled.div`
  margin: 0 10%;
`;

export const CustomTooltip = styled.div`
  background-color: rgba(0, 0, 0, 0.822);
  border-radius: 5px;
  width: fit-content;
  height: fit-content;
  color: ${colors.white};
  padding: 0.75rem 1rem;
`;

export const TooltipTitle = styled.div`
  font-size: 0.8rem;
  letter-spacing: 0;
  color: ${colors.white};
  margin: 0;
`;

export const TooltipValue = styled.div`
  font-size: 1.1rem;
  font-weight: 600;
  letter-spacing: 0.48px;
`;

export const Divider = styled.div`
  position: relative;
  top: -0.4rem;
  :after {
    content: '_____________';
  }
`;
