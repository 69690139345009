import * as Yup from 'yup';

export const CreateAreaSchema = Yup.object().shape({
  name: Yup.string().required('Este campo é obrigatório'),
  regionId: Yup.string().required('Este campo é obrigatório'),
  lat: Yup.string().matches(/^\(?[+-]?(90(\.0+)?|[1-8]?\d(\.\d+)?)$/, 'Latitude inválida'),
  lng: Yup.string().matches(/^\s?[+-]?(180(\.0+)?|1[0-7]\d(\.\d+)?|\d{1,2}(\.\d+)?)\)?$/, 'Longitude inválida'),
  address: Yup.string().required('Este campo é obrigatório'),
  numberOfApartments: Yup.number().typeError('Este campo deve ser numérico').min(0, 'Deve ser maior ou igual a 0.').required('Este campo é obrigatório'),
  numberOfBlocks: Yup.number().typeError('Este campo deve ser numérico').min(0, 'Deve ser maior ou igual a 0.').required('Este campo é obrigatório')
});

export const validate = (values, type) => {
  const errors = {};

  if (type === 'enterprise' && !values.address) {
    errors.address = 'Este campo é obrigatório';
  }

  return errors;
};
