import styled, { css } from 'styled-components';

import { ReactComponent as BallSvg } from 'assets/noise/small_ball.svg';
import colors from 'styles/colors';
import { respondTo } from 'styles/mixins';

import { positionCss, sizeCss } from './default.style';

const Ball = styled(BallSvg)`
  ${positionCss}
  ${sizeCss}
  .small-ball-a {
    fill: ${({ $color }) => colors[$color] || $color || 'white'};
  }
  transform: ${({ $rotatePos }) => $rotatePos && 'rotateZ(180deg)'};

  ${({ $mobileHide }) =>
    $mobileHide &&
    css`
      ${respondTo('medium')(css`
        display: none;
      `)}
    `}
`;

export default Ball;
