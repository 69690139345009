import { Select as AntSelect } from 'antd';
import styled, { css } from 'styled-components';

import { respondTo } from 'styles/mixins';

export const Container = styled.div`
  position: relative;
  margin: 0.625rem 0 1.5rem;
  width: 100%;
  max-width: 25.375rem;
`;

export const StyledSelect = styled(AntSelect)`
  position: relative;
  display: flex;
  border: none;
  font-size: 1.125rem;
  max-width: 25.375rem;
  margin-top: 1.375rem;

  ${respondTo('medium')(css`
    margin-top: 0.875rem;
  `)}

  .ant-select-selector {
    border-color: #ffffff !important;
    border: none;
    box-shadow: none !important;
    padding: 0 !important;
  }

  .ant-select-selection-placeholder {
    width: 100% !important;
    font-size: 1rem;
    font-weight: 600;
    color: #585858;

    ${respondTo('medium')(css`
      font-size: 0.875rem;
    `)}
  }

  .ant-select-arrow {
    right: 30px !important;
    top: 10px !important;
    font-size: 1.25rem !important;
    color: black !important;
  }

  .ant-select-selection-item {
    width: 100% !important;
    font-size: 1rem;
    font-weight: 600;
    color: #585858;

    ${respondTo('medium')(css`
      font-size: 0.875rem;
    `)}
  }

  .anticon {
    top: 3px !important;
    left: 13px !important;
  }

  ::after {
    position: absolute;
    bottom: -1px;
    height: 1px;
    width: 100%;
    background: transparent linear-gradient(94deg, #ffcf8f 0%, #f88122 100%) 0%
      0% no-repeat padding-box;
    content: '';
  }
`;

export const Label = styled.label`
  font-size: 1rem;
  color: #585858;
  letter-spacing: 0.02rem;

  ${respondTo('medium')(css`
    font-size: 0.875rem;
  `)}
`;

export const Error = styled.p`
  position: absolute;
  color: #707070;
  opacity: 1;
  font-style: italic;
  font-size: 0.875rem;
  margin: 0;
  bottom: -10px;
  transform: translateY(100%);
`;

export const ErrorIcon = styled.img`
  width: 0.875rem;
  margin-right: 0.625rem;
`;
