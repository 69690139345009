/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from 'react';
import moment from 'moment';

import {
  CalendarHeader,
  Container,
  Day,
  HeaderItem,
  Month,
  MonthChanger,
  StyledCalendar,
} from './Calendar.style';
import { dateMap, headerMap, subHeader } from './helpers';

function Calendar() {
  const renderedTime = moment();
  const [month, setMonth] = useState(headerMap[renderedTime.format('MMM')]);
  const [selected, setSelected] = useState(renderedTime);

  useEffect(() => {
    setMonth(headerMap[selected?.format('MMM')]);
  }, [selected]);

  const handleMonth = (move) => {
    setSelected(selected?.add(move, 'month'));
    setMonth(headerMap[selected?.format('MMM')]);
  };

  const cellRender = (e) => {
    if (e.format('DDMMYYYY') === selected?.format('DDMMYYYY')) {
      return <Day>{dateMap[selected?.format('ddd')]}</Day>;
    }
    return null;
  };

  return (
    <Container>
      <Month>
        <MonthChanger onClick={() => handleMonth(-1)}>{'<'}</MonthChanger>
        {month}
        <MonthChanger onClick={() => handleMonth(1)}>{'>'}</MonthChanger>
      </Month>
      <CalendarHeader>
        {subHeader.map((day, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <HeaderItem key={`${day}, ${index}`}>{day}</HeaderItem>
        ))}
      </CalendarHeader>
      <StyledCalendar
        fullscreen={false}
        validRange={[moment().add(-5, 'hour'), moment().add(1, 'year')]}
        dateCellRender={(e) => cellRender(e)}
        value={selected}
        onSelect={(e) => setSelected(e)}
      />
    </Container>
  );
}

export default Calendar;
