import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import FloatingLabelSelect from 'components/inputs/FormSelect/FloatingLabelSelect';
import TextArea from 'components/inputs/TextArea';
import TextField from 'components/inputs/TextField';
import { useUserInfo } from 'contexts/UserProvider/UserProvider';
import compareArrays from 'helpers/compareArrays';
import getDiffBetweenObjects from 'helpers/getDiffBetweenObjects';
import useRequest from 'hooks/use-request';
import useTouched from 'hooks/use-touched';
import colors from 'styles/colors';

import {
  AntdCol,
  AntdRow,
  AntdSpin,
  BoardSubTitle,
  DropDown,
  Form,
  FormButtonsContainer,
  InfiniteAutocomplete,
  MultiSelect,
  RoundButton,
  SpinIcon,
  StyledCheckbox,
} from './EditDataBoard.style';
import {
  cpfIsValid,
  cpfMask,
  phoneMask,
  phoneRegex,
  setFieldRules,
} from './helpers';

const spinIcon = <SpinIcon spin />;

const InputsContainer = ({
  disabled,
  setDisabled,
  setUser,
  inputs,
  user,
  activeSection,
  path,
  overrideOnCancel = false,
  submit = true,
  section,
  buttonsTexts = [],
  watchTouched = () => {},
  customRequest = () => {},
}) => {
  const [fieldsRules, setFieldsRules] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const [selectsOptions, setSelectsOptions] = useState({});

  // This is a workaround. This feature needs a refactor.
  const regionRef = useRef(null);
  const [autocompletePaginations, setAutocompletePaginations] = useState({});

  const { userData } = useUserInfo();

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const request = useRequest();

  useEffect(() => {
    const newValidationObject = fieldsRules;
    for (let i = 0; i < inputs?.length; i += 1) {
      let response = Yup.string();

      if (
        inputs[i].hasTheField &&
        !inputs[i]?.hasTheField.includes(userData.role)
      ) {
        i += 1;
      }
      response = setFieldRules(inputs[i]?.rules, response);

      if (inputs[i]?.name) {
        newValidationObject[inputs[i]?.name] = response;
      }
    }
    setFieldsRules(newValidationObject);
  }, []);

  useEffect(() => {
    inputs
      .filter((input) => ['autocomplete', 'multiselect'].includes(input.type))
      .forEach((input) => {
        setAutocompletePaginations((prev) => ({
          ...prev,
          [input.name]: {
            page: 0,
            hasMore: true,
          },
        }));
      });
  }, []);

  useEffect(() => {
    if (user) {
      const newInitialValuesObject = initialValues;

      inputs.forEach((input) => {
        if (user && input.name) {
          if (user[input.name]) {
            if (input.rules.indexOf('phone') !== -1) {
              newInitialValuesObject[input.name] = phoneMask(
                user[input.name].slice(3)
              );
            } else if (input.rules.indexOf('multiselect') !== -1) {
              newInitialValuesObject[input.name] = user[input.name].map(
                ({ id }) => id
              );
            } else {
              newInitialValuesObject[input.name] = user[input.name];
            }
          } else newInitialValuesObject[input.name] = '';
        }
      });

      setInitialValues(newInitialValuesObject);
      forceUpdate();
    }
  }, [user, section]);

  const InputSchema = Yup.object().shape(fieldsRules);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: InputSchema,
    validate: (values) => {
      const errors = {};

      inputs.forEach((item) => {
        if (item.type === 'conditional-select') {
          if (
            values[item.dependsOn] === item.triggerValue &&
            !values[item.name]
          ) {
            errors[item.name] = 'Este campo é obrigatório';
          }
        }

        if (item.rules?.indexOf('phone') !== -1 && !!values[item.name]) {
          if (!phoneRegex(values[item.name])) {
            errors[item.name] = 'Número de telefone inválido';
          }
        }

        if (item.rules?.indexOf('cpf') !== -1) {
          if (!cpfIsValid(values[item.name])) {
            errors[item.name] = 'CPF Inválido';
          }
        }
      });

      return errors;
    },
    validateOnChange: true,
    handleReset: (values, actions) => {
      actions.resetForm({
        values: initialValues,
      });
    },
    onSubmit: (fieldValues) => {
      const formValues = {
        ...fieldValues,
      };

      const patchUser = async (newValues) => {
        try {
          if (submit) {
            await request.patch({
              url: path,
              body: newValues,
              onSuccess: (data) => {
                setUser(data);
                setInitialValues(formValues);
                setDisabled(true);

                formik.handleReset();
              },
            });
          } else {
            await customRequest(newValues);
          }
        } catch (err) {
          console.log(err);
        }
        formik.setSubmitting(false);
      };
      if (JSON.stringify(formValues) === JSON.stringify(initialValues)) {
        setDisabled(true);
        formik.setSubmitting(false);
        return;
      }
      inputs
        .filter((item) => item.type === 'conditional-select')
        .forEach((item) => {
          if (formValues[item.dependsOn] !== item.triggerValue) {
            delete formValues[item.name];
          }
        });
      const newUser = {};
      Object.assign(newUser, user);
      const keys = Object.keys(formValues);
      const values = Object.values(formValues);

      for (let i = 0; i < keys.length; i += 1) {
        newUser[keys[i]] = values[i];
      }

      inputs.forEach((input) => {
        if (input.rules?.indexOf('phone') !== -1 && newUser[input.name]) {
          newUser[input.name] = `+55${newUser[input.name]
            .replaceAll('(', '')
            .replaceAll(')', '')
            .replaceAll('-', '')
            .replaceAll(' ', '')}`;
        } else if (
          input.rules?.indexOf('multiselect') !== -1 &&
          newUser[input.name]
        ) {
          newUser[input.submitId] = newUser[input.name];
          delete newUser[input.name];
        }
      });

      const changedData = getDiffBetweenObjects(user, newUser);

      const formFields = inputs.map(({ name, rules, submitId = null }) => {
        if (rules?.indexOf('multiselect') !== -1) return submitId;
        return name;
      });

      Object.keys(changedData).forEach((key) => {
        if (!formFields.includes(key)) {
          delete changedData[key];
        }
      });

      const userAreasIds = user.areas?.map((area) => area.id);
      if (
        newUser.areasIds &&
        userAreasIds &&
        compareArrays(userAreasIds, newUser.areasIds)
      ) {
        delete changedData.areasIds;
      }

      const { phoneWhatsapp, phone } = changedData;

      if (phoneWhatsapp && phone && phoneWhatsapp.length === 0) {
        changedData.phoneWhatsapp = phone;
      }

      patchUser(changedData);
    },
  });

  const selectValues = useMemo(
    () =>
      Object.entries(formik.values)
        .filter(([key]) => {
          const input = inputs.find(({ name }) => name === key);
          return (
            input &&
            [
              'select',
              'conditional-select',
              'multiselect',
              'autocomplete',
            ].includes(input.type)
          );
        })
        .map(([, value]) => value),
    [formik.values]
  );

  const getOptions = async (
    fieldName,
    optionsPath,
    handleFilter = (items) => items,
    parameters = {},
    page = null
  ) => {
    const config = {
      params: {
        ...parameters,
      },
    };

    await request.get({
      url: optionsPath,
      config,
      onSuccess: async (data, { data: { totalItems } }) => {
        const filteredItems = handleFilter(data, formik.values);

        const itemsArr = filteredItems.map(({ id, name }) => ({
          value: id,
          label: name,
          id,
        }));

        if (page !== null) {
          await setAutocompletePaginations((previous) => ({
            ...previous,
            [fieldName]: {
              page: page + 1,
              hasMore: itemsArr?.length < totalItems,
            },
          }));
        }

        setSelectsOptions((prevSelectsOptions) => {
          const previousPage =
            prevSelectsOptions[fieldName]?.length && page > 0
              ? prevSelectsOptions[fieldName]
              : [];

          const allOptions = [...previousPage, ...itemsArr];

          const filteredOptions = allOptions.filter(
            (value, index, self) =>
              index === self.findIndex((item) => item.id === value.id)
          );

          return {
            ...prevSelectsOptions,
            [fieldName]: filteredOptions,
          };
        });
      },
    });
  };

  useEffect(() => {
    inputs.forEach((element) => {
      if (
        ['select', 'conditional-select', 'multiselect', 'autocomplete'].indexOf(
          element.type
        ) !== -1
      ) {
        if (!Array.isArray(element.options)) {
          if (['autocomplete', 'multiselect'].includes(element.type)) {
            if (autocompletePaginations[element.name]?.page >= 0) {
              getOptions(
                element.name,
                element.options,
                element.filter,
                {
                  ...(autocompletePaginations[element.name]?.page !== null
                    ? {
                        skip: 0,
                        take:
                          autocompletePaginations[element.name]?.page * 10 + 10,
                      }
                    : {}),
                  regionsIds: [formik.values.regionId],
                },
                autocompletePaginations[element.name]?.page
              );
            }
          } else {
            getOptions(element.name, element.options, element.filter);
          }
        } else {
          setSelectsOptions((prevSelectsOptions) => ({
            ...prevSelectsOptions,
            [element.name]: element.options,
          }));
        }
      }
    });
  }, [selectValues.join(',')]);

  useEffect(() => {
    const multiSelectInputs = inputs.filter(
      (element) => element.type === 'multiselect'
    );

    multiSelectInputs?.forEach((element) => {
      if (!selectsOptions[element.name]) {
        if (autocompletePaginations[element.name]?.page >= 0) {
          getOptions(
            element.name,
            element.options,
            element.filter,
            {
              ...(autocompletePaginations[element.name]?.page !== null
                ? {
                    skip: 0,
                    take: autocompletePaginations[element.name]?.page * 10 + 10,
                  }
                : {}),
              regionsIds: [formik.values.regionId],
            },
            autocompletePaginations[element.name]?.page
          );

          if (
            user?.regionId === formik?.values?.regionId &&
            user?.areas?.length > 0
          ) {
            user?.areas?.forEach((area) => {
              setSelectsOptions((prevSelectsOptions) => {
                const prevAreas = prevSelectsOptions?.areas || [];

                return {
                  ...prevSelectsOptions,
                  areas: [
                    ...prevAreas,
                    { id: area.id, label: area.name, value: area.id },
                  ],
                };
              });
            });
          }
        }
      }
    });
  }, [autocompletePaginations]);

  const isTouched = useTouched(formik.values, initialValues);
  watchTouched(isTouched);

  const cancelFormChange = () => {
    setDisabled(true);
    formik.handleReset();
  };

  const cancelWrapper = () => {
    if (overrideOnCancel) {
      overrideOnCancel(isTouched);
    } else {
      cancelFormChange();
    }
  };

  useEffect(() => {
    cancelFormChange();
  }, [activeSection]);

  const getMoreOptions = (input, page, queryParameters = {}) => {
    getOptions(
      input.name,
      input.options,
      input.filter,
      {
        ...queryParameters,
        take: page * 10 + 10,
        skip: page * 10,
        regionsIds: [formik.values.regionId],
      },
      page
    );
  };

  return (
    <Form onSubmit={formik?.handleSubmit}>
      <AntdRow gutter={[50, 30]}>
        {inputs?.map((item) => {
          if (
            item.hasTheField &&
            !item.hasTheField.includes(formik?.values?.role)
          ) {
            return null;
          }

          const permaDisabledPermission =
            item.permanentDisabled && item.permanentDisabled[userData?.role];
          const othersUpdatePermission =
            item.updateOthersProperty &&
            item.updateOthersProperty[userData.role] &&
            item.updateOthersProperty[userData.role].includes(user?.role);
          const permissionsDisabled =
            permaDisabledPermission && !othersUpdatePermission;

          if (item.type === 'text' || item.type === 'email') {
            const customChange = (e) => {
              const input = e.target.value;
              let output = '';

              if (item.mask === 'cpf') output = cpfMask(input);
              else if (item.mask === 'phone') output = phoneMask(input);
              else output = input;

              formik?.setFieldValue(item.name, output);
            };

            return (
              <AntdCol
                key={item?.name}
                xs={{ span: 24 }}
                lg={{ span: item?.span || 12 }}
              >
                <TextField
                  type={item.type}
                  label={item?.label}
                  name={item?.name}
                  disabled={permissionsDisabled || disabled}
                  onChange={customChange}
                  onBlur={formik.handleBlur}
                  value={formik?.values[item?.name] || ''}
                  error={formik?.errors[item?.name] || ''}
                  schema={InputSchema}
                />
              </AntdCol>
            );
          }

          if (item.type === 'textarea') {
            const customChange = (e) => {
              const input = e.target.value;
              let output = input;

              if (item.mask === 'cpf') output = cpfMask(input);
              else if (item.mask === 'phone') output = phoneMask(input);

              formik.setFieldValue(item.name, output);
            };

            return (
              <AntdCol
                key={item?.name}
                xs={{ span: 24 }}
                lg={{ span: item?.span || 12 }}
              >
                <TextArea
                  type={item?.type}
                  label={item?.label}
                  name={item?.name}
                  disabled={permissionsDisabled || disabled}
                  onChange={customChange}
                  value={formik?.values[item?.name] || ''}
                  error={formik?.errors[item?.name] || ''}
                  placeholder={item?.placeholder}
                  schema={InputSchema}
                />
              </AntdCol>
            );
          }

          if (item.type === 'select') {
            return (
              <AntdCol
                key={item?.name}
                xs={{ span: 24 }}
                lg={{ span: item?.span || 12 }}
              >
                <FloatingLabelSelect
                  label={item?.label}
                  name={item?.name}
                  disabled={permissionsDisabled || disabled}
                  onChange={(e) => {
                    formik.setFieldValue(item.name, e);
                    item?.clearOnChange?.forEach((field) => {
                      formik.setFieldValue(field, '');
                      setAutocompletePaginations((prev) => ({
                        ...prev,
                        [field]: {
                          page: 0,
                          hasMore: true,
                        },
                      }));
                    });
                  }}
                  onBlur={formik.handleBlur}
                  value={formik?.values[item?.name] || ''}
                  options={selectsOptions[item?.name]}
                  error={formik?.errors[item?.name] || ''}
                  schema={InputSchema}
                />
              </AntdCol>
            );
          }

          if (item.type === 'autocomplete') {
            return (
              <AntdCol
                key={item?.name}
                xs={{ span: 24 }}
                lg={{ span: item?.span || 12 }}
              >
                <InfiniteAutocomplete
                  label={item?.label}
                  name={item?.name}
                  error={formik?.errors[item?.name] || ''}
                  options={selectsOptions[item?.name]}
                  onBlur={formik.handleBlur}
                  value={formik?.values[item?.name] || ''}
                  onSelect={(e) => formik.setFieldValue(item.name, e)}
                  disabled={permissionsDisabled || disabled}
                  schema={InputSchema}
                  dropdownClassName={item?.name}
                  hasMore={autocompletePaginations[item?.name]?.hasMore}
                  loadMore={(_, page) => {
                    getMoreOptions(item, page, {
                      regionsIds: [formik.values.regionId],
                    });
                  }}
                  page={autocompletePaginations[item?.name]?.page}
                  onSearch={(text) => {
                    getMoreOptions(item, 0, { name: text });
                  }}
                  labelClass="autocompleteLabel"
                />
              </AntdCol>
            );
          }

          if (item.type === 'multiselect') {
            return (
              <AntdCol
                key={item?.name}
                xs={{ span: 24 }}
                lg={{ span: item?.span || 12 }}
              >
                <MultiSelect
                  mode="multiple"
                  label={item?.label}
                  name={item?.name}
                  absoluteError
                  onChange={(e) => formik.setFieldValue(item.name, e)}
                  value={formik?.values[item?.name] || []}
                  error={formik?.errors[item?.name] || ''}
                  options={selectsOptions[item?.name]}
                  disabled={permissionsDisabled || disabled}
                  schema={InputSchema}
                  hasMore={autocompletePaginations[item?.name]?.hasMore}
                  loadMore={(_, page) => {
                    getMoreOptions(item, page, {
                      regionsIds: [formik.values.regionId],
                    });
                  }}
                  page={autocompletePaginations[item?.name]?.page}
                  onSearch={(text) => {
                    getMoreOptions(item, 0, { name: text });
                  }}
                  dropdownClassName={item?.name}
                />
              </AntdCol>
            );
          }

          if (item.type === 'checkbox') {
            const updateField = (e) => {
              formik.setFieldValue(item.name, e.target.checked);
            };
            return (
              <AntdCol key={item?.name} span={item?.span || 12}>
                <StyledCheckbox
                  disabled={permissionsDisabled || disabled}
                  name={item?.name}
                  checked={formik?.values[item?.name]}
                  onChange={(e) => updateField(e)}
                  schema={InputSchema}
                >
                  {item?.label}
                </StyledCheckbox>
              </AntdCol>
            );
          }

          if (item.type === 'conditional-select') {
            return (
              <AntdCol
                key={item?.name}
                xs={{ span: 24 }}
                lg={{ span: item?.span || 12 }}
              >
                <DropDown
                  active={formik.values[item.dependsOn] === item.triggerValue}
                >
                  <FloatingLabelSelect
                    label={item?.label}
                    name={item?.name}
                    disabled={permissionsDisabled || disabled}
                    onChange={(e) => formik.setFieldValue(item.name, e)}
                    onBlur={formik.handleBlur}
                    value={formik?.values[item?.name] || ''}
                    options={selectsOptions[item?.name]}
                    error={formik?.errors[item?.name] || ''}
                    schema={InputSchema}
                  />
                </DropDown>
              </AntdCol>
            );
          }

          if (item.type === 'title') {
            return (
              <AntdCol
                key={item?.label}
                xs={{ span: 24 }}
                lg={{ span: item?.span || 24 }}
              >
                <BoardSubTitle>{item?.label}</BoardSubTitle>
              </AntdCol>
            );
          }
          return null;
        })}
      </AntdRow>

      {!disabled && (
        <FormButtonsContainer>
          <span ref={regionRef} style={{ display: 'none' }}>
            {formik.values.regionId}
          </span>
          <RoundButton
            backgroundColor={colors.darkGreen}
            textColor={colors.white}
            type="submit"
            disabled={formik.isSubmitting}
            onClick={formik.handleSubmit}
          >
            {formik.isSubmitting && <AntdSpin indicator={spinIcon} />}
            {buttonsTexts[0] || 'Salvar alterações'}
          </RoundButton>

          <RoundButton
            backgroundColor={colors.gray}
            textColor={colors.smoothBlack}
            onClick={cancelWrapper || cancelFormChange}
            {...(cancelWrapper ? { type: 'button' } : {})}
          >
            {buttonsTexts[1] || 'Cancelar edição'}
          </RoundButton>
        </FormButtonsContainer>
      )}
    </Form>
  );
};

export default InputsContainer;
