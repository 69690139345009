import normalizeInputText from "./normalizeInputText";

const requesterMatchFunction = (text, options) => {
  const inputString = normalizeInputText(text);

  return options
    .filter(
      (option) =>
        normalizeInputText(option.label).indexOf(inputString) !== -1 ||
        normalizeInputText(option.email).indexOf(inputString) !== -1 
    ).map((option) => ({
      value: option.value,
      label: `${option.label} - Região ${option.region?.name}`,
    })).slice(0, 3);
}

export default requesterMatchFunction;
