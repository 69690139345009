import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import Citizen from 'assets/icons/citizen-icon.svg';
import UndoIcon from 'assets/icons/undo.svg';
import { useUserInfo } from 'contexts/UserProvider/UserProvider';
import useRequest from 'hooks/use-request';

import {
  AllocatedCard,
  AllocatedFooter,
  AllocatedTitle,
  Bold,
  Card,
  CitizenData,
  CitizenIcon,
  Content,
  Footer,
  FooterButton,
  InfoText,
  Name,
  SecondaryText,
  SecondTitle,
  StyledDivider,
  Text,
  Title,
  Undo,
  UndoButton,
  Wrapper,
} from './AttendantCallCard.style';

function AttendantCallCard({
  createdAt = new Date().toString(),
  requester = {},
  motive = {},
  id,
  get = () => {},
  ...props
}) {
  const [triggerAllocate, setTriggerAllocate] = useState(false);
  const [allocated, setAllocated] = useState(false);
  const [countdown, setCountdown] = useState(5);
  const { userData } = useUserInfo();
  const request = useRequest();
  const [hideCards, setHideCards] = useState(false);
  const history = useHistory();

  const handleAllocate = () => {
    setTriggerAllocate(true);
    setTimeout(() => setAllocated(true), 300);
  };

  const handleUndo = () => {
    setTriggerAllocate(false);
    setAllocated(false);
  };

  const allocateToCall = async () => {
    await request.patch({
      url: `/call/${id}`,
      body: {
        ownerId: userData.id,
        status: 'opened',
      },
      onSuccess: () => {
        setHideCards(true);
        get();
      },
    });
  };

  useEffect(() => {
    if (countdown === -1) {
      allocateToCall();
      setHideCards(true);
    } else if (allocated && countdown <= 5) {
      setTimeout(() => setCountdown(countdown - 1), 1000);
    } else {
      setCountdown(5);
    }
  }, [allocated, countdown]);

  const seeDetailsClick = () => {
    history.push(`/central-do-morador/${id}`);
  };

  return (
    <Wrapper hide={hideCards}>
      {!allocated ? (
        <Card hide={allocated} allocated={triggerAllocate}>
          <Content>
            <Title>{moment(createdAt).format('dddd, DD/MM/YYYY')}</Title>
            <Text>{moment(createdAt).fromNow()}</Text>
            <SecondTitle>{motive?.name}</SecondTitle>
            <SecondaryText>
              <CitizenIcon src={Citizen} alt="Avatar do solicitante" />
              Solicitante
            </SecondaryText>
            <CitizenData>
              <Name onClick={() => history.push(`/central-do-morador/${id}`)}>
                {requester?.name}
              </Name>
              <InfoText>
                <Bold>{`CPF: `}</Bold>
                {requester?.cpf}
              </InfoText>
              <InfoText>
                <Bold>{`Área/empreendimento: `}</Bold>
                {requester?.area?.name}
              </InfoText>
              <InfoText>
                <Bold>{`Regional ${requester?.region?.name}`}</Bold>
              </InfoText>
              <InfoText>
                <Bold>{`Canal de Comunicação: `}</Bold>
                {props?.channel?.name}
              </InfoText>
            </CitizenData>
          </Content>
          <Footer>
            <FooterButton onClick={seeDetailsClick} color="green">
              Ver detalhes
            </FooterButton>
            <FooterButton onClick={() => handleAllocate()}>
              Me alocar
            </FooterButton>
          </Footer>
        </Card>
      ) : (
        <AllocatedCard fade={countdown <= 0}>
          <AllocatedTitle>Você se alocou no chamado!</AllocatedTitle>
          <StyledDivider />
          <AllocatedFooter onClick={() => handleUndo()}>
            {`${countdown} seg`}
            <UndoButton>
              <Undo src={UndoIcon} alt="desfazer" />
              Desfazer
            </UndoButton>
          </AllocatedFooter>
        </AllocatedCard>
      )}
    </Wrapper>
  );
}

export default AttendantCallCard;
