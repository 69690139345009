export const usersInputs = [
	{
		type: 'text',
		label: 'Nome completo',
		name: 'name',
		rules: ['required'],
		span: 12,
	},
	{
		type: 'text',
		label: 'E-mail',
		name: 'email',
		rules: ['required'],
		span: 12,
		permanentDisabled: {
      admin: true,
      supervisor: true,
      coordinator: true,
      technique: true,
      attendant: true,
    },
	},
	{
		type: 'select',
		label: 'Regional',
		name: 'regionId',
		rules: ['required'],
		span: 6,
		options: '/region',
		permanentDisabled: {
      supervisor: false,
      technique: false,
    },
    updateOthersProperty: {
      supervisor: ['technique', 'attendant'],
    },
    hasTheField: [
      'technique',
      'supervisor',
    ]
	},
	{
		type: 'select',
		label: 'Tipo de Usuário',
		name: 'role',
		rules: ['required'],
		span: 9,
		options: [
			{
				value: 'technique',
				label: 'Técnica',
			},
			{
				value: 'coordinator',
				label: 'Coordenador',
			},
			{
				value: 'admin',
				label: 'Admin',
			},
			{
				value: 'supervisor',
				label: 'Supervisor',
			},
			{
				value: 'attendant',
				label: 'Atendente',
			},
		],
		permanentDisabled: {
      supervisor: true,
      coordinator: true,
      technique: true,
      attendant: true,
    },
	},
	{
		type: 'conditional-select',
		label: 'Cargo',
		name: 'typeOperatorId',
		rules: [],
		span: 9,
		options: '/type-operator',
		dependsOn: 'role',
		triggerValue: 'technique',
		permanentDisabled: {
      supervisor: true,
      coordinator: true,
      technique: false,
      attendant: true,
    },
	},
	{
		type: 'multiselect',
		label: 'Áreas / Empreendimentos',
		name: 'areas',
		submitId: 'areasIds',
		rules: ['multiselect'],
		filter: (items, context) => {
			if (context.regionId) {
				return items.filter(({ regionId }) => context.regionId === regionId);
			}
			return items;
		},
		span: 9,
		options: '/area',
		permanentDisabled: {
      supervisor: false,
      coordinator: false,
      technique: false,
      attendant: false,
    },
    updateOthersProperty: {
      supervisor: ['technique', 'attendant'],
    },
    hasTheField: [
      'technique',
    ]
	},
]
