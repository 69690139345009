import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { DownloadFile } from 'components';
import { FormButton, TextArea } from 'components/inputs';
import { useConfirmation } from 'contexts';
import { useConfirmCancel } from 'contexts/ConfirmCancelProvider/ConfirmCancelProvider';
import useTouched from 'hooks/use-touched';

import {
  Buttons,
  EditButton,
  FormButtonContainer,
  Select,
  SelectContainer,
  StyledUpload,
  SubTitle,
  UploadButton,
  UploadIcon,
  Wrapper,
} from './DocumentInfo.style';

function DocumentInfo({
  initialValues,
  footer = false,
  upload = false,
  closeModal = () => {},
  submitDocument,
  editDocument,
  removeDocument,
  documentTypes,
  watchTouched = () => {},
}) {
  const [file, setFile] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [options, setOptions] = useState(null);
  const { confirm } = useConfirmation();

  const CreateDocumentSchema = Yup.object().shape({
    status: Yup.string().required('Este campo é obrigatório'),
    typeId: Yup.string().required('Este campo é obrigatório'),
  });

  const handleDelete = async () => {
    await removeDocument(initialValues.id);
  };

  const handleDeleteModal = () => {
    confirm({
      question: 'Tem certeza que deseja excluir o documento?',
      okText: 'Excluir',
      onOk: handleDelete,
    });
  };

  const handleSubmit = async (values) => {
    const form = new FormData();
    if (file) {
      form.append('documentImage', file);
    }
    form.set('typeId', values.typeId);
    form.set('observation', values.observation);
    if (values.status !== 'pending' && !editMode) {
      form.set('status', values.status);
    }
    form.set(
      'requesterId',
      window.location.pathname.split('/central-do-morador/')[1]
    );

    if (editMode) {
      await editDocument(values.id, form);
      setEditMode(false);
    } else {
      await submitDocument(form);
      closeModal();
    }
  };

  const confirmCancel = useConfirmCancel();
  const formik = useFormik({
    initialValues: initialValues || {
      typeId: '',
      status: '',
      observation: '',
    },
    validationSchema: CreateDocumentSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  useEffect(() => {
    const itemArr = documentTypes?.map(({ id, name }) => ({
      value: id,
      label: name,
    }));
    setOptions(itemArr);
  }, [documentTypes]);

  const touched = useTouched(formik.values, initialValues);
  watchTouched(touched);

  return (
    <Wrapper upload={upload} onSubmit={formik.handleSubmit}>
      {!upload && (
        <Buttons>
          <EditButton type="button" onClick={() => setEditMode(true)}>
            Editar
          </EditButton>
          <EditButton type="button" onClick={handleDeleteModal}>
            Excluir
          </EditButton>
        </Buttons>
      )}
      <SelectContainer>
        <Select
          disabled={!upload && !editMode}
          label="Tipo do documento"
          name="typeId"
          absoluteError
          onChange={(e) => formik.setFieldValue('typeId', e)}
          error={formik.errors.typeId}
          value={formik.values.typeId}
          options={options}
          onBlur={formik.handleBlur}
          schema={CreateDocumentSchema}
        />
        <Select
          disabled={!upload && !editMode}
          label="Status do documento"
          name="status"
          absoluteError
          onChange={(e) => formik.setFieldValue('status', e)}
          error={formik.errors.status}
          value={formik.values.status}
          options={[
            { value: 'pending', label: 'Aguardando aprovação' },
            { value: 'approved', label: 'Aprovado' },
          ]}
          schema={CreateDocumentSchema}
          onBlur={formik.handleBlur}
        />
      </SelectContainer>
      <SubTitle>Anexo documento</SubTitle>
      {upload || editMode ? (
        <StyledUpload
          beforeUpload={() => false}
          maxCount={1}
          onChange={(e) => setFile(e.file)}
        >
          <UploadButton type="button">
            {editMode ? 'Editar arquivo' : 'Anexar arquivo'}
            <UploadIcon />
          </UploadButton>
        </StyledUpload>
      ) : (
        <DownloadFile url={initialValues?.path} />
      )}
      <TextArea
        disabled={!upload && !editMode}
        type="textarea"
        label="Observações sobre o documento"
        name="observation"
        schema={CreateDocumentSchema}
        onChange={formik.handleChange}
        value={formik.values.observation}
        error={formik.errors.observation}
        placeholder={
          upload &&
          'Observação sobre o documento que foi cadastrado na plataforma. Aqui deve conter informações que são relevantes para o conhecimento de todo o time.'
        }
      />
      {footer && (
        <FormButtonContainer>
          <FormButton
            onClick={() => confirmCancel(closeModal, touched)}
            $cancel
          >
            Cancelar
          </FormButton>
          <FormButton
            disabled={!file}
            loading={formik.isSubmitting}
            htmlType="submit"
          >
            Salvar
          </FormButton>
        </FormButtonContainer>
      )}

      {editMode && (
        <FormButtonContainer>
          <FormButton onClick={() => setEditMode(false)} $cancel>
            Cancelar
          </FormButton>
          <FormButton loading={formik.isSubmitting} htmlType="submit">
            Salvar
          </FormButton>
        </FormButtonContainer>
      )}
    </Wrapper>
  );
}

export default DocumentInfo;
