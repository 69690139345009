import moment from "moment";

export const disabledDates = (current) => {
  const yesterday = moment().subtract(1, 'days');
  return current.isBefore(yesterday);
}

export const disablePastHours = (reminderDate) =>  (now) => {
  
  if (reminderDate && !reminderDate.isSame(now, 'day')) {
    return {
      disabledHours: () => [],
      disabledMinutes: () => [],
      disabledSeconds: () => [],
    }
  }
  const currentHour = now.hour();

  return {
    disabledHours: () =>
      Array.from({ length: currentHour }, (_, index) => index),
    disabledMinutes: (selectedHour) => {
      if (selectedHour === currentHour) {
        const currentMinute = now.minute();
        return Array.from({ length: currentMinute }, (_, index) => index);
      }
      return [];
    },
    disabledSeconds: (selectedHour, selectedMinute) => {
      if (selectedHour === currentHour && selectedMinute === now.minute()) {
        const currentSecond = now.second();
        return Array.from({ length: currentSecond }, (_, index) => index);
      }
      return [];
    },
  };
};

export const getReminderDatetime = (date, time) => {
  const reminderDate = moment(date).format('YYYY-MM-DD');
  const reminderTime = moment(time).format('HH:mm:ss');

  return `${reminderDate}T${reminderTime}.000Z`;
};
