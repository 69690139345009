const paramModifierMap = {
  get: (url, body, config) => [url, config],
  post: (url, body, config) => [url, body, config],
  patch: (url, body, config) => [url, body, config],
  delete: (url, body, config) => [url, config],
};

const getParamModifier = (method) => paramModifierMap[method];

export default getParamModifier;
