import { useEffect, useRef, useState } from 'react';

import alertSvg from 'assets/icons/alert.svg';
import { thisFieldHasRequired } from 'helpers/lookForRequiredOnYupSchema';

import {
  Container,
  Error,
  ErrorIcon,
  Label,
  TextAreaInput,
} from './TextArea.style';

function TextArea({
  label,
  name,
  className = '',
  error,
  value,
  schema,
  absoluteError,
  placeholder = '',
  ...props
}) {
  const [focused, setFocused] = useState(!!value);
  const [rows, setRows] = useState(1);
  const inputRef = useRef(null);

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    if (inputRef.current.value === '') setFocused(false);
  };

  useEffect(() => {
    if (value) setFocused(true);
  }, [value]);

  useEffect(() => {
    const height = inputRef.current.scrollHeight;
    const rowHeight = 15;
    const textRows = Math.ceil(height / rowHeight) - 1;

    if (textRows >= 10) {
      setRows(10);
    } else {
      setRows(textRows);
    }
  }, [inputRef]);

  return (
    <Container className={className}>
      {label && (
        <Label focused={focused || placeholder} htmlFor={name}>
          {`${label} ${thisFieldHasRequired(schema, name) ? '*' : ''}`}
        </Label>
      )}
      <TextAreaInput
        rows={rows}
        height={rows !== 3 && `${rows * 15}px`}
        focused={focused}
        ref={inputRef}
        name={name}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={value}
        placeholder={placeholder}
        {...props}
      />
      {error && (
        <Error absoluteError={absoluteError}>
          <ErrorIcon src={alertSvg} alt="Ícone de erro" />
          {error}
        </Error>
      )}
    </Container>
  );
}

export default TextArea;
