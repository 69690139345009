import { Input } from 'antd';
import styled, { css } from 'styled-components';

import { Button } from 'components/inputs';
import { respondTo } from 'styles/mixins';

export const Grid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, 420px);
  grid-gap: 30px;
`;

export const Title = styled.div`
  font-size: 1.125rem;
  letter-spacing: 0.36px;
  margin-bottom: 35px;
`;

export const Container = styled.div`
  padding: 50px;
  ${respondTo('medium')(css`
    padding: 20px;
  `)}
`;

export const SearchBar = styled(Input)`
  border-radius: 10px;
  box-shadow: 1px 1px 20px #d6d6d6 !important;
  line-height: 40px;
  background-color: white !important;
  font-size: 1rem;
  margin-bottom: 30px;
  width: 75vw;

  ${respondTo('medium')(css`
    border-radius: 50px;
    background: transparent !important;
    .ant-input {
      background: transparent !important;
    }
    box-shadow: none !important;
    border: 1px solid black !important;
    width: 100%;
  `)}
`;

export const Center = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const MobileList = styled.div`
  display: flex;
  justify-content: center;
`;

export const Cards = styled.ul`
  width: 100%;
`;

export const WebTitle = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const ListItem = styled.li`
  list-style-type: none;
`;

export const StyledButton = styled(Button)`
  height: 2.45rem;
  width: 10rem;
  font-size: 0.875rem;
  ${respondTo('small')(css`
    width: 8.6rem;
    font-size: 0.8rem;
  `)}
`;
