import { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import TextArea from 'components/inputs/TextArea';
import TextField from 'components/inputs/TextField';

import { useSelectOptions } from '../SelectOptionsProvider';

import {
  Button,
  ButtonContainer,
  DropDown,
  SectionTitle,
  Select,
} from './FormSection.style';
import {
  cpfMask,
  getFieldRules,
  getInitialValues,
  getOrderStatus,
  phoneMask,
} from './helpers';

function FormSection({
  disabled = false,
  section,
  values,
  onSubmit,
  goBack,
  orderInfo,
  setTouched,
  watchTouched = () => {},
}) {
  const { inputs, title } = section;
  const selectsOptions = useSelectOptions();
  const [usableSelectsOptions, setUsableSelectsOptions] = useState({});
  const fieldRules = getFieldRules(inputs);

  const FormSchema = Yup.object().shape(fieldRules);

  useEffect(() => {
    if (typeof selectsOptions === 'object' && selectsOptions !== null) {
      setUsableSelectsOptions((previousObject) => ({
        ...previousObject,
        ...selectsOptions,
      }));
    }
  }, [selectsOptions]);

  const formik = useFormik({
    initialValues: getInitialValues(inputs, values),
    validationSchema: FormSchema,
    validateOnChange: false,
    onSubmit: (result) => onSubmit(result),
  });

  useEffect(() => {
    if (formik.values.regionId) {
      setUsableSelectsOptions((previousObject) => ({
        ...previousObject,
        areaId: selectsOptions?.areaId?.filter(
          ({ regionId }) => formik.values.regionId === regionId
        ),
      }));
    }
  }, [formik.values.regionId]);

  useEffect(() => {
    const touched = watchTouched(formik.touched);
    if (touched) {
      setTouched(true);
    }
  }, [formik.touched]);

  const orderState = getOrderStatus(orderInfo.total, orderInfo.current);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Row gutter={[50, 30]}>
        <Col>
          <SectionTitle>{title}</SectionTitle>
        </Col>
        {inputs?.map((item) => (
          <Col key={item?.name} span={24}>
            {(item.type === 'text' || item.type === 'email') &&
              (() => {
                const handleChangeMap = {
                  cpf: ({ target }) => {
                    formik.setFieldValue(item.name, cpfMask(target.value));
                  },
                  phone: ({ target }) => {
                    formik.setFieldValue(item.name, phoneMask(target.value));
                  },
                  default: formik.handleChange,
                };

                const onChange = handleChangeMap[item.mask || 'default'];

                return (
                  <TextField
                    type={item.type}
                    label={item?.label}
                    name={item?.name}
                    disabled={item?.permanentDisabled || disabled}
                    onChange={onChange}
                    onBlur={formik.handleBlur}
                    value={formik?.values[item?.name] || ''}
                    error={formik?.errors[item?.name] || ''}
                    schema={FormSchema}
                  />
                );
              })()}

            {item.type === 'textarea' &&
              (() => (
                <TextArea
                  type={item?.type}
                  label={item?.label}
                  name={item?.name}
                  disabled={item?.permanentDisabled || disabled}
                  onChange={formik.handleChange}
                  value={formik?.values[item?.name] || ''}
                  error={formik?.errors[item?.name] || ''}
                  placeholder={item?.placeholder}
                  schema={FormSchema}
                />
              ))()}

            {item.type === 'select' && (
              <Select
                label={item?.label}
                name={item?.name}
                disabled={disabled}
                onChange={(e) => formik.setFieldValue(item.name, e)}
                onBlur={formik.handleBlur}
                value={formik?.values[item?.name] || ''}
                options={usableSelectsOptions[item?.name]}
                error={formik?.errors[item?.name] || ''}
                schema={FormSchema}
              />
            )}

            {item.type === 'conditional-select' && (
              <DropDown
                active={formik.values[item.dependsOn] === item.triggerValue}
              >
                <Select
                  label={item?.label}
                  name={item?.name}
                  disabled={disabled}
                  onChange={(e) => formik.setFieldValue(item.name, e)}
                  onBlur={formik.handleBlur}
                  value={formik?.values[item?.name] || ''}
                  options={usableSelectsOptions[item?.name]}
                  error={formik?.errors[item?.name] || ''}
                  schema={FormSchema}
                />
              </DropDown>
            )}
          </Col>
        ))}
        <Col span={24}>
          <ButtonContainer>
            <Button type="button" onClick={() => goBack(formik.values)}>
              {orderState === 'start' ? 'Cancelar' : 'Voltar'}
            </Button>
            <Button
              type="submit"
              next={orderState !== 'end'}
              submit={orderState === 'end'}
            >
              {orderState === 'end' ? 'Enviar' : 'Próximo'}
            </Button>
          </ButtonContainer>
        </Col>
      </Row>
    </form>
  );
}

export default FormSection;
