import locale from 'antd/es/date-picker/locale/pt_BR';
import moment from 'moment-timezone';

import alertSvg from 'assets/icons/alert.svg';
import { thisFieldHasRequired } from 'helpers/lookForRequiredOnYupSchema';

import {
  Border,
  Error,
  ErrorIcon,
  Label,
  SingleTimePicker,
  Wrapper,
} from './TimePicker.style';

import 'moment/locale/pt-br';

function TimePicker({
  label,
  formik,
  value: initialValue,
  name,
  schema,
  error,
  absoluteError,
  single,
  disabled,
  renderLabel = true,
  customOnChange = null,
  ...props
}) {
  const handleFormat = (value) =>
    `${moment(value).locale('pt-br').format('HH[:]mm')}`;

  return (
    <Wrapper>
      <Border>
        {renderLabel && (
          <Label>
            {label
              ? `${label} ${thisFieldHasRequired(schema, name) ? '*' : ''}`
              : 'Horário'}
          </Label>
        )}
        <SingleTimePicker
          value={initialValue}
          disabled={disabled}
          format={(value) => handleFormat(value)}
          locale={locale}
          bordered={false}
          onSelect={(value) => {
            formik.setFieldValue(name, moment.tz(value, 'America/Sao_Paulo'));
          }}
          onChange={
            customOnChange ||
            ((value) => {
              formik.setFieldValue(name, moment.tz(value, 'America/Sao_Paulo'));
            })
          }
          {...props}
        />
      </Border>
      {error && (
        <Error absoluteError={absoluteError}>
          <ErrorIcon src={alertSvg} alt="Ícone de erro" />
          {error}
        </Error>
      )}
    </Wrapper>
  );
}

export default TimePicker;
