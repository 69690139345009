import { MessageBox, SystemMessage } from 'react-chat-elements';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled from 'styled-components';

import colors from 'styles/colors';

export const Wrapper = styled.div`
  background-color: #ffffff;
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
  padding: 1rem;
  margin: 0 auto;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
`;

export const StyledMessage = styled(MessageBox)`
  & > div {
    transition: background-color 0.5s ease, color 0.5s ease;
  }

  &.message-found > div {
    background-color: ${colors.disabledGray} !important;
  }

  .rce-mbox {
    overflow: hidden;
    max-width: 80%;
    color: #434446;
    margin-bottom: 0.8rem;
    background-color: ${colors.smoothGray};
    box-shadow: 1px 1px 1px 1px rgb(0 0 0 / 10%);

    .rce-mbox-text {
      white-space: pre-line;
    }

    .rce-mbox-right-notch path {
      fill: ${colors.smoothGray};
    }
  }

  .rce-mbox:not(.rce-mbox-right) {
    background-color: ${colors.primary};

    path {
      fill: ${colors.primary};
    }
  }

  .rce-mbox-photo--img {
    img {
      cursor: pointer;
    }
  }
`;

export const StyledSystemMessage = styled(SystemMessage)`
  .rce-smsg {
    max-width: 70%;
    color: #434446;
    margin-bottom: 0.8rem;
    background-color: ${colors.smoothGray};
    box-shadow: 1px 1px 1px 1px rgb(0 0 0 / 10%);
  }
`;

export const StyledScroll = styled(InfiniteScroll)`
  display: flex;
  flex-direction: column-reverse;
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 1rem;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
