import {
  ButtonContainer,
  Content,
  StyledButton,
  StyledModal,
  Subtitle,
  TextInput,
  Title,
} from './ConfirmPasswordModal.style';

function ConfirmPasswordModal({ visible, setVisible, onOk }) {
  const validate = (setValidated) => {
    setValidated(true);
    setVisible(false);
  };

  return (
    <StyledModal
      onCancel={() => setVisible(false)}
      destroyOnClose
      width="62rem"
      visible={visible}
      footer={null}
    >
      <Content>
        <Title>Confirme sua senha atual para modificar seus dados</Title>
        <Subtitle>
          Pedimos a confirmação de senha para sua segurança, tendo em vista que
          seus dados são sensíveis pedimos a confirmação de que é você que está
          alterando
        </Subtitle>
        <TextInput label="Coloque a sua senha atual" type="password" />
        <ButtonContainer>
          <StyledButton onClick={() => validate(onOk)}>Confirmar</StyledButton>
        </ButtonContainer>
      </Content>
    </StyledModal>
  );
}

export default ConfirmPasswordModal;
