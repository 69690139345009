import { Button } from 'antd';
import styled from 'styled-components';

import colors from 'styles/colors';

export const StyledButton = styled(Button)`
  height: 1.7rem;
  background-color: ${colors.green};
  color: ${colors.white};
  border-radius: 20px;
  min-width: 7.5rem;
  font-weight: 600;
  font-size: 0.875rem;
  border: none;

  &:hover,
  &:focus {
    background-color: ${colors.info};
    color: ${colors.white};
  }
`;
