import { useEffect, useState } from 'react';

import { DoughnutChart } from 'components';
import colors from 'styles/colors';

function OperatorResolution({ data: requestData }) {
  const [data, setData] = useState();
  const staticColors = [colors.green, colors.aquaBlueLight, colors.lightOrange];
  useEffect(() => {
    const graphData = requestData?.graphOperator;
    if (graphData) {
      const formattedData = requestData?.graphData?.map((obj, index) => ({
        ...obj,
        color: staticColors[index],
      }));
      setData(formattedData);
    }
  }, [requestData]);

  return (
    data?.length > 0 && (
      <DoughnutChart
        title="Taxa de resolução por operador"
        data={data}
        colors={staticColors}
        flex
      />
    )
  );
}

export default OperatorResolution;
