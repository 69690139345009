import alertSvg from 'assets/icons/alert.svg';

import {
  Container,
  Error,
  ErrorIcon,
  Label,
  StyledSelect,
} from './FormSelect.style';

function FormSelect({ children, label, error, ...props }) {
  return (
    <Container>
      <Label>{label}</Label>
      <StyledSelect {...props}>{children}</StyledSelect>
      {error && (
        <Error>
          <ErrorIcon src={alertSvg} alt="Ícone de erro" />
          {error}
        </Error>
      )}
    </Container>
  );
}

export default FormSelect;
