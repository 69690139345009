import { Col as AntCol, Row as AntRow } from 'antd';
import styled, { css } from 'styled-components';

import { LongStrings as LongStringsNoise } from 'components/icons/noise';
import { respondTo } from 'styles/mixins';

export const Page = styled.section`
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100vh;
`;

export const Content = styled.section`
  width: 62%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${respondTo('medium')(css`
    width: 100%;
  `)}
`;

export const Row = styled(AntRow)`
  width: 100%;
`;

export const Col = styled(AntCol)`
  display: flex;
  justify-content: center;
  align-items: center;

  ${respondTo('medium')(css`
    flex-direction: column;
  `)}
`;

export const LongStrings = styled(LongStringsNoise)`
  transform: rotateZ(-20deg);
`;
