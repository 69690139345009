import { BackPathButton } from 'components';
import useMobile from 'hooks/use-mobile';

import {
  Buttons,
  Content,
  Dash,
  PageContainer,
  Title,
  TitleContainer,
} from './TitledPage.style';

function TitledPage({
  children,
  title,
  buttons = [],
  backPath = '/dashboard',
  hasBack,
  hasText = false,
  noScroll,
  justifyButtons = '',
}) {
  const isMobile = useMobile();

  return (
    <PageContainer>
      <TitleContainer>
        {(isMobile || hasBack) && (
          <BackPathButton hasText={hasText} path={backPath} />
        )}
        <Title>{title}</Title>
        <Buttons justifyButtons={justifyButtons}>
          {buttons.map((button) => button())}
        </Buttons>
      </TitleContainer>
      <Dash />
      <Content noScroll={noScroll}>{children}</Content>
    </PageContainer>
  );
}

export default TitledPage;
