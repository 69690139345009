import { useUserInfo } from 'contexts';

export const useIsTechniqueAbleToEdit = (unifiedAttendance) => {
  const { userData } = useUserInfo();

  const isUserTechnique = userData?.role === 'technique';

  const isUserInUnifiedAttendanceUsers = unifiedAttendance?.users?.some(
    (user) => user.id === userData?.id
  );
  const isUserTheUnifiedAttendanceCreator =
    unifiedAttendance?.userId === userData?.id;

  return {
    isUserTechnique,
    isTechniqueAbleToEdit:
      isUserInUnifiedAttendanceUsers || isUserTheUnifiedAttendanceCreator,
  };
};
