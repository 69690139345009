import { useEffect, useState } from 'react';

import { DoughnutChart } from 'components';
import colors from 'styles/colors';

function OperatorUnifiedAttendances({ data: requestData }) {
  const [data, setData] = useState([]);
  const staticColors = [colors.green, colors.aquaBlueLight, colors.lightOrange];

  useEffect(() => {
    const graphOperator = requestData?.graphOperator;
    if (graphOperator) {
      const formattedData = graphOperator.map((obj, index) => ({
        ...obj,
        color: staticColors[index],
      }));
      setData(formattedData);
    }
  }, [requestData]);

  return (
    <DoughnutChart
      title="Atendimentos em aberto por operador"
      data={data}
      colors={staticColors}
      flex
    />
  );
}

export default OperatorUnifiedAttendances;
