import { useEffect, useRef, useState } from 'react';

import search from 'assets/icons/search.svg';
import useDebounce from 'hooks/use-debounce';

import { StyledSearchBar } from './SearchBar.style';

function SearchBar({
  queryIds = [],
  requestFunction,
  debounceTime = 1000,
  ...props
}) {
  const [searchTerm, setSearchTerm] = useState(null);
  const [isSearching, setIsSearching] = useState(true);
  const debouncedTerm = useDebounce(searchTerm, debounceTime);
  const inputRef = useRef();

  const filterBySearch = () => {
    const queryObject = {};
    if (debouncedTerm) {
      queryIds.forEach((query) => {
        queryObject[query] = debouncedTerm;
      });
    }
    setIsSearching(true);
    requestFunction(queryObject, () => setIsSearching(false));
  };

  useEffect(() => {
    if (isSearching === false) inputRef?.current?.focus();
  }, [isSearching]);

  useEffect(() => {
    if (searchTerm !== null) filterBySearch();
    else setIsSearching(false);
  }, [debouncedTerm]);

  return (
    <StyledSearchBar
      ref={inputRef}
      suffix={<img src={search} alt="Ícone de busca" width={25} />}
      onChange={(e) => setSearchTerm(e.target.value || '')}
      disabled={isSearching}
      {...props}
    />
  );
}
export default SearchBar;
