export default [
  {
    id: 1,
    name: 'Osório C',
    residentCount: 150,
    technicianCount: 7,
  },
  {
    id: 2,
    name: 'Palacetes dos Artistas',
    residentCount: 150,
    technicianCount: 7,
  },
  {
    id: 3,
    name: 'Palacetes dos Artistas',
    residentCount: 150,
    technicianCount: 7,
  },
  {
    id: 4,
    name: 'Palacetes dos Artistas',
    residentCount: 150,
    technicianCount: 7,
  },
  {
    id: 5,
    name: 'Palacetes dos Artistas',
    residentCount: 150,
    technicianCount: 7,
  },
  {
    id: 6,
    name: 'Osório C',
    residentCount: 150,
    technicianCount: 7,
  },
];
