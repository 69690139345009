import styled, { css } from 'styled-components';

import Autocomplete from 'components/inputs/AutocompleteInput';
import colors from 'styles/colors';

export const AdminMapContainer = styled.div`
  width: calc(100% - 2.5rem);
  margin-left: auto;
  margin-right: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
`;

export const AutocompleteInput = styled(Autocomplete)`
  box-shadow: 1px 1px 10px ${colors.darkGray};
`;

export const MapTitle = styled.h2`
  font-size: 0.8rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: ${colors.smoothBlack};
`;

export const MapWrapper = styled.div`
  width: 100%;
  position: relative;
  border-radius: 10px;
  box-shadow: 1px 1px 10px ${colors.darkGray};
  padding: 1.5rem;
  background-color: ${colors.white};
  height: 100%;
`;

export const CityTag = styled.div`
  position: absolute;
  top: 1.8rem;
  z-index: 2;
  padding: 0.15rem 0.4rem;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  box-shadow: 1px 1px 10px ${colors.darkGray};
  right: 1.4rem;
  background-color: ${colors.white};
`;

export const CityTagTitle = styled.h3`
  font-size: 0.7rem;
  margin-bottom: 0;
  color: ${colors.smoothBlack};
`;

export const CircleMark = styled.div`
  background-color: transparent;
  border-radius: 100%;
  width: 3rem;
  height: 3rem;
  border: 1px solid ${colors.lightYellow()};
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ color }) => {
    if (color === 'green')
      return css`
        border: 1px solid ${colors.cyanGreen()};

        & > div {
          background-color: ${colors.cyanGreen()};
          box-shadow: 0 0 0 0.35rem ${colors.cyanGreen(0.3)};
        }
      `;
    if (color === 'yellow')
      return css`
        border: 1px solid ${colors.lightYellow()};

        & > div {
          background-color: ${colors.lightYellow()};
          box-shadow: 0 0 0 0.35rem ${colors.lightYellow(0.3)};
        }
      `;
    if (color === 'red')
      return css`
        border: 1px solid ${colors.orangeRed()};

        & > div {
          background-color: ${colors.orangeRed()};
          box-shadow: 0 0 0 0.35rem ${colors.orangeRed(0.3)};
        }
      `;
    return css`
      border: initial;
    `;
  }}
`;

export const InnerCircle = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
`;

export const TooltipTitle = styled.h3`
  color: ${colors.black};
  font-size: 0.8rem;
  font-weight: bold;
  text-align: center;
`;

export const TooltipContent = styled.p`
  font-size: 0.8rem;
  color: ${colors.black};
  text-align: center;
  margin-bottom: 0;

  & > span {
    font-weight: 600;
    ${({ color }) => {
      if (color === 'green')
        return css`
          color: ${colors.cyanGreen()};
        `;
      if (color === 'yellow')
        return css`
          color: ${colors.lightYellow()};
        `;
      if (color === 'red')
        return css`
          color: ${colors.orangeRed()};
        `;
      return css`
        color: ${colors.black};
      `;
    }};
  }
`;
