import { UploadOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import styled, { css } from 'styled-components';

import FloatingLabelSelect from 'components/inputs/FormSelect/FloatingLabelSelect';
import colors from 'styles/colors';
import { respondTo } from 'styles/mixins';

export const Wrapper = styled.form`
  background-color: ${colors.white};
  flex: 1;
  ${({ upload }) =>
    !upload &&
    css`
      padding-bottom: 2rem;
      border-bottom: 1px solid ${colors.gray};
    `}
`;

export const SelectContainer = styled.div`
  display: flex;
  gap: 2rem;

  ${respondTo('medium')(css`
    flex-direction: column;
    gap: 1.1875rem;
  `)}
`;

export const Select = styled(FloatingLabelSelect)`
  margin: 0;
`;

export const SubTitle = styled.h3`
  color: ${colors.smoothBlack};
  font-weight: 600;
  font-size: 1.125rem;
  letter-spacing: 0.0225rem;
  line-height: 1.375rem;
  opacity: 0.8;
  padding: 1.5625rem 0 1.375rem;
  margin: 0;

  ${respondTo('medium')(css`
    font-size: 0.875rem;
  `)}
`;

export const FormButtonContainer = styled.div`
  width: 100%;
  margin: 1.875rem 0 0.375rem;
  display: flex;
  justify-content: space-around;
`;

export const StyledUpload = styled(Upload)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 2rem;

  .ant-upload {
    display: block;
    width: 100%;
  }
`;

export const UploadButton = styled.button`
  margin: 0;
  padding: 0;
  width: 100%;
  outline: none;
  border: none;
  background-color: #f7f8fa;
  border-radius: 8px;
  padding: 0.5rem;
  margin: 0;
  color: ${colors.smoothBlack};
  font-weight: 600;
  font-size: 0.875rem;
  position: relative;

  ${respondTo('medium')(css`
    font-size: 0.625rem;
  `)}
`;

export const UploadIcon = styled(UploadOutlined)`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 1rem;
`;

export const Buttons = styled.div`
  width: fit-content;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const EditButton = styled.button`
  outline: none;
  border: none;
  margin-right: 2rem;
  background-color: #e6e6e6;
  padding: 0.2rem 1rem;
  border-radius: 20px;
  font-weight: bold;
  color: ${colors.grayBlue};
`;
