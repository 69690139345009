import { useEffect, useState } from 'react';

import { DoughnutChart } from 'components';
import colors from 'styles/colors';

function AttendanceGraph({ data: requestData }) {
  const [data, setData] = useState();
  const staticColors = [
    colors.green,
    colors.aquaBlueLight,
    colors.lightOrange,
    colors.disabledGray,
  ];

  useEffect(() => {
    const graphData = requestData?.graphUnifiedAttendance;
    if (graphData) {
      const formattedData = graphData.map((obj, index) => ({
        ...obj,
        color: staticColors[index],
      }));
      setData(formattedData);
    }
  }, [requestData]);

  return (
    <DoughnutChart
      title="Canais de atendimento"
      data={data}
      colors={staticColors}
    />
  );
}

export default AttendanceGraph;
