import { Upload } from 'antd';
import styled, { keyframes } from 'styled-components';

import colors from 'styles/colors';

export const show = keyframes`
  0% {
    left: 20rem;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  background-color: #eee;
  padding: 1.1875rem 0.5rem;
`;

export const MessageInput = styled.input`
  width: 100%;
  height: 3.5625rem;
  border: none;
  border-radius: 50px;
  background-color: ${colors.white};
  outline: none;
  padding: 1rem 1.5rem;
  margin: 0 1.25rem;
  display: ${({ isRecording }) => (isRecording ? 'none' : 'block')};
`;

export const InputButton = styled.button`
  padding: 0;
  border: none;
  outline: none;
  margin: 0 0.625rem;
`;

export const ButtonIcon = styled.img`
  height: 1.5635rem;
`;

export const Container = styled.div``;

export const EmojiContainer = styled.div``;

export const RecordButtons = styled.div`
  position: relative;
  width: 12rem;
  display: flex;
  justify-content: center;
  margin: auto;
  animation: ${show} 0.5s linear;
  padding: 0.8rem 0;
`;

export const CancelAudio = styled.button`
  border-radius: 100%;
  position: relative;
  font-size: 1rem;
  width: 2rem;
  height: 2rem;
  color: ${colors.white};
  background-color: ${colors.primaryDarker};
  outline: none;
  border: none;
`;

export const SendAudio = styled.img`
  width: 2rem;
  height: 2rem;
  cursor: pointer;
`;

export const Counter = styled.div`
  text-align: center;
  margin: auto;
  color: ${colors.grayText};
  cursor: pointer;
`;

export const StyledUpload = styled(Upload)`
  height: 100%;
  display: flex;
  align-items: center;
`;
