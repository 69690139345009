import { Tabs } from 'antd';

import { ScheduleCard, UnifiedAttendanceCard } from 'components';

import { Counter, List, TabContainer } from './ScheduleTabs.styles';

const { TabPane } = Tabs;

function ScheduleTabs({ concluded, schedules }) {
  return (
    <div>
      <TabContainer>
        <TabPane tab="Atendimentos em aberto" key="schedules">
          <Counter>{`${schedules.length} atendimentos em aberto`}</Counter>
          <List>
            {schedules.map((props) => (
              <UnifiedAttendanceCard key={props.id} {...props} />
            ))}
          </List>
        </TabPane>
        <TabPane tab="Atendimentos concluídos" key="concluded">
          <Counter>{`${concluded.length} atendimentos concluídos`}</Counter>
          <List>
            {concluded.map((props) => (
              <ScheduleCard key={props.id} {...props} margin />
            ))}
          </List>
        </TabPane>
      </TabContainer>
    </div>
  );
}

export default ScheduleTabs;
