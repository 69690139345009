import { createContext, useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

import { logout } from 'api/request';
import socket from 'api/socket';
import useRequest from 'hooks/use-request';
import { ACCESS_TOKEN, REFRESH_TOKEN, USER_ID, USER_INFO } from 'variables';

const AuthContext = createContext({
  login: () => {},
  logout: () => {},
  authenticated: null,
});

function AuthProvider({ children }) {
  const [authenticated, setAuthenticated] = useState(null);
  const request = useRequest();
  const [cookies, setCookie] = useCookies([
    ACCESS_TOKEN,
    USER_ID,
    USER_INFO,
    REFRESH_TOKEN,
  ]);

  const accessMs = Date.now() + 1000 * 60 * 60 * 3;
  const threeHours = new Date(accessMs);

  const fourteenDaysMs = Date.now() + 1000 * 60 * 60 * 24 * 14;
  const fourteenDays = new Date(fourteenDaysMs);

  useEffect(() => {
    const getUpdatedInfo = async (id) => {
      await request.get({
        url: `/user/${id}`,
        onSuccess: (data) => {
          setCookie(USER_INFO, data[0], { expires: fourteenDays, path: '/' });
          socket.off('new-published-calendar');
        },
        notificationLevel: 'none',
      });
    };

    const userInfo = cookies[USER_INFO];

    if (
      !!cookies[USER_ID] &&
      !userInfo?.calendarUrl &&
      userInfo?.role === 'technique'
    ) {
      socket.on('new-published-calendar', ({ userId }) => {
        if (cookies[USER_ID] === userId) {
          getUpdatedInfo(userId);
        }
      });
    }

    return () => {
      socket.off('new-published-calendar');
    };
  }, [cookies[USER_ID], cookies[USER_INFO]]);

  useEffect(() => {
    const checkCookies = () => {
      const realTimeCookie = document.cookie.match(
        /(^| )_portal_access=([^;]+)/
      );

      if (realTimeCookie && realTimeCookie.length > 0) {
        const newAuth = !!realTimeCookie[2];

        setAuthenticated(newAuth);
      } else {
        setAuthenticated(false);
      }
    };

    const interval = setInterval(checkCookies, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const login = async (email, password) => {
    const res = await request.unauthorized.post({
      url: '/login',
      body: {
        email,
        password,
      },
      onSuccess: (info) => {
        setCookie(ACCESS_TOKEN, info.accessToken, {
          expires: threeHours,
          path: '/',
        });
        setCookie(REFRESH_TOKEN, info.refreshToken, {
          expires: fourteenDays,
          path: '/',
        });
        setCookie(USER_ID, info.user.id, { expires: fourteenDays, path: '/' });
        const { areas, ...restOfUserInfo } = info.user;

        setCookie(USER_INFO, JSON.stringify(restOfUserInfo), {
          expires: fourteenDays,
          path: '/',
        });

        setAuthenticated(true);
      },
      onError: (error) => {
        setAuthenticated(false);
        throw error;
      },
      notificationLevel: 'none',
    });

    return res;
  };

  const handleLogout = () => {
    logout();
    setAuthenticated(false);
  };

  return (
    <AuthContext.Provider
      value={{ authenticated, login, logout: handleLogout }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;
