import { Modal as StyledModal } from 'antd';
import styled, { css } from 'styled-components';

import { Button as BaseButton } from 'components/inputs';
import { respondTo } from 'styles/mixins';

export const Modal = styled(StyledModal)`
  top: 20px;

  .ant-modal-body {
    padding: 5rem;
    width: 90vw;
    max-width: 61.875rem;

    ${respondTo('medium')(css`
      padding: 1.875rem;
    `)}
  }
`;

export const Title = styled.h2`
  font-size: 1.5rem;
  letter-spacing: 0.03rem;
  font-weight: 600;
  margin-bottom: 1.75rem;

  ${respondTo('medium')(css`
    font-size: 1.125rem;
  `)}
`;

export const Button = styled(BaseButton)`
  height: 2.5rem;
  width: 9.25rem;
  margin: auto;
  font-size: 1rem;
  margin-top: 3.125rem;
`;
