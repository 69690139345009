import styled, { css } from 'styled-components';

import { TextInput as BaseTextInput } from 'components/inputs';
import { respondToMin } from 'styles/mixins';

export const TextInput = styled(BaseTextInput)`
  ${({ disable }) =>
    disable &&
    css`
      opacity: 0.3 !important;
    `}

  label {
    font-size: 0.8rem;
  }

  .ant-input-affix-wrapper {
    background: #f8f8f8 !important;
    margin: 0 0 1.15vw !important;
  }

  .ant-input-prefix {
    display: none;
  }

  .ant-input {
    background: #f8f8f8 !important;
    font-weight: 600;
    font-size: 0.88rem;
    color: #585858;
  }

  ${respondToMin('medium')(css`
    width: 21.09vw;
  `)}

  ${respondToMin('big')(css`
    .ant-input-affix-wrapper {
      margin: 0 0 0.5vw !important;
    }
  `)}

  ${respondToMin('large')(css`
    label {
      font-size: 1rem;
    }

    .ant-input {
      font-size: 1.13rem;
    }

    .ant-input-affix-wrapper {
      margin: 0.75vw 0 !important;
    }
  `)}
`;
