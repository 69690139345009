import downloadIcon from 'assets/icons/download-button.svg';

import { Background, Button, Icon, Text } from './DownloadButton.style';

function DownloadButton(props) {
  const { onClick, extension, withoutDot } = props;
  return (
    <>
      <Button onClick={onClick}>
        <Text>{`Download ${!withoutDot ? '.' : ''}${extension}`}</Text>
        <Icon src={downloadIcon} alt="download" />
        <Background />
      </Button>
    </>
  );
}

export default DownloadButton;
