import styled, { css } from 'styled-components';

import { Button as AntButton } from 'components/inputs';
import colors from 'styles/colors';
import { respondTo } from 'styles/mixins';

export const ButtonContainer = styled.div`
  display: flex;
  margin-bottom: 1.5rem;
  margin-top: 1rem;

  ${respondTo('medium')(css`
    display: flex;
    flex-direction: column;
    align-items: center;
  `)}
`;

export const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;

  & > span {
    margin-bottom: 0;
  }
`;

export const Button = styled(AntButton)`
  width: max-content;
  font-size: 1rem;
  margin: 0 0.875rem;
  padding: 0 2rem;
  height: 3.125rem;
  margin-left: 0.25rem;
`;

export const Bold = styled.p`
  margin: 0;
  color: ${colors.smoothBlack};
  font-weight: 600;
`;

export const OptionButton = styled.button`
  background-color: ${colors.persianGreen};
  color: white;
  font-size: 0.875rem;
  border-radius: 50px;
  font-weight: 600;
  min-height: 1.6875rem;
  padding: 0.3125rem 1.25rem;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  line-height: 1.0625rem;
`;
