import { Input } from 'antd';

import { AutoCompleteSearcher } from './AutocompleteInput.style';

const AutocompleteInput = ({
  value,
  onChange,
  onSelect,
  onSearch,
  options,
  placeholder = '',
  ...props
}) => (
  <AutoCompleteSearcher
    dropdownClassName="certain-category-search-dropdown"
    dropdownMatchSelectWidth={500}
    onChange={onChange}
    onSelect={onSelect}
    onSearch={onSearch}
    value={value}
    options={options}
    {...props}
  >
    <Input.Search size="large" placeholder={placeholder} />
  </AutoCompleteSearcher>
);

export default AutocompleteInput;
