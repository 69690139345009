import { Button, Divider, Image } from 'antd';
import styled, { css, keyframes } from 'styled-components';

import colors from 'styles/colors';
import { respondTo } from 'styles/mixins';

const hideCard = keyframes`
  0% {
    opacity: 1;
    top: 0;
  }
  100% {
    top: -40rem;
    opacity: 0;
  }
`;

export const showCard = keyframes`
  0% {
    top: 10rem;
    opacity: 0;
    padding-bottom: 10rem;
  }
  100% {
    opacity: 1;
    top: 0;
    padding-bottom: 0rem;
  }
`;

export const undo = keyframes`
  0% {
    top: -10rem;
    opacity: 0;
  }
  100% {
    opacity: 1;
    top: 0;
  }
`;

export const opacityFade = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const Wrapper = styled.div`
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  width: 100%;
  justify-content: center;

  ${respondTo('medium')(css`
    margin-bottom: 2rem;
  `)}
`;

export const Card = styled.div`
  height: fit-content;
  max-width: 23.438rem;
  background-color: ${colors.white};
  border-radius: 15px 15px 15px 15px;
  box-shadow: 8px 5px 10px #e2e2e2;
  position: relative;
  animation: ${undo} 0.1s linear;
  display: ${({ hide }) => (hide ? 'none' : 'block')};

  ${({ allocated }) =>
    allocated &&
    css`
      animation: ${hideCard} 0.5s linear;
      visibility: hidden;
    `};
`;

export const AllocatedCard = styled.div`
  background-color: ${colors.white};
  height: fit-content;
  max-width: 23.188rem;
  width: 100%;
  padding: 1rem 0.813rem;
  text-align: center;
  border-radius: 15px;
  position: relative;
  box-shadow: 8px 5px 10px #e2e2e2;
  animation: ${showCard} 0.3s ease-in;

  ${({ fade }) =>
    fade &&
    css`
      animation: ${hideCard} 0.6s ease-out;
      opacity: 0;
    `};
`;

export const AllocatedTitle = styled.div`
  position: relative;
  font-size: 1rem;
  font-weight: bold;
`;

export const StyledDivider = styled(Divider)`
  margin: 0.8rem 0;
`;

export const AllocatedFooter = styled.div`
  font-weight: bold;
  display: flex;
  justify-content: space-evenly;
`;

export const UndoButton = styled.div`
  color: ${colors.green};
  cursor: pointer;
  border-radius: 15px;
  padding: 0rem 1rem;
`;

export const Content = styled.div`
  padding: 1.8rem 2.2rem;
`;

export const Text = styled.div`
  font-size: 0.875rem;
  font-family: 'Fira Sans';
  letter-spacing: 0.02rem;
  color: #727272;
  margin-bottom: 1.25rem;
`;

export const SecondaryText = styled.div`
  ${respondTo('medium')(css`
    display: none;
  `)}
  display: flex;
  font-size: 1.125rem;
  font-family: 'Fira Sans';
  letter-spacing: 0.02rem;
  color: #727272;
  margin-bottom: 1rem;
`;

export const Title = styled.div`
  display: flex;
  font-size: 1.125rem;
  font-weight: 600;
  letter-spacing: 0.023rem;
  color: #000000;
  margin-bottom: 0.5rem;
`;

export const SecondTitle = styled.div`
  font-size: 1.25rem;
  letter-spacing: 0.025rem;
  line-height: 1.7rem;
  margin-bottom: 0.8rem;
  font-weight: bold;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${colors.gray};
  padding: 1.875rem 2.2rem;
`;

export const CitizenData = styled.div`
  margin-bottom: 1.25rem;
`;

export const CitizenImage = styled(Image)`
  border-radius: 100%;
  height: 2.8rem;
  width: 2.8rem;
`;

export const Name = styled.div`
  font-size: 1.125rem;
  font-weight: 600;
  letter-spacing: 0.023rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
`;

export const CitizenIcon = styled.img`
  margin-right: 0.625rem;
`;

export const FooterButton = styled(Button)`
  height: 1.7rem;
  background-color: ${({ color }) =>
    color === 'green' ? colors.green : colors.primary};
  border-radius: 20px;
  min-width: 7.5rem;
  font-weight: 600;
  font-size: 0.875rem;
  border: none;

  &:hover,
  &:focus {
    background-color: ${colors.primaryLight};
    color: ${colors.black};
    outline: none;
  }

  ${({ color }) =>
    color === 'green' &&
    css`
      color: ${colors.white};
      &:hover,
      &:focus {
        background-color: ${colors.info};
        color: ${colors.white};
      }
    `}
`;

export const InfoText = styled.div`
  font-size: 1rem;
  margin-bottom: 0.4rem;
  width: 90%;
`;

export const Bold = styled.span`
  font-weight: 600;
`;

export const Undo = styled.img`
  margin-right: 1rem;
`;
