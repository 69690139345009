import { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Spin } from 'antd';

import socket from 'api/socket';
import bell from 'assets/icons/bell.svg';
import { useUserInfo } from 'contexts/UserProvider/UserProvider';
import useCrud from 'hooks/use-crud';
import useRequest from 'hooks/use-request';

import {
  Center,
  Count,
  Icon,
  LoadingContainer,
  NotificationButton,
  ScrollableContainer,
  StyledDrawer,
  Title,
  Wrapper,
} from './NotificationDrawer.style';
import NotificationItem from './NotificationItem';

const loading = (
  <LoadingContainer>
    <Spin />
  </LoadingContainer>
);

function NotificationDrawer({ open, setOpen }) {
  const [visible, setVisible] = useState(false);
  const [notifications, { get, getMore, itemsAmount, setData }] = useCrud(
    '/notification',
    {
      invertedList: true,
      pagination: { itemsPerPage: 5 },
    }
  );
  const [unreadNotifications, setUnreadNotifications] = useState([]);
  const request = useRequest();
  const { userData } = useUserInfo();

  const handleClose = () => {
    setVisible(false);
    if (setOpen) setOpen(false);
  };

  const handleClick = async () => {
    try {
      setVisible(true);

      const readPromises = unreadNotifications.map(({ id }) =>
        request.patch({
          url: `/notification/${id}`,
          body: { status: 'seen' },
          notificationLevel: 'none',
        })
      );

      await Promise.all(readPromises);

      setUnreadNotifications([]);
      setData((prevNotifications) =>
        prevNotifications.map((notification) => ({
          ...notification,
          status: 'seen',
        }))
      );
    } catch (err) {
      console.log(err);
    }
  };

  const handleMore = () => {
    getMore();
  };

  const loadNotifications = useCallback(() => {
    const fetchData = async () => {
      get();
      request.get({
        url: '/notification?notificationStatus=not-seen',
        onSuccess: setUnreadNotifications,
      });
    };

    fetchData();
  }, []);

  const handleDeleteNotification = async (id) => {
    await request.delete({
      url: `/notification/${id}`,
    });

    loadNotifications();
  };

  useEffect(loadNotifications, [loadNotifications]);

  useEffect(() => {
    const handleNewNotification = ({ userId }) => {
      if (userId === userData.id) loadNotifications();
    };

    socket.on('new-notification', handleNewNotification);

    return () => {
      socket.off('new-notification', handleNewNotification);
    };
  }, [userData]);

  return (
    <Wrapper>
      <NotificationButton button onClick={handleClick}>
        <Icon src={bell} alt="bell-icon" />
        {unreadNotifications.length > 0 && (
          <Count>{unreadNotifications.length}</Count>
        )}
      </NotificationButton>
      <StyledDrawer
        placement="right"
        onClose={handleClose}
        visible={open || visible}
        maskStyle={{ background: 'none' }}
        closable
        width="100vw"
      >
        <ScrollableContainer id="notifications-container">
          <Center>
            <NotificationButton>
              <Icon src={bell} alt="bell-icon" />
              {unreadNotifications.length > 0 && (
                <Count>{unreadNotifications.length}</Count>
              )}
            </NotificationButton>
          </Center>
          <Title>Últimas notificações</Title>
          <InfiniteScroll
            dataLength={notifications.length}
            hasMore={itemsAmount > notifications.length}
            loader={loading}
            next={handleMore}
            scrollableTarget="notifications-container"
          >
            {notifications.map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
                handleDeleteNotification={handleDeleteNotification}
              />
            ))}
          </InfiniteScroll>
        </ScrollableContainer>
      </StyledDrawer>
    </Wrapper>
  );
}

export default NotificationDrawer;
