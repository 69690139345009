import { Button, Image } from 'antd';
import styled, { css } from 'styled-components';

import colors from 'styles/colors';
import { respondTo } from 'styles/mixins';

export const Card = styled.div`
  height: fit-content;
  max-width: 22.5rem;
  background-color: ${colors.white};
  border-radius: 15px;
  box-shadow: 8px 5px 10px ${colors.shadow};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${({ margin }) =>
    margin &&
    css`
      margin-bottom: 1rem;
    `};
`;

export const Content = styled.div`
  padding: 1.8rem 1.8rem;
`;

export const Text = styled.div`
  font-size: 0.875rem;
  font-family: 'Fira Sans';
  letter-spacing: 0.02rem;
  color: ${colors.lightGray};
  margin-bottom: 1.25rem;
`;

export const SecondaryText = styled.div`
  ${respondTo('medium')(css`
    display: none;
  `)}
  display: flex;
  font-size: 1rem;
  font-family: 'Fira Sans';
  letter-spacing: 0.02rem;
  color: ${colors.lightGray};
  margin-bottom: 1rem;
`;

export const Title = styled.div`
  display: flex;
  font-size: 1.125rem;
  font-weight: 600;
  letter-spacing: 0.023rem;
  color: ${colors.black};
  margin-bottom: 0.5rem;
`;

export const SecondTitle = styled.div`
  font-size: 1.25rem;
  letter-spacing: 0.025rem;
  line-height: 1.7rem;
  margin-bottom: 0.8rem;
  font-weight: bold;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  border-top: 1px solid ${colors.gray};
  padding: 1.875rem 2.2rem;
`;

export const CitizenData = styled.div`
  display: flex;
`;

export const CitizenText = styled.div``;

export const CitizenImage = styled(Image)`
  border-radius: 100%;
  height: 2.8rem;
  width: 2.8rem;
`;

export const Name = styled.div`
  font-size: 1.125rem;
  font-weight: 600;
  letter-spacing: 0.023rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
`;

export const CitizenIcon = styled.img`
  margin-right: 0.625rem;
`;

export const FooterButton = styled(Button)`
  height: 1.7rem;
  background-color: ${colors.green};
  color: ${colors.white};
  border-radius: 20px;
  min-width: 7.5rem;
  font-weight: 600;
  font-size: 0.875rem;
  border: none;

  &:hover,
  &:focus {
    background-color: ${colors.info};
    color: ${colors.white};
  }
`;

export const InfoText = styled.div`
  font-size: 1rem;
  margin-bottom: 0.4rem;
  width: 100%;
`;

export const Bold = styled.span`
  font-weight: 600;
`;

export const LinkText = styled.span`
  font-style: italic;
`;

export const AnchorLinkText = styled.a`
  text-decoration: underline;
  cursor: pointer;
  color: black !important;
  font-weight: 600;
`;

export const Allocated = styled.div`
  background-color: ${colors.smoothGray};
  text-align: center;
  font-size: 1.125rem;
  letter-spacing: 0.023rem;
  padding: 0.625rem;
  margin-top: 0.8rem;
  margin-bottom: 1.063rem;
`;
