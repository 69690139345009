import styled, { css } from 'styled-components';

import colors from 'styles/colors';

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const TextAreaInput = styled.textarea`
  resize: none;
  border: 1px solid ${colors.green};
  outline: none;
  padding: 0.75rem 1.25rem;
  border-radius: 8px;
  width: ${({ height }) => height || '5rem'};
  width: ${({ width }) => width || '100%'};
  width: 100%;
  font-size: 0.8rem;
  background-color: transparent;
  transition: border 0.2s ease-in-out;
  font-weight: 500;
  color: ${colors.grayBlue};

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media screen and (max-width: 600px) {
    height: 7.5rem;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${colors.gray};
    `}

  ${({ focused }) =>
    focused &&
    css`
      border: 1px solid ${colors.green};
    `}
`;

export const Error = styled.p`
  color: ${colors.textSecondary};
  opacity: 1;
  font-style: italic;
  font-size: 0.875rem;
  margin: 0;
  margin-top: 0.5rem;

  ${({ absoluteError }) =>
    absoluteError &&
    css`
      position: absolute;
      margin-top: 0;
      bottom: -0.125rem;
      transform: translateY(100%);
    `}
`;

export const ErrorIcon = styled.img`
  width: 0.875rem;
  margin-right: 0.625rem;
`;

export const Label = styled.label`
  position: absolute;
  transition: top 0.2s ease-in-out, left 0.2s ease-in-out,
    color 0.2s ease-in-out, font-size 0.2s ease-in-out;
  left: 0.625rem;
  top: 1.25rem;
  transform: translateY(-50%);
  background-color: white;
  padding: 0 0.25rem;
  pointer-events: none;
  border-radius: 5px;
  font-size: 0.7rem;
  font-weight: 500;
  color: ${colors.smoothBlack};

  ${({ focused }) =>
    focused &&
    css`
      left: 0.937rem;
      top: 0;
      color: ${colors.smoothBlack};
      font-size: 0.85rem;
    `};
`;
