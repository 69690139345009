import { Button as AntButton } from 'antd';
import styled, { css } from 'styled-components';

import colors from 'styles/colors';

const StyledFormButton = styled(AntButton)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  border: none;
  height: 2.5rem;
  padding: 0.625rem 4.625rem;
  background-color: ${colors.darkGreen};
  color: white;
  font-size: 1rem;

  .anticon-loading {
    position: unset;
  }

  &:hover,
  &:focus {
    background-color: ${colors.darkGreen};
    color: white;
    opacity: 0.9;
  }

  ${({ $cancel }) =>
    $cancel &&
    css`
      background-color: ${colors.gray};
      color: ${colors.smoothBlack};

      &:hover,
      &:focus {
        background-color: ${colors.gray};
        color: ${colors.smoothBlack};
        opacity: 0.9;
      }
    `}
`;

function FormButton(props) {
  return <StyledFormButton htmlType="button" type="primary" {...props} />;
}

export default FormButton;
