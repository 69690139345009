import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: ${({ padding }) => padding || '15px'};
  border-radius: ${({ square }) => (square ? '5px' : '15px')};
  box-shadow: 0px 0px 6px #e5e2e2;
  background-color: white;
  ${({ resident }) =>
    resident &&
    css`
      border-radius: 0px;
      box-shadow: none;
      border-bottom: 1px solid #bebdbd;
    `}
`;
