import styled from 'styled-components';

import { Modal } from 'components/Modal';
import colors from 'styles/colors';

const ConfirmCancelModal = styled(Modal)`
  .ant-modal-header {
    text-align: center;
  }

  .ant-modal-body {
    color: ${colors.smoothBlack};
    font-size: 0.875rem;
  }

  .ant-modal-footer {
    button {
      font-weight: 600;
    }
    > button:nth-child(2) {
      background-color: ${colors.red} !important;
    }
  }
`;

export default ConfirmCancelModal;
