import styled from 'styled-components';

export const Icon = styled.img`
  margin: 0rem 0.8rem;
  position: relative;
  cursor: pointer;
  margin-left: auto;

  &:last-child {
    margin-left: 0;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  right: 2rem;
  top: 2rem;
`;
