import { Modal as AntModal } from 'antd';
import styled, { css } from 'styled-components';

import { Button as AntButton } from 'components/inputs';
import colors from 'styles/colors';
import {
  flex,
  flexcc,
  respondTo,
  respondToMin,
  translateBackground,
} from 'styles/mixins';

export const Container = styled.header`
  padding: 1.75rem 0rem 3rem 0rem;
  width: fit-content;

  ${flex('space-between', 'center')}

  ${respondTo('medium')(css`
    padding: 1.5625rem;
  `)}
`;

export const ProfileContainer = styled.div`
  ${flexcc}
  position: relative;

  ${respondTo('medium')(css`
    padding: 0.8rem;
    justify-content: start;
  `)}
`;

export const TitleContainer = styled.div`
  margin: 0 2.75rem 0 0;

  ${flex('center', 'flex-end', 'column')}

  ${respondTo('medium')(css`
    margin-right: 1rem; ;
  `)}
`;

export const ImagePlaceholder = styled.div`
  height: 3.875rem;
  width: 3.875rem;
  border-radius: 50px;
  z-index: 1;

  ${translateBackground(css`
    top: 0px;
    left: -2px;
    height: 95%;
    width: 95%;
    background-color: ${colors.secondary};
  `)}

  ${flexcc}

  ${respondTo('medium')(css`
    height: 3rem;
    width: 3rem;
  `)}
`;

export const PlaceholderIcon = styled.img`
  width: 1.375rem;

  ${respondTo('medium')(css`
    width: 1rem;
  `)}

  ${respondToMin('big')(css`
    padding: 0;
  `)}
`;

export const LinkText = styled.div`
  font-size: 1.125rem;
  text-decoration: underline;
  font-weight: 400;
  cursor: pointer;
`;

export const Title = styled.h1`
  font-size: 2rem;
  line-height: 2.375rem;
  font-weight: bold;
  margin: 0;
  margin-left: 1rem;
  color: ${colors.textBlue};

  ${respondTo('medium')(css`
    font-size: 1.125rem;
    line-height: 1.375rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
  `)}
`;

export const EditProfile = styled.p`
  font-size: 1.125rem;
  margin: 0;
  text-decoration: underline;
  letter-spacing: 0.0225rem;
  cursor: pointer;
`;

export const ButtonContainer = styled.ul`
  display: flex;
  width: 66.66%;
  max-width: 66.66vw;
  margin: 0;
`;

export const Button = styled(AntButton)`
  height: 2.9375rem;
  width: fit-content;
  font-size: 1rem;
  margin-right: 1.5rem;
`;

export const ExitButton = styled.button`
  margin: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
`;

export const ExitIcon = styled.img`
  width: 1.4375rem;
  margin-right: 0.875rem;
`;

export const MenuButton = styled.button`
  border-radius: 50px;
  width: 3.8125rem;
  height: 3.8125rem;
  text-transform: uppercase;
  border: none;
  background-color: transparent;
  color: white;
  font-weight: 600;
  outline: none;
  z-index: 1;

  ${translateBackground(css`
    top: 0px;
    left: -2px;
    height: 97%;
    width: 97%;
    background-color: ${colors.primary};
  `)}
`;

export const Modal = styled(AntModal)`
  margin: 0;
  position: absolute;
  max-width: 80%;

  .ant-modal-content {
    border-radius: 0px;
    box-shadow: none;

    .ant-modal-close-x {
      top: 0 !important;
      left: 0;
      margin: 1.375rem;
      background-color: transparent !important;

      ${translateBackground(css`
        top: -2px;
        left: -2px;
        height: 100%;
        width: 100%;
        background-color: ${colors.primary};
      `)}
    }
  }
`;

export const ModalList = styled.ul`
  ${flex('center', 'flex-start', 'column')}
  padding: 4.25rem 1.25rem 1.25rem;
`;

export const ModalItem = styled.li`
  list-style: none;
  font-size: 1.25rem;
  font-weight: 600;
`;

export const Dash = styled.div`
  width: 10rem;
  height: 1px;
  background-color: #00000029;
  margin: 0.8rem 0 2.5rem;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 50vw;
`;
