import { useState } from 'react';

import { SteppedForm } from 'components';
import InputsContainer from 'components/EditDataBoard/InputsContainer';
import { useConfirmCancel } from 'contexts/ConfirmCancelProvider';
import useMobile from 'hooks/use-mobile';

import customNotificationMessages from '../../hooks/use-request/services/notification/customNotificationMessages.json';

import inputs from './inputs';
import mobileSections from './mobileSections';

function CreateRequesterModal({ closeModal, create, watchTouched }) {
  const [user, setUser] = useState({});
  const confirmCancel = useConfirmCancel();
  const isMobile = useMobile();

  const customRequest = async (userFields) => {
    try {
      await create(userFields, {
        onError: (error) => {
          const errorMessage = error.response.data.error;
          const customMessage =
            customNotificationMessages.requester[errorMessage];

          if (customMessage) {
            return { customMessage };
          }

          return {};
        },
      });
      setUser(userFields);
      closeModal();
    } catch (err) {
      console.log(err);
    }
  };

  if (isMobile) {
    return (
      <SteppedForm
        sections={mobileSections}
        onSubmit={(result) => customRequest(result)}
        onCancel={(touched) => confirmCancel(closeModal, touched)}
        watchTouched={watchTouched}
      />
    );
  }

  return (
    <InputsContainer
      setDisabled={() => {}}
      submit={false}
      disabled={false}
      setUser={setUser}
      customRequest={customRequest}
      user={user}
      inputs={inputs}
      overrideOnCancel={(touched) => confirmCancel(closeModal, touched)}
      buttonsTexts={['Criar solicitante', 'Cancelar']}
      watchTouched={watchTouched}
    />
  );
}

export default CreateRequesterModal;
