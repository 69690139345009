import { useEffect, useState } from 'react';

import UpdateImageField from 'components/inputs/ImageField/UpdateImageField';
import useRequest from 'hooks/use-request';

import {
  Container,
  HighlightTag,
  HighlightTitle,
  InfoWrapper,
  UserName,
} from './ProfilePhotoEdit.style';

const ProfilePhotoEdit = ({ path, user, mobileHeader }) => {
  const [profilePicture, setProfilePicture] = useState([]);
  const [usableUser, setUsableUser] = useState(null);

  const request = useRequest();

  useEffect(() => {
    if (user) {
      setUsableUser(user);
    }
  }, [user]);

  const updateImage = async (filelist) => {
    const patchImage = async () => {
      const updatedImageData = new FormData();
      updatedImageData.append('profileImage', filelist[0].originFileObj);

      await request.patch({
        url: path,
        body: updatedImageData,
        config: {
          headers: {
            'content-type': 'multipart/form-data',
          },
        },
      });

      const profileImage = URL.createObjectURL(filelist[0].originFileObj);
      setUsableUser({
        ...usableUser,
        profileImage,
      });
      setProfilePicture([]);
    };

    patchImage();
  };

  return (
    <Container>
      <UpdateImageField
        pictureUrl={usableUser?.profileImage || ''}
        label=""
        onUpdate={updateImage}
        value={profilePicture}
        setValue={setProfilePicture}
        mobileHeader={mobileHeader}
      />
      {!mobileHeader && (
        <InfoWrapper>
          <UserName>{usableUser?.name}</UserName>
          <HighlightTag>
            <HighlightTitle>
              {
                {
                  coordinator: 'Coordenador',
                  technique: 'Equipe Técnica',
                  admin: 'Administrador',
                  supervisor: 'Supervisor',
                  attendant: 'Atendente',
                }[usableUser?.role]
              }
            </HighlightTitle>
          </HighlightTag>
        </InfoWrapper>
      )}
    </Container>
  );
};

export default ProfilePhotoEdit;
