import { createContext, useContext } from 'react';
import { notification } from 'antd';

const NotificationContext = createContext({
  addNotification: () => {},
});

function NotificationProvider({ children }) {
  const addNotification = ({
    message = '',
    type = 'success',
    description = '',
  }) => {
    notification[type]({
      message,
      description,
    });
  };

  return (
    <NotificationContext.Provider value={{ addNotification }}>
      {children}
    </NotificationContext.Provider>
  );
}

export const useNotification = () => useContext(NotificationContext);

export default NotificationProvider;
