import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Can,
  CreateRequesterModal,
  FilterButton,
  ImportResidentsModal,
  PageTable,
  TablesSearchBar,
  TitledPage,
} from 'components';
import { CustomDownloadButton } from 'components/DownloadButton/components/CustomDownloadButton/CustomDownloadButton';
import ImportResidentsErrorModal from 'components/ImportResidentsErrorModal';
import { useUserInfo } from 'contexts';
import { useModal, withModalProvider } from 'contexts/ModalProvider';
import useCrud from 'hooks/use-crud';
import useMobile from 'hooks/use-mobile';
import useRequest from 'hooks/use-request';

import { filters, searchParameters } from './filters';
import {
  Bold,
  Button,
  ButtonContainer,
  ButtonsGroup,
  FiltersContainer,
  OptionButton,
  Text,
} from './Residents.style';

function Residents() {
  const { userData } = useUserInfo();
  const history = useHistory();
  const request = useRequest();
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const isMobile = useMobile();
  const queryParams = new URLSearchParams(document.location.search);

  const [requesters, { create, get, getSpecificPage, itemsAmount, loading }] =
    useCrud('/requester', {
      autoinit: true,
      trackQueryParameters: true,
      pagination: true,
    });

  const { modal } = useModal();

  const onDownloadRequesters = async () => {
    const searchParams = new URLSearchParams();

    if (!searchParams.has('rangeStartDate')) {
      const currentDate = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        23,
        59,
        59
      );

      const lastThreeMonths = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - 3,
        currentDate.getDate(),
        0,
        0,
        0
      );

      searchParams.set('rangeStartDate', [
        lastThreeMonths.toISOString(),
        currentDate.toISOString(),
      ]);
    }

    const getSearchParameters = () => {
      const aux = [];

      searchParams.forEach((value, key) => {
        aux.push(`${key}=${value}`);
      });

      return aux.join('&');
    };

    await request.get({
      url: `/unified-attendance/export-requesters-xlsx?${
        getSearchParameters().length && getSearchParameters()
      }`,
      config: {
        responseType: 'arraybuffer',
      },
      onSuccess: (data, res) => {
        const file = new Blob([res.data]);
        const reader = new FileReader();

        const hiddenElement = document.createElement('a');

        reader.addEventListener('load', () => {
          hiddenElement.href = reader.result;
          hiddenElement.target = '_blank';
          hiddenElement.download = 'solicitantes.xlsx';
          hiddenElement.click();
        });

        reader.readAsDataURL(file);
      },
      notificationLevel: 'none',
    });
  };

  const onDownloadClick = async () => {
    const searchParams = new URLSearchParams(queryParams);

    if (!searchParams.has('rangeStartDate')) {
      const currentDate = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        23,
        59,
        59
      );

      const lastThreeMonths = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - 3,
        currentDate.getDate(),
        0,
        0,
        0
      );

      searchParams.set('rangeStartDate', [
        lastThreeMonths.toISOString(),
        currentDate.toISOString(),
      ]);
    }

    const getSearchParameters = () => {
      const aux = [];

      searchParams.forEach((value, key) => {
        aux.push(`${key}=${value}`);
      });

      return aux.join('&');
    };

    await request.get({
      url: `/unified-attendance/export-xlsx?${
        getSearchParameters().length && getSearchParameters()
      }`,
      config: {
        responseType: 'arraybuffer',
      },
      onSuccess: (data, res) => {
        const file = new Blob([res.data]);
        const reader = new FileReader();

        const hiddenElement = document.createElement('a');

        reader.addEventListener('load', () => {
          hiddenElement.href = reader.result;
          hiddenElement.target = '_blank';
          hiddenElement.download = 'atendimentos.xlsx';
          hiddenElement.click();
        });

        reader.readAsDataURL(file);
      },
      notificationLevel: 'none',
    });
  };

  const handleCreateRequester = () => {
    const modalProps = {
      title: 'Cadastrar solicitante',
      footer: null,
      width: '90vw',
      watchTouched: true,
      maskClosable: false,
    };
    modal(
      (props) => <CreateRequesterModal create={create} {...props} />,
      modalProps
    );
  };

  const handleImportResidentsErrors = (errors) => {
    const modalProps = {
      title: 'Erro ao importar solicitantes',
      footer: null,
      width: '40vw',
      watchTouched: true,
      maskClosable: false,
    };

    return modal(
      () => <ImportResidentsErrorModal errors={errors} />,
      modalProps
    );
  };

  const importData = async (file) => {
    setIsUploadingFile(true);

    const importedRequestersData = new FormData();
    importedRequestersData.append('content', file.fileList[0].originFileObj);

    await request.post({
      url: '/requester/import-xlsx',
      body: importedRequestersData,
      config: {
        headers: {
          'content-type': 'multipart/form-data',
        },
      },
      onSuccess: (data, res) => {
        if (res.status === 207) {
          handleImportResidentsErrors(data.data);
        }

        setIsUploadingFile(false);
        setTimeout(() => window.location.reload(), 3000);
      },
      onError: () => setIsUploadingFile(false),
    });
  };

  const handleImportResidents = () => {
    const modalProps = {
      title: 'Importar solicitantes',
      footer: null,
      width: '40vw',
      watchTouched: true,
      maskClosable: false,
    };

    modal(
      ({ closeModal }) => (
        <ImportResidentsModal create={importData} closeModal={closeModal} />
      ),
      modalProps
    );
  };

  const titleButtons = [
    () =>
      !isMobile ? (
        <ButtonsGroup>
          <Can perform={['resident-center:create']}>
            <Button onClick={handleCreateRequester}>
              Cadastrar solicitante
            </Button>
          </Can>
          {userData?.role === 'admin' ? (
            <Can perform={['resident-center:create']}>
              <Button loading={isUploadingFile} onClick={handleImportResidents}>
                Importar solicitantes
              </Button>
            </Can>
          ) : (
            <></>
          )}
        </ButtonsGroup>
      ) : null,
    () => <FilterButton filters={filters} />,
  ];

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      render: (name) => <Bold>{name}</Bold>,
      span: 1.5,
    },
    {
      title: 'Regional',
      dataIndex: 'region',
      key: 'region',
      render: ({ name }) => name,
    },
    {
      title: 'Área / empreendimento',
      dataIndex: 'area',
      key: 'area',
      span: 1.5,
      render: ({ name }) => name,
    },
    {
      title: 'Atendimentos em aberto',
      dataIndex: 'openedUnifiedAttendances',
      key: 'openedUnifiedAttendances',
      render: (openedUnifiedAttendances) => openedUnifiedAttendances || '0',
    },
    {
      title: 'Atendimentos concluídos',
      dataIndex: 'concludedUnifiedAttendances',
      key: 'concludedUnifiedAttendances',
      render: (concludedUnifiedAttendances) =>
        concludedUnifiedAttendances || '0',
    },
    {
      title: '',
      dataIndex: 'details',
      key: 'details',
      align: 'middle',
    },
  ];

  const seeDetailsButton = ({ id }) => (
    <OptionButton onClick={() => history.push(`/central-do-morador/${id}`)}>
      Ver detalhes
    </OptionButton>
  );

  return (
    <TitledPage buttons={titleButtons} title="Central do Morador">
      <CustomDownloadButton
        extension="atendimentos.xlsx"
        onClick={onDownloadClick}
        withoutDot
        bottom="3.5rem"
      />
      <CustomDownloadButton
        extension="solicitantes.xlsx"
        onClick={onDownloadRequesters}
        withoutDot
        bottom="1rem"
      />
      {isMobile && (
        <>
          <ButtonContainer>
            <Can perform={['resident-center:create']}>
              <Button onClick={handleCreateRequester}>
                Cadastrar solicitante
              </Button>
            </Can>
          </ButtonContainer>
          <ButtonContainer>
            <Can perform={['resident-center:create']}>
              <Button loading={isUploadingFile} onClick={handleImportResidents}>
                Importar solicitantes
              </Button>
            </Can>
          </ButtonContainer>
        </>
      )}
      <FiltersContainer>
        <TablesSearchBar
          requestFunction={get}
          queryIds={searchParameters}
          placeholder="Pesquise por nome completo ou CPF"
        />
      </FiltersContainer>
      <Text>Últimos solicitantes cadastrados</Text>
      <PageTable
        dataSource={requesters}
        columns={columns}
        loading={loading}
        extras={{ details: seeDetailsButton }}
        get={getSpecificPage}
        itemsAmount={itemsAmount}
      />
    </TitledPage>
  );
}

export default withModalProvider(Residents);
