import { useEffect, useState } from 'react';

import { Can } from 'components';
import TextField from 'components/inputs/TextField';
import { useModal } from 'contexts';
import { withModalProvider } from 'contexts/ModalProvider';
import useRequest from 'hooks/use-request';

import EditModal from './EditModal';
import {
  ButtonContainer,
  Container,
  FieldContainer,
  Header,
  HeaderContainer,
  ItemText,
  Loading,
  SubmitButton,
  Table,
  TableButton,
  TableContent,
  Title,
} from './TableAdder.style';
import { textContent } from './variables';

function TableAdder({ width, title, label, path, type }) {
  const [field, setField] = useState();
  const [items, setItems] = useState();
  const { modal } = useModal();
  const request = useRequest();

  const getList = async () => {
    if (type) {
      const filteredPath = `${path}?filter={"tipo": ["${type}"]}`;
      await request.get({ url: filteredPath, onSuccess: setItems });
    } else {
      await request.get({ url: path, onSuccess: setItems });
    }
  };

  useEffect(() => {
    getList();
  }, []);

  const deleteItem = async (closeModal, id) => {
    await request.delete({
      url: `/${path}/${id}`,
      onSuccess: () => {
        getList();
      },
    });
    closeModal();
  };

  const handleSubmit = async () => {
    if (field) {
      const requestBody = { name: field, tipo: type };
      await request.post({
        url: `/${path}`,
        body: requestBody,
        onSuccess: () => {
          getList();
          setField('');
        },
      });
    }
  };

  const handleDeleteItem = (item) => {
    const modalParams = {
      title: `Deseja apagar a "${item.name}"?`,
      okText: 'Apagar',
      onOk: (closeModal) => deleteItem(closeModal, item.id),
      footerButtonColor: 'rgba(237, 104, 87, 1)',
    };
    modal(() => textContent(item), modalParams);
  };

  const editItem = async (closeModal, result, id) => {
    if (result) {
      const requestBody = { name: result };
      await request.patch({
        url: `/${path}/${id}`,
        body: requestBody,
        onSuccess: () => {
          closeModal();
          getList();
        },
      });
    }
  };

  const handleEdit = (item) => {
    const modalParams = {
      title: `Editar ${title}`,
      okText: 'Editar',
      width: 400,
      destroyOnClose: true,
      onOk: (closeModal, result) => editItem(closeModal, result, item.id),
    };

    modal((props) => <EditModal {...props} item={item} />, modalParams);
  };

  return (
    <Container width={width}>
      <Title>{title}</Title>
      <HeaderContainer>
        <Header>Nome</Header>
      </HeaderContainer>
      <Table>
        {items?.map((item, index) => (
          <TableContent index={index} key={item.name}>
            <ItemText>{item.name}</ItemText>
            <ButtonContainer>
              <Can perform={['configurations:update']}>
                <TableButton onClick={() => handleEdit(item)} color="green">
                  Editar
                </TableButton>
              </Can>
              <Can perform={['configurations:delete']}>
                <TableButton onClick={() => handleDeleteItem(item)}>
                  Remover
                </TableButton>
              </Can>
            </ButtonContainer>
          </TableContent>
        )) || <Loading />}
      </Table>
      <Can perform={['configurations:create']}>
        <FieldContainer>
          <TextField
            width={400}
            label={label}
            value={field}
            onChange={(e) => setField(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && handleSubmit()}
          />
          <SubmitButton onClick={handleSubmit} />
        </FieldContainer>
      </Can>
    </Container>
  );
}

export default withModalProvider(TableAdder);
