import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth, useNotification } from 'contexts';
import useRequest from 'hooks/use-request';

import { Page } from './DataConfirmation.style';
import { errorMap } from './errorMap';
import Step2 from './Step2';

function DataConfirmation() {
  const [error, setError] = useState('');
  const history = useHistory();
  const { login } = useAuth();
  const { addNotification } = useNotification();
  const request = useRequest();

  const handleSubmit = async (
    { email, temporaryPassword, newPassword, confirmNewPassword },
    formik
  ) => {
    if (newPassword === confirmNewPassword) {
      await request.unauthorized.post({
        url: '/user/first-access',
        body: {
          email,
          temporaryPassword,
          newPassword,
        },
        onError: ({ response }) => {
          formik.setSubmitting(false);
          addNotification({
            message: 'Ocorreu um erro ao efetuar o primeiro login',
            description: errorMap[response.data.error],
            type: 'error',
          });
        },
        notificationLevel: 'none',
      });

      try {
        const info = await login(email, newPassword);
        const { role } = info.user;
        if (role) history.push('/');
        addNotification({
          message: 'Primeiro login feito com sucesso',
        });
      } catch (err) {
        formik.setSubmitting(false);
        console.log(err);
      }
    } else {
      // formik.setSubmitting(false);
      setError('As senhas não coincidem');
    }
  };

  return (
    <>
      <Page>
        <Step2 error={error} onSubmit={handleSubmit} />
      </Page>
    </>
  );
}

export default DataConfirmation;
