import { Button as AntButton, Checkbox } from 'antd';
import styled, { css } from 'styled-components';

import FloatingLabelSelect from 'components/inputs/FormSelect/FloatingLabelSelect';
import TextField from 'components/inputs/TextField';
import colors from 'styles/colors';
import { respondTo } from 'styles/mixins';

export const Input = styled(TextField)`
  margin: 1.75rem 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const FormButtonContainer = styled.div`
  width: 100%;
  margin: 1.875rem 0 0.375rem;
  display: flex;
  justify-content: space-around;
`;

export const Select = styled(FloatingLabelSelect)`
  margin: 1.8125rem 0;
`;

export const StyledCheckbox = styled(Checkbox)`
  font-size: 1rem;
  letter-spacing: 0.04rem;
  .ant-checkbox-inner {
    border-radius: 4px !important;
  }
`;

export const EditButton = styled.div`
  position: absolute;
  top: 1.4rem;
  right: 2rem;
  cursor: pointer;
  font-weight: 600;
  color: ${colors.grayText};
`;

export const EditIcon = styled.img`
  height: 0.8rem;
  position: relative;
  top: -0.1rem;
  margin-right: 0.9rem;
`;

export const StyledFormButton = styled(AntButton)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  border: none;
  height: 2.5rem;
  padding: 0.625rem 4.625rem;
  background-color: ${colors.darkGreen};
  color: white;
  font-size: 1rem;

  .anticon-loading {
    position: unset;
  }

  &:hover,
  &:focus {
    background-color: ${colors.darkGreen};
    color: white;
    opacity: 0.9;
  }

  ${({ $cancel }) =>
    $cancel &&
    css`
      background-color: ${colors.gray};
      color: ${colors.smoothBlack};

      &:hover,
      &:focus {
        background-color: ${colors.gray};
        color: ${colors.smoothBlack};
        opacity: 0.9;
      }
    `}

  ${respondTo('medium')(css`
    padding: 0.625rem 2rem;
  `)}
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 14rem;
`;

export const TopButton = styled.button`
  padding: 0.15rem 0;
  outline: none;
  border: none;
  background-color: ${({ red }) => (red ? colors.red : colors.gray)};
  color: ${({ red }) => (red ? colors.white : colors.glassGray)};
  width: 6rem;
  border-radius: 15px;
`;
