import { Tabs } from 'antd';
import styled from 'styled-components';

import colors from 'styles/colors';

export const TabContainer = styled(Tabs)`
  .ant-tabs-nav-wrap {
    background-color: ${colors.white};
    justify-content: center;
    border-bottom: 1px solid ${colors.gray};
  }

  .ant-tabs-content-holder {
    overflow: auto;
    height: 60vh;
    padding-bottom: 10rem;
  }
  .ant-tabs-nav-list {
    font-size: 0.4rem;
  }

  .ant-tabs-ink-bar {
    width: 100%;
    height: 0.2rem !important;
    background-color: ${colors.orange};
  }

  .ant-tabs-tab {
    padding-bottom: 0.2rem !important;
  }

  .ant-tabs-tab .ant-tabs-tab-btn,
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
  .ant-tabs-tab-btn:focus {
    color: ${colors.black};
    font-size: 0.8rem;
  }

  .ant-tabs-tab-disabled .ant-tabs-tab-btn {
    color: ${colors.gray} !important;
  }
`;

export const List = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Counter = styled.div`
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
`;
