import { Pagination } from 'antd';
import styled, { css } from 'styled-components';

import { Spin } from 'components';
import colors from 'styles/colors';

export const NoDataParagraph = styled.p`
  font-size: 1rem;
  color: ${colors.disabledGray};
  margin: 0.5rem 0;
  text-align: center;
`;

export const Table = styled.table`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 15px;
  width: calc(100% - 1rem);
  padding: 0.3125rem;

  * {
    display: block;
  }
`;

const containerBase = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AntdPagination = styled(Pagination)`
  display: flex;
  margin-top: 1rem;
  justify-content: center;

  .ant-pagination-item a {
    &::after {
      content: '';
      position: absolute;
      top: 1.5rem;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0.2rem;
      background-color: ${colors.secondary};
      opacity: 0;
      transition: opacity 0.3s linear, width 0.1s linear;
    }
  }

  .ant-pagination-item-active a {
    &::after {
      opacity: 1 !important;
      width: 0.8rem;
    }
  }

  .ant-pagination-item,
  .ant-pagination-item * {
    position: relative !important;
    background-color: transparent !important;
    border: none !important;
    min-width: 1rem !important;
    font-weight: 400 !important;
    color: ${colors.black} !important;

    &:hover,
    &:active,
    &:focus {
      color: ${colors.black} !important;
    }
  }

  .ant-pagination-item-ellipsis {
    color: ${colors.black} !important;
  }

  .anticon-double-left,
  .anticon-double-right {
    color: ${colors.black} !important;
  }

  .ant-pagination-item-active,
  .ant-pagination-item-active * {
    font-weight: 600 !important;
  }

  .ant-pagination-next,
  .ant-pagination-prev {
    min-width: initial !important;
    width: 1.5rem !important;
    height: 1.5rem !important;

    & button {
      border: none !important;
      outline: none !important;
      box-shadow: 1px 1px 10px ${colors.darkGray};
      display: flex;
      align-items: center;
      justify-content: center;

      & span {
        color: ${colors.black} !important;
      }
    }
  }
`;

export const Header = styled.thead`
  background-color: ${colors.persianGreen};
  box-shadow: 5px 5px 20px #00000029;
  border-radius: 15px;
  ${containerBase}
`;

export const Body = styled.tbody`
  ${containerBase}
`;

export const Row = styled.tr`
  display: flex;
  width: 98%;

  &:nth-child(2n) {
    background-color: #f7f8fa;
    border-radius: 15px;
  }
`;

const cellBase = css`
  display: flex;
  align-items: center;
  padding: 0.8125rem 1rem;
  flex-grow: 1;

  max-width: ${({ span, columnSpans }) => {
    const sumTotal = columnSpans.reduce((a, b) => (a || 1) + (b || 1));
    return `${((span || 1) * 100) / sumTotal}%`;
  }};

  ${({ justify }) => {
    const directionMap = {
      right: 'flex-end',
      left: 'flex-start',
      middle: 'center',
    };

    if (justify && directionMap[justify]) {
      return css`
        justify-content: ${directionMap[justify]};
      `;
    }

    return null;
  }}
`;

export const HeaderCell = styled.th`
  ${cellBase}
  color: white;
  font-weight: 600;
  font-size: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;

export const Cell = styled.td`
  ${cellBase}
  color: ${colors.grayText};
  font-size: 0.875rem;
`;

export const LoadingRow = styled.tr`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 98%;
  margin: 1rem 0;
`;

export const Loading = styled(Spin)`
  .anticon {
    position: relative;
  }
`;
