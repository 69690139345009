import queryString from 'query-string';

export const calculateNewSearchParameter = (location, filterName, options) => {
  const filterObj = queryString.parse(location.search, { arrayFormat: 'comma' });

  const valueArray = options.map(({ value }) => value);

  filterObj[filterName] = valueArray;

  return queryString.stringify(filterObj, { arrayFormat: 'comma'});
}
