import moment from 'moment';

import { Bold } from './ManagerResidentEdit.style';

const statusObject = {
  hold: 'Em espera',
  opened: 'Em aberto',
  canceled: 'Cancelado',
  concluded: 'Concluído',
};

export const columns = {
  unifiedAttendance: [
    {
      title: 'Solicitante',
      dataIndex: 'requester',
      key: 'requester',
      span: 1,
      render: ({ name }) => <Bold>{name}</Bold>,
    },
    {
      title: 'Técnica',
      dataIndex: 'user',
      key: 'user',
      span: 1,
      render: (user) => user?.name || '',
    },
    {
      title: 'Data e horário',
      dataIndex: 'startDate',
      key: 'startDate',
      span: 1,
      render: (timestamp) => moment(timestamp).format('DD/MM/YYYY [às] HH:mm'),
    },
    {
      title: 'Categoria do Atendido',
      dataIndex: 'requester',
      key: 'requester',
      span: 1,
      render: (requester) => requester?.typeRequester?.name,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      span: 0.75,
      render: (status) => statusObject[status],
    },
    {
      title: 'Ver detalhes',
      dataIndex: 'details',
      key: 'details',
      align: 'middle',
      span: 1,
    },
  ],
};
