import { Button } from 'antd';
import styled, { css } from 'styled-components';

import background from 'assets/images/data_confirmation_background.png';
import { TextInput } from 'components/inputs';
import colors from 'styles/colors';
import { respondTo, respondToMin } from 'styles/mixins';

export const Page = styled.div`
  display: flex;
  min-height: 100vh;
  ${respondToMin('medium')(css`
    font-size: 2rem;
    background: url(${background}) no-repeat;
    background-size: cover;
    background-position: center;
  `)}
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  margin-bottom: 2rem;
  font-weight: 900;
  text-align: center;
  font-size: 2rem;
`;

export const Container = styled.section`
  width: 100%;
  height: fit-content;
  z-index: 10;
  ${respondToMin('medium')(css`
    width: 35%;

    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 60%;
  `)}
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 2rem;
  height: 80%;
`;

export const Label = styled.span`
  color: #727272;
  font-weight: normal;
  font-size: 1rem;
  display: flex;
  margin-top: 1.5rem;
`;

export const StyledTextInput = styled(TextInput)`
  margin-top: 0;
`;

export const Icon = styled.img`
  display: flex;
  position: relative;
  width: 2.75vw;
  top: -6vw;
  right: -81vw;

  ${respondToMin('medium')(css`
    width: 0.52vw;
    top: -1.3vw;
    right: -25vw;
  `)}
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 -2rem;
  margin-top: 3rem;
  ${respondTo('medium')(css`
    margin-top: 8rem;
  `)}
`;

export const Previous = styled.button`
  border: none;
  width: 8.19rem;
  height: 3.25rem;
  border-radius: 0 5.56vw 5.56vw 0;
  font-weight: 600;
  font-size: 1.13rem;
  background-color: #cccccc9b;
  text-transform: uppercase;

  &:focus {
    outline: none;
  }

  ${respondToMin('medium')(css`
    border-radius: 2.08vw;
    width: 12.5vw;
    height: 3rem;
    border: 1px solid #000000;
    background-color: transparent;
    box-shadow: none;
    text-transform: none;

    &:before {
      content: '';
      position: absolute;
      border-radius: 2.1rem;
      display: flex;
      height: 3rem;
      width: 12.5vw;
      background-color: #cccccc;
      z-index: -1;
      margin: -0.7vw -0.3vw;
      box-shadow: 0 0 0.78vw #cccccc;
    }
  `)}

  ${respondToMin('medium')(css`
    font-size: 1.4rem;
  `)}
`;

export const Next = styled(Button)`
  width: 8.19rem;
  height: 3.25rem;
  border-radius: 5.56vw 0 0 5.56vw;
  font-weight: 600;
  font-size: 1.13rem;
  background-color: #ffcf8f !important;
  box-shadow: 0 0 4.17vw #ffcf8f;
  text-transform: uppercase;

  &:focus,
  &:active,
  &:hover {
    border: 1px solid ${colors.black} !important;
    color: black !important;
  }

  ${({ loading }) =>
    loading &&
    css`
      color: rgba(0, 0, 0, 0.5) !important;
      background-color: transparent !important;
    `}

  ${respondToMin('medium')(css`
    border-radius: 2.08vw;
    width: 12.5vw;
    height: 3rem;
    border: 1px solid #000000;
    background-color: transparent;
    box-shadow: none;
    text-transform: none;

    &:before {
      content: '';
      position: absolute;
      border-radius: 2.08vw;
      display: flex;
      height: 3rem;
      width: 12.5vw;
      background-color: #ffcf8f;
      z-index: -1;
      margin: -0.7vw -0.3vw;
      box-shadow: 0 0 0.78vw #ffcf8f !important;
    }
  `)}

  ${respondToMin('medium')(css`
    font-size: 1.5rem;
  `)}
`;

export const Subtitle = styled.div`
  font-size: 1.13rem;
  font-weight: normal;
`;
