import { useHistory } from 'react-router-dom';
import moment from 'moment';

import Citizen from 'assets/icons/citizen-icon.svg';
import { UnifiedAttendanceDetailButton } from 'components';

import {
  AnchorLinkText,
  Bold,
  Card,
  CitizenData,
  CitizenIcon,
  CitizenText,
  Content,
  Footer,
  InfoText,
  LinkText,
  Name,
  SecondaryText,
  SecondTitle,
  Text,
  Title,
} from './ScheduleCard.style';

function ScheduleCard(props) {
  const {
    forwarding,
    margin,
    requester = {},
    startDate = '',
    content,
    getSchedules,
    isOnline,
  } = props;

  const history = useHistory();

  const handleRedirect = () =>
    history.push(`/central-do-morador/${requester?.id}`);

  return (
    <Card margin={margin}>
      <Content>
        <Title>{moment(startDate).format('dddd, DD/MM/YYYY')}</Title>
        <Text>{moment(startDate).format('HH[h]mm[min]')}</Text>
        <SecondTitle>{forwarding}</SecondTitle>
        <SecondaryText>
          <CitizenIcon src={Citizen} alt="Avatar do solicitante" />
          {requester?.typeRequester?.name}
        </SecondaryText>
        <CitizenData>
          <CitizenText>
            <Name onClick={handleRedirect}>{requester?.name}</Name>
            <InfoText>
              <Bold>{'CPF: '}</Bold>
              {requester?.cpf}
            </InfoText>
            <InfoText>
              <Bold>{'Área/empreendimento: '}</Bold>
              {requester?.area?.name}
            </InfoText>
            <InfoText>
              <Bold>{'Regional: '}</Bold>
              {requester?.region?.name}
            </InfoText>
            <InfoText>
              {isOnline ? (
                <AnchorLinkText href={content} target="_blank">
                  Link da Reunião
                </AnchorLinkText>
              ) : (
                <>
                  <Bold>{'Local do atendimento: '}</Bold>
                  <LinkText>{content}</LinkText>
                </>
              )}
            </InfoText>
          </CitizenText>
        </CitizenData>
      </Content>
      <Footer>
        <UnifiedAttendanceDetailButton get={getSchedules} content={props} />
      </Footer>
    </Card>
  );
}

export default ScheduleCard;
