import { useEffect, useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { Row } from 'antd';

import {
  BackPathButton,
  DocumentList,
  EditDataBoard,
  FilterButton,
  NewUnifiedAttendanceButton,
  PageTable,
  ResidentEditTabs,
  UnifiedAttendanceDetailButton,
} from 'components';
import useCrud from 'hooks/use-crud';
import useRequest from 'hooks/use-request';

import { columns } from './columns';
import { filters } from './filters';
import { usersInputs } from './inputs';
import {
  AntdCol,
  ButtonsContainer,
  Container,
  PhotoContainer,
  TableWrapper,
} from './ManagerResidentEdit.style';

const ManagerResidentEdit = ({ history }) => {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [section, setSection] = useState('unifiedAttendance');
  const [tableData, setTableData] = useState([]);
  const request = useRequest();
  const queryParams = new URLSearchParams(document.location.search);

  const downloadUnifiedAttendance = async () => {
    const searchParams = new URLSearchParams(queryParams);

    console.log(searchParams);

    if (!searchParams.has('rangeStartDate')) {
      const currentDate = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        23,
        59,
        59
      );

      const lastThreeMonths = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - 3,
        currentDate.getDate(),
        0,
        0,
        0
      );

      searchParams.set('rangeStartDate', [
        lastThreeMonths.toISOString(),
        currentDate.toISOString(),
      ]);
    }

    const getSearchParameters = () => {
      const aux = [];

      searchParams.forEach((value, key) => {
        aux.push(`${key}=${value}`);
      });

      return aux.join('&');
    };

    await request.get({
      url: `/unified-attendance/export-xlsx?requestersIds=${id}&${
        getSearchParameters().length && getSearchParameters()
      }`,
      config: {
        responseType: 'arraybuffer',
      },
      onSuccess: (data, res) => {
        const file = new Blob([res.data]);
        const reader = new FileReader();

        const hiddenElement = document.createElement('a');

        reader.addEventListener('load', () => {
          hiddenElement.href = reader.result;
          hiddenElement.target = '_blank';
          hiddenElement.download = 'atendimentos.xlsx';
          hiddenElement.click();
        });

        reader.readAsDataURL(file);
      },
      notificationLevel: 'none',
    });
  };

  const [unifiedAttendance, { get, getSpecificPage, itemsAmount, loading }] =
    useCrud(`/unified-attendance?requestersIds=${id}`, {
      autoinit: true,
      pagination: true,
      trackQueryParameters: true,
    });

  useEffect(() => {
    setTableData(unifiedAttendance || []);
  }, [unifiedAttendance]);

  useEffect(() => {
    const fetchData = async () => {
      await request.get({
        url: `/requester/${id}`,
        onSuccess: (data) => {
          setUser(data[0]);
        },
        onError: () => {
          history.push('/central-do-morador');
        },
      });
    };

    fetchData();

    const open = queryParams.get('open');

    if (open === 'documents') {
      setSection('documents');
    }
  }, []);

  const changeSection = (newSection) => {
    setSection(newSection);
  };

  return (
    <Container className="managerEditSection">
      <Row gutter={[50, 35]}>
        <BackPathButton path="/central-do-morador" />
      </Row>

      <Row gutter={[50, 35]}>
        <AntdCol xs={{ span: 24 }} lg={{ span: 22 }}>
          <PhotoContainer>
            <ResidentEditTabs
              changeSection={changeSection}
              activeSection={section}
            />
          </PhotoContainer>
        </AntdCol>
      </Row>

      <Row justify="start" gutter={[50, 35]}>
        <AntdCol xs={{ span: 24 }} lg={{ span: 22 }}>
          {['responsableData', 'secondPersonData'].includes(section) && (
            <EditDataBoard
              user={user}
              setUser={setUser}
              path={`requester/${id}`}
              boardTitle="Dados do solicitante"
              inputs={usersInputs[section]}
              activeSection={section}
            />
          )}
          {['unifiedAttendance'].includes(section) && (
            <TableWrapper>
              <ButtonsContainer>
                <NewUnifiedAttendanceButton updateList={get} />
                <FilterButton filters={filters} />
              </ButtonsContainer>
              <PageTable
                columns={columns[section]}
                extras={{
                  details: (content) => (
                    <UnifiedAttendanceDetailButton
                      content={content}
                      get={get}
                    />
                  ),
                  download: downloadUnifiedAttendance,
                }}
                dataSource={tableData}
                loading={loading}
                get={getSpecificPage}
                itemsAmount={itemsAmount}
              />
            </TableWrapper>
          )}
          {section === 'documents' && <DocumentList />}
        </AntdCol>
      </Row>
    </Container>
  );
};

export default withRouter(ManagerResidentEdit);
