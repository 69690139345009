const shouldNotificate = (requiredLevel, requestedLevel) => {
  if (
    !notificationLevels.includes(requiredLevel)
    || !notificationLevels.includes(requestedLevel)
  ) {
    throw new Error(`Invalid notification level '${requiredLevel}'`);
  }

  const getLevelValue = (level) => notificationLevels.indexOf(level);

  const requiredValue = getLevelValue(requiredLevel);
  const requestedValue = getLevelValue(requestedLevel);

  return requiredValue >= requestedValue;
}

export const notificationLevels = ['none', 'error', 'all'];

export default shouldNotificate;
