import { useEffect } from 'react';

import { useModal, withModalProvider } from 'contexts/ModalProvider';

import { InitialImage, Wrapper } from './ImageWithZoom.style';

function ImageWithZoom({
  src,
  alt = '',
  showNormalImage = true,
  active = false,
  customPreview = false,
  customPreviewUrl = '',
  customPreviewThrigger = false,
  onClose = () => {},
}) {
  const { modal } = useModal();

  const handleZoom = () => {
    const modalProps = {
      title: null,
      footer: null,
      destroyOnClose: true,
      afterClose: onClose,
      maskClosable: false,
    };

    modal(
      (props) => (
        <InitialImage src={src} onClick={handleZoom} alt={alt} {...props} />
      ),
      modalProps,
      'zoomImageModal'
    );
  };

  useEffect(() => {
    if (customPreviewThrigger) {
      handleZoom();
    }
  }, [customPreviewThrigger]);

  useEffect(() => {
    if (active) {
      handleZoom();
    }
  }, [active]);

  if (!showNormalImage) return null;

  return (
    <Wrapper>
      <InitialImage
        src={customPreview ? customPreviewUrl : src}
        onClick={handleZoom}
        customPreview={customPreview}
        alt={alt}
      />
    </Wrapper>
  );
}

export default withModalProvider(ImageWithZoom);
