const getInitialValues = (inputs, values = {}) => {
  const initialValues = { ...values };

  inputs.forEach((input) => {
    if (!initialValues[input.name]) {
      initialValues[input.name] = '';
    }
  });

  return initialValues;
};

export default getInitialValues;
