import { EllipsisOutlined } from '@ant-design/icons';
import styled, { css } from 'styled-components';

import FloatingLabelSelect from 'components/inputs/FormSelect/FloatingLabelSelect';
import TextField from 'components/inputs/TextField';
import colors from 'styles/colors';

export const Input = styled(TextField)`
  margin: 1.75rem 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const FormButtonContainer = styled.div`
  width: 100%;
  margin: 1.875rem 0 0.375rem;
  display: flex;
  justify-content: space-around;
`;

export const Select = styled(FloatingLabelSelect)`
  margin: 1.8125rem 0;
`;

export const OptionButton = styled.button`
  background-color: ${colors.persianGreen};
  color: white;
  font-size: 0.875rem;
  border-radius: 50px;
  font-weight: 600;
  min-height: 1.6875rem;
  padding: 0.3125rem 1.25rem;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  line-height: 1.0625rem;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      background-color: ${colors.disabledGray};
    `}
`;

export const ModalCloseContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const ModalClose = styled(EllipsisOutlined)`
  transform: rotateZ(90deg);
  width: 1.25rem;
  height: 1.25rem;

  path {
    fill: black;
  }
`;

export const ModalOptions = styled.div`
  width: 9.375rem;
  height: 9.375rem;
  max-height: 0rem;
  max-width: 0rem;
  transition: max-height 0.3s ease-in, max-width 0.3s ease-in;
  background-color: white;
  box-shadow: 0px 0px 10px #00000030;
  position: absolute;
  top: 2.25rem;
  right: 1rem;
  z-index: 10;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ${({ active }) =>
    active &&
    css`
      max-width: 9.375rem;
      max-height: 9.375rem;
    `}
`;

export const OptionsItem = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  border: none;
  height: 3.125rem;
  width: 9.375rem;
  background-color: white;
  outline: none;
  font-size: 1rem;
  color: ${colors.smoothBlack};
  font-weight: 600;

  &:hover {
    background-color: #e6e6e6;
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      cursor: not-allowed;
      background-color: #eee;
      opacity: 0.8;
    `}

  ${({ $delete }) =>
    $delete &&
    css`
      color: #b23030;
    `}
`;

export const OptionsIcon = styled.img`
  width: 0.9365rem;
  height: 0.9365rem;
  margin: 0 0.9365rem 0 1.25rem;
`;

export const UneditableInfoContainer = styled.div`
  width: 100%;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;

  & > h2 {
    color: ${colors.textBlue};
    font-size: 0.9rem;
    margin-bottom: 0;
  }

  & > p {
    color: ${colors.smoothBlack};
    font-size: 0.9rem;
    margin-bottom: 0;
    margin-left: 1rem;
  }
`;
