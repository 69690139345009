import { useState } from 'react';

import { Spin } from 'components';
import FileInput from 'components/FileInput/FileInput';
import colors from 'styles/colors';

import {
  FileInputWrapper,
  FormButtonsContainer,
  RoundButton,
} from './ImportResidentsModal.style';

function ImportResidentsModal({ create, closeModal }) {
  const [fileList, setFileList] = useState(null);
  const [loading, setLoading] = useState(false);

  return (
    <>
      {loading ? (
        <FileInputWrapper>
          <p>Aguarde, isto pode demorar um pouco.</p>
          <Spin font={36} />
        </FileInputWrapper>
      ) : (
        <FileInput
          fileType=".xlsx"
          name="file"
          onChange={(file) => {
            if (file.fileList.length > 0) setFileList(file);
            else setFileList(null);
          }}
          maxCount={1}
        />
      )}
      <FormButtonsContainer>
        <RoundButton onClick={closeModal} backgroundColor={colors.gray}>
          Cancelar
        </RoundButton>
        <RoundButton
          onClick={() => {
            create(fileList);
            setLoading(true);
          }}
          backgroundColor={colors.green}
          textColor="white"
          disabled={!fileList || loading}
        >
          Cadastrar Solicitantes
        </RoundButton>
      </FormButtonsContainer>
    </>
  );
}

export default ImportResidentsModal;
