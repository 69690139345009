import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';

import {
  Can,
  FilterButton,
  PageTable,
  TablesSearchBar,
  TitledPage,
} from 'components';
import { useModal, withModalProvider } from 'contexts/ModalProvider';
import useCrud from 'hooks/use-crud';
import { USER_INFO } from 'variables';

import CreateUserModal from './CreateUserModal';
import { filters, searchParameters } from './filters';
import {
  Bold,
  Button,
  ButtonContainer,
  FiltersContainer,
  OptionButton,
} from './Users.style';

function Users() {
  const history = useHistory();
  const [cookies] = useCookies([USER_INFO]);
  const [users, { create, get, getSpecificPage, itemsAmount, loading }] =
    useCrud('/user', {
      autoinit: true,
      trackQueryParameters: true,
      pagination: true,
    });
  const { modal } = useModal();

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      render: (name) => <Bold>{name}</Bold>,
    },
    {
      title: 'Regional',
      dataIndex: 'region',
      key: 'region',
      render: (region) => region?.name || '',
    },
    {
      title: 'Tipo de Operador',
      dataIndex: 'role',
      key: 'category',
      render: (role) => {
        const translateMap = {
          technique: 'Equipe Técnica',
          admin: 'Administrador',
          supervisor: 'Supervisor',
          coordinator: 'Coordenador',
          attendant: 'Atendente',
        };
        return translateMap[role] || 'Desconhecida';
      },
    },
    {
      title: 'Área / Empreendimento',
      dataIndex: 'areas',
      key: 'user-type',
      render: (areasArray) => {
        const areaNames = areasArray.map(({ name }) => name);

        return areaNames.join(', ');
      },
    },
    {
      title: 'Ver detalhes',
      dataIndex: 'details',
      key: 'details',
      align: 'middle',
      span: 1.25,
    },
  ];

  const seeDetailsButton = ({ id }) => (
    <OptionButton onClick={() => history.push(`/usuarios/${id}`)}>
      Ver detalhes
    </OptionButton>
  );

  const handleCreateUser = () => {
    const modalProps = {
      title: 'Adicionar usuário',
      footer: null,
      watchTouched: true,
      maskClosable: false,
    };
    modal(
      (props) => <CreateUserModal create={create} {...props} />,
      modalProps
    );
  };

  const redirectToActiveUserEdit = () => {
    history.push(`/usuarios/${cookies[USER_INFO].id}`);
  };

  const titleButtons = [() => <FilterButton filters={filters} />];

  return (
    <TitledPage
      justifyButtons="flex-end"
      buttons={titleButtons}
      title="Usuários"
    >
      <ButtonContainer>
        <Can perform={['users:create']}>
          <Button onClick={handleCreateUser}>Criar Usuário</Button>
        </Can>
        <Button onClick={redirectToActiveUserEdit}>Alterar meus dados</Button>
      </ButtonContainer>
      <FiltersContainer>
        <TablesSearchBar
          placeholder="Pesquise por nome completo"
          requestFunction={get}
          queryIds={searchParameters}
        />
      </FiltersContainer>
      <PageTable
        dataSource={users}
        columns={columns}
        loading={loading}
        get={getSpecificPage}
        itemsAmount={itemsAmount}
        extras={{ details: seeDetailsButton }}
      />
    </TitledPage>
  );
}

export default withModalProvider(Users);
