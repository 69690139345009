import { useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';

import {
  AdminMapContainer,
  AutocompleteInput,
  CityTag,
  CityTagTitle,
  MapTitle,
  MapWrapper,
} from './AdminMap.style';
import Map from './Map';

const AdminMap = ({ data: requestData }) => {
  const [value, setValue] = useState('');
  const [tagTitle, setTagTitle] = useState('São Paulo, SP, BR');
  const [options, setOptions] = useState([]);
  const [emptyQueryOptions, setEmptyQueryOptions] = useState([]);
  const [inputFilterList, setInputFilterList] = useState([]);
  const [areaToFly, setAreaToFly] = useState('');

  const { width, height, ref } = useResizeDetector({
    refreshMode: 'debounce',
    refreshRate: 200,
  });

  const suggestOptions = (text) => {
    const inputString = text
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
    return inputFilterList
      .filter(
        (listVal) =>
          listVal.value
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .indexOf(inputString) !== -1
      )
      .slice(0, 3);
  };

  const onSearch = (searchText) => {
    setOptions(!searchText ? emptyQueryOptions : suggestOptions(searchText));
  };

  const onSelect = (data) => {
    setAreaToFly(data);
  };

  const onChange = (data) => {
    setValue(data);
  };

  return (
    <AdminMapContainer>
      <MapTitle>
        Pesquise aqui por áreas/empreendimentos e veja o status dos chamados
      </MapTitle>

      <AutocompleteInput
        value={value}
        options={options}
        onChange={onChange}
        onSelect={onSelect}
        onSearch={onSearch}
        placeholder="Pesquise por uma área / empreendimento"
      />

      <MapWrapper ref={ref}>
        <CityTag>
          <CityTagTitle>{tagTitle}</CityTagTitle>
        </CityTag>
        <Map
          setInputFilterList={setInputFilterList}
          setOptions={setOptions}
          setEmptyQueryOptions={setEmptyQueryOptions}
          areaToFly={areaToFly}
          setTagTitle={setTagTitle}
          containerWidth={width}
          containerHeight={height}
          requestData={requestData}
        />
      </MapWrapper>
    </AdminMapContainer>
  );
};

export default AdminMap;
