import compareArrays from "./compareArrays";

const getDiffBetweenObjects = (base, altered) => {
  const alteredEntries = Object.entries(altered);

  const validEntries = alteredEntries.filter(([key, value]) => {
    if (Array.isArray(value) && Array.isArray(base[key])) {
      return !compareArrays(value, base[key]);
    } 

    return value !== base[key];
  });

  return Object.fromEntries(validEntries);
};

export default getDiffBetweenObjects;
