import { useEffect, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { Spin } from 'antd';

import socket from 'api/socket';
import CallDataProvider from 'contexts/CallDataProvider';
import useCrud from 'hooks/use-crud';
import useRequest from 'hooks/use-request';

import { Container, InputSkeleton } from './Chat.style';
import Header from './Header';
import Input from './Input';
import MessageList from './MessageList';
import SearchDrawer from './SearchDrawer';

function Chat({ callData, openDrawer, isUserAllowed }) {
  const [searchDrawerVisible, setSearchDrawerVisible] = useState(false);
  const [messageToScroll, setMessageToScroll] = useState('');
  const [scrollFlag, setScrollFlag] = useState(false);
  const { width, ref } = useResizeDetector();
  const request = useRequest();
  const [
    messages,
    { get, create, getMore, hasMore, getOne, setData, setPage },
  ] = useCrud('/message', {
    invertedList: true,
    pagination: { itemsPerPage: 10 },
  });

  useEffect(() => {
    socket.on('new-message', ({ callId, messageId }) => {
      if (callId !== callData?.id) return;
      if (messages.map(({ id }) => id).includes(messageId)) return;

      getOne(messageId, { concatenate: true });
    });

    return () => {
      socket.off('new-message');
    };
  }, [callData, messages]);

  useEffect(() => {
    if (callData?.id) {
      get({ callId: callData.id });
    }
  }, [callData]);

  const handleMore = () => {
    getMore({ callId: callData?.id });
  };

  const requestCallMessages = async (callId, messageId) => {
    if (!messages.filter(({ id }) => id === messageId).length) {
      try {
        await request.get({
          url: '/message',
          config: {
            params: {
              callId,
            },
          },
          onSuccess: (data) => {
            let messageIndex = 0;

            data.every((message, index) => {
              if (message.id === messageId) {
                messageIndex = index;
                return false;
              }
              return true;
            });

            const messagesList = data.slice(0, messageIndex + 11);

            setData(messagesList);
            setPage(messagesList.length / 10 - 1);
          },
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleMessageSelect = async (messageId) => {
    await requestCallMessages(callData?.id, messageId);
    setSearchDrawerVisible(false);
    setMessageToScroll(messageId);
    setScrollFlag(!scrollFlag);
  };

  return (
    <CallDataProvider value={callData} name="test">
      <Container ref={ref}>
        <SearchDrawer
          visible={searchDrawerVisible}
          onClose={() => setSearchDrawerVisible(false)}
          onMessageSelect={handleMessageSelect}
          width={width}
          callId={callData?.id}
        />
        <Header
          openDrawer={openDrawer}
          setSearchDrawer={setSearchDrawerVisible}
        />
        <MessageList
          messages={messages}
          getMore={handleMore}
          hasMore={hasMore}
          messageToScroll={messageToScroll}
          scrollFlag={scrollFlag}
        />
        {isUserAllowed && callData?.status !== 'concluded' && (
          <Input submitMessage={create} />
        )}
        {isUserAllowed === false && callData?.status !== 'concluded' && (
          <InputSkeleton>
            Você não pode enviar mensagens nesse chamado
          </InputSkeleton>
        )}
        {callData?.status === 'concluded' && (
          <InputSkeleton>O chamado foi concluido</InputSkeleton>
        )}
        {isUserAllowed === null && (
          <InputSkeleton>
            <Spin />
          </InputSkeleton>
        )}
      </Container>
    </CallDataProvider>
  );
}

export default Chat;
