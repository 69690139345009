import PersonImage from 'assets/images/userDefaultImage.svg';

import {
  AddPhoto,
  CameraIcon,
  IconWrapper,
  Picture,
  Wrapper,
} from './ProfileImage.style';

function ProfileImage({ path, mobile }) {
  return (
    <Wrapper>
      <IconWrapper>
        <CameraIcon mobile={mobile} />
        <Picture
          mobile={mobile}
          src={path || PersonImage}
          alt="Avatar do solicitante"
        />
      </IconWrapper>
      {mobile && <AddPhoto>Adicione foto</AddPhoto>}
    </Wrapper>
  );
}

export default ProfileImage;
