import searchSvg from 'assets/icons/chat-search.svg';
import moreSvg from 'assets/icons/more.svg';
import { BackPathButton } from 'components';
import { useCallData } from 'contexts/CallDataProvider';

import {
  Button,
  Container,
  Icon,
  IconContainer,
  RequesterName,
  RequesterNameContainer,
} from './Header.style';

function Header({ openDrawer, setSearchDrawer }) {
  const callData = useCallData();

  const openSearchDrawer = () => setSearchDrawer(true);

  return (
    <Container>
      <RequesterNameContainer>
        <BackPathButton path="/chamados" />
        <RequesterName>{callData?.requester?.name}</RequesterName>
      </RequesterNameContainer>
      <IconContainer>
        <Button type="button" onClick={openSearchDrawer}>
          <Icon src={searchSvg} />
        </Button>
        <Button type="button" hideOnLarge onClick={openDrawer}>
          <Icon src={moreSvg} />
        </Button>
      </IconContainer>
    </Container>
  );
}

export default Header;
