import styled, { css } from 'styled-components';

import { Button as AntButton } from 'components/inputs';
import colors from 'styles/colors';
import { respondTo } from 'styles/mixins';

export const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 1rem;

  ${respondTo('small')(css`
    align-items: center;
    flex-direction: column-reverse;
  `)}
`;

export const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;

  & > span {
    margin-bottom: 0;
  }

  ${respondTo('medium')(css`
    padding-right: 1rem;
  `)}
`;

export const Button = styled(AntButton)`
  width: max-content;
  font-size: 1rem;
  margin: 0 0.875rem;
  padding: 0 2rem;
  height: 3.125rem;
  margin-bottom: 2.5rem;

  ${respondTo('medium')(css`
    margin: 0 1rem 1rem 0;
  `)}
`;

export const SubTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: 0.03rem;
`;

export const Count = styled.p`
  font-size: 1.125rem;
  font-weight: 600;
`;

export const Bold = styled.p`
  margin: 0;
  color: ${colors.smoothBlack};
  font-weight: 600;
`;

export const OptionContainer = styled(ButtonContainer)`
  min-width: 14.0625rem;
  justify-content: space-between;
`;
