import styled, { css } from 'styled-components';

import { respondTo } from 'styles/mixins';

export const List = styled.ul`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, 30rem);
  grid-gap: 2.1875rem;
  align-items: center;

  ${respondTo('medium')(css`
    grid-template-columns: none;
    display: grid;
  `)}
`;
