import { Route } from 'react-router-dom';

import { Can, Loading as LoadingSpinner } from 'components';
import { useUserInfo } from 'contexts';

function ProtectedRoute({ exact, path, component, action }) {
  const { userData, loading } = useUserInfo();
  if (!userData || loading) return <LoadingSpinner />;

  return (
    <Can perform={action}>
      <Route exact={exact} path={path} component={component} />
    </Can>
  );
}

export default ProtectedRoute;
