import wrapMethod from './wrapMethod';

const instanceWrapper = (instance, notificationService) => ({
  get: wrapMethod(instance, 'get', notificationService),
  post: wrapMethod(instance, 'post', notificationService),
  patch: wrapMethod(instance, 'patch', notificationService),
  delete: wrapMethod(instance, 'delete', notificationService),
});

export default instanceWrapper;
