import styled, { css } from 'styled-components';

import backIcon from 'assets/icons/back.png';
import { respondToMin } from 'styles/mixins';

export const Page = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: #f8f8f8;
  z-index: 1;
  padding: 8.33vw 7.22vw 25vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;

  ${respondToMin('medium')(css`
    padding: 2.5vw 2.4vw;
  `)}
`;

export const BackButton = styled.button`
  background: url(${backIcon}) no-repeat left center;
  background-size: 5.62vw;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  padding: 0 0 0 8vw;
  align-self: baseline;

  &:focus {
    outline: none;
  }

  ${respondToMin('medium')(css`
    background-size: 1.24vw;
    font-size: 1.13rem;
    padding: 0 0 0 2vw;
  `)}
`;
