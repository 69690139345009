import { Col } from 'antd';
import styled from 'styled-components';

export const Container = styled.section`
  margin: 2.25rem 3rem;
`;

export const AntdCol = styled(Col)`
  padding: 0 !important;
`;

export const PhotoContainer = styled.div`
  width: 100%;
  height: 10rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;
